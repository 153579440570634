<template>
  <div
    class="popover_play"
    tabIndex="0"
    @blur="handleClosePopOver()"
  >
    <template v-if="isTimerRunning">
      <div
        class="popover_play_trigger timer"
        style="color: white"
        @mouseleave="hovering = false"
      >
        <div
          :class="hovering ? 'hovering popover-timer-container' : 'popover-timer-container'"
          @mouseenter="hovering = true"
          @click="handleClickStopButton()"
        >
          <StopTimerIcon />
          <span
            :class="
              timer.blink.class ? 'popover_play_current_time blink' : 'popover_play_current_time'
            "
          >
            {{ `${getCurrentTime.hours}:${getCurrentTime.minutes}:${getCurrentTime.seconds}` }}
          </span>
        </div>
        <span
          v-if="timer.title"
          class="popover_play_project"
        >
          <div class="popover_separator" />
          <span
            v-b-tooltip.hover="{
              title: timer.title,
              boundary: 'window',
              disabled: disableTooltipTitle,
              customClass: `width-tooltip ${!hovering ? 'hide-tooltip' : ''}`
            }"
            class="popover_project_name"
            >{{ timer.title }}</span
          >
        </span>
      </div>
      <div
        v-if="isOpen"
        class="popover_play_content timer"
      >
        <span @click="handleRegisterAppointment()"> <StopTimerIcon />Finalizar Temporizador</span>
        <span @click="handleDiscardTimer()">
          <IconTrash class="icon-trash" /> Descartar Temporizador
        </span>
        <span @click="handleRegisterAppointmentManualWithTimer()">
          <ClockIcon />Visualizar Apontamentos</span
        >
      </div>
    </template>
    <template v-else>
      <Dropdown
        @startManualTimer="startManualTimer"
        @handleManualAppointment="handleManualAppointment"
      />
    </template>
  </div>
</template>

<script>
  import ClockIcon from "@/assets/images/clock.svg";
  import StopTimerIcon from "@/assets/images/icon-stop-timer.svg";
  import IconTrash from "@/assets/images/icon-trash2.svg";
  import IconPencil from "@/assets/images/icon-pencil2.svg";
  import { toDate, isToday, set, format } from "date-fns";
  import { mapActions, mapMutations, mapState } from "vuex";
  import { getCurrentTimeFormatted } from "@/utils/currentTime";

  import Dropdown from "@/components/DropdownPlay.vue";

  export default {
    props: ["startTimerByCardProject", "resetTimerPopOver"],
    components: {
      ClockIcon,
      StopTimerIcon,
      IconTrash,
      Dropdown,
      IconPencil
    },
    data() {
      return {
        disableTooltipTitle: true,
        hovering: false,
        isOpen: false,
        isTimerRunning: false,
        tabActive: true,
        timer: {
          projectId: null,
          funcionalityId: null,
          title: null,
          current: null,
          startTime: null,
          paused: {
            status: false,
            pausedAt: null
          },
          hasStartedToday: true,
          blink: {
            interval: null,
            class: ""
          }
        },
        interval: null,
        timerInfos: null
      };
    },
    updated() {
      let tooltipProjectName = this.$el.querySelector(".popover_project_name");
      let isWidthScrollSmallerClient =
        tooltipProjectName?.scrollWidth <= tooltipProjectName?.clientWidth;
      this.disableTooltipTitle = isWidthScrollSmallerClient;
    },
    mounted: async function () {
      this.fetchRunningTimer();
      if (typeof document.hidden !== "undefined") {
        document.addEventListener("visibilitychange", this.handleVisibilityChange);
      }
    },
    beforeDestroy() {
      document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    },
    methods: {
      ...mapActions("Appointments", [
        "ActionContextProject",
        "ActionContextFuncionality",
        "ActionGetRunningTimer",
        "ActionStartTimer",
        "ActionStopTimer",
        "ActionDeleteTimer"
      ]),
      ...mapMutations("CompFeat", ["setIsTimerAppoint"]),
      ...mapMutations("Appointments", ["setTempStartTime"]),
      showDiscardTimerModal() {
        const appointmentIcon = `<svg class="appointment-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.75 10.375H2.25C1.90625 10.375 1.625 10.0938 1.625 9.75V2.25C1.625 1.90625 1.90625 1.625 2.25 1.625H5.375C5.71875 1.625 6 1.34375 6 1C6 0.65625 5.71875 0.375 5.375 0.375H1.625C0.93125 0.375 0.375 0.9375 0.375 1.625V10.375C0.375 11.0625 0.9375 11.625 1.625 11.625H10.375C11.0625 11.625 11.625 11.0625 11.625 10.375V6.625C11.625 6.28125 11.3438 6 11 6C10.6562 6 10.375 6.28125 10.375 6.625V9.75C10.375 10.0938 10.0938 10.375 9.75 10.375ZM7.25 1C7.25 1.34375 7.53125 1.625 7.875 1.625H9.49375L3.7875 7.33125C3.54375 7.575 3.54375 7.96875 3.7875 8.2125C4.03125 8.45625 4.425 8.45625 4.66875 8.2125L10.375 2.50625V4.125C10.375 4.46875 10.6562 4.75 11 4.75C11.3438 4.75 11.625 4.46875 11.625 4.125V1C11.625 0.65625 11.3438 0.375 11 0.375H7.875C7.53125 0.375 7.25 0.65625 7.25 1Z" fill="white"/></svg>`;
        this.$swal({
          customClass: {
            confirmButton: "timer-modal-confirm-button",
            closeButton: "timer-modal-close-button",
            container: "timer-modal-container",
            title: "timer-modal-title",
            cancelButton: "timer-modal-cancel-button"
          },
          title: "Descartar",
          iconHtml: `<svg viewBox="0 0 118 119" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.5 93.9167C29.5 99.325 33.925 103.75 39.3334 103.75H78.6667C84.0751 103.75 88.5 99.325 88.5 93.9167V34.9167H29.5V93.9167ZM39.3334 44.75H78.6667V93.9167H39.3334V44.75ZM76.2084 20.1667L71.2917 15.25H46.7084L41.7917 20.1667H24.5834V30H93.4167V20.1667H76.2084Z" fill="#A9B9C9"/></svg>`,
          html: "<p>Deseja realmente descartar o temporizador?</p>",
          confirmButtonText: `${appointmentIcon} Confirmar`,
          cancelButtonText: "Cancelar",
          allowOutsideClick: true
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            this.setTempStartTime(null);
            this.deleteCurrentTimer();
            this.resetTimer();
          } else {
            this.resumeVisualTimer();
          }
        });
      },
      handleVisibilityChange() {
        if (document.hidden) {
          this.tabActive = false;
        } else {
          this.tabActive = true;
        }
      },
      showPausedTimerModal() {
        const appointmentIcon = `<svg class="appointment-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.75 10.375H2.25C1.90625 10.375 1.625 10.0938 1.625 9.75V2.25C1.625 1.90625 1.90625 1.625 2.25 1.625H5.375C5.71875 1.625 6 1.34375 6 1C6 0.65625 5.71875 0.375 5.375 0.375H1.625C0.93125 0.375 0.375 0.9375 0.375 1.625V10.375C0.375 11.0625 0.9375 11.625 1.625 11.625H10.375C11.0625 11.625 11.625 11.0625 11.625 10.375V6.625C11.625 6.28125 11.3438 6 11 6C10.6562 6 10.375 6.28125 10.375 6.625V9.75C10.375 10.0938 10.0938 10.375 9.75 10.375ZM7.25 1C7.25 1.34375 7.53125 1.625 7.875 1.625H9.49375L3.7875 7.33125C3.54375 7.575 3.54375 7.96875 3.7875 8.2125C4.03125 8.45625 4.425 8.45625 4.66875 8.2125L10.375 2.50625V4.125C10.375 4.46875 10.6562 4.75 11 4.75C11.3438 4.75 11.625 4.46875 11.625 4.125V1C11.625 0.65625 11.3438 0.375 11 0.375H7.875C7.53125 0.375 7.25 0.65625 7.25 1Z" fill="white"/></svg>`;
        const closeButtonIcon = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_6458_7977)"><path d="M15.375 7.50625L14.4938 6.625L11 10.1188L7.50625 6.625L6.625 7.50625L10.1188 11L6.625 14.4938L7.50625 15.375L11 11.8812L14.4938 15.375L15.375 14.4938L11.8812 11L15.375 7.50625Z" fill="#006D3A"/></g><rect x="0.5" y="0.5" width="21" height="21" rx="10.5" stroke="#006D3A"/><defs><clipPath id="clip0_6458_7977"><rect width="15" height="15" fill="white" transform="translate(3.5 3.5)"/></clipPath></defs></svg>`;
        this.$swal({
          showCloseButton: true,
          closeOnClickOutside: false,
          buttonStyling: false,
          customClass: {
            closeButton: "timer-modal-close-button",
            container: "timer-modal-container",
            title: "timer-modal-title",
            popup: "swal-warning timer-modal-popup",
            confirmButton: "timer-modal-confirm-button",
            cancelButton: "timer-modal-cancel-button"
          },
          title: "Atenção",
          html: "<p>Seu temporizador estava ativo até 23:59h e foi pausado.</p><p> O que deseja?</p>",
          confirmButtonText: `${appointmentIcon} Registrar Apontamento`,
          cancelButtonText: "Descartar Temporizador",
          allowOutsideClick: true,
          closeButtonHtml: closeButtonIcon
        }).then((props) => {
          if (props.isConfirmed) {
            this.timer.hasStartedToday = false;
            this.handleRegisterAppointment();
          } else if (props.dismiss === "cancel") {
            this.deleteCurrentTimer();
            this.resetTimer();
          }
        });
      },
      async generateTimerTitle() {
        let currentTimer;
        if (!isToday(this.timer.startTime)) {
          currentTimer = await this.ActionStopTimer(format(this.timer.startTime, "yyyy-MM-dd"));
        } else {
          currentTimer = await this.ActionStopTimer();
        }

        const devopsId = currentTimer?.devopsId ?? null;
        const projectId = currentTimer?.projectId ?? null;
        const functionalityId = currentTimer?.funcionalityId ?? null;
        this.timer.projectId = projectId;
        this.timer.funcionalityId = functionalityId;

        if (!projectId && !functionalityId) {
          this.timer.title = null;
          return;
        }

        const title = {
          project: null,
          functionality: null,
          devops: null
        };

        if (projectId) {
          const context = await this.ActionContextProject();
          title.project = context.projects.find((item) => item.id === projectId)?.name;
        }

        if (functionalityId && projectId) {
          const context = await this.ActionContextFuncionality(projectId);
          title.functionality = context?.funcionalities.find(
            (item) => item.id === functionalityId
          )?.name;
        }

        if (devopsId) {
          title.devops = devopsId;
        }

        title.project = title.project ?? "";
        title.devops = title.devops ? " - " + title.devops : "";
        title.functionality = title.functionality ? " - " + title.functionality : "";
        this.timer.title = `${title.project}${title.devops}${title.functionality}`;
      },
      pauseVisualTimer() {
        if (!this.timer.paused.status) {
          this.timer.paused.status = true;
          this.timer.paused.pausedAt = Date.now();
          this.timer.current = Date.now() - this.timer.startTime;
        }
      },
      resumeVisualTimer() {
        this.timer.paused.status = false;
        this.timer.paused.pausedAt = null;
        this.timer.current = Date.now() - this.timer.startTime;
        this.startVisualTimer();
      },
      resetTimer() {
        this.isTimerRunning = false;
        this.isOpen = false;
        if (this.interval) {
          clearInterval(this.interval);
        }
        this.timer.current = null;
        this.timer.startTime = null;
        this.timer.paused.status = false;
      },
      async deleteCurrentTimer() {
        await this.ActionDeleteTimer();
      },
      handleDiscardTimer() {
        this.showDiscardTimerModal();
      },
      handleTriggerPopOver() {
        this.isOpen = !this.isOpen;
      },
      handleClickStopButton() {
        this.handleTriggerPopOver();
        this.pauseVisualTimer();
      },
      handleClosePopOver() {
        this.isOpen = false;
        if (!this.modalAppointment) {
          if (this.timer.paused.status == true) {
            this.resumeVisualTimer();
          }
        }
      },
      handleManualAppointment() {
        const params = this.$route.params;
        this.isOpen = false;
        this.$emit("openModalAppointment", "new");
        this.$emit("isTimerAppoint", false);
        this.$emit("setCurrentProjectId", params?.projectId);
        this.$emit("setCurrentFunctionalityId", params?.featureId);
        this.$emit("action", "new");
      },
      handleRegisterAppointment() {
        this.setIsTimerAppoint(true);
        this.isOpen = false;
        this.$emit("openModalAppointment", "edit");
        this.$emit("isTimerAppoint", true);
        this.$emit("setTimerInfo", this.timer);
      },
      handleRegisterAppointmentManualWithTimer() {
        this.isOpen = false;
        this.setIsTimerAppoint(false);
        this.$emit("openModalAppointment", "new");
        this.$emit("action", "new");
      },
      async startManualTimer() {
        // Ativar o timer manualmente pelo temporizador
        const currentParams = this.$route.params;
        const startTimer = getCurrentTimeFormatted();
        this.setTempStartTime(startTimer);
        const timer = await this.ActionStartTimer({
          projectId: this.cardProjectId ? this.cardProjectId : currentParams?.projectId,
          functionalityId: currentParams?.featureId
        });
        if (!timer?.errors.length) {
          this.startVisualTimer();
        }
        this.handleClosePopOver();
      },
      async fetchRunningTimer() {
        // Buscar por timer em andamento toda vez que abrir a página
        const timer = await this.ActionGetRunningTimer();
        this.setTempStartTime(timer);

        if (timer?.status) {
          this.startVisualTimer();
        }
      },
      async startVisualTimer() {
        // Define o tempo que deve se iniciar
        this.generateTimerTitle();
        this.isTimerRunning = true;

        // Inicia o interval no front-end, buscando no back a cada segundo pelo tempo atual
        this.interval = setInterval(async () => {
          if (this.tabActive) {
            if (this.timer.paused.status) {
              clearInterval(this.interval);
              return;
            }

            const timer = await this.ActionGetRunningTimer();
            if (!timer.status) {
              clearInterval(this.interval);
              return;
            }
            const date = timer.date.split("-");
            this.timer.startTime = toDate(
              new Date(
                date[0],
                date[1] - 1,
                date[2],
                timer.startHour,
                timer.startMinute,
                timer.startSecond
              )
            );
            this.timer.current = Date.now() - this.timer.startTime;

            if (!isToday(this.timer.startTime)) {
              const endOfTimer = set(this.timer.startTime, {
                hours: 23,
                minutes: 59,
                seconds: 59
              });
              this.timer.current = endOfTimer - this.timer.startTime;
              this.timer.paused.status = true;
              this.timer.paused.pausedAt = endOfTimer;
              this.timer.hasStartedToday = false;
              this.showPausedTimerModal();
              return;
            }
          }
        }, 1000);
      }
    },
    computed: {
      ...mapState({
        modalAppointment: (state) => state.root.modalAppointment
      }),
      ...mapState("Appointments", {
        cardProjectId: "cardProjectId"
      }),
      ...mapState({
        modalAppointment: (state) => state.root.modalAppointment
      }),
      getCurrentTime() {
        let seconds = Math.floor((this.timer.current / 1000) % 60);
        let minutes = Math.floor((this.timer.current / (1000 * 60)) % 60);
        let hours = Math.floor((this.timer.current / (1000 * 60 * 60)) % 24);

        seconds = seconds > 9 ? seconds : "0" + seconds;
        minutes = minutes > 9 ? minutes : "0" + minutes;
        hours = hours > 9 ? hours : "0" + hours;

        if (!hours || !minutes || !seconds) return null;

        return { hours, minutes, seconds };
      }
    },
    watch: {
      modalAppointment() {
        if (!this.modalAppointment) {
          this.handleClosePopOver();
        }
      },
      "timer.paused.status"(isPaused, _) {
        if (isPaused) {
          this.timer.blink.interval = setInterval(() => {
            this.timer.blink.class = this.timer.blink.class.length ? "" : "blink";
          }, 700);
        } else {
          if (this.timer.blink.interval) {
            clearInterval(this.timer.blink.interval);
            this.timer.blink.class = "";
          }
        }
      },
      startTimerByCardProject() {
        this.startManualTimer();
      },
      resetTimerPopOver() {
        this.resetTimer();
      }
    }
  };
</script>

<style lang="scss">
  .swal2-html-container {
    font-family: "Work Sans", sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  .swal2-title {
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
  }
  .blink {
    filter: opacity(0.6);
  }

  .popover_play {
    margin: 0 13px;
    max-width: calc(60%);

    span {
      user-select: none !important;
    }

    svg {
      cursor: pointer;
    }
  }

  .popover_play_trigger {
    height: 100%;
    padding: auto 0;
    margin-top: -2px;
    cursor: default;
    margin-right: -10px;
  }

  .popover_play_content {
    position: absolute;
    z-index: 1001;
    margin-top: 4px;

    span {
      width: 100%;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5px;
    gap: 5px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background: #ffffff;
  }

  .popover_play_content > span {
    user-select: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    gap: 4px;
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    border-radius: 3px;
    color: #1a1c1a;

    svg {
      width: 15px;
    }

    svg path {
      width: 11.25px;
      height: 11.25px;
    }

    &:hover {
      background-color: #d1e8d3;
      color: #006d3a;

      svg path {
        fill: #006d3a;
      }
    }

    &:active {
      filter: brightness(0.8);
    }
  }

  .popover_play_trigger.timer {
    margin-top: -1px !important;
    max-width: max-content;
    overflow: hidden;
    color: #ffffff;
    border-radius: 12px;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    margin-left: -10.75px !important;

    svg path {
      margin-top: -4px;
    }

    svg {
      margin-right: 0.25rem;
      margin-top: -0.75px;
      overflow: visible;
    }

    &:has(.hovering) {
      outline: 0.5px solid #006d3a;
    }

    .hovering {
      cursor: pointer;

      .popover_play {
        outline: 0.5px solid #006d3a;
      }

      & ~ .popover_play_project {
        visibility: visible;
      }
    }

    .popover_play_current_time {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      width: 50px !important;
      color: #ffffff;
    }
  }

  .popover_play_content.timer {
    width: max-content;

    .icon-trash {
      margin-left: -1px;
      margin-right: 1px;
    }

    svg {
      width: 15px;
    }

    svg path {
      width: 11.25px;
      height: 11.25px;
      fill: #1a1c1a;
    }

    span:hover {
      svg path {
        fill: #006d3a;
      }
    }
  }

  .popover_play_project {
    display: flex;
    visibility: hidden;

    justify-content: flex-start;
    align-items: center;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .popover_separator {
      display: inline-block;
      width: 0.5px;
      height: 11.96px;
      background: rgba(255, 255, 255, 0.11);
      margin-left: 6px;
      vertical-align: middle;
    }

    span {
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: 7px;
      vertical-align: middle;

      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .timer-modal-confirm-button,
  .timer-modal-cancel-button {
    font-family: "Open Sans" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    letter-spacing: 0.0025em !important;

    svg {
      margin-right: 5px !important;
      margin-top: -3.5px !important;
    }

    &:hover {
      svg path {
        fill: #006d3a;
      }
    }
  }

  .hide-tooltip {
    display: none;
  }

  .popover-timer-container {
    min-width: 65px;
  }

  .timer-modal-title,
  .swal2-html-container > p {
    font-family: "Work Sans" !important;
    font-style: normal !important;
    color: #1a1c1a !important;
  }

  .timer-modal-title {
    font-weight: 600 !important;
    font-size: 40px !important;
    line-height: 54px !important;
  }

  .swal2-html-container > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  .timer-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: fit-content !important;
    height: fit-content !important;
  }

  .timer-modal-popup {
    padding-bottom: 14.5px !important;
    padding-top: 14.5px !important;
  }
</style>
