<template>
  <div class="main">
    <Overlay :is-active="isLoading" />
    <header
      v-if="$route.name != `login`"
      class="main-menu"
    >
      <div class="nav-left-side">
        <div class="div-logo">
          <LogoTop class="logo" />
        </div>
        <div class="divisor" />
        <div class="main-menu">
          <div
            v-if="$route.name != `login`"
            class="breadcrumbs"
          >
            <span style="margin-right: 6px">
              <router-link
                :to="`/projects`"
                :class="$route.path !== '/projects' ? 'path-pages' : 'actual-page'"
              >
                Projetos
              </router-link>
            </span>
            <template v-if="!!project">
              <span class="path-pages-divisor">/</span>
              <span class="text-format-breadcrumbs">
                <router-link
                  id="path-tooltip"
                  v-b-tooltip.hover="{
                    title: project,
                    placement: 'top',
                    disabled: disableBreadCrumbTooltipPath,
                    customClass: 'tooltip-width-breadcrumps'
                  }"
                  style="
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 100%;
                  "
                  :to="`/project/${$route.params.projectId}`"
                  :class="!!feature ? 'path-pages' : 'actual-page'"
                  >{{ project }}
                </router-link>
              </span>
            </template>
            <template v-if="!!feature">
              <span class="path-pages-divisor">/</span>
              <span
                id="actual-tooltip"
                v-b-tooltip.hover="{
                  title: feature,
                  placement: 'top',
                  disabled: disableBreadCrumbTooltipActual,
                  customClass: 'tooltip-width-breadcrumps'
                }"
                style="
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  max-width: 100%;
                "
                class="actual-page"
              >
                <p
                  style="width: 100%"
                  class="text-format-breadcrumbs"
                >
                  {{ feature }}
                </p>
              </span>
            </template>
          </div>
          <PopOverPlay
            :start-timer-by-card-project="startTimerByCardProject"
            :reset-timer-pop-over="resetTimerPopOver"
            @setCurrentFunctionalityId="setCurrentFunctionalityId"
            @setCurrentProjectId="setCurrentProjectId"
            @openModalAppointment="openModalAppointment"
            @setTimerInfo="setTimerInfo($event)"
            @isTimerAppoint="($event) => setTimerAppointInfos($event)"
          />
        </div>
      </div>
      <div class="nav-right-side">
        <button
          type="button"
          class="btn-logout"
          @click="blockOutside ? openDiscardModal($event) : logout()"
        >
          <IconExit />
        </button>
      </div>
    </header>
    <router-view
      :key="$route.fullPath"
      :block-outside="blockOutside"
      @alterBlockOutside="alterBlockOutside"
      @startTimer="startTimer"
      @setProject="setProject"
      @setFeature="setFeature"
      @logout="logout"
    ></router-view>
    <AppointmentModal
      v-if="modalAppointment"
      :action="action"
      :timer-appoint-infos="timerAppoint"
      :timer="timer"
      @resetTimer="resetTimer"
      @closeModalAppointment="closeModalAppointment"
    />
    <ModalLoginComponent v-if="modalLogin == true" />
  </div>
</template>
<script>
  import { mapActions, mapState, mapMutations } from "vuex";
  import Overlay from "@/components/Overlay.vue";
  import ModalLoginComponent from "@/components/ModalLoginComponent";
  import IconExit from "@/assets/images/icon-logout.svg";
  import LogoTop from "@/assets/images/logo-top.svg";
  import PopOverPlay from "@/views/ProjectsView/components/PopOverPlay.vue";
  import AppointmentModal from "./views/ComponentsView/components/AppointmentModal.vue";
  export default {
    components: {
      LogoTop,
      IconExit,
      PopOverPlay,
      AppointmentModal,
      ModalLoginComponent,
      Overlay
    },
    data() {
      return {
        disableBreadCrumbTooltipActual: true,
        disableBreadCrumbTooltipPath: true,
        project: "",
        feature: "",
        blockOutside: false,
        action: null,
        timerAppoint: null,
        startTimerByCardProject: false,
        timer: null,
        resetTimerPopOver: false
      };
    },
    watch: {
      isLoading() {
        this.isLoading
          ? (document.body.style.overflow = "hidden")
          : (document.body.style.overflow = "");
      }
    },
    computed: {
      ...mapState({
        modalLogin: (state) => state.root.modalLogin,
        modalAppointment: (state) => state.root.modalAppointment
      }),
      ...mapState("Loader", {
        isLoading: "isLoading"
      })
    },

    methods: {
      ...mapMutations(["SET_MODAL_APPOINTMENT"]),
      ...mapActions("Login", ["ActionLogout"]),
      ...mapMutations("Appointments", ["setCardProjectId", "setCardFunctionalityId"]),
      setCurrentProjectId($event) {
        this.setCardProjectId($event);
      },
      setCurrentFunctionalityId($event) {
        this.setCardFunctionalityId($event);
      },
      setTimerInfo($event) {
        this.timer = $event;
      },
      openModalAppointment(action) {
        this.action = action;
        this.SET_MODAL_APPOINTMENT(!this.modalAppointment);
      },
      setTimerAppointInfos($event) {
        this.timerAppoint = $event;
      },
      closeModalAppointment() {
        this.SET_MODAL_APPOINTMENT(false);
      },
      alterBlockOutside(e) {
        this.blockOutside = e;
      },
      startTimer() {
        this.startTimerByCardProject = !this.startTimerByCardProject;
      },
      resetTimer() {
        this.resetTimerPopOver = !this.resetTimerPopOver;
      },
      openDiscardModalProject(e) {
        if (!this.isModalActive(e)) {
          this.$swal({
            customClass: "swal-warning",
            title: "Atenção",
            text: "Você tem alterações não salvas. Deseja descartar as alterações?"
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "projects" });
            }
          });
        }
      },
      isModalActive(e) {
        // Verifica se existe alguma modal ativa (usando o event)
        let element = e.target;
        while (element) {
          if (element.classList?.contains("swal2-shown")) {
            return true;
          }
          element = element.parentNode;
        }
        return false;
      },
      openDiscardModal(e) {
        if (!this.isModalActive(e)) {
          this.$swal({
            customClass: "swal-warning",
            title: "Atenção",
            text: "Você tem alterações não salvas. Deseja descartar as alterações?"
          }).then((result) => {
            if (result.isConfirmed) {
              this.logout();
            }
          });
        }
      },
      logout() {
        this.ActionLogout().then(() => {
          this.$router.push({ name: "login" });
        });
      },
      setProject(project) {
        this.project = project;
      },
      setFeature(feature) {
        this.feature = feature;
      }
    },
    updated() {
      let lastBreadCrumb = this.$el.querySelector("#actual-tooltip")?.lastElementChild;
      let lastBreadCrumbSmaller = lastBreadCrumb?.clientWidth < lastBreadCrumb?.scrollWidth;
      this.disableBreadCrumbTooltipActual = !lastBreadCrumbSmaller;

      let pathBreadCrumb = this.$el.querySelector("#path-tooltip")?.lastElementChild;
      let pathBreadCrumbSmaller = pathBreadCrumb?.clientWidth < lastBreadCrumb?.scrollWidth;
      this.disableBreadCrumbTooltipPath = !pathBreadCrumbSmaller;
    }
  };
</script>

<style lang="scss">
  .text-format-breadcrumbs {
    margin-right: 6px;
    display: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: max-content;
  }

  .actual-tooltip {
    width: max-content;
  }

  .tooltip-width-breadcrumps {
    max-width: 350px;
  }

  .tooltip-width-breadcrumps .tooltip-inner {
    max-width: 350px !important;
  }

  .main-menu {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .breadcrumbs {
    width: fit-content !important;
  }
</style>
