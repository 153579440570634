<template>
  <div v-show="allowProjects ? true : false">
    <div class="container-fluid container-projects-card-list">
      <div class="row">
        <div class="col-12">
          <div class="group-card">
            <div :class="['row', recentsProjects && recentsProjects.length < 3 && 'flex-start']">
              <div
                v-for="(project, index) in recentsProjects"
                :id="'project' + index"
                :key="'project' + index"
                class="col-3 col-card"
              >
                <CardProject
                  :project-title="project.title"
                  :project-company="project.company"
                  :description="project.description"
                  :indicators="project.indicators"
                  :image="project.image"
                  :project-id="project.id"
                  :project-functionality="project.features"
                  :project-indicators="project.indicators"
                  @startTimer="startTimer"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form_search">
            <form
              v-if="projectsFilteredSearch.length > 0 || projectsSearchFilter != ''"
              class="form-filter"
            >
              <input
                v-model="projectsSearchFilter"
                type="text"
                :class="['form-control', projects != '' && 'active']"
                placeholder="Pesquise aqui por projetos"
              />

              <button
                v-if="projectsSearchFilter == ''"
                class="btn-input btn-input-search"
                type="button"
              >
                <IconSearch class="icon-search" />
              </button>
              <button
                v-else
                type="button"
                class="btn-input"
                @click="clearProjectsFilter"
              >
                <IconClose />
              </button>
            </form>
          </div>
        </div>
        <div
          v-for="(project, index) in projectsFilteredSearch"
          :key="'project' + index"
          class="group-item-list"
        >
          <ItemListProject
            :project-title="project.title"
            :project-company="project.company"
            :description="project.description"
            :indicators="project.indicators"
            :image="project.image"
            :project-id="project.id"
            :project-functionality="project.features"
            :project-indicators="project.indicators"
            @startTimer="startTimer"
          />
        </div>
        <div
          v-if="projectsFilteredSearch.length == 0"
          class="empty-search"
        >
          <ImageEmpty />
          <span>Nenhum projeto encontrado</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as storage from "@/utils/localStorage";
  import { mapActions } from "vuex";
  import CardProject from "./components/CardProject.vue";
  import ItemListProject from "./components/ItemListProject.vue";
  import IconSearch from "@/assets/images/icon-search.svg";
  import ImageEmpty from "@/assets/images/empty-search.svg";
  import IconClose from "@/assets/images/icon-close.svg";

  export default {
    components: {
      CardProject,
      ItemListProject,
      IconSearch,
      IconClose,
      ImageEmpty
    },
    data() {
      return {
        projects: {},
        projectsFiltered: [],
        projectsSearchFilter: "",
        recentsProjects: {},
        allowProjects: null
      };
    },
    methods: {
      ...mapActions("Projects", ["ActionGetProjects"]),
      orderList(projects) {
        projects.sort(function (a, b) {
          let first = (a.company + " - " + a.title).toLowerCase();
          let second = (b.company + " - " + b.title).toLowerCase();
          if (first > second) {
            return 1;
          }
          if (first < second) {
            return -1;
          }
          return 0;
        });
      },
      startTimer() {
        this.$emit("startTimer");
      },

      clearProjectsFilter() {
        if (this.projectsSearchFilter !== "") {
          this.projectsSearchFilter = "";
        }
      },

      filterComponents() {
        this.projectsFiltered = JSON.parse(JSON.stringify(this.projects));

        this.projectsFiltered = this.recursiveSearchComponent(this.projectsFiltered, {});
        return this.projectsFiltered;
      },
      recursiveSearchComponent(listComponents) {
        let listFiltered = [];

        if (listComponents) {
          listFiltered = listComponents.filter((component) =>
            Object.keys(component)
              .map(
                (key) =>
                  (key == "company" || key == "description" || key == "title") &&
                  component[key]
                    ?.toString()
                    .toUpperCase()
                    .includes(this.projectsSearchFilter.toUpperCase())
              )
              .includes(true)
          );
        }
        return listFiltered;
      }
    },
    computed: {
      projectsFilteredSearch() {
        // Gera um novo array com apenas os itens (grupos, subgrupos e funcionalidades) filtrados a partir do termo digitado
        if (this.projectsSearchFilter != "") {
          return this.filterComponents();
        } else {
          return this.projects;
        }
      }
    },
    mounted() {
      this.allowProjects = JSON.parse(storage.getStorage("allowProjects"));
      this.$emit("setProject", null);
      this.$emit("setFeature", null);
      if (this.allowProjects == true) {
        this.ActionGetProjects().then((response) => {
          this.projects = JSON.parse(JSON.stringify(response.data.projects));
          this.orderList(this.projects);
          let recentsProjectsIds = response.data.recents;
          const projectFilter = [];
          recentsProjectsIds.map((project) => {
            response.data.projects.map((filteredProject) => {
              if (project == filteredProject.id) {
                projectFilter.push(filteredProject);
              }
            });
          });
          this.recentsProjects = projectFilter;
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  .container-projects-card-list {
    padding: 0 15px;
  }

  .project-new {
    text-decoration: none;

    .card {
      background-color: transparent !important;
      border: 2px dashed #888;
      box-shadow: none;
      box-sizing: border-box;

      div {
        font-family: "Open Sans";
        font-style: bold;
        font-weight: 900;
        font-size: 24px;
        line-height: 22px;
        text-align: center;
        color: #006d3a;
      }
    }
  }

  .group-card {
    padding: 20px 0px;

    .row {
      margin: 0 -15px;
      justify-content: space-between;

      &.flex-start {
        justify-content: flex-start;
      }
      & > div.col-card {
        padding: 0 15px;
        width: 33% !important;

        & > div {
          height: 100%;
        }
      }
    }
  }
  .form_search {
    position: relative;
    margin-bottom: 20px;
    input {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 14px 16px;
      background: #ffffff;
      border-radius: 15px;
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #1a1c1a;
      transform: rotate(0.1deg);
    }
    .btn-input {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      width: 34px;
      height: 34px;
      background: #006d3a;
      border-radius: 50px;
      align-items: center;
      justify-content: center;
      display: flex;
      border: none;
      &.btn-input-search {
        cursor: default;
      }
      svg {
        width: 16px;
        filter: brightness(0) invert(1);
      }
    }
  }
  .empty-search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    span {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
      color: #1a1c1a;
      text-align: center;
      margin-top: 10px;
    }
  }
</style>
