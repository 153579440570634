var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Draggable',{attrs:{"list":_vm.list,"tag":"div","group":{ name: 'components' }},on:{"start":_vm.saveComponentIdOnDragging,"end":function($event){return _vm.changeOrder($event)}}},_vm._l((_vm.list),function(elem){return _c('div',{key:elem.id,attrs:{"draggable-key":elem.id}},[(elem && !elem.hidden)?_c('ul',[_c('li',{staticClass:"li-tree-component"},[_c('div',{staticClass:"line-align-comp"},[_c('div',{class:[
              'draggable-item',
              elem.id == _vm.componentEditId && !elem.highlightOff && 'componentBeingEdited',
              elem.createdNow && 'active-modal'
            ],on:{"click":function($event){$event.stopPropagation();return _vm.openComponent(_vm.blockOutside, $event, elem)}}},[(elem.components.length != 0)?_c('button',{staticClass:"btn-toggle",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleComponents(elem, false)}}},[(elem.open || !('open' in elem))?_c('IconToggleDown',{staticStyle:{"margin-left":"1.5px"}}):_c('IconToggle')],1):_vm._e(),(!elem.createdNow)?_c('p',{staticClass:"name-tree",class:elem.copyNotEdit == true ? 'copied-not-edit' : ''},[_c('TitleTreeComp',{attrs:{"title":elem.name,"title-format-comp":_vm.formatNameTree(elem.name.replace(/\\(.)/g, '$1'))}})],1):_c('p',{staticClass:"name-tree name-tree-new text-format"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatNameTree(elem.name))}})]),(!elem.createdNow)?_c('div',{staticClass:"options-item-draggable"},[(elem.allowChildren)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                  title: 'Adicionar Subcomponente',
                  placement: 'topleft',
                  boundary: 'window'
                }),expression:"{\n                  title: 'Adicionar Subcomponente',\n                  placement: 'topleft',\n                  boundary: 'window'\n                }",modifiers:{"hover":true}}],staticClass:"btn-new",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.openComponentEdit(_vm.blockOutside, $event, elem)}}},[_c('IconPlus')],1):_vm._e(),(!elem.copyNotEdit)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                  title: 'Duplicar Componente',
                  html: true,
                  placement: 'topleft',
                  boundary: 'window'
                }),expression:"{\n                  title: 'Duplicar Componente',\n                  html: true,\n                  placement: 'topleft',\n                  boundary: 'window'\n                }",modifiers:{"hover":true}}],staticClass:"btn-duplicate-funcionality",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.openModalDuplicate(elem.id, elem.name, elem)}}},[_c('IconDuplicate')],1):_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                  title: 'Editar registro duplicado antes de gerar uma nova versão.',
                  html: true,
                  placement: 'topright',
                  boundary: 'window',
                  customClass: 'tooltip-lg'
                }),expression:"{\n                  title: 'Editar registro duplicado antes de gerar uma nova versão.',\n                  html: true,\n                  placement: 'topright',\n                  boundary: 'window',\n                  customClass: 'tooltip-lg'\n                }",modifiers:{"hover":true}}],staticClass:"icon-not-edit btn-new-version copied-not-edited",attrs:{"type":"button"}},[_c('IconVersion')],1)]):_vm._e()])]),(_vm.forceRenderTreeDraggable)?_c('DraggableComp',{directives:[{name:"show",rawName:"v-show",value:(elem.open == null || elem.open),expression:"elem.open == null || elem.open"}],attrs:{"list":elem.components,"block-outside":_vm.blockOutside,"component-edit-id":_vm.componentEditId,"component-parent-id":elem.id,"component-parent-type-id":elem.ComponentId,"term-filter-tree-components":_vm.termFilterTreeComponents},on:{"copyItem":_vm.openModalDuplicate,"newComponent":_vm.newComponent,"editComponent":_vm.editComponent,"reloadTreeComponent":_vm.reloadTreeComponent,"openDiscardChanges":_vm.openDiscardChanges,"undoChangesTree":_vm.undoChangesTree,"reloadFeature":_vm.reloadFeature,"forceRenderTreeComponentsToggle":_vm.forceRenderTreeComponentsToggle}}):_vm._e()],1)]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }