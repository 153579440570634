import LoginView from "@/views/LoginView";
import ProjectsView from "@/views/ProjectsView";
import ProjectNew from "@/views/ProjectsView/NewProject";
import FeaturesView from "@/views/FeaturesView";
import ComponentsView from "@/views/ComponentsView";
import ReportsView from "@/views/ReportsView";

export default [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    component: LoginView
  },
  {
    path: "/projects",
    name: "projects",
    component: ProjectsView,
    meta: { title: "Projetos" }
  },
  {
    path: "/project-new",
    name: "project-new",
    component: ProjectNew,
    meta: { title: "Novo Projeto" }
  },
  {
    path: "/project/:projectId",
    name: "project",
    component: FeaturesView,
    meta: { title: "Projetos" }
  },
  {
    path: "/project/:projectId/group/:groupId/subgroup/:subgroupId/feature",
    name: "featureNew",
    component: ComponentsView,
    meta: { title: "Projetos" }
  },
  {
    path: "/project/:projectId/group/:groupId/subgroup/:subgroupId/feature/:featureId/component/:componentId",
    name: "componentView",
    component: ComponentsView
  },
  {
    path: "/project/:projectId/group/:groupId/subgroup/:subgroupId/feature/:featureId/:copyNotEdit?",
    name: "featureView",
    component: ComponentsView,
    meta: { title: "Projetos" },
    props: (route) => {
      const copyNotEdit = route.params.copyNotEdit;
      if (typeof copyNotEdit === "object" && Object.keys(copyNotEdit).length === 0) {
        return {};
      } else {
        return { copyNotEdit };
      }
    }
  },
  {
    path: "/report/projects/:projectId/groups/:groupId/subgroups/:subgroupId/features/:featureId",
    name: "report",
    component: ReportsView
  },
  {
    path: "*",
    redirect: "/"
  }
];
