import ProjectsService from "@/services/Projects";

export const ActionGetProjects = () => {
  return ProjectsService.getProjects();
};

export const ActionGetProjectTree = ({}, projectId) => {
  return ProjectsService.getProject(projectId);
};

export const ActionPutOrderTree = ({}, data) => {
  return ProjectsService.orderTreeGroup(data.projectId, data.payload);
};
