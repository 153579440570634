var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type < 2 && _vm.featureId != null)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
    title: _vm.messageTooltipVersion(_vm.copyNotEdit, _vm.type),
    html: true,
    placement: 'topright',
    boundary: 'window',
    customClass: 'tooltip-lg'
  }),expression:"{\n    title: messageTooltipVersion(copyNotEdit, type),\n    html: true,\n    placement: 'topright',\n    boundary: 'window',\n    customClass: 'tooltip-lg'\n  }",modifiers:{"hover":true}}],staticClass:"btn-new-version",class:_vm.copyNotEdit == 1 || _vm.copyNotEdit == 2
      ? 'copied-not-edited'
      : _vm.type === 1
      ? 'active-feature'
      : 'inactive-feature',attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.discardChangesReport($event)}}},[_c('IconVersion')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }