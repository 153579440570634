var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Popper',{ref:"popper",attrs:{"trigger":"clickToToggle","options":{
      placement: 'bottom-start',
      modifiers: {
        preventOverflow: {
          boundariesElement: 'viewport'
        }
      }
    }}},[_c('PlayIcon',{staticClass:"popper-button",attrs:{"slot":"reference"},on:{"click":_vm.handleOpenPopover},slot:"reference"}),_c('div',{staticClass:"popper"},[(_vm.isOpen)?_c('div',{staticClass:"popover_play_content"},[_c('span',{on:{"click":_vm.handleManualAppointment}},[_c('PencilIcon'),_vm._v("Apontamento manual ")],1),_c('span',{on:{"click":_vm.startManualTimer}},[_c('ClockIcon'),_vm._v("Iniciar temporizador ")],1)]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }