import AppointmentServices from "@/services/Appointments";
import { getCurrentTimeFormatted } from "@/utils/currentTime";

export const ActionGetTimerStatus = () => {
  return AppointmentServices.getTimerStatus(); // OK
};

export const ActionPostTimeStart = ({}, payload) => {
  return AppointmentServices.postTimeStart(payload);
};
export const ActionPostTimeStop = ({}, payload) => {
  return AppointmentServices.postTimeStop(payload);
};

export const ActionDeleteTimeDiscard = () => {
  return AppointmentServices.deleteTimeDiscard();
};

export const ActionPostTask = ({}, payload) => {
  return AppointmentServices.postTask(payload);
};

export const ActionPutTask = ({}, data) => {
  return AppointmentServices.putTask(data.id, data.payload);
};

export const ActionGetTaskDate = ({}, data) => {
  return AppointmentServices.getTaskDate(data.startDate, data.endDate);
};

export const ActionGetTask = ({}, id) => {
  return AppointmentServices.getTask(id);
};

export const ActionDeleteTask = ({}, id) => {
  return AppointmentServices.deleteTask(id);
};

export const ActionContextProject = ({}) => {
  return AppointmentServices.contextProject();
};

export const ActionContextStages = ({}) => {
  return AppointmentServices.contextStages();
};

export const ActionContextFuncionality = ({}, id) => {
  return AppointmentServices.contextFuncionality(id);
};

export const ActionContextTaskType = ({}) => {
  return AppointmentServices.contextTaskType();
};

export const ActionNonConformityOrigin = ({}) => {
  return AppointmentServices.contextNonConformityOrigin();
};

export const ActionNonConformityImpact = ({}) => {
  return AppointmentServices.contextNonConformityImpact();
};

export const ActionAppointmentType = ({}) => {
  return AppointmentServices.contextAppointmentType();
};

export const ActionHolidayType = ({}) => {
  return AppointmentServices.contextHolidayType();
};

export const ActionTaskItemTypes = ({}) => {
  return AppointmentServices.contextTaskItemTypes();
};

export const ActionFuncionalityItemType = ({}, ids) => {
  return AppointmentServices.contextFuncionalityItemType(ids.projectId, ids.featureid);
};
export const ActionStartTimer = ({}, data) => {
  const currentDate = getCurrentTimeFormatted();
  let projectId = data?.projectId ? Number(data?.projectId) : null;
  const functionalityId = data?.functionalityId ? Number(data?.functionalityId) : null;

  return AppointmentServices.startTimer({
    date: currentDate.date,
    hour: currentDate.hour,
    minute: currentDate.minute,
    second: currentDate.second,
    projectId,
    funcionalityId: functionalityId
  });
};
export const ActionGetRunningTimer = ({}) => {
  return AppointmentServices.getRunningTimer();
};
export const ActionStopTimer = ({}, data = null) => {
  let currentDate = null;
  if (data == null) {
    currentDate = getCurrentTimeFormatted();
  } else {
    currentDate = {
      // Horário definido a mão, pois sempre que um apontamento ficar de um dia para outro ligado, o mesmo sempre irá parar as 23:59:59
      date: data,
      hour: 23,
      minute: 59,
      second: 59
    };
  }

  return AppointmentServices.stopTimer({
    date: currentDate.date,
    hour: currentDate.hour,
    minute: currentDate.minute,
    second: currentDate.second
  });
};
export const ActionDeleteTimer = ({}) => {
  return AppointmentServices.deleteTimer();
};
