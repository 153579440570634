<template>
  <div class="config-feature">
    <div class="feature-settings">
      <div class="general">
        <div class="top">
          <IconPage class="top-icon" />
          <p class="title">Configurações da Funcionalidade</p>
        </div>
        <form
          v-if="forceRenderFeature"
          @submit.prevent="saveFeature"
        >
          <div class="feature-form">
            <div class="row item-feature">
              <div class="col-sm-4">
                <IconTextA class="icon" />
                <span class="mandatory-indicator">*</span>
                <label>Nome</label>
              </div>
              <div class="col-sm-8">
                <InputTextarea
                  id="name"
                  v-model="info.name"
                  :touched-form="touchedForm"
                  :mandatory="true"
                  :placeholder="'vazio'"
                  :accept-enter="false"
                />
              </div>
            </div>
            <div class="row item-feature">
              <div class="col-sm-4">
                <IconArrowDown class="icon" />
                <span class="mandatory-indicator">*</span>
                <label>Tipo de Funcionalidade</label>
              </div>
              <div class="col-sm-8">
                <InputSelect
                  id="featureTypeId"
                  v-model="info.featureTypeId"
                  :touched-form="touchedForm"
                  :mandatory="true"
                  :value="info.featureTypeId"
                >
                  <option
                    value=""
                    hidden
                    selected
                  >
                    vazio
                  </option>
                  <option
                    v-for="(featureType, index) in info.featureTypes"
                    :key="'feature' + index"
                    :value="featureType.id"
                  >
                    {{ featureType.name }}
                  </option>
                </InputSelect>
              </div>
            </div>
            <div class="row item-feature">
              <div class="col-sm-4">
                <IconFormatList class="icon" />
                <span class="span-width"></span>
                <label class="list-label">Persona</label>
              </div>
              <div class="col-sm-8">
                <InputTag
                  id="personas"
                  v-model="info.personas"
                  :value="info.personas"
                />
              </div>
            </div>
            <div class="row item-feature">
              <div class="col-sm-4">
                <IconFormatList class="icon" />
                <span
                  :class="
                    info.featureTypeId == 1 || info.featureTypeId == 2 ? 'require' : 'span-width'
                  "
                ></span>
                <label class="list-label">Interface</label>
              </div>
              <div class="col-sm-8">
                <InputTag
                  id="interface"
                  v-model="info.interface"
                  :touched-form="touchedForm"
                  :mandatory="info.featureTypeId == 1 || (info.featureTypeId == 2 && true)"
                  :value="info.interface"
                />
              </div>
            </div>
            <div class="row item-feature">
              <div class="col-sm-4">
                <IconTextA class="icon" />
                <span class="mandatory-indicator">*</span>
                <label>Descrição</label>
              </div>
              <div class="col-sm-8">
                <QuillEditor
                  id="description"
                  v-model="info.description"
                  :allow-style="true"
                  :touched-form="touchedForm"
                  :accept-enter="true"
                  :mandatory="true"
                  placeholder="vazio"
                />
              </div>
            </div>
            <div class="row item-feature">
              <div class="col-sm-4">
                <IconTextA class="icon" />
                <span class="span-width"></span>
                <label>Detalhes de Segurança</label>
              </div>
              <div class="col-sm-8">
                <InputTextarea
                  id="security"
                  v-model="info.security"
                  :touched-form="touchedForm"
                  :placeholder="'vazio'"
                />
              </div>
            </div>
            <div class="row item-feature">
              <div class="col-sm-4">
                <IconLinkOut class="icon" />
                <span class="span-width"></span>
                <label class="list-label">Link de Protótipo</label>
              </div>
              <div class="col-sm-8">
                <InputTextarea
                  id="prototypeLink"
                  v-model="info.prototypeLink"
                  :touched-form="touchedForm"
                  :placeholder="'vazio'"
                  :custom-class="info.prototypeLink ? 'link-input' : ''"
                />
              </div>
            </div>
            <div class="row item-feature">
              <div class="col-sm-4">
                <IconPhase class="icon" />
                <span class="span-width"></span>
                <label class="list-label">Fase</label>
              </div>
              <div class="col-sm-8">
                <InputTextarea
                  id="phase"
                  v-model="info.phase"
                  :touched-form="touchedForm"
                  :placeholder="'vazio'"
                />
              </div>
            </div>
            <div class="row item-feature">
              <div class="col-sm-4">
                <IconPackage class="icon" />
                <span class="span-width"></span>
                <label class="list-label">Pacote</label>
              </div>
              <div class="col-sm-8">
                <InputTextarea
                  id="phase"
                  v-model="info.package"
                  :touched-form="touchedForm"
                  :placeholder="'vazio'"
                />
              </div>
            </div>
            <div class="row item-feature">
              <div class="col-sm-4">
                <IconDevops class="icon" />
                <span class="span-width"></span>
                <label class="list-label">ID DevOps</label>
              </div>
              <div class="col-sm-8">
                <div class="container-textarea">
                  <input
                    id="DevOpsId"
                    v-model="info.DevOpsId"
                    type="number"
                    :touchedForm="touchedForm"
                    :placeholder="'vazio'"
                    @keypress="validateNumber"
                  />
                </div>
              </div>
            </div>
            <div class="row item-feature">
              <div class="col-sm-4">
                <IconComment class="icon" />
                <span class="span-width"></span>
                <label>Comentário Técnico</label>
              </div>
              <div class="col-sm-8">
                <InputTextarea
                  id="TechnicalComment"
                  v-model="info.TechnicalComment"
                  :touched-form="touchedForm"
                  :placeholder="'vazio'"
                />
              </div>
            </div>
          </div>

          <div class="buttons">
            <button
              v-if="action === 'new'"
              type="button"
              class="btn btn-cancel"
              @click="cancelFeature"
            >
              <IconClose class="icon-button" />
              Cancelar
            </button>
            <button
              v-if="action === 'edit'"
              type="button"
              class="btn btn-cancel"
              @click="deleteFeature"
            >
              <IconDelete class="icon-button" />
              Apagar
            </button>
            <button
              v-if="action === 'edit'"
              type="button"
              class="btn btn-discard"
              :disabled="!alterInfo"
              @click="discardAlter"
            >
              <IconHistory class="icon-button" />
              Descartar Alterações
            </button>
            <button
              type="submit"
              class="btn btn-save"
              :disabled="!alterInfo"
            >
              <IconSave class="icon-button" />
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import IconPage from "@/assets/images/icon-features.svg";
  import IconPhase from "@/assets/images/icon-phase.svg";
  import IconPackage from "@/assets/images/icon-package.svg";
  import IconArrowDown from "@/assets/images/icon-arrow-drop-down.svg";
  import IconComment from "@/assets/images/icon-comment.svg";
  import IconFormatList from "@/assets/images/icon-format-list.svg";
  import IconLinkOut from "@/assets/images/icon-link-out.svg";
  import IconTextA from "@/assets/images/icon-text.svg";
  import IconSave from "@/assets/images/icon-save.svg";
  import IconClose from "@/assets/images/icon-close.svg";
  import IconDelete from "@/assets/images/icon-trash.svg";
  import IconHistory from "@/assets/images/icon-clock-return.svg";
  import IconDevops from "@/assets/images/icon-devops.svg";
  import InputTag from "../../../components/InputTag.vue";
  import InputTextarea from "../../../components/InputTextarea.vue";
  import InputSelect from "../../../components/InputSelect.vue";
  import QuillEditor from "./QuillEditorComp.vue";

  export default {
    components: {
      IconPage,
      IconPhase,
      IconPackage,
      IconDevops,
      IconArrowDown,
      IconComment,
      IconFormatList,
      IconLinkOut,
      IconTextA,
      IconSave,
      IconClose,
      IconDelete,
      IconHistory,
      InputTag,
      InputTextarea,
      InputSelect,
      QuillEditor
    },
    props: ["feature", "action"],
    data() {
      return {
        info: {
          name: "",
          featureTypeId: "",
          personas: [],
          interface: [],
          description: "",
          security: "",
          prototypeLink: "",
          TechnicalComment: "",
          phase: "",
          package: "",
          DevOpsId: "",
          featureTypes: []
        },
        infoStart: {
          name: "",
          featureTypeId: "",
          personas: [],
          interface: [],
          description: "",
          security: "",
          prototypeLink: "",
          TechnicalComment: "",
          phase: "",
          package: "",
          DevOpsId: "",
          featureTypes: []
        },
        headerConfigAllowLoginModal: {
          headers: {
            allowLoginModal: true
          }
        },
        projectId: null,
        groupId: null,
        subgroupId: null,
        featureId: null,
        forceRenderFeature: true,
        touchedForm: false
      };
    },
    computed: {
      alterInfo: function () {
        let value = JSON.stringify(this.info) != JSON.stringify(this.infoStart);
        this.$emit("blockOutside", value);
        return value;
      },
      invalidForm: function () {
        return this.validateForm();
      }
    },
    methods: {
      ...mapActions("Features", [
        "ActionGetFeatureTypes",
        "ActionPostFeature",
        "ActionPutFeature",
        "ActionDeleteFeature"
      ]),
      resetForm() {
        this.info = JSON.parse(JSON.stringify(this.infoStart));
      },
      validateNumber: (event) => {
        ["e", "E", "+", "-", ",", "."].includes(event.key) && event.preventDefault();
      },
      reloadFeature() {
        this.forceRenderFeature = false;
        this.$nextTick().then(() => {
          this.forceRenderFeature = true;
          this.disabledSave = true;
          this.disabledDiscard = true;
        });
      },
      validateForm() {
        let invalid = false;
        if (!this.info.name || !this.info.featureTypeId || !this.info.description) {
          invalid = true;
        }
        if (
          (this.info.featureTypeId === 1 || this.info.featureTypeId === 2) &&
          this.info.interface.length === 0
        ) {
          invalid = true;
        }
        return invalid;
      },
      saveFeature() {
        // Ação de salvar o formulário, para criar ou editar uma funcionalidade
        this.touchedForm = true;

        if (!this.invalidForm) {
          // Valida se todos os campos obrigatórios estão preenchidos
          let payload = {
            name: this.info.name || null,
            description: this.info.description || null,
            featureTypeId: this.info.featureTypeId || null,
            personas: this.info.personas || null,
            interface: this.info.interface || null,
            prototypeLink: this.info.prototypeLink || null,
            security: this.info.security || null,
            TechnicalComment: this.info.TechnicalComment || null,
            phase: this.info.phase || null,
            package: this.info.package || null,
            DevOpsId: this.info.DevOpsId || null
          };
          let projectId = this.projectId;
          let groupId = this.groupId;
          let subgroupId = this.subgroupId;
          let config = this.headerConfigAllowLoginModal;

          if (this.action === "new") {
            // Caso a ação do formulário for de criação de funcionalidade, aciona a rota de criação (POST)
            this.ActionPostFeature({ projectId, groupId, subgroupId, payload, config }).then(
              (response) => {
                let featureId = response.data.id;
                this.$router.push({
                  name: "featureView",
                  params: { projectId, groupId, subgroupId, featureId }
                });
              }
            );
          } else {
            // Caso a ação do formulário for de edição de uma funcionalidade existente, aciona a rota de edição (PUT)
            let featureId = this.featureId;
            this.ActionPutFeature({
              projectId,
              groupId,
              subgroupId,
              featureId,
              payload,
              config
            }).then(() => {
              this.$emit("loadFeature");
            });
          }
        }
      },
      cancelFeature() {
        // Cancelamento da criação de nova funcionalidade

        if (this.alterInfo) {
          // Se houveram informações alteradas no formulário, exibe alerta de confirmação
          this.$swal({
            customClass: "swal-warning",
            title: "Atenção",
            text: "Você tem alterações não salvas. Deseja descartar as alterações?”"
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ path: `/project/${this.projectId}` });
            }
          });
        } else {
          // Caso o usuário não tenha digitado nenhuma informação nos campos, redireciona ele direto para a tela anterior
          this.$router.push({ path: `/project/${this.projectId}` });
        }
      },
      loadFeatureType() {
        let projectId = this.projectId;
        let groupId = this.groupId;
        let subgroupId = this.subgroupId;
        this.ActionGetFeatureTypes({ projectId, groupId, subgroupId }).then((response) => {
          this.info.featureTypes = response.data.filter((elem) => elem.available == true);
        });
      },
      discardAlter() {
        // Descarta as alterações na edição da funcionalidade

        this.$swal({
          customClass: "swal-warning",
          title: "Atenção",
          text: "Você tem alterações não salvas. Deseja descartar as alterações?"
        }).then((result) => {
          if (result.isConfirmed) {
            this.resetForm();
            this.reloadFeature();
          }
        });
      },
      deleteFeature() {
        // Deleta a funcionalidade na edição dela

        this.$swal({
          customClass: "swal-delete",
          title: "Apagar",
          text: "Todas as configurações da funcionalidade e componentes existentes nela serão apagados. Deseja continuar?"
        }).then((result) => {
          if (result.isConfirmed) {
            let projectId = this.projectId;
            let groupId = this.groupId;
            let subgroupId = this.subgroupId;
            let featureId = this.featureId;
            this.ActionDeleteFeature({
              projectId,
              groupId,
              subgroupId,
              featureId
            }).then(() => {
              this.$router.push({ path: `/project/${this.projectId}` });
            });
          }
        });
      }
    },
    watch: {
      feature() {
        if (this.action != "new") {
          this.info = JSON.parse(JSON.stringify(this.feature));
          this.infoStart = JSON.parse(JSON.stringify(this.info));
          this.reloadFeature();
        }
      }
    },
    created() {
      this.projectId = this.$route.params.projectId || null;
      this.groupId = this.$route.params.groupId || null;
      this.subgroupId = this.$route.params.subgroupId || null;
      this.featureId = this.$route.params.featureId || null;

      if (this.action === "new") {
        this.loadFeatureType();
      }
    }
  };
</script>

<style lang="scss">
  .feature-settings {
    height: 100%;
    background: #fbfdf7;
    border: 1px solid #f0f1ec;
    box-sizing: border-box;

    .general {
      height: 100%;
      overflow: hidden;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      svg {
        width: 21px !important;
        height: 21px !important;
      }

      .top {
        display: flex;
        align-items: center;
        margin: 10px 0 0 0;

        .top-icon {
          margin-right: 5px;
        }
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          color: #1a1c1a;
          margin: 0;
          width: auto;
        }
      }

      form {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        overflow-x: hidden;
        overflow-y: auto;
        padding-top: 15px;
        padding-bottom: 20px;
      }

      .feature-form {
        width: 100%;

        .item-feature {
          display: flex;
          margin-bottom: 13px;

          & > div {
            display: flex;
            align-items: flex-start;
          }

          label {
            color: #5c5f5b;
            font-size: 14px;
          }
          .list-label {
            margin-top: 2px;
          }

          .link-input {
            color: #006d3a;
            text-decoration: underline;
          }
        }
      }
      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-top: 15px;

        .btn-cancel {
          svg {
            path {
              fill: #ef3e4d;
            }
          }
          color: #ef3e4d;
          background: transparent;
          font-weight: 600;
          font-size: 14px;
          border-radius: 15px;
          box-shadow: none;

          .icon-button {
            margin-right: 6px;
            margin-bottom: 2px;
          }

          &:hover {
            opacity: 0.7;
          }
        }

        .btn-save {
          color: #fff;
          background: #006d3a;
          border-radius: 15px;
          font-weight: 600;
          font-size: 14px;
          margin-left: 16px;
          box-shadow: none;

          .icon-button {
            margin-right: 6px;
            margin-bottom: 2px;
            width: 15px;
          }

          &:hover {
            transition: 0.2s;
            border: 1px solid #006d3a;
            color: #006d3a;
            background: #fff;

            svg {
              path {
                fill: #006d3a;
              }
            }
          }
        }
        .btn-save[disabled],
        .btn-discard[disabled] {
          color: #989899;
          background: #ebebec;
          border: 1px solid transparent;
          svg {
            path {
              fill: #989899;
            }
          }
        }
        .btn-discard {
          border: 1px solid #006d3a;
          border-radius: 15px;
          font-weight: 600;
          font-size: 14px;
          box-shadow: none;
          margin-left: 16px;
          color: #006d3a;

          svg {
            margin-bottom: 2px;
            width: 15px;
          }

          &:hover {
            transition: 0.2s;
            color: #fff;
            background: #006d3a;
            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }

  .mandatory-indicator {
    width: 5px;
    margin-right: 3px;
    height: 31px;
    color: #ef3e4d;
  }

  .require::after {
    display: inherit;
    width: 5px;
    margin-right: 3px;
    height: 31px;
    color: #ef3e4d;
    content: "*";
  }

  .span-width {
    height: 31px;
    width: 5px;
    margin-right: 3px;
  }

  .config-feature {
    position: relative;
    height: 100%;
  }

  .container-textarea {
    width: 100%;
    position: relative;

    input {
      background: transparent;
      border: none;
      outline: none;
      resize: none;
      padding: 3px 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.35em;
      min-height: 28px;
      width: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      border: 1px solid transparent;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }

    input::placeholder {
      color: #c6c7c3;
    }

    input::-webkit-scrollbar {
      width: 0;
    }

    input:focus {
      border: 1px solid #5c5f5b;
      background: #ffffff;
      border-radius: 3px;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
        0px 1px 3px rgba(0, 0, 0, 0.2);
    }
  }
</style>
