<template>
  <div
    v-if="content"
    ref="popover"
    class="popover-content"
  >
    <div class="popover-header-atividades font">
      {{ content.client }} - {{ content.project }}
      <div
        v-show="actionAppoint == 'new'"
        class="buttons"
      >
        <button
          :disabled="!content.canEdit"
          type="button"
          class="btn-popover"
          @click="editTaskAppointment"
        >
          <IconPencil class="btn-icon" /> <span>Editar</span>
        </button>
        <button
          type="button"
          class="btn-popover"
          @click="duplicateTaskAppointment"
        >
          <IconCopy class="btn-icon" /> <span>Duplicar</span>
        </button>
        <button
          :disabled="!content.canEdit"
          type="button"
          class="btn-popover"
          @click="deleteTaskAppointment"
        >
          <IconTrash class="btn-icon" /> <span>Excluir</span>
        </button>
      </div>
    </div>
    <div :class="['popover-body-atividades', 'font']">
      <div
        v-if="!content.canEdit && actionAppoint == 'new'"
        class="blocked-day"
      >
        Dia Bloqueado
      </div>
      <div
        v-if="content.funcionalityId && findFunctionnality()"
        class="item-body"
      >
        <div class="title-body">FUNCIONALIDADE</div>
        <div class="content-body">
          {{ functionalityName != undefined ? functionalityName : "Funcionalidade não encontrada" }}
        </div>
      </div>
      <div
        v-if="content.item.id && content.item.typeId"
        class="item-body"
      >
        <div class="title-body">TIPO DO ITEM</div>
        <div class="content-body">
          {{ findTypeItem() }}
        </div>
      </div>
      <div class="item-body">
        <div class="title-body">TAREFA</div>
        <div class="content-body">
          {{ findTask() }}
        </div>
      </div>
      <div
        v-if="content.task.description"
        class="item-body"
      >
        <div class="title-body">DESCRIÇÃO</div>
        <div class="content-body">
          {{ content.task.description }}
        </div>
      </div>
      <div class="item-body">
        <div class="title-body">HORA</div>
        <div class="content-body">
          {{
            `${formatHour(content.task.startHour, content.task.startMinute)} • ${formatHour(
              content.task.endHour,
              content.task.endMinute
            )}`
          }}
          <IconAlarm
            v-if="content.appointmentTypeId == 2"
            class="icon-hour"
          />
        </div>
      </div>
      <div
        v-if="content.nonConformity.originId && content.nonConformity.impactId"
        class="non-conformity"
      >
        <div class="font">INCONFORMIDADE</div>
        <div class="item-non-conformity-body">
          <div class="title-body-non-conformity">ORIGEM</div>
          <div class="content-body-non-conformity">
            {{ findNonConformityOrigin() }}
          </div>
        </div>
        <div class="item-non-conformity-body">
          <div class="title-body-non-conformity">DESCRIÇÃO</div>
          <div class="content-body-non-conformity">
            {{ content.nonConformity.description }}
          </div>
        </div>
        <div class="item-non-conformity-body">
          <div class="content-body-non-conformity">IMPACTO</div>
          <div class="content-body-non-conformity">
            {{ findNonConformityImpact() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import IconPencil from "@/assets/images/icon-edit-pencil.svg";
  import IconTrash from "@/assets/images/icon-trash.svg";
  import IconCopy from "@/assets/images/icon-copy.svg";
  import IconAlarm from "@/assets/images/icon-alarm.svg";
  import { mapActions, mapMutations, mapState } from "vuex";
  import { format } from "date-fns";

  export default {
    components: {
      IconPencil,
      IconTrash,
      IconCopy,
      IconAlarm
    },
    props: {
      content: {
        type: Object,
        default: function () {
          return {};
        }
      },
      contextsPopOver: {
        type: Object,
        default: function () {
          return {};
        }
      },
      calendarType: String
    },
    data() {
      return {
        functionalityName: null,
        functionalities: [],
        appointmentTypes: [
          {
            id: 1,
            name: "Manual"
          },
          {
            id: 2,
            name: "Temporizador"
          }
        ],
        holidayType: [
          {
            id: 1,
            name: "Nacional"
          },
          {
            id: 2,
            name: "Municipal"
          },
          {
            id: 3,
            name: "Estadual"
          }
        ]
      };
    },
    watch: {},
    methods: {
      ...mapActions("Appointments", ["ActionContextFuncionality", "ActionDeleteTask"]),
      ...mapMutations("Appointments", ["setIsEdit", "setActionAppoint", "setFromEditButton"]),
      formatHour(hour, minute) {
        if (hour < 10) {
          hour = `0${hour}`;
        }
        if (minute < 10) {
          minute = `0${minute}`;
        }
        let time = `${hour}:${minute}`;
        return time;
      },
      editTaskAppointment() {
        this.setFromEditButton(true);
        this.setActionAppoint("edit");
        this.setIsEdit(true);
        this.$emit("editTaskAppointment", { id: this.content.id, action: "edit" });
        this.$emit("closePopover");
      },
      duplicateTaskAppointment() {
        this.setFromEditButton(true);
        this.setActionAppoint("duplicate");
        this.setIsEdit(true);
        this.$emit("duplicateTaskAppointment", { id: this.content.id, action: "duplicate" });
        this.$emit("closePopover");
      },
      deleteTaskAppointment() {
        const appointmentIcon = `<svg class="appointment-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.75 10.375H2.25C1.90625 10.375 1.625 10.0938 1.625 9.75V2.25C1.625 1.90625 1.90625 1.625 2.25 1.625H5.375C5.71875 1.625 6 1.34375 6 1C6 0.65625 5.71875 0.375 5.375 0.375H1.625C0.93125 0.375 0.375 0.9375 0.375 1.625V10.375C0.375 11.0625 0.9375 11.625 1.625 11.625H10.375C11.0625 11.625 11.625 11.0625 11.625 10.375V6.625C11.625 6.28125 11.3438 6 11 6C10.6562 6 10.375 6.28125 10.375 6.625V9.75C10.375 10.0938 10.0938 10.375 9.75 10.375ZM7.25 1C7.25 1.34375 7.53125 1.625 7.875 1.625H9.49375L3.7875 7.33125C3.54375 7.575 3.54375 7.96875 3.7875 8.2125C4.03125 8.45625 4.425 8.45625 4.66875 8.2125L10.375 2.50625V4.125C10.375 4.46875 10.6562 4.75 11 4.75C11.3438 4.75 11.625 4.46875 11.625 4.125V1C11.625 0.65625 11.3438 0.375 11 0.375H7.875C7.53125 0.375 7.25 0.65625 7.25 1Z" fill="white"/></svg>`;
        this.$swal({
          customClass: {
            popup: "swal-delete",
            confirmButton: "btn-confirm"
          },
          title: "Excluir",
          confirmButtonText: `${appointmentIcon} Confirmar`,
          html: `Deseja realmente deletar o apontamento? <br/>
        ${this.content.client} - ${this.content.project}
        ${format(new Date(this.content.task.date), "dd/MM/yyyy")} • ${this.formatHour(
            this.content.task.startHour,
            this.content.task.startMinute
          )} às ${this.formatHour(this.content.task.endHour, this.content.task.endMinute)}`
        }).then((result) => {
          if (result.isConfirmed) {
            this.ActionDeleteTask(this.content.id).then((response) => {
              if (!response?.errors) {
                this.$emit("deletedTaskAppointment", true);
              }
            });
          }
        });
      },
      findTypeItem() {
        let title = this.contextsPopOver.contextTaskItemType.find(
          (itemType) => itemType.id == this.content.item.id
        );
        return title !== undefined ? `${title.name}: ${this.content.item.typeId}` : "";
      },
      async findFunctionnality() {
        let funcionalities = await this.ActionContextFuncionality(this.content.projectId).then(
          (response) => {
            return response.funcionalities;
          }
        );
        let funcionalityName = funcionalities.find(
          (funcionality) => funcionality.id == this.content.funcionalityId
        );
        this.functionalityName = funcionalityName.name;
      },
      findTask() {
        let title = this.contextsPopOver.contextTaskType.find(
          (task) => task.id == this.content.taskTypeId
        );
        return title !== undefined ? title.name : "";
      },
      findNonConformityOrigin() {
        let title = this.contextsPopOver.nonConformityOrigin.find(
          (origin) => origin.id == this.content.nonConformity.originId
        );
        return title !== undefined ? title.name : "";
      },
      findNonConformityImpact() {
        let title = this.contextsPopOver.nonConformityImpact.find(
          (impact) => impact.id == this.content.nonConformity.impactId
        );
        return title !== undefined ? title.name : "";
      }
    },
    computed: {
      ...mapState("Appointments", {
        actionAppoint: "actionAppoint"
      })
    }
  };
</script>

<style lang="scss">
  .btn-confirm {
    .appointment-icon {
      margin-right: 6px;
      margin-bottom: 3px;
    }
    &:hover {
      .appointment-icon {
        path {
          fill: #006d3a;
        }
      }
    }
  }
  .swal2-html-container {
    font-family: "Work Sans", sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  .swal2-title {
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
  }
</style>

<style lang="scss" scoped>
  .popover-content {
    .font {
      font-family: "Work Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
    }

    .popover-header-atividades {
      background-color: #1d2c39;
      border-radius: 5px 5px 0px 0px;
      padding: 16px;
      position: relative;
      top: 1px;
      .buttons {
        display: flex;
        gap: 10px;
        margin-top: 16px;
        justify-content: center;
        .btn-popover {
          background-color: transparent;
          padding: 1.5px 10px;
          display: flex;
          align-items: center;
          border: 0;
          color: #ffffff;

          &:hover {
            border-radius: 15px;
            background-color: #d1e8d3;
            color: #005229;
            svg {
              path {
                fill: #005229;
              }
            }
          }

          span {
            font-family: "Work Sans", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
          }
          .btn-icon {
            width: 15px;
            height: 15px;
            margin-right: 3px;
            path {
              fill: #ffffff;
            }
          }

          &:disabled,
          &:disabled:hover {
            background-color: transparent;
            pointer-events: none;
            color: #616b74;
            svg {
              path {
                fill: #616b74;
              }
            }
          }
        }
      }
    }
    .popover-body-atividades {
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #3d5366;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #a9aca7;
        border-radius: 5px;
        border: 3px solid #3d5366;
      }
      background: #3d5366;
      border-radius: 0px 0px 5px 5px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 100%;
      max-height: 366px;
      overflow-y: auto;

      .blocked-day {
        padding: 8px 0;
        background: #2f4050;
        border: 1px solid #1d2c39;
        border-radius: 5px;
        text-align: center;
        margin-bottom: 8px;
      }

      .item-body {
        border-bottom: 1px solid #1d2c39;
        padding-bottom: 8px;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }

        .title-body {
          margin-bottom: 2px;
          text-align: left;
        }
        .content-body {
          font-weight: 400;
          display: inline-block;
          width: calc(100%);
          overflow-x: hidden;
          word-wrap: break-word;
          .icon-hour {
            margin-left: 4px;
          }
        }
      }

      .non-conformity {
        border: 1px solid #1d2c39;
        border-radius: 5px;
        background-color: #2f4050;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .font {
          text-align: center;
        }

        .item-non-conformity-body {
          border-bottom: 1px solid #1d2c39;
          padding-bottom: 8px;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }

          .content-body-non-conformity {
            word-wrap: break-word;
          }
        }
      }
    }
  }
</style>
