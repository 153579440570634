<template>
  <div>
    <Popper
      ref="popper"
      trigger="clickToToggle"
      :disabled="!task.id"
      :options="{
        placement: 'right',
        modifiers: {
          arrow: {
            enabled: true,
            arrowElement: '.popover__arrow'
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window'
          },
          flip: {
            enabled: true
          },
          offset: {
            offset: '100px, 5px'
          }
        }
      }"
      @hide="handleShow"
    >
      <button
        slot="reference"
        class="popper-button"
        :style="`width: ${resizeCard != 0 ? resizeCard : 0}px`"
        @click="handleClick"
      >
        <div
          :id="`appointment-card${
            !['null', 'undefined'].includes(typeof task.id) ? '-' + task.id : ''
          }`"
        >
          <div
            :class="classAppointment"
            @mouseenter="isHover = true"
            @mouseleave="isHover = popOverIsActive == true ? true : false"
          >
            <div
              v-if="!interval && title"
              :style="`width: ${resizeCard != 0 ? resizeCard : 0}px`"
              :class="[
                'AppointmentCardDiv',
                (isHover || popOverIsActive) && 'appointment',
                classAppointment
              ]"
            >
              <div class="title-icon">
                <p class="clientTitle">{{ title.client }}</p>
                <p class="projectTitle">{{ title.project }}</p>
              </div>
              <div>
                <span class="appointmentHours"
                  >{{ formatHour(startAppointment.initialHour, startAppointment.initialMinute) }}
                  •
                  {{ formatHour(endAppointment.endHour, endAppointment.endMinute) }}</span
                >
                <IconTimer
                  v-show="task.appointmentTypeId === 2"
                  class="icon-clock"
                />
              </div>
            </div>
            <div
              v-if="interval"
              :class="['AppointmentCardDiv', 'interval', classAppointment]"
              :style="`width: ${resizeCard != 0 ? resizeCard : 0}px`"
              @click="!isEdit && createTask()"
            >
              <div
                v-if="isHover"
                class="plus-appointment"
              >
                <IconPlus class="icon-plus orange-color" />
                <span class="projectTitle orange-text">Apontamento</span>
              </div>
              <div
                v-else
                class="title-icon"
              >
                <IconTempFilled class="icon-hourglass" />
                <p class="intervalName">Intervalo</p>
              </div>
              <span class="intervalHours"
                >{{ formatHour(startAppointment.initialHour, startAppointment.initialMinute) }}
                •
                {{ formatHour(endAppointment.endHour, endAppointment.endMinute) }}</span
              >
            </div>
          </div>
        </div>
      </button>
      <div
        class="popper"
        :class="[isLoading ? 'popover-loading' : '']"
      >
        <PopOverAtividades
          :calendar-type="calendarType"
          :content="contentTask"
          :contexts-pop-over="contextsAppointmentsActivity"
          @closePopover="closePopover"
          @editTaskAppointment="emitEditTaskId($event)"
          @duplicateTaskAppointment="emitDuplicateTaskId($event)"
          @deletedTaskAppointment="emitDeletTask($event)"
        />
      </div>
    </Popper>
  </div>
</template>

<script>
  import Popper from "vue-popperjs";
  import "vue-popperjs/dist/vue-popper.css";
  import IconTimer from "@/assets/images/icon-timer-appointment.svg";
  import IconTempFilled from "@/assets/images/icon-temp-filled-down.svg";
  import PopOverAtividades from "@/views/ComponentsView/components/PopOverAtividades.vue";
  import IconPlus from "@/assets/images/icon-plus-arrounded.svg";
  import { mapActions, mapState } from "vuex";

  export default {
    components: {
      IconTimer,
      IconTempFilled,
      IconPlus,
      PopOverAtividades,
      Popper
    },
    props: [
      "calendarType",
      "task",
      "title",
      "startAppointment",
      "endAppointment",
      "interval",
      "classAppointment",
      "taskId",
      "date",
      "contextsAppointmentsActivity",
      "canEdit",
      "triggerClosePopover",
      "resizeCard"
    ],
    data() {
      return {
        isLoading: false,
        isHover: false,
        contentTask: null,
        popOverIsActive: false
      };
    },
    computed: {
      ...mapState("Appointments", {
        isEdit: "isEdit"
      })
    },
    methods: {
      ...mapActions("Appointments", ["ActionGetTask"]),
      async handleClick() {
        this.isLoading = true;
        this.popOverIsActive = true;
        if (this.task?.id) {
          this.infoTask(this.task.id);
        }
      },
      handleShow() {
        this.popOverIsActive = false;
        this.isHover = false;
      },
      closePopover() {
        this.$refs.popper.doClose();
        this.popOverIsActive = false;
        this.isHover = false;
      },
      refreshPopover() {
        this.$refs.popper.createPopper();
      },
      emitEditTaskId(value) {
        this.$emit("taskAppointmentEdit", value);
      },
      emitDuplicateTaskId(value) {
        this.$emit("taskAppointmentDuplicate", value);
      },
      emitDeletTask(value) {
        this.$emit("taskAppointmentDeleted", value);
      },
      createTask() {
        this.$emit("createTask", {
          date: this.date,
          hour: {
            start: this.formatHour(
              this.startAppointment.initialHour,
              this.startAppointment.initialMinute
            ),
            end: this.formatHour(this.endAppointment.endHour, this.endAppointment.endMinute)
          }
        });
      },
      formatHour(hour, minute) {
        if (hour < 10) {
          hour = `0${hour}`;
        }
        if (minute < 10) {
          minute = `0${minute}`;
        }
        let time = `${hour}:${minute}`;
        return time;
      },
      infoTask(idTask) {
        this.ActionGetTask(idTask).then(async (response) => {
          this.contentTask = {
            id: idTask,
            canEdit: this.canEdit,
            client: this.title.client,
            project: this.title.project,
            projectId: response.projectId,
            funcionalityId: response.funcionalityId,
            taskTypeId: response.taskTypeId,
            appointmentTypeId: response.appointmentTypeId,
            item: response.item,
            task: response.task,
            nonConformity: response.nonConformity
          };
          this.$nextTick(() => {
            this.isLoading = false;
            this.refreshPopover();
          });
          return response;
        });
      }
    },
    watch: {
      triggerClosePopover(value) {
        if (value) {
          this.closePopover();
        }
      }
    }
  };
</script>

<style lang="scss">
  .popover-loading {
    visibility: hidden !important;
  }
  .popper-button {
    outline: 0;
    border: 0;
    padding: 0;
    background: transparent !important;
  }
  .popper {
    width: 307px !important;
    max-height: 452px !important;
    text-align: left;
    padding: 0 !important;
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14))
      drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  }
  div[x-placement="left"] {
    div[x-arrow] {
      border-color: transparent transparent transparent #1d1d1e !important;
    }
  }
  div[x-placement="right"] {
    div[x-arrow] {
      border-color: transparent #1d1d1e transparent transparent !important;
    }
  }
  .popover-body {
    padding: 0 !important;
    width: 307px !important;
    height: 452px !important;
  }

  .b-popover {
    background: transparent !important;
    color: transparent !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
</style>

<style lang="scss" scoped>
  .week {
    min-width: 163.8px;
    width: 163.8px;
    cursor: pointer;
  }
  .day {
    min-width: 300px;
    width: 300px;
    cursor: pointer;
  }

  .AppointmentCardDiv {
    height: 79px;

    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: #c6c7c3;

    .plus-appointment {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5.5px;

      .icon-plus {
        width: 16px;
        height: 16px;
      }

      .orange-color {
        path {
          fill: #ff6f00;
        }
      }

      .orange-text {
        color: #ff6f00;
      }

      .green-text {
        color: #006d3a;
      }
    }

    .title-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 4px;

      .clientTitle {
        width: 90%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        font-family: "Work Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #1a1c1a;
      }

      .projectTitle {
        width: 90%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        font-family: "Work Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #8f918d;
      }
    }

    .appointmentHours {
      font-family: "Work Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #8f918d;

      &:hover {
        color: #006d3a;
      }
    }

    .icon-clock {
      margin-left: 4px;
      width: 10px;
      height: 10px;

      path {
        fill: #8f918d;
      }
    }
  }

  .interval {
    background: #fff8e1;

    .intervalName {
      font-family: "Work Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ff6f00;
    }

    .intervalHours {
      font-family: "Work Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ff6f00;
    }
  }

  .appointmentActivity {
    height: 79px;
  }

  .appointment {
    background: #bfdaca;
    color: #006d3a;
    border: 1px solid #006d3a;

    .clientTitle,
    .projectTitle {
      color: #006d3a !important;
    }

    .appointmentHours {
      color: #006d3a;
    }

    svg {
      path {
        fill: #006d3a !important;
      }
    }
  }
</style>
