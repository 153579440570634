import ReportsServices from "@/services/Reports";

export const ActionPutFeatureNewVersionReport = ({}, data) => {
  return ReportsServices.putFeatureNewVersionReport(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const ActionGetReport = ({}, data) => {
  return ReportsServices.getFeatureReport(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId
  );
};

export const ActionGetFeatureReportToDownload = ({}, data) => {
  return ReportsServices.getFeatureReportToDownload(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId,
    data.to,
    data.hash
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const ActionGetFeatureReportToVisualize = ({}, data) => {
  return ReportsServices.getFeatureReportToVisualize(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId,
    data.hash
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
