import { api } from "@/utils/request";

export default {
  async getFeatureTypes(projectId, groupId, subgroupId) {
    const data = await api.get(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/featureType`
    );
    return data.data;
  },
  async postFeature(projectId, groupId, subgroupId, payload, config) {
    const data = await api.post(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features`,
      payload,
      config
    );
    return data.data;
  },
  async getFeature(projectId, groupId, subgroupId, featureId) {
    const data = await api.get(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}`
    );
    return data.data;
  },
  async putFeature(projectId, groupId, subgroupId, featureId, payload, config) {
    const data = await api.put(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}`,
      payload,
      config
    );
    return data.data;
  },
  async deleteFeature(projectId, groupId, subgroupId, featureId) {
    const data = await api.delete(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}`
    );
    return data.data;
  },
  async getTreeFeature(projectId, groupId, subgroupId, featureId) {
    const data = await api.get(
      `/projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/tree-component`
    );
    return data.data;
  },
  async putFeatureNewVersionReport(projectId, groupId, subgroupId, featureId) {
    const data = await api.put(
      `/projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/version`
    );
    return data.data;
  },
  async getFeatureReport(projectId, groupId, subgroupId, featureId) {
    const data = await api.get(
      `/projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}`
    );
    return data.data;
  },
  async getDownloadFeatureReport(projectId, groupId, subgroupId, featureId, hash, to) {
    const data = await api.get(
      `/projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/report/${hash}/to/${to}`
    );
    return data.data;
  }
};
