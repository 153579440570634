<template>
  <div>
    <Popper
      ref="popper"
      trigger="clickToOpen"
      :options="{
        placement: 'bottom-start',
        modifiers: {
          preventOverflow: {
            boundariesElement: 'window'
          }
        }
      }"
      @show="handleShow"
      @hide="handleHide"
    >
      <div
        slot="reference"
        :class="['select-btn', 'popper-button', !v.required && v.$anyError && 'error']"
        @click="handleClick"
      >
        <div>
          <p :class="optionSelectedName ? 'option-selected' : 'option-selected inital-placeholder'">
            {{ optionSelectedName ? optionSelectedName : "Selecione" }}
          </p>
        </div>
        <div>
          <IconArrowDown class="icon-arrow-down" />
          <IconCancel
            v-show="allowCloseButton && optionSelectedName"
            class="icon-cancel"
            @click.stop="cleanField"
          />
        </div>
      </div>
      <div
        v-show="isOpenSelect"
        class="content popper"
      >
        <div class="search">
          <input
            ref="inputSearch"
            v-model="filterInput"
            spellcheck="false"
            type="text"
            placeholder="Pesquise aqui"
          />
          <IconSearch class="icon-search" />
        </div>
        <div class="options">
          <div
            v-for="option in filteredOptions"
            :key="option.id"
            :class="optionSelectedId == option.id ? 'option active' : 'option'"
            @click="optionSelect(option.id)"
          >
            <p>{{ option.name }}</p>
          </div>
        </div>
      </div>
    </Popper>
    <div
      v-if="!isOpenSelect && !v.required && v.$anyError"
      class="select-error"
    >
      <p>Campo Obrigatório</p>
    </div>
  </div>
</template>

<script>
  import IconArrowDown from "@/assets/images/icon-arrow-down-modal.svg";
  import IconSearch from "@/assets/images/icon-search.svg";
  import IconCancel from "@/assets/images/icon-cancel.svg";
  import { mapMutations } from "vuex";

  import Popper from "vue-popperjs";
  import "vue-popperjs/dist/vue-popper.css";
  export default {
    components: {
      IconArrowDown,
      IconSearch,
      IconCancel,
      Popper
    },
    data() {
      return {
        filterInput: "",
        allowCloseButton: this.closeButton || false,
        isOpenSelect: false,
        optionSelectedId: null,
        optionSelectedName: null,
        optionsValue: this.options
      };
    },
    props: ["closeButton", "options", "v", "value"],
    methods: {
      ...mapMutations("Appointments", ["setStepControl"]),
      handleShow() {
        this.isOpenSelect = true;
      },
      handleHide() {
        this.isOpenSelect = false;

        if (!this.optionSelectedId) {
          this.v.$touch();
        }
      },
      handleClick() {
        this.filterInput = "";
        this.isOpenSelect = !this.isOpenSelect;
        if (!this.isOpenSelect) {
          this.v.$touch();
        }
        setTimeout(() => {
          this.$refs.inputSearch.focus();
        }, 10);
      },
      optionSelect(value) {
        this.filterInput = "";
        const nameSelected = this.optionsValue.filter((option) => option.id == value);
        this.optionSelectedName = nameSelected[0].name;
        this.isOpenSelect = false;
        this.optionSelectedId = value;
        if (nameSelected[0].functionalityStep != null) {
          this.setStepControl(nameSelected[0].functionalityStep);
        }
      },
      cleanField() {
        this.optionSelectedId = null;
        this.optionSelectedName = null;
      },
      setValue() {
        this.optionSelectedId = this.value;
        let result = this.optionsValue.filter((option) => option.id == this.value);
        this.optionSelectedName = result[0].name;
        if (result[0].functionalityStep != null) {
          this.setStepControl(result[0].functionalityStep);
        }
      }
    },
    computed: {
      filteredOptions() {
        function normalizeString(str) {
          return str
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
        }
        const normalizedSearchTerm = normalizeString(this.filterInput);
        return this.optionsValue.filter((option) => {
          const normalizedName = normalizeString(option.name);
          return normalizedName.includes(normalizedSearchTerm);
        });
      }
    },
    watch: {
      optionSelectedId() {
        if (this.optionSelectedId == null || this.optionSelectedId == "") {
          this.$emit("cleanDescription");
          this.optionSelectedName = null;
        }
        this.$emit("optionSelectedId", this.optionSelectedId);
      },
      options() {
        this.optionsValue = this.options;
        this.setValue();
      },
      value() {
        this.setValue();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .icon-arrow-down {
    width: 24px;
  }
  .icon-search {
    width: 15px;
  }
  .icon-cancel {
    width: 16px;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Work Sans", sans-serif;
  }
  .select-btn,
  li {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .select-btn {
    height: 28px;
    padding: 4.5px 5px 4.5px 10px;
    font-family: "Work Sans", sans-serif;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid var(--cores-padro-grey-400, #b8b8b9);
    background: var(--cores-principais-neutral-100, #fff);
    justify-content: space-between;

    .option-selected {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .inital-placeholder {
      color: var(--cores-principais-neutral-80, #c6c7c3);
    }

    &.error {
      border-color: #dc3546;
    }
  }
  .select-btn div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 310px;
  }
  .select-btn svg {
    font-size: 31px;
  }

  .content {
    max-height: 250px !important;
    width: 375px !important;
    padding: 5px !important;
    margin-top: 5px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 10px 0px rgba(0, 0, 0, 0.12),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14);
    position: absolute;
    z-index: 1;
  }
  .popper {
    filter: none;
    background-color: #fff !important;
  }
  .content .search {
    display: flex;
    padding: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    svg {
      top: 50%;
      left: 92%;
      color: #999;
      font-size: 20px;
      pointer-events: none;
      transform: translateY(-50%);
      position: absolute;
    }
    input {
      height: 28px;
      width: 100%;
      outline: none;
      font-family: "Work Sans", sans-serif;
      font-size: 14px;
      border-radius: 3px;
      padding: 0 25px 0 5px;
      border: 1px solid #b3b3b3;
    }
  }
  .search input::placeholder {
    color: #c6c7c3;
  }
  .content .options {
    max-height: 180px;
    overflow-y: auto;
    padding-right: 0 5px;
    color: #1a1c1a;

    .active {
      background-color: #006d3a;
      color: #ffffff;
      border-radius: 3px;
    }
    div {
      height: 100%;
      padding: 7px 5px;
      font-size: 14px;

      &:hover {
        border-radius: 5px;
        background: #d1e8d3;
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    *::-webkit-scrollbar-track {
      background: #ffffff;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #a9aca7;
      border-radius: 5px;
      border: 3px solid #ffffff;
    }
  }
  .icon-search {
    path {
      fill: #c6c7c3;
    }
  }
  .option {
    font-family: "Work Sans", sans-serif;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .select-error {
    margin: 0 0 5px 0;
    p {
      color: #dc3546;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }
  }
  .formSelect {
    span {
      display: flex;
      flex-direction: column-reverse;
    }
  }
</style>
