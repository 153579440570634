<template>
  <Draggable
    :list="list"
    tag="div"
    :group="{ name: 'components' }"
    @start="saveComponentIdOnDragging"
    @end="changeOrder($event)"
  >
    <div
      v-for="elem in list"
      :key="elem.id"
      :draggable-key="elem.id"
    >
      <ul v-if="elem && !elem.hidden">
        <li class="li-tree-component">
          <div class="line-align-comp">
            <div
              :class="[
                'draggable-item',
                elem.id == componentEditId && !elem.highlightOff && 'componentBeingEdited',
                elem.createdNow && 'active-modal'
              ]"
              @click.stop="openComponent(blockOutside, $event, elem)"
            >
              <button
                v-if="elem.components.length != 0"
                type="button"
                class="btn-toggle"
                @click="toggleComponents(elem, false)"
              >
                <IconToggleDown
                  v-if="elem.open || !('open' in elem)"
                  style="margin-left: 1.5px"
                />
                <IconToggle v-else />
              </button>
              <p
                v-if="!elem.createdNow"
                class="name-tree"
                :class="elem.copyNotEdit == true ? 'copied-not-edit' : ''"
              >
                <TitleTreeComp
                  :title="elem.name"
                  :title-format-comp="formatNameTree(elem.name.replace(/\\(.)/g, '$1'))"
                />
              </p>
              <p
                v-else
                class="name-tree name-tree-new text-format"
              >
                <span v-html="formatNameTree(elem.name)"></span>
              </p>

              <div
                v-if="!elem.createdNow"
                class="options-item-draggable"
              >
                <button
                  v-if="elem.allowChildren"
                  v-b-tooltip.hover="{
                    title: 'Adicionar Subcomponente',
                    placement: 'topleft',
                    boundary: 'window'
                  }"
                  type="button"
                  class="btn-new"
                  @click.stop="openComponentEdit(blockOutside, $event, elem)"
                >
                  <IconPlus />
                </button>

                <button
                  v-if="!elem.copyNotEdit"
                  v-b-tooltip.hover="{
                    title: 'Duplicar Componente',
                    html: true,
                    placement: 'topleft',
                    boundary: 'window'
                  }"
                  type="button"
                  class="btn-duplicate-funcionality"
                  @click.stop="openModalDuplicate(elem.id, elem.name, elem)"
                >
                  <IconDuplicate />
                </button>
                <button
                  v-else
                  v-b-tooltip.hover="{
                    title: 'Editar registro duplicado antes de gerar uma nova versão.',
                    html: true,
                    placement: 'topright',
                    boundary: 'window',
                    customClass: 'tooltip-lg'
                  }"
                  type="button"
                  class="icon-not-edit btn-new-version copied-not-edited"
                >
                  <IconVersion />
                </button>
              </div>
            </div>
          </div>
          <DraggableComp
            v-if="forceRenderTreeDraggable"
            v-show="elem.open == null || elem.open"
            :list="elem.components"
            :block-outside="blockOutside"
            :component-edit-id="componentEditId"
            :component-parent-id="elem.id"
            :component-parent-type-id="elem.ComponentId"
            :term-filter-tree-components="termFilterTreeComponents"
            @copyItem="openModalDuplicate"
            @newComponent="newComponent"
            @editComponent="editComponent"
            @reloadTreeComponent="reloadTreeComponent"
            @openDiscardChanges="openDiscardChanges"
            @undoChangesTree="undoChangesTree"
            @reloadFeature="reloadFeature"
            @forceRenderTreeComponentsToggle="forceRenderTreeComponentsToggle"
          />
        </li>
      </ul>
    </div>
  </Draggable>
</template>

<script>
  import IconPlus from "@/assets/images/icon-plus-arrounded.svg";
  import IconToggle from "@/assets/images/icon-arrow-right.svg";
  import IconToggleDown from "@/assets/images/icon-arrow-down.svg";
  import IconDuplicate from "@/assets/images/icon-duplicate-hover.svg";
  import IconVersion from "@/assets/images/icon-paper-archive.svg";
  import Draggable from "vuedraggable";
  import { mapActions, mapMutations, mapState } from "vuex";
  import TitleTreeComp from "./TitleTreeComp.vue";

  export default {
    name: "DraggableComp",
    props: [
      "list",
      "componentParentTypeId",
      "componentParentId",
      "termFilterTreeComponents",
      "blockOutside",
      "componentEditId",
      "forceCloseComponent"
    ],
    components: {
      IconPlus,
      IconToggle,
      IconToggleDown,
      IconDuplicate,
      Draggable,
      TitleTreeComp,
      IconVersion
    },
    data() {
      return {
        compOverflowActive: true,
        forceRenderTreeDraggable: true,
        componentIdOnDragging: null,
        componentTypeIdOnDragging: null,
        scrollPos: 0,
        loaded: false,
        afterReloadScroll: 0,
        closedChildTree: []
      };
    },
    methods: {
      ...mapActions("Components", ["ActionPutComponentOrder"]),
      ...mapMutations("CompFeat", ["setElemToOpen", "setIsNewSubComponent"]),
      openModalDuplicate(id, compId, name) {
        this.$emit("copyItem", id, compId, name);
      },
      openComponent(blockOutside, $event, elem) {
        if (blockOutside) {
          this.openDiscardChanges($event, elem);
        } else {
          this.editComponent(elem.id, elem.ComponentId);
        }
      },
      openComponentEdit(blockOutside, $event, elem) {
        this.setIsNewSubComponent(true);
        if (blockOutside) {
          this.openDiscardChanges($event, elem);
        } else {
          this.newComponent(elem);
        }
      },
      reloadFeature() {
        this.reloadTreeDraggable();
        this.$emit("reloadFeature");
      },
      reloadTreeDraggable() {
        this.forceRenderTreeDraggable = false;
        this.$nextTick().then(() => {
          this.forceRenderTreeDraggable = true;
        });
      },
      saveComponentIdOnDragging(e) {
        this.$emit("saveActualTree");
        this.componentIdOnDragging = this.list[e?.oldIndex]?.id;
        this.componentTypeIdOnDragging = this.list[e?.oldIndex]?.ComponentId;
      },
      async changeOrder(e) {
        if (this.blockOutside) {
          let prossegue = false;
          await this.$swal({
            customClass: "swal-warning",
            title: "Atenção",
            text: "Você tem alterações não salvas. Deseja descartar as alterações?"
          }).then((result) => {
            if (result.isConfirmed) {
              this.reloadFeature();
              this.$emit("handleDiscard", true);
              prossegue = true;
              this.saveOrder(e);
            }
          });

          if (!prossegue) {
            this.undoChangesTree();
            return;
          }
        }
        this.saveOrder(e);
      },
      saveOrder(e) {
        let projectId = this.$route.params.projectId || null;
        let groupId = this.$route.params.groupId || null;
        let subgroupId = this.$route.params.subgroupId || null;
        let featureId = this.$route.params.featureId || null;
        let componentTypeId = this.componentTypeIdOnDragging;
        let componentId = this.componentIdOnDragging;
        const payload = {
          order: e.newIndex + 1
        };

        if (e.to.parentNode.parentNode.parentNode.attributes["draggable-key"]) {
          payload["elementId"] = parseInt(
            e.to.parentNode.parentNode.parentNode.attributes["draggable-key"].value
          );
        }
        let el = document.querySelector(".content");
        this.ActionPutComponentOrder({
          projectId,
          groupId,
          subgroupId,
          featureId,
          componentTypeId,
          componentId,
          payload
        })
          .then(() => {
            this.reloadFeature();
            setTimeout(() => {
              el.scrollTo(0, this.afterReloadScroll);
            }, 10);
          })
          .catch(() => {
            this.modalMovimentBlocked();
            this.undoChangesTree();
          });
        this.scrollPos = el.scrollTop;
      },
      modalMovimentBlocked() {
        this.$swal({
          customClass: "swal-danger",
          title: "Erro",
          text: "Não é possível mover  itens da árvore de componentes que não respeitem as relações disponíveis entre componentes.",
          confirmButtonText: "Fechar",
          showCancelButton: false
        });
      },
      undoChangesTree() {
        this.$emit("undoChangesTree");
      },
      openDiscardChanges(e, elem) {
        this.setElemToOpen(elem);
        this.$emit("openDiscardChanges", e);
      },
      formatNameTree(name) {
        // Formata os nomes dos itens (grupo ou subgrupo) destacando o termo pesquisado
        if (this.termFilterTreeComponents != "") {
          let compName = name.replace(
            new RegExp("(?<!\w)(" + this.termFilterTreeComponents + ")(?!\w)", "gi"),
            '<span class="mark-term-search">$1</span>'
          );
          return compName;
        } else {
          return name;
        }
      },
      editComponent(componentId, componentTypeId) {
        if (!this.elemToOpen) {
          this.$emit("editComponent", componentId, componentTypeId);
        } else {
          this.$emit("editComponent");
        }
      },
      newComponent(elem = null) {
        let el = document.querySelector(".content");
        this.scrollPos = el.scrollTop;
        this.$emit("newComponent", elem);
      },
      reloadTreeComponent() {
        this.reloadTreeDraggable();
        this.$emit("reloadTreeComponent");
      },
      toggleComponents(elem, bool) {
        if (bool && (typeof elem.open == "undefined" || elem.open == true)) {
          return null;
        }
        // Exibe/esconde os subcomponentes do componente selecionado
        let closedIds = sessionStorage.getItem("closedIds")
          ? JSON.parse(sessionStorage.getItem("closedIds"))
          : [];
        if (typeof elem.open == "undefined" || elem.open == true) {
          elem["open"] = false;
          !closedIds.includes(elem.id) && closedIds.push(elem.id);
        } else {
          elem["open"] = true;
          const index = closedIds.findIndex((id) => id === elem.id);
          index >= 0 && closedIds.splice(index, 1);
        }
        sessionStorage.setItem("closedIds", JSON.stringify(closedIds));
        this.$emit("forceRenderTreeComponentsToggle");
      },
      verifyClosedIds(array) {
        // Array recursivo para fechar os Id's que estão salvos no sessionStorage
        let closedIds = sessionStorage.getItem("closedIds")
          ? JSON.parse(sessionStorage.getItem("closedIds"))
          : [];
        let filteredArray = this.arrayComplete(array);
        filteredArray.map((item) => {
          if (closedIds.includes(item.id)) {
            item["open"] = false;
          }
        });
      },
      arrayComplete(array) {
        let elements = [];
        for (let element of array) {
          if (element.id) {
            elements.push(element);
          }
          if (element.components && Array.isArray(element.components)) {
            elements = elements.concat(this.arrayComplete(element.components));
          }
        }
        return elements;
      },
      forceRenderTreeComponentsToggle() {
        this.reloadTreeComponent();
      }
    },
    beforeMount() {
      this.saveComponentIdOnDragging();
    },
    updated() {
      this.loaded = true;
    },
    watch: {
      scrollPos(newValue, _) {
        // Faz o scroll descer até o newValue
        let el = document.querySelector(".content");
        this.afterReloadScroll = newValue;
        if (this.loaded) {
          setTimeout(() => {
            el.scrollTo(0, newValue);
          }, 10);
        }
      },
      forceCloseComponent() {
        // Ao criar/excluir um componente impede o scroll de subir e mantem os itens que estavam fechados
        let el = document.querySelector(".content");
        this.scrollPos = el.scrollTop;
        this.reloadTreeDraggable();
        this.verifyClosedIds(this.list);
      }
    },
    computed: {
      ...mapState("CompFeat", {
        elemToOpen: "elemToOpen",
        isNewSubComponent: "isNewSubComponent"
      })
    }
  };
</script>

<style lang="scss">
  div ul * li div.line-align-comp,
  li * .line-align-comp ~ div {
    border-left: 1px solid #006d3a;
    padding-left: 5px;
  }

  .name-tree {
    width: 100%;
  }
  .copied-not-edit {
    span {
      color: #c02333 !important;
    }
  }
  .icon-not-edit {
    svg {
      path {
        fill: #bf360c;
      }
    }
  }
</style>
