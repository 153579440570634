var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Popper',{ref:"popper",attrs:{"trigger":"clickToOpen","options":{
      placement: 'bottom-start',
      modifiers: {
        preventOverflow: {
          boundariesElement: 'window'
        }
      }
    }},on:{"show":_vm.handleShow,"hide":_vm.handleHide}},[_c('div',{class:['select-btn', 'popper-button', !_vm.v.required && _vm.v.$anyError && 'error'],attrs:{"slot":"reference"},on:{"click":_vm.handleClick},slot:"reference"},[_c('div',[_c('p',{class:_vm.optionSelectedName ? 'option-selected' : 'option-selected inital-placeholder'},[_vm._v(" "+_vm._s(_vm.optionSelectedName ? _vm.optionSelectedName : "Selecione")+" ")])]),_c('div',[_c('IconArrowDown',{staticClass:"icon-arrow-down"}),_c('IconCancel',{directives:[{name:"show",rawName:"v-show",value:(_vm.allowCloseButton && _vm.optionSelectedName),expression:"allowCloseButton && optionSelectedName"}],staticClass:"icon-cancel",on:{"click":function($event){$event.stopPropagation();return _vm.cleanField.apply(null, arguments)}}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpenSelect),expression:"isOpenSelect"}],staticClass:"content popper"},[_c('div',{staticClass:"search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterInput),expression:"filterInput"}],ref:"inputSearch",attrs:{"spellcheck":"false","type":"text","placeholder":"Pesquise aqui"},domProps:{"value":(_vm.filterInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filterInput=$event.target.value}}}),_c('IconSearch',{staticClass:"icon-search"})],1),_c('div',{staticClass:"options"},_vm._l((_vm.filteredOptions),function(option){return _c('div',{key:option.id,class:_vm.optionSelectedId == option.id ? 'option active' : 'option',on:{"click":function($event){return _vm.optionSelect(option.id)}}},[_c('p',[_vm._v(_vm._s(option.name))])])}),0)])]),(!_vm.isOpenSelect && !_vm.v.required && _vm.v.$anyError)?_c('div',{staticClass:"select-error"},[_c('p',[_vm._v("Campo Obrigatório")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }