<script>
  import { mapActions } from "vuex";
  export default {
    methods: {
      ...mapActions("Reports", ["ActionGetFeatureReportToVisualize"])
    },
    mounted() {
      let projectId = this.$route.params.projectId;
      let groupId = this.$route.params.groupId;
      let subgroupId = this.$route.params.subgroupId;
      let featureId = this.$route.params.featureId;
      let hash = this.$route.query.hash;

      this.ActionGetFeatureReportToVisualize({
        projectId,
        groupId,
        subgroupId,
        featureId,
        hash
      }).then((response) => {
        document.open();
        document.write(response);
        document.close();
      });
    }
  };
</script>
