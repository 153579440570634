<template>
  <Draggable
    :list="list"
    :group="{ name: 'components' }"
    :disabled="true"
  >
    <div
      v-for="elem in list"
      :key="elem.id"
      :draggable-key="elem.id"
    >
      <ul>
        <li class="li-tree-component">
          <div class="line-align-comp">
            <div
              class="draggable-item"
              :class="valueSelected == elem.id ? 'selected' : ''"
              @click="selectDestiny(elem.id)"
            >
              <button
                v-if="elem.components.length != 0"
                class="btn-toggle"
                type="button"
                @click.stop="toggleComponents(elem)"
              >
                <IconToggleDown
                  v-if="elem.open"
                  style="margin-left: 1.5px"
                />
                <IconToggle v-else />
              </button>
              <p
                class="name-tree"
                v-html="elem.name.replace(/\\(.)/g, '$1')"
              ></p>
            </div>
          </div>
          <DraggableDestiny
            v-if="forceRenderTreeDraggable"
            v-show="elem.open"
            :value-selected="valueSelected"
            :list="elem.components"
            @input="selectDestiny"
            @forceRenderTreeComponentsToggle="forceRenderTreeComponentsToggle"
            @reloadTreeComponent="reloadTreeComponent"
          />
        </li>
      </ul>
    </div>
  </Draggable>
</template>

<script>
  import Draggable from "vuedraggable";
  import IconToggleDown from "@/assets/images/icon-arrow-down.svg";
  import IconToggle from "@/assets/images/icon-arrow-right.svg";
  export default {
    name: "DraggableDestiny",
    data() {
      return {
        forceRenderTreeDraggable: true,
        elemSelected: null
      };
    },
    components: {
      Draggable,
      IconToggleDown,
      IconToggle
    },
    props: ["list", "valueSelected"],
    methods: {
      toggleComponents(elem) {
        if (typeof elem.open == "undefined" || elem.open == false) {
          elem["open"] = true;
        } else {
          elem["open"] = false;
        }
        this.$emit("forceRenderTreeComponentsToggle");
      },
      selectDestiny(value) {
        this.$emit("input", value);
      },
      forceRenderTreeComponentsToggle() {
        this.reloadTreeComponent();
      },
      reloadTreeComponent() {
        this.reloadTreeDraggable();
        this.$emit("reloadTreeComponent");
      },
      reloadTreeDraggable() {
        this.forceRenderTreeDraggable = false;
        this.$nextTick().then(() => {
          this.forceRenderTreeDraggable = true;
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .li-tree-component {
    padding-left: 10px;

    .draggable-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      padding: 5px 15px;
      padding-left: 20px;
      position: relative;

      .btn-toggle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
        transition: 0.2s;

        svg {
          width: 24px;
        }
      }

      .name-tree {
        font-family: "Work Sans", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #1a1c1a;
      }
    }

    .selected {
      background: #d1e8d3;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
        0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 5px;

      .name-tree {
        color: #006d3a;
      }
    }
  }
</style>
