<template>
  <div class="modalAppointment">
    <Overlay :is-active="isLoading" />
    <AppointmentComp
      ref="appointmentCompRef"
      :timer="timer"
      :action-modal.sync="actionModal"
      :task-id-edit.sync="taskIdEdit"
      :timer-appoint-info="timerAppointInfos"
      :create-task="createTaskInfo"
      :close-modal="discardAppointment"
      @formAppointmentChange="updateFormAppointment"
      @formAppointmentStartChange="updateFormAppointmentStart"
      @discardAppointmentCloseModal="canCloseModal = $event"
      @alterDateAppointmentValue="alterDateAppointmentValue"
      @contextTaskType="contextsAppointments.contextTaskType = $event"
      @contextTaskItemType="contextsAppointments.contextTaskItemType = $event"
      @nonConformityOrigin="contextsAppointments.nonConformityOrigin = $event"
      @nonConformityImpact="contextsAppointments.nonConformityImpact = $event"
      @updateGridAppointments="updateGrid = $event"
      @closeModalAppointment="closeModalAppointment"
      @editTaskTg="editTaskTg"
      @createTaskTg="createTaskTg"
      @changeViewWeek="changeViewWeek"
      @resetTimer="resetTimer"
    />
    <AppointmentActivity
      ref="AppointmentActivityRef"
      :contexts-appointments="contextsAppointments"
      :update-grid="updateGrid"
      :get-dates-actv="getDatesActv"
      :date-appointment-value="dateAppointmentValue"
      @closeModal="closeModalAppointment"
      @editTask="editTask($event)"
      @duplicateTask="duplicateTask($event)"
      @createTask="createTask"
    />
  </div>
</template>

<script>
  import AppointmentComp from "./AppointmentComp.vue";
  import AppointmentActivity from "./ActivityComp";
  import Overlay from "@/components/Overlay.vue";
  import { mapMutations, mapState } from "vuex";
  export default {
    data() {
      return {
        formAppointment: {
          projectValue: this.projectCardId ? this.projectCardId : "",
          funcionalityValue: this.functionalityCardId ?? "",
          taskValue: "",
          itemTypeValue: null,
          itemTypeId: null,
          hour: null,
          description: "",
          originValue: null,
          descriptionNonConformity: "",
          selectImpact: "",
          dateValue: new Date(),
          appointmentTypeId: 1
        },
        formAppointmentReset: {
          projectValue: this.projectCardId ? this.projectCardId : "",
          funcionalityValue: this.functionalityCardId ?? "",
          taskValue: "",
          itemTypeValue: null,
          itemTypeId: null,
          hour: null,
          description: "",
          originValue: null,
          descriptionNonConformity: "",
          selectImpact: "",
          dateValue: new Date(),
          appointmentTypeId: 1
        },
        dates: [],
        formAppointmentStart: null,
        canCloseModal: null,
        discardAppointment: false,
        actionModal: this.action,
        updateGrid: false,
        taskIdEdit: null,
        createTaskInfo: null,
        dateAppointmentValue: new Date(),
        contextsAppointments: {
          contextTaskType: [],
          contextTaskItemType: [],
          nonConformityOrigin: [],
          nonConformityImpact: []
        }
      };
    },
    props: ["action", "timerAppointInfos", "projectCardId", "timer", "functionalityCardId"],
    components: {
      AppointmentComp,
      AppointmentActivity,
      Overlay
    },
    methods: {
      ...mapMutations(["SET_MODAL_APPOINTMENT"]),
      updateFormAppointment(value) {
        this.formAppointment = value;
      },
      updateFormAppointmentStart(value) {
        this.formAppointmentStart = value;
      },
      alterDateAppointmentValue(e, callback) {
        this.dateAppointmentValue = e;
        !!callback && callback();
      },
      getDatesActv(array) {
        this.dates = array.map((data) => data.setHours(0, 0, 0));
      },

      changeViewWeek(date) {
        const dataFormat = new Date(date).setHours(0, 0, 0, 0);
        const checkDates = this.dates.find((dia) => {
          const diaFormat = new Date(dia).setHours(0, 0, 0, 0);
          return diaFormat === dataFormat;
        });
        if (!checkDates) {
          this.$refs.AppointmentActivityRef.optionSelect(this.optionViewSelected);
        }
      },

      createTask(info) {
        if (this.showDiscardbutton) {
          this.$refs.appointmentCompRef.discardAppointment(info);
        } else {
          this.createTaskTg(info);
        }
      },

      createTaskTg(info) {
        this.actionModal = "new";
        this.createTaskInfo = info;
      },

      editTask(value) {
        if (this.showDiscardbutton) {
          this.$refs.appointmentCompRef.discardAppointment(value);
        } else {
          this.editTaskTg(value);
        }
      },
      editTaskTg(value) {
        this.actionModal = value.action;
        this.taskIdEdit = value.id;
      },
      duplicateTask(value) {
        if (this.showDiscardbutton) {
          this.$refs.appointmentCompRef.discardAppointment(value);
        } else {
          this.duplicateTaskTg(value);
        }
      },

      duplicateTaskTg(value) {
        this.actionModal = value.action;
        this.taskIdEdit = value.id;
      },

      closeModalAppointment() {
        if (this.actionAppoint != "new") {
          this.$refs.appointmentCompRef.discardAppointment();
        } else {
          this.SET_MODAL_APPOINTMENT(false);
        }
      },
      resetTimer() {
        this.$emit("resetTimer");
      }
    },
    watch: {
      actionModal(value) {
        this.$emit("update:action", value);
      },
      canCloseModal(value) {
        if (value) {
          this.$emit("closeModalAppointment");
        }
      }
    },
    mounted() {
      document.querySelector(".main").style.overflow = "hidden";
    },
    destroyed() {
      document.querySelector(".main").style.overflow = "";
    },
    computed: {
      ...mapState("Appointments", {
        actionAppoint: "actionAppoint",
        optionViewSelected: "optionViewSelected"
      }),
      ...mapState("Loader", {
        isLoading: "isLoading"
      }),
      showDiscardbutton: function () {
        const reset = JSON.parse(JSON.stringify(this.formAppointmentReset));
        const inicial = JSON.parse(JSON.stringify(this.formAppointmentStart));
        const atual = JSON.parse(JSON.stringify(this.formAppointment));
        if (!atual.hour) {
          inicial.hour = {};
          atual.hour = {};
        }
        inicial.dateValue = null;
        atual.dateValue = null;
        if (Object.keys(this.formAppointmentStart).length == 0) {
          return false;
        } else if (JSON.stringify(atual) != JSON.stringify(inicial)) {
          return false;
        } else {
          return JSON.stringify(atual) == JSON.stringify(reset);
        }
      }
    }
  };
</script>

<style lang="scss">
  .modalAppointment {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #4f6353;
    z-index: 1000;
    overflow: hidden;
    justify-content: center;

    *::-webkit-scrollbar {
      width: 10px;
    }

    *::-webkit-scrollbar-track {
      background: #ffffff;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #a9aca7;
      border-radius: 5px;
      border: 3px solid #ffffff;
    }
  }
</style>
