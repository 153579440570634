import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import rootStore from "./root";

Vue.use(Vuex);

const mergedModules = Object.assign({}, modules, {
  root: rootStore
});

export default new Vuex.Store({
  modules: mergedModules
});
