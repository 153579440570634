<template>
  <div class="area">
    <div
      v-if="showMandatoryMessage"
      class="div-error"
      @click="$refs['input-tag-' + id].focus()"
    >
      <IconDangerous class="icon-error" />
      <p>Campo Obrigatório</p>
    </div>
    <div
      class="container-tag"
      @click="$refs['input-tag-' + id].focus()"
    >
      <div
        v-for="(item, index) in lst"
        :key="index"
        class="tag"
      >
        <div class="text-tag">
          {{ item }}
        </div>
        <a @mousedown="removeTag(index)"><IconLittleClose class="icon-tag" /></a>
      </div>
      <input
        id="input-tag"
        :ref="'input-tag-' + id"
        v-model="text"
        :placeholder="showMandatoryMessage ? '' : lst.length == 0 && placeholder"
        name="input"
        type="text"
        @keyup="createTag($event)"
        @blur="blurEvents($event)"
        @focus="focusField"
      />
    </div>
  </div>
</template>

<script>
  import IconLittleClose from "@/assets/images/icon-little-close.svg";
  import IconDangerous from "@/assets/images/icon-dangerous-filled.svg";

  export default {
    components: {
      IconLittleClose,
      IconDangerous
    },
    props: {
      id: {
        default: ""
      },
      value: {
        default: () => {
          [];
        }
      },
      mandatory: {
        type: Boolean,
        default: false
      },
      touchedForm: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        lst: [],
        text: "",
        mandatoryShow: true,
        touchedField: false,
        placeholder: "vazio",
        noText: false,
        createChip: false
      };
    },
    watch: {
      touchedForm() {
        if (this.mandatory && this.valueTxta == "") {
          this.mandatoryShow = this.mandatory;
        }
      }
    },
    computed: {
      showMandatoryMessage: function () {
        return (
          this.mandatory &&
          this.mandatoryShow &&
          (this.touchedField || this.touchedForm) &&
          this.lst.length == 0
        );
      }
    },
    methods: {
      blurEvents(event) {
        this.createTag(event, true);
        this.blurField();
      },
      blurField() {
        this.mandatoryShow = true;
      },
      focusField() {
        this.touched();
        this.mandatoryShow = false;
        this.createChip = true;
      },
      touched() {
        this.touchedField = true;
        this.validateField();
      },
      validateField() {
        if (this.lst.length == 0 && this.touchedField && this.mandatory) {
          this.mandatoryShow = true;
        }
      },
      createTag(e, onblur = false) {
        if (e.keyCode == 8) {
          if (this.createChip && this.text == "") {
            this.removeTag(this.lst.length - 1);
          } else if (this.text == "" && !this.noText) {
            this.noText = true;
          } else if (this.text == "" && this.noText) {
            this.removeTag(this.lst.length - 1);
          } else {
            this.noText = false;
            this.createChip = false;
          }
        }
        if (e.keyCode === 188 || e.keyCode === 13 || e.keyCode === 191 || onblur == true) {
          let listInput = e.target.value.replace(";", "").split(",");
          if (listInput[0].length > 0 && listInput[0] != "") {
            this.lst.push(listInput[0]);
          }
          this.createChip = true;
          this.text = "";
          this.$emit("input", this.lst);
        }
      },
      removeTag(index) {
        let firstArr = this.lst.slice(0, index);
        let secondArr = this.lst.slice(index + 1);
        this.lst = [...firstArr, ...secondArr];
        this.$emit("input", this.lst);
      }
    },
    mounted() {
      if (this.value.length > 0) {
        this.lst = this.value;
      }
    }
  };
</script>

<style lang="scss">
  .area:focus-within {
    padding: 2px 9px;
    border: 1px solid #5c5f5b;
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 100%;

    .container-tag {
      .tag {
        height: 23px;
        background: #006d3a;
        color: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 2px 5px 2px 5px;
        font-size: 14px;
        line-height: 19px;
        font-weight: 400;
        margin-right: 5px;

        input {
          margin: 0;
        }

        .text-tag {
          white-space: nowrap;
        }

        a {
          cursor: pointer;
          .icon-tag {
            display: block;
            width: unset !important;
            height: unset !important;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .area {
    position: relative;
    padding: 2px 9px;
    border: 1px solid transparent;
    height: 100%;
    width: 100%;

    .div-error {
      /* display: flex;
        height: 29px;
        position: relative;
        right: 10px; */
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      display: flex;
      position: absolute;

      .icon-error {
        margin-right: 5px;
      }
      p {
        margin: 0;
        font-size: 14px;
        line-height: 1.35em;
        font-weight: 600;
        color: #ef3e4d;
      }
    }
    input {
      background: transparent;
      border-color: transparent;
      outline: none;
    }

    input::placeholder {
      color: #c6c7c3;
      margin-right: 2px;
    }

    .container-tag {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      .tag {
        display: inline-block;
        background: #e1e3de;
        border-radius: 5px;
        color: #1a1c1a;
        width: fit-content;
        padding: 2px 5px;
        font-size: 14px;
        line-height: 19px;
        margin-right: 5px;
        height: 23px;

        .text-tag {
          white-space: nowrap;
        }

        .icon-tag {
          display: none;
        }
      }
      input {
        border-left: none;
      }
    }
  }
</style>
