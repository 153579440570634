import { api } from "@/utils/request";

export default {
  async getTimerStatus() {
    const data = await api.get(`/timer/status`);
    return data.data.data;
  },
  async postTimeStart(payload) {
    const data = await api.post(`/task/timer/start`, payload);
    return data.data.data;
  },
  async postTimeStop(payload) {
    const data = await api.post(`/task/timer/stop`, payload);
    return data.data.data;
  },
  async deleteTimeDiscard() {
    const data = await api.delete(`/task/timer/discard`);
    return data.data.data;
  },
  async postTask(payload) {
    const data = await api.post(`/task`, payload);
    return data.data.data;
  },
  async putTask(id, payload) {
    const data = await api.put(`/task/${id}`, payload);
    return data.data.data;
  },
  async getTaskDate(startDate, endDate = "") {
    const data = await api.get(
      `/tasks?startDate=${startDate}${endDate != "" ? `&endDate=${endDate}` : ""}`
    );
    return data.data.data;
  },
  async getTask(id) {
    const data = await api.get(`/task/${id}`);
    return data.data.data;
  },
  async deleteTask(id) {
    const data = await api.delete(`/task/${id}`);
    return data.data.data;
  },
  async contextProject() {
    const data = await api.get(`/context/project`);
    return data.data.data;
  },
  async contextStages() {
    const data = await api.get(`/context/funcionality/stage`);
    return data.data.data;
  },
  async contextFuncionality(id) {
    const data = await api.get(`/context/funcionality/${id}`);
    return data.data.data;
  },
  async contextTaskType() {
    const data = await api.get(`/context/task-type`);
    return data.data.data;
  },
  async contextNonConformityOrigin() {
    const data = await api.get(`/context/nonconformity/origin`);
    return data.data.data;
  },
  async contextNonConformityImpact() {
    const data = await api.get(`/context/nonconformity/impact`);
    return data.data.data;
  },
  async contextAppointmentType() {
    const data = await api.get(`/context/task/appointment-type`);
    return data.data.data;
  },
  async contextHolidayType() {
    const data = await api.get(`/context/holiday-type`);
    return data.data.data;
  },
  async contextTaskItemTypes() {
    const data = await api.get(`/context/task-item-types`);
    return data.data.data;
  },
  async contextFuncionalityItemType(projectid, featureid) {
    const data = await api.get(`/project/${projectid}/feature/${featureid}/type-item`);
    return data.data.data;
  },
  async startTimer(body) {
    const data = await api
      .post(`/task/timer/start`, body)
      .then((data) => data.data)
      .catch((err) => ({ errors: [err] }));

    return data;
  },
  async stopTimer(body) {
    const data = await api
      .post(`/task/timer/stop`, body)
      .then((data) => data.data.data)
      .catch(() => ({}));

    return data;
  },
  async deleteTimer() {
    const data = await api
      .delete(`/task/timer/discard`)
      .then(() => true)
      .catch(() => false);

    return data;
  },
  async getRunningTimer() {
    const data = await api
      .get(`/timer/status`, { headers: { ignoreLoading: true } })
      .then((data) => data.data.data)
      .catch(() => ({}));
    return data;
  }
};
