<template>
  <PopOver>
    <template v-slot:trigger>
      <IconTemp style="cursor: pointer" />
    </template>
    <template v-slot:content>
      <div class="divAppointment">
        <div class="divContent">
          <IconPencil class="iconPencil" />
          <div
            class="text"
            @click="openModalAppointment"
          >
            <p>Apontamento Manual</p>
          </div>
        </div>
        <div class="divContent">
          <IconTimer class="iconTimer" />
          <div
            class="text"
            @click="startTimer"
          >
            <p>Iniciar Temporizador</p>
          </div>
        </div>
      </div>
    </template>
  </PopOver>
</template>

<script>
  import PopOver from "@/components/PopOver.vue";
  import IconTemp from "@/assets/images/icon-temp.svg";
  import IconPencil from "@/assets/images/icon-pencil2.svg";
  import IconTimer from "@/assets/images/icon-timer.svg";
  export default {
    components: {
      PopOver,
      IconTemp,
      IconPencil,
      IconTimer
    },
    methods: {
      openModalAppointment() {
        this.$emit("openModal");
      },
      startTimer() {
        this.$emit("startTimer");
      }
    }
  };
</script>

<style lang="scss" scoped>
  .divAppointment {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;

    .divContent {
      display: flex;
      align-items: center;
      gap: 4px;
      width: 177px;
      height: 30px;
      margin: 5px 5px 0 5px;
    }
    .divContent:last-child {
      margin: 5px;
    }
    .divContent:hover {
      background: #d1e8d3;
      border-radius: 3px;
      cursor: pointer;

      .text {
        p {
          color: #005229;
        }
      }
    }

    .iconPencil {
      width: 15px;
      height: 15px;
      margin-left: 5px;

      path {
        fill: #1a1c1a;
      }
    }
    .iconTimer {
      width: 15px;
      height: 15px;
      margin-left: 5px;

      path {
        fill: #1a1c1a;
      }
    }

    p {
      font-family: "Work Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;

      color: #1a1c1a;
    }
  }
</style>
