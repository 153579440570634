import { api } from "@/utils/request";

export default {
  async getProjects() {
    const data = await api.get(`projects`);
    return data.data;
  },
  async getProject(projectId) {
    const data = await api.get(`projects/${projectId}/tree-group`);
    return data.data;
  },
  async orderTreeGroup(projectId, payload) {
    const data = await api.put(`projects/${projectId}/tree-group`, payload);
    return data.data;
  }
};
