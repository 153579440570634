import { api } from "@/utils/request";

export default {
  async getComponentTypes(projectId, groupId, subgroupId, featureId, componentTypeId) {
    const data = await api.get(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/component${
        componentTypeId ? `/${componentTypeId}/children` : ""
      }`
    );
    return data.data;
  },
  async getComponentTypesProperties(projectId, groupId, subgroupId, featureId, componentTypeId) {
    const data = await api.get(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/component/${componentTypeId}`
    );
    return data.data;
  },

  async getComponentProperties(
    projectId,
    groupId,
    subgroupId,
    featureId,
    componentTypeId,
    componentId
  ) {
    const data = await api.get(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/component/${componentTypeId}/element/${componentId}`
    );
    return data.data;
  },

  async postComponentProperties(
    projectId,
    groupId,
    subgroupId,
    featureId,
    componentTypeId,
    componentId,
    payload,
    config
  ) {
    const data = await api.post(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/component/${componentTypeId}/element${
        componentId ? `/${componentId}` : ""
      }`,
      payload,
      config
    );
    return data.data;
  },
  async putComponentProperties(
    projectId,
    groupId,
    subgroupId,
    featureId,
    componentTypeId,
    componentId,
    payload,
    config
  ) {
    const data = await api.put(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/component/${componentTypeId}/element/${componentId}`,
      payload,
      config
    );
    return data.data;
  },
  async deleteComponentProperties(
    projectId,
    groupId,
    subgroupId,
    featureId,
    componentTypeId,
    componentId
  ) {
    const data = await api.delete(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/component/${componentTypeId}/element/${componentId}`
    );
    return data.data;
  },
  async putComponentOrder(
    projectId,
    groupId,
    subgroupId,
    featureId,
    componentTypeId,
    componentId,
    payload
  ) {
    const data = await api.put(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/component/${componentTypeId}/element/${componentId}/tree-component`,
      payload
    );
    return data.data;
  }
};
