<template>
  <div class="container-textarea">
    <div
      v-if="showMandatoryMessage"
      class="div-error"
      @click="$refs['input-textarea-' + id].focus()"
    >
      <IconDangerous class="icon-error" />
      <p>Campo Obrigatório</p>
    </div>
    <textarea
      :id="'input-textarea-' + id"
      :ref="'input-textarea-' + id"
      v-model="valueTxta"
      :class="['customClass']"
      :placeholder="showMandatoryMessage ? '' : placeholder"
      rows="1"
      @input="updateInputValue"
      @keydown="autoResize"
      @blur="blurField"
      @focus="focusField"
    >
    </textarea>
  </div>
</template>

<script>
  import IconDangerous from "@/assets/images/icon-dangerous-filled.svg";

  export default {
    components: {
      IconDangerous
    },
    props: {
      id: {
        default: ""
      },
      value: {
        default: ""
      },
      placeholder: {
        type: String,
        default: ""
      },
      customClass: {
        type: String,
        default: ""
      },
      mandatory: {
        type: Boolean,
        default: false
      },
      touchedForm: {
        type: Boolean,
        default: false
      },
      acceptEnter: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        valueTxta: "",
        mandatoryShow: true,
        touchedField: false
      };
    },
    watch: {
      touchedForm() {
        if (this.mandatory && this.valueTxta == "") {
          this.mandatoryShow = this.mandatory;
        }
      }
    },
    computed: {
      showMandatoryMessage: function () {
        return (
          this.mandatory &&
          this.mandatoryShow &&
          (this.touchedField || this.touchedForm) &&
          !this.valueTxta
        );
      }
    },
    methods: {
      blurField() {
        this.mandatoryShow = true;
      },
      focusField() {
        this.touched();
        this.mandatoryShow = false;
      },
      touched() {
        this.touchedField = true;
        this.validateField();
      },
      validateField() {
        if (this.valueTxta == "" && this.touchedField && this.mandatory) {
          this.mandatoryShow = true;
        }
      },
      autoResize(event) {
        // Manipula a altura do componente/textarea de acordo com a quantidade de texto digitado no mesmo
        if (event?.key == "Enter" && !this.acceptEnter) {
          event.preventDefault();
        }
        let textarea = this.$el.getElementsByTagName("textarea")[0];
        textarea.style.height = "auto";
        let textareaScrollHeight = textarea.scrollHeight;
        textarea.style.height = `${textareaScrollHeight}px`;
      },
      updateInputValue() {
        if (!this.acceptEnter) {
          this.notAllowBreakLines();
        }
        this.$emit("input", this.valueTxta);
      },
      notAllowBreakLines() {
        // Removendo todas as quebras de linha e substituindo por espaços
        let stringNoBreakLines = this.valueTxta.replace(/\r?\n|\r/g, " ");

        // Remontando a string colocando espaços no lugar das quebras de linha para separar as palavras e/ou letras
        // e substituindo cadeias de espaços em branco por apenas um espaço em branco
        this.valueTxta = stringNoBreakLines.replace(/\s{2,}/g, " ");
      }
    },
    mounted() {
      let vm = this;
      if (vm.value) {
        this.valueTxta = vm.value;
      }
    },
    updated() {
      this.autoResize();
    }
  };
</script>

<style lang="scss">
  .container-textarea {
    width: 100%;
    position: relative;

    .div-error {
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      display: flex;
      position: absolute;

      .icon-error {
        margin-right: 5px;
      }
      p {
        margin: 0;
        font-size: 14px;
        line-height: 1.35em;
        font-weight: 600;
        color: #ef3e4d;
      }
    }

    textarea {
      background: transparent;
      border: none;
      outline: none;
      resize: none;
      padding: 3px 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.35em;
      min-height: 28px;
      width: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      border: 1px solid transparent;
    }
    textarea::placeholder {
      color: #c6c7c3;
    }

    textarea::-webkit-scrollbar {
      width: 0;
    }

    textarea:focus {
      border: 1px solid #5c5f5b;
      background: #ffffff;
      border-radius: 3px;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
        0px 1px 3px rgba(0, 0, 0, 0.2);
    }
  }
</style>
