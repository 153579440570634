var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"column-tree",attrs:{"id":"column-tree-components"}},[_c('div',{class:['header', _vm.featureId == null && 'top-header']},[_c('span',[_c('IconFeatures',{staticClass:"top-icon",staticStyle:{"width":"21px","text-align":"center"}})],1),_c('h3',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
          title: _vm.feature.name,
          placement: 'toprigth',
          boundary: 'window',
          disabled: _vm.disableTooltipTitle,
          customClass: 'width-tooltip'
        }),expression:"{\n          title: feature.name,\n          placement: 'toprigth',\n          boundary: 'window',\n          disabled: disableTooltipTitle,\n          customClass: 'width-tooltip'\n        }",modifiers:{"hover":true}}],staticClass:"text-format-tree-comp"},[_vm._v(" "+_vm._s(_vm.featureId ? _vm.feature.name : "Nova Funcionalidade")+" ")]),_c('div',{staticClass:"tools"},[(_vm.featureId)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
            title: 'Editar Funcionalidade',
            placement: 'topright',
            boundary: 'window'
          }),expression:"{\n            title: 'Editar Funcionalidade',\n            placement: 'topright',\n            boundary: 'window'\n          }",modifiers:{"hover":true}}],on:{"click":function($event){_vm.blockOutside ? _vm.openDiscardChanges($event) : _vm.editFeature()}}},[_c('IconPencil')],1):_vm._e(),_c('ReportVersion',{attrs:{"block-outside":_vm.blockOutside,"type":_vm.feature.active,"feature-id":_vm.featureId,"copy-not-edit":_vm.feature.copyNotEdit,"subgroup-id":_vm.subgroupId,"group-id":_vm.groupId,"hash":_vm.feature.hash,"project-id":_vm.projectId,"devops-id":_vm.feature.DevOpsId,"feature-name":_vm.feature.name,"access-features":function () { return (_vm.feature.copyNotEdit == 1 ? _vm.editFeature() : _vm.findAndEditCopy(_vm.treeComponents)); }},on:{"refresh":_vm.reloadComponent,"openDiscardChanges":_vm.openDiscardChanges,"reloadFeature":_vm.reloadComponent}}),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
            title: 'Retornar para gestão de funcionalidades',
            placement: 'topleft',
            boundary: 'window'
          }),expression:"{\n            title: 'Retornar para gestão de funcionalidades',\n            placement: 'topleft',\n            boundary: 'window'\n          }",modifiers:{"hover":true}}],on:{"click":function($event){_vm.blockOutside
              ? _vm.openDiscardChanges($event)
              : _vm.$router.push({
                  name: 'project',
                  params: { projectid: _vm.projectId }
                })}}},[_c('IconReturn')],1)],1)]),_c('div',{staticClass:"content"},[(_vm.treeComponentsFiltered.length > 0 || _vm.termFilterTreeComponents != '')?_c('form',{staticClass:"form-filter"},[_c('IconSearch',{staticClass:"icon-search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.termFilterTreeComponents),expression:"termFilterTreeComponents"}],class:['form-control', _vm.termFilterTreeComponents != '' && 'active'],attrs:{"type":"text","placeholder":"Pesquisar"},domProps:{"value":(_vm.termFilterTreeComponents)},on:{"input":function($event){if($event.target.composing){ return; }_vm.termFilterTreeComponents=$event.target.value}}}),(_vm.termFilterTreeComponents != '')?_c('button',{staticClass:"btn-clear",on:{"click":_vm.clearFilterTreeComponents}},[_c('IconClose')],1):_vm._e()],1):_vm._e(),(_vm.featureId != null)?_c('div',{staticClass:"tree"},[(_vm.forceRenderTreeComponent && _vm.treeComponentsFiltered.length > 0)?_c('DraggableComp',{ref:"draggable",staticClass:"draggable draggable-groups",attrs:{"list":_vm.treeComponentsFiltered,"component-edit-id":_vm.componentEditId,"component-parent-edit-id":_vm.componentParentEditId,"block-outside":_vm.blockOutside,"force-close-component":_vm.forceCloseComponent,"term-filter-tree-components":_vm.termFilterTreeComponents},on:{"copyItem":_vm.copyItem,"newComponent":_vm.newComponent,"editComponent":_vm.editComponent,"openDiscardChanges":_vm.openDiscardChanges,"undoChangesTree":_vm.undoChangesTree,"reloadFeature":_vm.reloadComponent,"reload":_vm.reloadTreeComponent,"saveActualTree":_vm.saveActualTree,"forceRenderTreeComponentsToggle":_vm.forceRenderTreeComponentsToggle,"handleDiscard":_vm.handleDiscard}}):_vm._e(),_c('div',[_c('button',{staticClass:"btn-new-group",attrs:{"type":"button"},on:{"click":function($event){return _vm.openNewComponent(_vm.blockOutside, $event)}}},[_c('IconPlus'),_vm._v(" Novo Componente ")],1)])],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }