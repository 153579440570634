import ComponentsServices from "@/services/Components";

export const ActionGetComponentTypes = ({}, data) => {
  return ComponentsServices.getComponentTypes(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId,
    data.componentTypeId
  );
};

export const ActionGetComponentTypesProperties = ({}, data) => {
  return ComponentsServices.getComponentTypesProperties(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId,
    data.componentTypeId
  );
};

export const ActionGetComponentProperties = ({}, data) => {
  return ComponentsServices.getComponentProperties(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId,
    data.componentTypeId,
    data.componentId
  );
};

export const ActionPostComponentProperties = ({}, data) => {
  return ComponentsServices.postComponentProperties(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId,
    data.componentTypeId,
    data.componentId,
    data.payload,
    data.config
  );
};

export const ActionPutComponentProperties = ({}, data) => {
  return ComponentsServices.putComponentProperties(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId,
    data.componentTypeId,
    data.componentId,
    data.payload,
    data.config
  );
};
export const ActionDeleteComponentProperties = ({}, data) => {
  return ComponentsServices.deleteComponentProperties(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId,
    data.componentTypeId,
    data.componentId
  );
};
export const ActionPutComponentOrder = ({}, data) => {
  return ComponentsServices.putComponentOrder(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId,
    data.componentTypeId,
    data.componentId,
    data.payload
  );
};
