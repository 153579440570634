<template>
  <div
    class="card card-project"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
  >
    <router-link
      :to="`/project/${projectId}`"
      class="link-card-project"
    >
      <div>
        <div class="header-card">
          <div
            v-if="image"
            class="circle-logo"
          >
            <img
              :src="`data:${mimeTypeBase64(image)};base64,${image}`"
              class="circle-logo-svg"
              alt=""
            />
          </div>
          <div class="title-card">
            <h3>{{ projectCompany }}</h3>
            <h4>{{ projectTitle }}</h4>
          </div>
        </div>
      </div>
      <div class="info-card">
        <div
          class="description-card"
          data-mh="description"
        >
          {{ description || "&nbsp;" }}
        </div>
      </div>
    </router-link>
    <div class="info-card">
      <div class="indicators">
        <div class="row-list-indicators">
          <div
            v-if="!isHover"
            class="empty-indicator"
          >
            <div class="icon-indicator-empty">
              <IconEmpty />
            </div>
            <div class="icon-indicator-empty">
              <IconEmpty />
            </div>
            <div class="icon-indicator-empty">
              <IconEmpty />
            </div>
          </div>
          <div
            v-if="isHover"
            class="full-indicator"
          >
            <div class="icon-indicator">
              <PopOverFunctionality :project-func="projectFunctionality" />
            </div>
            <div class="icon-indicator">
              <PopOverBacklog :project-indicators="projectIndicators" />
            </div>
            <div class="icon-indicator">
              <PopOverAppointment
                @openModal="openModal"
                @startTimer="startTimer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import IconEmpty from "@/assets/images/indicator-empty.svg";
  import { base64 } from "@/utils/base64";
  import PopOverFunctionality from "./PopOverFunctionality.vue";
  import PopOverBacklog from "./PopOverBacklog.vue";
  import PopOverAppointment from "./PopOverAppointment.vue";
  import { mapMutations } from "vuex";

  export default {
    components: {
      PopOverFunctionality,
      PopOverBacklog,
      IconEmpty,
      PopOverAppointment
    },
    props: {
      projectFunctionality: [],
      projectIndicators: [],
      projectTitle: {
        type: String,
        default: ""
      },
      projectCompany: {
        type: String,
        default: ""
      },
      description: {
        type: String,
        default: ""
      },
      indicators: {
        type: Object,
        default: () => ({})
      },
      image: {
        type: String,
        default: ""
      },
      projectId: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        isHover: false,
        isModalOpen: false
      };
    },
    methods: {
      ...mapMutations(["SET_MODAL_APPOINTMENT"]),
      ...mapMutations("Appointments", ["setCardProjectId"]),
      mimeTypeBase64(value) {
        return base64.mimeType(value);
      },
      openModal() {
        this.SET_MODAL_APPOINTMENT(true);
        this.setCardProjectId(this.projectId);
      },
      startTimer() {
        this.setCardProjectId(this.projectId);
        this.$emit("startTimer");
      }
    }
  };
</script>

<style lang="scss">
  .link-card-project {
    text-decoration: none !important;
    color: inherit !important;
    display: inline-block;
    height: 100%;
    width: 100%;
  }
  .card.card-project {
    background-color: #ffff !important;
    border-radius: 15px !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    padding: 17.5px 16px 25px 16px;
  }
  .card-project .header-card {
    display: flex;
    align-items: center;
    margin-bottom: 17.5px;
  }
  .card-project .header-card .circle-logo {
    border-radius: 100%;
    background-color: #fff;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    position: relative;
    overflow: hidden;
  }
  .card-project .header-card .circle-logo .circle-logo-svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
  }
  .card-project .header-card .title-card {
    color: #1a1c1a;
  }
  .card-project .header-card .title-card h3 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 2px;
  }
  .card-project .header-card .title-card h4 {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  .card-project .info-card {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .card-project .info-card .description-card {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 35.5px;
    color: #1a1c1a;
  }
  .card-project .info-card .description-card {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 35.5px;
    color: #1a1c1a;
  }
  .card-project .info-card .indicators {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .card-project .info-card .indicators .row-list-indicators {
    display: flex;
    margin: 0 -8px;
    justify-content: flex-end;
    width: 100%;
  }
  .card-project .info-card .indicators .row-list-indicators > div {
    padding: 0 8px;
  }

  .empty-indicator {
    height: 20px;
    display: flex;
    gap: 20px;
  }

  .full-indicator {
    height: 20px;
    display: flex;
    gap: 20px !important;
  }

  .icon-indicator {
    display: flex;
    width: 20px;
    justify-content: center;
    align-items: center;
  }

  .icon-indicator:hover {
    background: #d1e8d3;
    border-radius: 2px;
  }

  .icon-indicator-empty {
    display: flex;
    width: 20px;
    justify-content: center;
    align-items: center;
  }
</style>
