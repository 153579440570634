<template>
  <div class="row row-index">
    <ModalDuplicate
      v-if="copyItemModal"
      :item-duplicate="copyItemModal"
      @closeModal="closeModal"
      @refreshTreeFeatures="refreshTreeFeatures"
    />
    <div class="col-5">
      <ColumnTreeFeatures
        :is-active="isActive"
        :project-id="projectId"
        :tree-features="treeFeatures"
        @refreshTreeFeatures="refreshTreeFeatures"
        @copyItem="copyItem"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import ModalDuplicate from "@/components/ModalDuplicate.vue";
  import ColumnTreeFeatures from "./components/ColumnTreeFeatures";

  export default {
    components: {
      ColumnTreeFeatures,
      ModalDuplicate
    },
    data() {
      return {
        projectId: null,
        treeFeatures: null,
        modalIsOpen: false,
        ids: null,
        copyItemModal: null,
        isActive: false
      };
    },
    methods: {
      ...mapActions("Projects", ["ActionGetProjectTree"]),
      closeModal() {
        this.copyItemModal = null;
      },
      copyItem(data) {
        if (data) {
          this.copyItemModal = data;
        }
      },
      refreshTreeFeatures() {
        this.treeFeatures = null;
        this.groups = [];
        this.isActive = true;
        this.ActionGetProjectTree(this.projectId).then((response) => {
          if (response.data.project) {
            this.$emit("setProject", response.data.project.title);
            this.$emit("setFeature", null);
            this.isActive = false;
          }
          if (response.data.groups && response.data.groups.length > 0) {
            this.treeFeatures = response.data.groups;
            this.datas = response.data;
            this.treeFeatures.map((group) => {
              if (group.subgroups == null) {
                group.subgroups = [];
              }

              group.subgroups.map((subgroup) => {
                if (subgroup.features == null) {
                  subgroup.features = [];
                }
              });
            });
          } else {
            this.treeFeatures = [];
          }
        });
      }
    },
    mounted() {
      if (this.$route.params.projectId) {
        this.projectId = parseInt(this.$route.params.projectId);
        this.refreshTreeFeatures();
      } else {
        this.$router.push({ name: "projects" });
      }
    }
  };
</script>

<style lang="scss">
  .row-index {
    margin: 0px !important;
    & > div {
      padding: 0px !important;
    }
  }
</style>
