<template>
  <div>
    <span
      v-b-tooltip.hover="{
        title: title,
        placement: 'top',
        disabled: disableTooltipTitle,
        customClass: 'tooltip-width'
      }"
      class="text-format-tree-features"
      v-html="titleFormat"
    ></span>
  </div>
</template>

<script>
  export default {
    name: "TitleTreeFeature",
    props: {
      title: {
        default: "",
        type: String
      },
      titleFormat: {
        default: "",
        type: String
      }
    },
    data() {
      return {
        disableTooltipTitle: true
      };
    },
    updated() {
      let titleElement = this.$el.querySelector(".text-format-tree-features");
      let isWidthScrollSmallerClient = titleElement.scrollWidth <= titleElement.clientWidth;
      this.disableTooltipTitle = isWidthScrollSmallerClient;
    }
  };
</script>

<style lang="scss" scoped>
  div {
    width: 100%;
  }
  .text-format-tree-features {
    width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .tooltip-width {
    width: 350px !important;

    .tooltip-inner {
      width: 100%;
      max-width: 350px !important;
    }
  }
</style>
