import { api } from "@/utils/request";

export default {
  async putFeatureNewVersionReport(projectId, groupId, subgroupId, featureId) {
    const data = await api.put(
      `/projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/version`
    );
    return data.data;
  },
  async getFeatureReport(projectId, groupId, subgroupId, featureId) {
    const data = await api.get(
      `/projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}`
    );
    return data.data;
  },
  async getFeatureReportToDownload(projectId, groupId, subgroupId, featureId, to, hash) {
    const data = await api.get(
      `/projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/report/to/${to}?hash=${hash}`
    );
    return data.data;
  },

  async getFeatureReportToVisualize(projectId, groupId, subgroupId, featureId, hash) {
    const data = await api.get(
      `/projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/report/developer?hash=${hash}`
    );
    return data.data;
  }
};
