<template>
  <div>
    <Popper
      ref="popper"
      trigger="clickToToggle"
      :options="{
        placement: 'bottom-start',
        modifiers: {
          preventOverflow: {
            boundariesElement: 'viewport'
          }
        }
      }"
    >
      <PlayIcon
        slot="reference"
        class="popper-button"
        @click="handleOpenPopover"
      />
      <div class="popper">
        <div
          v-if="isOpen"
          class="popover_play_content"
        >
          <span @click="handleManualAppointment"> <PencilIcon />Apontamento manual </span>
          <span @click="startManualTimer"> <ClockIcon />Iniciar temporizador </span>
        </div>
      </div>
    </Popper>
  </div>
</template>

<script>
  import PlayIcon from "@/assets/images/play.svg";
  import ClockIcon from "@/assets/images/clock.svg";
  import PencilIcon from "@/assets/images/pencil.svg";

  import Popper from "vue-popperjs";
  import "vue-popperjs/dist/vue-popper.css";
  export default {
    components: {
      PlayIcon,
      ClockIcon,
      PencilIcon,
      Popper
    },
    data() {
      return {
        isOpen: false
      };
    },
    methods: {
      handleManualAppointment() {
        this.$emit("handleManualAppointment");
        this.closePopover();
      },
      startManualTimer() {
        this.$emit("startManualTimer");
      },
      handleOpenPopover() {
        this.isOpen = true;
      },
      closePopover() {
        this.isOpen = false;
      }
    }
  };
</script>

<style>
  .popper[x-placement^="bottom"] .popper__arrow {
    display: none;
  }
</style>

<style lang="scss">
  .popover-body {
    padding: 0 0 0 16px !important;
    height: 150px !important;
  }

  .popover_play {
    .popper {
      width: 230px !important;
      filter: none !important;
      .popover_play_content {
        margin-top: 0px;
      }
    }
  }
</style>
