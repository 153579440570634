<template>
  <span
    v-b-tooltip.hover="{
      title: title,
      placement: 'top',
      customClass: 'position-tooltip',
      disabled: disableTooltipTitleComp
    }"
    class="text-format"
    v-html="titleFormatComp"
  ></span>
</template>

<script>
  export default {
    name: "TitleTreeComp",
    props: {
      title: {
        default: "",
        type: String
      },
      titleFormatComp: {
        default: "",
        type: String
      }
    },
    data() {
      return {
        disableTooltipTitleComp: true
      };
    },
    updated() {
      let titleComp = this.$el;
      let isWidthScrollSmallerClientComp = titleComp.scrollWidth <= titleComp.clientWidth;
      this.disableTooltipTitleComp = isWidthScrollSmallerClientComp;
    }
  };
</script>
<style lang="scss">
  .text-format > p {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
<style lang="scss" scoped>
  .text-format {
    display: flex;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  span > * {
    background: red !important;
  }

  .position-tooltip {
    top: -6px;
    width: 350px;
    .tooltip-inner {
      max-width: 350px !important;
    }
  }
</style>
