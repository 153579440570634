<template>
  <div
    class="item-list-project"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
  >
    <router-link
      :to="`/project/${projectId}`"
      class="link-item-list-project"
    >
      <div>
        <div class="infos-item-list">
          <div
            v-if="image"
            class="circle-logo"
          >
            <img
              :src="`data:${mimeTypeBase64(image)};base64,${image}`"
              class="circle-logo-svg"
              alt=""
            />
          </div>
          <div class="col-content">
            <div class="content-list">
              <div class="title-list">{{ projectCompany }} - {{ projectTitle }}</div>
              <div class="description-list">{{ description }}</div>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <div class="indicators-list">
      <div
        v-if="!isHover"
        class="empty-indicator"
      >
        <div class="icon-indicator-empty">
          <IconEmpty />
        </div>
        <div class="icon-indicator-empty">
          <IconEmpty />
        </div>
        <div class="icon-indicator-empty">
          <IconEmpty />
        </div>
      </div>
      <div
        v-if="isHover"
        class="full-indicator"
      >
        <div class="icon-indicator">
          <PopOverFunctionality :project-func="projectFunctionality" />
        </div>
        <div class="icon-indicator">
          <PopOverBacklog :project-indicators="projectIndicators" />
        </div>
        <div class="icon-indicator">
          <PopOverAppointment
            @openModal="openModal"
            @startTimer="startTimer"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { base64 } from "@/utils/base64";
  import IconEmpty from "@/assets/images/indicator-empty.svg";
  import PopOverFunctionality from "@/views/ProjectsView/components/PopOverFunctionality.vue";
  import PopOverBacklog from "@/views/ProjectsView/components/PopOverBacklog.vue";
  import PopOverAppointment from "../components/PopOverAppointment.vue";
  import { mapMutations } from "vuex";

  export default {
    components: {
      IconEmpty,
      PopOverFunctionality,
      PopOverBacklog,
      PopOverAppointment
    },
    props: {
      projectFunctionality: [],
      projectIndicators: [],
      projectTitle: {
        type: String,
        default: ""
      },
      projectCompany: {
        type: String,
        default: ""
      },
      description: {
        type: String,
        default: ""
      },
      indicators: {
        type: Object,
        default: () => ({})
      },
      image: {
        type: String,
        default: ""
      },
      projectId: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        isHover: false,
        isModalOpen: false
      };
    },
    methods: {
      ...mapMutations(["SET_MODAL_APPOINTMENT"]),
      ...mapMutations("Appointments", ["setCardProjectId"]),
      mimeTypeBase64(value) {
        return base64.mimeType(value);
      },
      openModal() {
        this.SET_MODAL_APPOINTMENT(true);
        this.setCardProjectId(this.projectId);
      },
      startTimer() {
        this.setCardProjectId(this.projectId);
        this.$emit("startTimer");
      }
    }
  };
</script>

<style style lang="scss">
  .link-item-list-project {
    width: 100%;
    text-decoration: none !important;
    color: inherit !important;
  }

  .item-list-project {
    background-color: #ffff !important;
    border-radius: 15px !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 17.5px 16px;
  }

  .item-list-project .infos-item-list .circle-logo {
    border-radius: 100%;
    background-color: #fff;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  .item-list-project .infos-item-list .circle-logo .circle-logo-svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
  }
  .item-list-project .indicators-list {
    display: flex;
    justify-content: flex-end;
    margin: 0 -8px;
  }
  .item-list-project .indicators-list > div {
    padding: 0 8px;
  }
  .item-list-project .infos-item-list {
    display: flex;
  }
  .item-list-project .infos-item-list .col-content {
    display: flex;
    align-items: center;
  }
  .item-list-project .infos-item-list .col-content .content-list {
    display: inline-block;
  }
  .item-list-project .infos-item-list .col-content .content-list .description-list {
    width: 100%;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  .item-list-project .infos-item-list .col-content .content-list .title-list {
    color: #1a1c1a;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  .full-indicator {
    height: 20px;
    display: flex;
    gap: 20px;
  }

  .icon-indicator {
    display: flex;
    width: 20px;
    justify-content: center;
    align-items: center;
  }

  .icon-indicator-empty {
    display: flex;
    width: 20px;
    justify-content: center;
    align-items: center;
  }
</style>
