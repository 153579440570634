export const SET_API_CALL_IN_PROGRESS = "SET_API_CALL_IN_PROGRESS";
export const SET_MODAL_LOGIN = "SET_MODAL_LOGIN";
export const SET_MODAL_APPOINTMENT = "SET_MODAL_APPOINTMENT";

export default {
  state: {
    isAPICallInProgress: false,
    modalLogin: false,
    modalAppointment: false
  },
  mutations: {
    [SET_API_CALL_IN_PROGRESS]: (state, status) => {
      state.isAPICallInProgress = status;
    },
    [SET_MODAL_LOGIN]: (state, value) => {
      state.modalLogin = value;
    },
    [SET_MODAL_APPOINTMENT]: (state, value) => {
      state.modalAppointment = value;
    }
  },
  actions: {}
};
