import { api } from "@/utils/request";

export default {
  async postSubgroup(projectId, groupId, payload, config) {
    const data = await api.post(
      `projects/${projectId}/groups/${groupId}/subgroups`,
      payload,
      config
    );
    return data.data;
  },
  async putSubgroup(projectId, groupId, subgroupId, payload, config) {
    const data = await api.put(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}`,
      payload,
      config
    );
    return data.data;
  },
  async deleteSubgroup(projectId, groupId, subgroupId) {
    const data = await api.delete(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}`
    );
    return data.data;
  }
};
