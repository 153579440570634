<template>
  <div
    v-if="isActive"
    class="spin"
  >
    <div class="loading">
      <div></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style type="text/css">
  @keyframes loading {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  .spin {
    z-index: 1000 !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    position: absolute !important;

    width: 100% !important;
    height: 100% !important;
  }

  .loading div {
    top: 50% !important;
    left: 50% !important;

    position: absolute !important;
    width: 100px !important;
    height: 100px !important;
    border: 12.1px solid #7eda9b !important;
    border-top-color: transparent !important;
    border-radius: 50% !important;
    animation: loading 0.9900990099009901s linear infinite !important;
  }

  .loading {
    width: 100% !important;
    height: 100% !important;
    transform-origin: 0 0 !important;
    overflow: hidden !important;
    background: none !important;
  }
</style>
