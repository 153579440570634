import FeaturesServices from "@/services/Features";

export const ActionGetFeatureTypes = ({}, data) => {
  return FeaturesServices.getFeatureTypes(data.projectId, data.groupId, data.subgroupId);
};

export const ActionPostFeature = ({}, data) => {
  return FeaturesServices.postFeature(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.payload,
    data.config
  );
};

export const ActionGetFeature = ({}, data) => {
  return FeaturesServices.getFeature(data.projectId, data.groupId, data.subgroupId, data.featureId);
};

export const ActionPutFeature = ({}, data) => {
  return FeaturesServices.putFeature(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId,
    data.payload,
    data.config
  );
};

export const ActionDeleteFeature = ({}, data) => {
  return FeaturesServices.deleteFeature(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId
  );
};

export const ActionGetTreeFeature = ({}, data) => {
  return FeaturesServices.getTreeFeature(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId
  );
};
