import GroupsServices from "@/services/Groups";

export const ActionPostGroup = ({}, data) => {
  return GroupsServices.postGroup(data.projectId, data.payload, data.config);
};

export const ActionPutGroup = ({}, data) => {
  return GroupsServices.putGroup(data.projectId, data.groupId, data.payload, data.config);
};

export const ActionDeleteGroup = ({}, data) => {
  return GroupsServices.deleteGroup(data.projectId, data.groupId);
};
