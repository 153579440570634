<template>
  <div class="content row">
    <div class="col-lg-6 left-side">
      <form class="project-form">
        <EditLine caption="Nome" />
        <EditLine caption="Cliente" />
        <EditLine caption="Método Centro de Custo" />
        <EditLine caption="Centro de Custo" />
        <EditLine caption="Controle de Escopo" />
        <EditLine caption="Descrição" />
      </form>
    </div>
    <div class="col-lg-6 right-side">
      <ExpandableBox
        caption="Membros"
        :index="0"
        :active="activeRightPanel"
        @setPanel="setPanel"
      >
        <div>Amber</div>
        <div>Área</div>
        <div>Função</div>
        <div>Valor Hora</div>
        <div>Valor Mensal</div>
      </ExpandableBox>
      <ExpandableBox
        caption="Atividades"
        :index="1"
        :active="activeRightPanel"
        @setPanel="setPanel"
      >
        <div>Função</div>
        <div>Valor Hora</div>
        <div>Valor Mensal</div>
      </ExpandableBox>
    </div>
  </div>
</template>

<script>
  const ExpandableBox = {
    props: ["caption", "active", "index"],
    template: `<div class="expandable-box" :class="active === index && 'active'">
        <div class="expandable-header" @click="$emit('setPanel',index)">
          {{caption}}
        </div>
        <div class="expandable-content" v-if="active === index">
          <div>
            <slot></slot>
          </div>
        </div>
      </div>
`
  };

  const EditLine = {
    props: ["caption"],
    template: `<div class="row py-2">
      <div class="col-sm-6">
        <label>{{caption}}</label>
      </div>
      <div class="col-sm-6">
        <input class="w-100"/>
      </div>
    </div>`
  };

  import { mapActions } from "vuex";

  export default {
    components: {
      EditLine,
      ExpandableBox
    },
    data() {
      return {
        activeRightPanel: 1
      };
    },
    methods: {
      ...mapActions("Projects", ["ActionGetProjects"]),
      setPanel(index) {
        this.activeRightPanel = index;
      }
    },
    mounted() {
      this.$emit("setProject", null);
      this.$emit("setFeature", null);
    }
  };
</script>
<style lang="scss" scoped>
  .content {
    width: 100%;
    margin: 0;

    .left-side {
      padding: 5px;
      height: 100%;

      .project-form {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
          0px 1px 3px rgba(0, 0, 0, 0.2);
        background-color: #fff;
        width: 100%;
        padding-left: 16.5px;
        padding-right: 10px;
        height: 100%;
      }
    }

    .right-side {
      padding: 5px;
      padding-left: 0px;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      align-content: flex-start;

      /deep/ .expandable-box {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
          0px 1px 3px rgba(0, 0, 0, 0.2);
        background-color: #ffff !important;
        width: 100%;
        height: auto;
        margin-bottom: 5px;

        .expandable-header {
          width: 100%;
          height: 30px;
          padding: 7px;
          padding-bottom: 14px;
          font-size: 16px;
        }

        .expandable-content {
          height: auto;
          width: 100%;
          padding: 7px;
          border-top: 1px solid #888;
        }
      }

      /deep/ .expandable-box.active .expandable-header {
        background-color: #aaa !important;
        font-weight: 900;
        color: #fff;
      }
    }
  }
</style>
