<template>
  <div>
    <div :class="['inputRangeHour', (verifyInvalidRequired || mandatoryMessage) && 'error']">
      <div class="containerInput">
        <input
          v-model="startTime"
          v-mask="mask"
          class="startHour"
          type="text"
          placeholder="00:00"
          @keypress="(e) => validTime(e, startTime)"
          @blur="touchInput.start = true"
          @change="alterStartTime"
        />
        <IconWarning
          v-if="messageId == 2 || messageId == 3"
          class="iconWarning"
        />
      </div>
      <div class="hourSeparator">
        <p>até</p>
      </div>
      <div class="containerInput">
        <input
          v-model="endTime"
          v-mask="mask"
          class="endHour"
          type="text"
          placeholder="00:00"
          @blur="touchInput.end = true"
          @keypress="(e) => validTime(e, endTime)"
          @change="alterEndTime"
        />
        <IconWarning
          v-if="messageId == 1 || messageId == 3"
          class="iconWarning"
        />
      </div>
    </div>
    <div class="msg-error">
      <p v-if="verifyInvalidRequired">Campo obrigatório</p>
      <p v-else>{{ mandatoryMessage }}</p>
    </div>
  </div>
</template>

<script>
  import IconWarning from "@/assets/images/icon-warning.svg";
  import moment from "moment";
  import { mapState, mapMutations } from "vuex";
  export default {
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      id: {
        type: Number,
        required: false
      },
      touched: {
        type: Boolean,
        default: false
      },
      appointmentsTasks: {
        type: Object,
        default: () => {}
      },
      disableFutureTime: {
        type: Boolean,
        default: false
      },
      currentDate: {
        type: [String, Date],
        required: true
      }
    },
    components: {
      IconWarning
    },
    data() {
      return {
        startTime: null,
        endTime: null,
        valueTime: null,
        mask: [/^[0-2]/, /^[0-9]/, ":", /^[0-5]/, /^[0-9]/],
        mandatoryMessage: null,
        erroMandatory: false,
        touchInput: {
          start: false,
          end: false
        },
        messageId: null
      };
    },
    watch: {
      value() {
        if (this.value) {
          this.valueTime = this.value;
          this.startTime = `${this.formatHour(this.value.startHour, this.value.startMinute)}`;
          this.endTime = `${this.formatHour(this.value.endHour, this.value.endMinute)}`;
        } else {
          this.valueTime = null;
          this.startTime = null;
          this.endTime = null;
          this.messageId = null;
        }
      },
      valueTime() {
        this.$emit("input", this.valueTime);
      },
      touched() {
        if (!this.touched) {
          this.touchInput = {
            start: false,
            end: false
          };
        }
      },
      appointmentsTasks() {
        this.validaHours();
      },
      messageId() {
        if (this.messageId == null) {
          this.mandatoryMessage = null;
        }
      }
    },
    computed: {
      ...mapState("Appointments", {
        tempStartTime: "tempStartTime"
      }),
      ...mapState("CompFeat", ["isTimerAppoint"]),
      verifyInvalidRequired() {
        if (
          !this.touched &&
          (this.touched ||
            ((this.touchInput.start || this.touchInput.end) && (!this.startTime || !this.endTime)))
        ) {
          this.setErrorAppoint(true);
          return true;
        } else {
          this.setErrorAppoint(false);
          return false;
        }
      }
    },
    methods: {
      ...mapMutations("Appointments", ["setErrorAppoint"]),
      ...mapMutations("CompFeat", ["setIsTimerAppoint"]),
      validTime(e, value) {
        if (value?.length == 1 && value[0] == 2 && e.key > 3) {
          e.preventDefault();
          return;
        }
      },
      checkFutureTime(current, timeInput) {
        if (timeInput) {
          const today = new Date(current);
          const time = today.setHours(timeInput.startHour, timeInput.startMinute, 0);
          return new Date(time) > new Date();
        }
      },
      validaHours() {
        const dateAppoint = moment(this.currentDate).format("YYYY-MM-DD");
        const dateTempAppoint = moment(this.tempStartTime?.date).format("YYYY-MM-DD");
        if (
          this.valueTime?.startHour &&
          this.valueTime?.startMinute &&
          this.valueTime?.endHour &&
          this.valueTime?.endMinute
        ) {
          if (this.checkFutureTime(this.currentDate, this.valueTime)) {
            this.setErrorAppoint(true);
            this.mandatoryMessage = "Não é permitido lançamento futuro";
            this.messageId = 2;
          } else if (
            !this.isTimerAppoint &&
            dateAppoint == dateTempAppoint &&
            (this.tempStartTime?.hour < this.valueTime.endHour ||
              (this.tempStartTime?.hour == this.valueTime.endHour &&
                this.tempStartTime?.minute < this.valueTime.endMinute) ||
              this.tempStartTime?.startHour < this.valueTime.endHour ||
              (this.tempStartTime?.startHour == this.valueTime.endHour &&
                this.tempStartTime?.startMinute < this.valueTime.endMinute))
          ) {
            this.setErrorAppoint(true);
            this.mandatoryMessage =
              "A hora final precisa ser menor que a hora inicial do temporizador";
            this.messageId = 1;
          } else if (
            this.valueTime.startHour == this.valueTime.endHour &&
            this.valueTime.startMinute == this.valueTime.endMinute
          ) {
            this.setErrorAppoint(true);
            this.mandatoryMessage = "Hora Inicial deve ser menor e diferente da hora Final";
            this.messageId = 1;
          } else if (
            this.valueTime.startHour > this.valueTime.endHour ||
            (this.valueTime.startHour == this.valueTime.endHour &&
              this.valueTime.startMinute > this.valueTime.endMinute)
          ) {
            this.setErrorAppoint(true);
            this.mandatoryMessage = "Hora Inicial deve ser menor que a hora Final";
            this.messageId = 2;
          } else {
            this.mandatoryMessage = "";
            this.messageId = null;
            this.setErrorAppoint(false);
          }
          this.appointmentsTasks.period.map((elem) => {
            elem.tasks?.map((task) => {
              const fictitiousDate = "1900-01-01";

              const appointStart = new Date(
                `${fictitiousDate}T${
                  task.datetime.initialHour < 10
                    ? `0${task.datetime.initialHour}`
                    : task.datetime.initialHour
                }:${
                  task.datetime.initialMinute < 10
                    ? `0${task.datetime.initialMinute}`
                    : task.datetime.initialMinute
                }:00`
              );

              const appointEnd = new Date(
                `${fictitiousDate}T${
                  task.datetime.endHour < 10 ? `0${task.datetime.endHour}` : task.datetime.endHour
                }:${
                  task.datetime.endMinute < 10
                    ? `0${task.datetime.endMinute}`
                    : task.datetime.endMinute
                }:00`
              );

              const newStart = new Date(
                `${fictitiousDate}T${this.valueTime.startHour}:${this.valueTime.startMinute}:00`
              );

              const newEnd = new Date(
                `${fictitiousDate}T${this.valueTime.endHour}:${this.valueTime.endMinute}:00`
              );
              if (this.id && this.id == task.id) {
                return;
              } else if (
                (newStart >= appointStart && newStart < appointEnd) ||
                (newEnd > appointStart && newEnd <= appointEnd) ||
                (newStart <= appointStart && newEnd >= appointEnd)
              ) {
                this.mandatoryMessage = "Conflito de apontamentos";
                this.messageId = 3;
                this.setErrorAppoint(true);
              }
            });
          });
        }
      },
      alterStartTime() {
        this.valueTime = {
          ...this.valueTime,
          startHour: this.startTime ? this.startTime.split(":")[0] : "",
          startMinute: this.startTime ? this.startTime.split(":")[1] : ""
        };
        this.validaHours();
      },
      alterEndTime() {
        this.valueTime = {
          ...this.valueTime,
          endHour: this.endTime ? this.endTime.split(":")[0] : "",
          endMinute: this.endTime ? this.endTime.split(":")[1] : ""
        };
        this.validaHours();
      },
      formatHour(hour, minute) {
        const hourSt = hour?.toString();
        const minuteSt = minute?.toString();
        if (hourSt < 10 && hourSt.length == 1) {
          hour = `0${hour}`;
        }
        if (minuteSt < 10 && minuteSt.length == 1) {
          minute = `0${minute}`;
        }
        let time = `${hour}:${minute}`;
        return time;
      }
    }
  };
</script>

<style lang="scss">
  .containerInput {
    position: relative;

    .iconWarning {
      position: absolute;
      right: 0;
      margin-top: 6px;
      margin-right: 5px;
    }
  }

  .inputRangeHour {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: 100%;
      height: 28px;
      border: 1px solid #b8b8b9;
      color: #3d3d3e;
      font-family: "Work Sans", sans-serif;

      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #c6c7c3;
      }
    }

    .startHour {
      border-radius: 3px 0 0 3px;
      padding: 4.5px 20px 4.5px 10px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
    .endHour {
      border-radius: 0 3px 3px 0;
      padding: 4.5px 25px 4.5px 10px;
    }
    .hourSeparator {
      height: 28px;
      width: 49px;
      display: flex;
      align-items: center;
      justify-content: center;

      border-top: 1px solid #b8b8b9;
      border-bottom: 1px solid #b8b8b9;

      p {
        font-family: "Work Sans", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #3d3d3e;
      }
    }

    &.error {
      input {
        border-color: #dc3546;
      }
      .hourSeparator {
        border-color: #dc3546;
      }
    }
  }
  .msg-error {
    margin: 1px 0 1px 5px;
    p {
      color: #dc3546;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }
  }
</style>
