<template>
  <div class="container-quill">
    <div
      v-if="showMandatoryMessage"
      class="div-error"
      @click="$refs['quill-editor-' + id].focus()"
    >
      <IconDangerous class="icon-error" />
      <p>Campo Obrigatório</p>
    </div>
    <quill-editor
      v-if="quillRender"
      :id="id"
      :ref="'quill-editor-' + id"
      v-model="content"
      :class="[
        showMandatoryMessage && 'hide-editor',
        !allowStyle && 'ctrl-disabled',
        !toggleOpen ? 'hide-toolbar' : 'show-toolbar'
      ]"
      :options="editorOptions"
      @blur="blurField"
      @focus="focusField"
      @change="onChange($event)"
    />
  </div>
</template>

<script>
  import Quill from "quill";
  import IconDangerous from "@/assets/images/icon-dangerous-filled.svg";

  const icons = Quill.import("ui/icons");
  icons["bold"] = `
    <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.6 7.29C9.57 6.62 10.25 5.52 10.25 4.5C10.25 2.24 8.5 0.5 6.25 0.5H0V14.5H7.04C9.13 14.5 10.75 12.8 10.75 10.71C10.75 9.19 9.89 7.89 8.6 7.29ZM3 3H6C6.83 3 7.5 3.67 7.5 4.5C7.5 5.33 6.83 6 6 6H3V3ZM6.5 12H3V9H6.5C7.33 9 8 9.67 8 10.5C8 11.33 7.33 12 6.5 12Z" fill="#5C5F5B"/></svg>
  `;
  icons["italic"] = `
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 0.5V3.5H6.21L2.79 11.5H0V14.5H8V11.5H5.79L9.21 3.5H12V0.5H4Z" fill="#5C5F5B"/></svg>
  `;
  icons["strike"] = `
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.24 6.25C3.98 5.77 3.85 5.22 3.85 4.58C3.85 3.97 3.98 3.42 4.25 2.91C4.51 2.41 4.88 1.98 5.36 1.62C5.84 1.27 6.41 0.99 7.06 0.79C7.72 0.6 8.45 0.5 9.24 0.5C10.05 0.5 10.78 0.61 11.45 0.84C12.11 1.06 12.68 1.38 13.14 1.78C13.61 2.18 13.97 2.66 14.22 3.21C14.47 3.76 14.6 4.36 14.6 5.02H11.59C11.59 4.71 11.54 4.43 11.44 4.17C11.35 3.9 11.2 3.68 11 3.49C10.8 3.3 10.55 3.16 10.25 3.05C9.95 2.95 9.59 2.89 9.19 2.89C8.8 2.89 8.45 2.93 8.16 3.02C7.87 3.11 7.63 3.23 7.44 3.38C7.25 3.54 7.1 3.72 7 3.93C6.9 4.14 6.85 4.36 6.85 4.59C6.85 5.07 7.1 5.47 7.59 5.8C7.97 6.05 8.36 6.28 9 6.5H4.39C4.34 6.42 4.28 6.33 4.24 6.25ZM18 9.5V7.5H0V9.5H9.62C9.8 9.57 10.02 9.64 10.17 9.7C10.54 9.87 10.83 10.04 11.04 10.21C11.25 10.38 11.39 10.57 11.47 10.78C11.54 10.98 11.58 11.21 11.58 11.47C11.58 11.7 11.53 11.92 11.44 12.13C11.35 12.33 11.21 12.51 11.02 12.66C10.83 12.81 10.6 12.92 10.31 13.01C10.02 13.09 9.68 13.14 9.3 13.14C8.87 13.14 8.47 13.1 8.12 13.01C7.77 12.92 7.46 12.78 7.21 12.59C6.96 12.4 6.76 12.15 6.62 11.84C6.48 11.53 6.37 11.08 6.37 10.63H3.4C3.4 11.18 3.48 11.76 3.64 12.21C3.8 12.66 4.01 13.06 4.29 13.42C4.57 13.77 4.89 14.08 5.27 14.34C5.64 14.6 6.05 14.82 6.49 14.99C6.93 15.16 7.39 15.29 7.87 15.38C8.35 15.46 8.83 15.51 9.31 15.51C10.11 15.51 10.84 15.42 11.49 15.23C12.14 15.04 12.7 14.78 13.16 14.44C13.62 14.1 13.98 13.67 14.23 13.17C14.48 12.67 14.61 12.1 14.61 11.46C14.61 10.86 14.51 10.32 14.3 9.85C14.25 9.74 14.19 9.62 14.13 9.52H18V9.5Z" fill="#5C5F5B"/></svg>
  `;
  icons["underline"] = `
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 14C10.31 14 13 11.31 13 8V0H10.5V8C10.5 9.93 8.93 11.5 7 11.5C5.07 11.5 3.5 9.93 3.5 8V0H1V8C1 11.31 3.69 14 7 14ZM0 16V18H14V16H0Z" fill="#5C5F5B"/></svg>
  `;
  icons["list"]["ordered"] = `
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 13.5H2V14H1V15H2V15.5H0V16.5H3V12.5H0V13.5ZM1 4.5H2V0.5H0V1.5H1V4.5ZM0 7.5H1.8L0 9.6V10.5H3V9.5H1.2L3 7.4V6.5H0V7.5ZM5 1.5V3.5H19V1.5H5ZM5 15.5H19V13.5H5V15.5ZM5 9.5H19V7.5H5V9.5Z" fill="#5C5F5B"/></svg>
  `;
  icons["list"]["bullet"] = `
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 6C1.17 6 0.5 6.67 0.5 7.5C0.5 8.33 1.17 9 2 9C2.83 9 3.5 8.33 3.5 7.5C3.5 6.67 2.83 6 2 6ZM2 0C1.17 0 0.5 0.67 0.5 1.5C0.5 2.33 1.17 3 2 3C2.83 3 3.5 2.33 3.5 1.5C3.5 0.67 2.83 0 2 0ZM2 12C1.17 12 0.5 12.68 0.5 13.5C0.5 14.32 1.18 15 2 15C2.82 15 3.5 14.32 3.5 13.5C3.5 12.68 2.83 12 2 12ZM5 14.5H19V12.5H5V14.5ZM5 8.5H19V6.5H5V8.5ZM5 0.5V2.5H19V0.5H5Z" fill="#5C5F5B"/></svg>
  `;

  export default {
    components: {
      IconDangerous
    },
    props: {
      id: {
        default: ""
      },
      value: {
        default: ""
      },
      mandatory: {
        type: Boolean,
        default: false
      },
      forceMandatory: {
        type: Boolean,
        default: false
      },
      touchedForm: {
        type: Boolean,
        default: false
      },
      allowStyle: {
        type: Boolean
      },
      closeQuill: {
        type: Boolean
      },
      acceptEnter: {
        type: Boolean,
        default: true
      }
    },
    data() {
      const nameBindings = {
        tab: false,
        handleEnter: {
          key: 13,
          handler: function () {}
        }
      };
      const normalBindings = {
        tab: true
      };
      const toolbar = [
        "bold",
        "italic",
        "strike",
        "underline",
        { list: "ordered" },
        { list: "bullet" }
      ];
      return {
        content: "",
        toggleOpen: false,
        mandatoryShow: true,
        touchedField: false,
        quillRender: true,
        editorOptions: {
          placeholder: "Vazio",
          modules: {
            toolbar: toolbar,
            keyboard: {
              bindings: !this.acceptEnter ? nameBindings : normalBindings
            }
          }
        }
      };
    },
    watch: {
      touchedForm() {
        if (this.mandatory && this.content == "") {
          this.mandatoryShow = this.mandatory;
        }
      },
      closeQuill() {
        if (this.closeQuill) {
          this.blurField();
        }
      }
    },
    computed: {
      showMandatoryMessage: function () {
        return (
          this.mandatory &&
          this.mandatoryShow &&
          (this.touchedField || this.touchedForm || this.forceMandatory) &&
          !this.content
        );
      }
    },
    methods: {
      forceRender() {
        this.quillRender = false;
        this.$nextTick().then(() => {
          this.quillRender = true;
        });
      },

      blurField() {
        this.allowStyle ? (this.toggleOpen = false) : "";
        this.mandatoryShow = true;
        this.touched();
        if (this.showMandatoryMessage) {
          this.editorOptions.placeholder = "";
          setTimeout(() => {
            this.forceRender();
          }, 10);
        }
      },
      focusField() {
        this.allowStyle ? (this.toggleOpen = true) : "";
        this.mandatoryShow = false;
        this.$emit("QuillFocus");
      },
      touched() {
        this.touchedField = true;
        this.validateField();
      },
      validateField() {
        if (this.content == "" && this.touchedField && this.mandatory) {
          this.mandatoryShow = true;
        }
      },
      removerTags(string, tags) {
        const regexString = `<(${tags.join("|")})(\\s[^>]*)?>|<\\/(?:${tags.join("|")})>`;
        const regex = new RegExp(regexString, "gi");
        return string.replace(regex, "");
      },
      onChange({ html, text, _ }) {
        let qlEditors = document.querySelectorAll(".ql-editor");
        if (qlEditors.length >= 1) {
          for (let i = 0; i < qlEditors.length; i++) {
            let items = qlEditors[i]?.children;
            for (let item of items) {
              let elems = item?.children;
              if (elems.length > 0) {
                for (let elem of elems) {
                  this.onChangeRemoveReplace(elem, text, html);
                }
              } else {
                this.onChangeRemoveReplace(item, text, html);
              }
            }
          }
        }
      },
      onChangeRemoveReplace(item, text, html) {
        let tagsToRemove = ["span", "strong", "em", "u", "s", "li", "ol", "ul"];
        // Removendo os styles copiados
        this.recursiveStyleRemove(item);
        if (!this.allowStyle) {
          this.$emit("input", this.removerTags(html, tagsToRemove));
        } else {
          this.$emit("input", html.replace(/<\s*\/?\s*span\s*[^>]*>/g, ""));
        }
      },

      recursiveStyleRemove(elem) {
        if (elem.children) {
          for (var i = 0; i < elem.children.length; i++) {
            this.recursiveStyleRemove(elem.children[i]);
          }
          elem.removeAttribute("style");
        }
      }
    },
    mounted() {
      if (this.value) {
        this.content = this.value;
      }
      let editors = this.$refs["quill-editor-" + this.id].$el.id;
      let element = document.getElementById(editors);
      // Índices do children usados para chegar até o componente
      element.children[1].children[0].focus();
      // element.children[div.ql-container].children[div.ql-editor]
      setTimeout(() => {
        element.children[1].children[0].blur();
      }, 10);
    }
  };
</script>

<style lang="scss">
  .container-quill {
    width: 100%;
    transition: max-height 0.5s;
    position: relative;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
      margin: 18px;
    }
    ::-webkit-scrollbar-thumb {
      background: #a9aca7;
      height: 58px;
      border-radius: 5px;
    }

    .div-error {
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      display: flex;
      position: absolute;

      .icon-error {
        margin-right: 5px;
      }
      p {
        margin: 0;
        font-size: 14px;
        line-height: 1.35em;
        font-weight: 600;
        color: #ef3e4d;
      }
    }
    .hide-editor {
      opacity: 0 !important;
    }
    .ctrl-disabled {
      .ql-container .ql-editor {
        &:focus {
          border: 1px solid #5c5f5b;
          border-radius: 3px;
          transition: max-height 0.5s;
          max-height: 350px;
        }
        strong,
        em,
        u,
        s,
        li,
        ol,
        ul,
        li::before {
          content: "";
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #1a1c1a;
          text-decoration: none;
          list-style: none;
          list-style-type: none;
          margin: 0;
          padding: 0;
        }
      }
    }
    .quill-editor {
      transition: max-height 0.5s;
      overflow: hidden;
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      &.show-toolbar {
        overflow-y: hidden;
        .ql-container.ql-snow {
          border: 1px solid #5c5f5b;
          border-bottom: none;
          background: #ffffff;
          border-radius: 3px 3px 0 0;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
            0px 1px 3px rgba(0, 0, 0, 0.2);
        }
      }
      .ql-toolbar {
        &.ql-snow {
          border: 1px solid #5c5f5b;
          border-top: none;
          padding: 0;
          background: #ffffff;
          border-radius: 0 0 3px 3px;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
            0px 1px 3px rgba(0, 0, 0, 0.2);
        }
        .ql-formats {
          display: flex;
          justify-content: center;
          gap: 16px;
          padding: 6px 0;
          width: 452px;
          margin: 0;
          &:after {
            content: none;
          }
        }
      }
      .ql-editor {
        font-family: "Work Sans";
        background: transparent;
        border: 1px solid transparent;
        padding: 3px 8px 3px 10px;
        width: 100%;
        transition: max-height 0.5s;
        overflow-y: auto;
        max-height: 100px;
        strong {
          font-weight: bold;
        }
        em {
          font-style: italic;
        }
        p {
          padding: 0 5px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #1a1c1a;
        }
        &.ql-blank {
          * {
            height: auto;
          }
        }
        &::before {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #c6c7c3;
        }
      }
      .ql-container.ql-snow {
        border: none;
      }
      .ql-active svg path {
        fill: #006d3a !important;
      }
      button[class*="ql-"] {
        width: 24px;
        svg path {
          fill: #5c5f5b !important;
        }
        &.ql-active {
          svg path {
            fill: #006d3a !important;
          }
        }
        svg:hover path {
          fill: #006d3a !important;
        }
      }
    }
    .hide-toolbar {
      .ql-toolbar {
        display: none;
      }
    }
    .show-toolbar {
      .ql-toolbar {
        display: block;
      }
      .ql-editor {
        max-height: 350px;
        height: 100%;
        overflow-y: auto;
        animation: hide-scroll 1.2s backwards;
      }
    }
  }
  @keyframes hide-scroll {
    0% {
      overflow-y: hidden;
    }
    100% {
      overflow-y: auto;
    }
  }
</style>
