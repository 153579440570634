<template>
  <div class="row login-screen">
    <div class="col-sm-6 form-login">
      <form @submit.prevent="doLogin()">
        <label class="label-login">Login</label>
        <label class="label-form">
          <p>E-mail</p>
          <input
            id="user-login"
            v-model="email"
            :class="errorEmail ? 'error' : ''"
            type="email"
            name="user"
            placeholder="Digite seu e-mail"
            @blur="showErrorEmail"
          />
        </label>
        <p
          v-if="errorEmail"
          class="msg-error"
        >
          Campo Obrigatório
        </p>
        <label class="label-form margin-top">
          <p>Senha</p>
          <input
            id="password-login"
            v-model="password"
            :class="errorPassword ? 'error' : ''"
            type="password"
            name="password"
            placeholder="Digite sua senha"
            @blur="showErrorPassword"
          />
        </label>
        <p
          v-if="errorPassword"
          class="msg-error"
        >
          Campo Obrigatório
        </p>
        <p
          v-if="errorLogin"
          class="msg-error-login"
        >
          *E-mail ou senha incorretos
        </p>
        <button
          type="submit"
          :class="['btn', loader || errorEmail || errorPassword ? 'event-none' : '']"
          :disabled="disabled || email == '' || password == ''"
        >
          <span v-if="!loader">Entrar</span>
          <IconLoading
            v-else
            class="loader"
          />
        </button>
      </form>
    </div>
    <div class="col-sm-6 logo">
      <IconLogo />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from "vuex";
  import IconLoading from "@/assets/images/icon-loading.svg";
  import IconLogo from "@/assets/images/icon-logo.svg";
  import { required, email } from "vuelidate/lib/validators";

  export default {
    validations() {
      return {
        email: { required, email },
        password: { required }
      };
    },
    components: {
      IconLoading,
      IconLogo
    },
    data() {
      return {
        emailStart: "",
        email: "",
        password: "",
        disabled: true,
        errorLogin: false,
        errorEmail: false,
        errorPassword: false,
        loader: false
      };
    },
    watch: {
      email() {
        if (this.email !== "" && this.password !== "") {
          this.disabled = false;
        }
        if (this.email !== "") {
          this.errorEmail = false;
        }
        if (this.email == this.emailStart) {
          this.errorEmail = true;
        }
      },
      password() {
        if (this.email !== "" && this.password !== "") {
          this.disabled = false;
        }
        if (this.password !== "") {
          this.errorPassword = false;
        }
      }
    },
    methods: {
      ...mapMutations(["SET_MODAL_LOGIN"]),
      ...mapActions("Login", ["ActionLogin"]),
      showErrorEmail() {
        if (!this.disabled && this.email == "") {
          this.errorEmail = true;
        }
      },
      showErrorPassword() {
        if (!this.disabled && this.password == "") {
          this.errorPassword = true;
        }
      },
      doLogin() {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.loader = true;
          this.errorLogin = false;
          this.ActionLogin({ user: this.email, password: this.password }).then((result) => {
            if (result.status === 200) {
              this.$router.push({ path: "/projects" });
            } else {
              this.errorLogin = true;
              this.loader = false;
            }
          });
        } else {
          this.errorLogin = true;
          this.loader = false;
        }
      }
    }
  };
</script>

<style lang="scss">
  .login-screen {
    height: 100vh !important;

    .form-login {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: url("@/assets/images/left-background.png");
      background-repeat: no-repeat;
      background-position: center;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      .label-login {
        text-align: left;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 38px;
        color: #fbfdf7;
        margin-bottom: 20px;
      }

      .margin-top {
        margin-top: 15px;
      }

      .label-form {
        display: flex;
        flex-direction: column;

        p {
          margin-bottom: 4px;
          color: #fbfdf7;
        }

        input {
          width: 317px;
          height: 28px;
          border: 1px solid #b8b8b9;
          border-radius: 3px;
          background-color: #fff;
          padding: 4.5px 10px;
        }
        input::placeholder {
          color: #b8b8b9;
        }
        input:focus {
          outline: none;
        }

        .error {
          border-color: #f19ea6;
        }
      }

      .btn {
        background: #ffffff;
        color: #006d3a;
        border-radius: 15px;
        padding: 8px 0;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        height: 35px;
        width: 317px;
        box-shadow: none;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .loader {
          animation: is-rotating 1s linear infinite;
        }
        @keyframes is-rotating {
          to {
            transform: rotate(1turn);
          }
        }
      }
      .event-none {
        pointer-events: none;
      }
      .btn[disabled] {
        color: #b8b8b9;
        background: #dbdbdc;
      }
      .msg-error {
        margin: 1px 0 0 5px;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #f19ea6;
      }

      .msg-error-login {
        color: #f19ea6;
        margin-bottom: -10px;
        margin-top: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
      }
    }

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 54px;
        color: #fff;
      }
    }
  }
</style>
