export const valida = {
  cnpj: (value) => {
    return value;
  },
  cpf: (value) => {
    return value;
  },
  cpfCnpj: (value) => {
    return value;
  },
  telefone: (value) => {
    return value;
  },
  dataPassada: (value) => {
    return value;
  },
  dataFutura: (value) => {
    return value;
  },
  horario: (value) => {
    return value;
  }
};
