const state = {
  isLoading: false,
  requestsPending: 0
};

const mutations = {
  show(state) {
    state.isLoading = true;
  },
  hide(state) {
    state.isLoading = false;
  },
  pending(state) {
    if (state.requestsPending == 0) {
      this.commit("Loader/show");
    }

    state.requestsPending++;
  },
  done(state) {
    if (state.requestsPending >= 1) {
      state.requestsPending--;
    }

    if (state.requestsPending <= 0) {
      this.commit("Loader/hide");
    }
  }
};

const actions = {
  show({ commit }) {
    commit("show");
  },
  hide({ commit }) {
    commit("hide");
  },
  pending({ commit }) {
    commit("pending");
  },
  done({ commit }) {
    commit("done");
  }
};

export default {
  state,
  mutations,
  actions,
  namespaced: true
};
