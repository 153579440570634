export function getCurrentTimeFormatted() {
  let date;
  date = new Date();

  const dateTimeObj = {
    date: "",
    hour: "",
    minute: "",
    second: ""
  };

  dateTimeObj.date = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}`;
  dateTimeObj.hour = date.getHours();
  dateTimeObj.minute = date.getMinutes();
  dateTimeObj.second = date.getSeconds();

  return dateTimeObj;
}
