import store from "@/store";
import * as storage from "@/utils/localStorage";

export default async (to, from, next) => {
  if (to.name == "report") {
    next();
  } else if (to.name != "login" && !storage.getStorage("token")) {
    try {
      await store.dispatch("Login/ActionCheckToken").then((response) => {
        storage.setStorage("token", response);
      });
      next({ name: to.name, params: to.params });
    } catch {
      next({ name: "login" });
    }
  } else if (
    to.name != "projects" &&
    JSON.parse(storage.getStorage("allowProjects")) == false &&
    storage.getStorage("token")
  ) {
    next({ name: "projects" });
  } else if (to.name == "projects" && !storage.getStorage("token")) {
    next({ name: "login" });
  } else {
    if (to.name == "login" && storage.getStorage("token")) {
      next({ name: "projects" });
    } else {
      next();
    }
  }
};
