export const base64 = {
  mimeType: (value) => {
    let mimeTypes = {
      "/": "image/jpg",
      i: "image/png",
      R: "image/gif",
      P: "image/svg+xml"
    };
    return mimeTypes[value.charAt(0)];
  }
};
