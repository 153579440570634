<template>
  <div class="trigger-date-picker">
    <slot
      :trigger-datepicker="triggerDatepicker"
      name="trigger"
    ></slot>
    <DatePicker
      ref="popupCalendar"
      v-model="date"
      :disabled="disabled"
      :clearable="false"
      :disabled-date="disabledAfterToday"
      :type="format"
      :show-week-number="false"
      :lang="lang"
      input-class="datepicker_input"
      popup-class="datepicker_popup"
      tab-index="0"
      :open="open"
      @change="closeDatepicker"
    >
      <template v-slot:footer>
        <div class="footer-container">
          <span
            class="today-button"
            @click="handleTodayButtonClick"
          >
            Hoje
          </span>
        </div>
      </template>
    </DatePicker>
  </div>
</template>

<script>
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import "vue2-datepicker/locale/pt-br";

  export default {
    data() {
      return {
        date: this.value,
        open: false,
        lang: {
          formatLocale: {
            monthsShort: [
              "Janeiro",
              "Fevereiro",
              "Março",
              "Abril",
              "Maio",
              "Junho",
              "Julho",
              "Agosto",
              "Setembro",
              "Outubro",
              "Novembro",
              "Dezembro"
            ],
            weekdaysMin: ["D", "S", "T", "Q", "Q", "S", "S"]
          }
        }
      };
    },
    methods: {
      handleTodayButtonClick() {
        this.date = new Date();
        this.closeDatepicker();
      },
      closeDatepicker() {
        this.open = false;
        window.removeEventListener("click", this.closeClickOutsideDatepicker);
      },
      closeClickOutsideDatepicker(e) {
        const isClickingOutsideDatepicker = !e.target.closest(".mx-datepicker-main");
        if (isClickingOutsideDatepicker) {
          this.closeDatepicker();
        }
      },
      disabledAfterToday(date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date > today;
      },
      triggerDatepicker() {
        this.open = !this.open;
        if (this.open) {
          setTimeout(() => {
            window.addEventListener("click", this.closeClickOutsideDatepicker);
          }, 10);
        }
      }
    },
    components: {
      DatePicker
    },
    props: {
      format: String,
      value: [Date, String],
      disabled: Boolean
    },
    watch: {
      date() {
        this.$emit("update:value", this.date);
      },
      value() {
        this.date = this.value;
      }
    }
  };
</script>

<style lang="scss">
  .trigger-date-picker {
    position: relative;
  }
  .mx-remove-calendar {
    display: none;
  }

  .datepicker_input,
  .datepicker_popup {
    * {
      font-family: "Work Sans", sans-serif;
      font-style: normal;
      font-size: 14px;
    }
  }

  .mx-datepicker {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .mx-input-wrapper {
      display: none;
    }
  }

  .footer-container {
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 5px;
  }

  .today-button {
    outline: 0;
    border: 0;
    background-color: transparent;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #006d3a;
    cursor: pointer;
    user-select: none;
  }

  .mx-calendar {
    width: 245px;
    // height: 281px;
    padding: 8px 0;
    padding-bottom: 0;
  }

  .datepicker_popup {
    background: white;

    border: 1px solid #b8b8b9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    .mx-calendar-content {
      padding: 0 8px;
    }

    .mx-calendar-header {
      padding: 0 8px;
      margin-bottom: 3px;

      span > button {
        font-weight: 700;
        color: #1a1c1a;
      }
    }

    .mx-table thead th {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #1a1c1a;
    }

    tbody::before {
      content: "";
      display: block;
      height: 3px;
    }

    .mx-button-text {
      &:hover {
        color: #1a1c1a !important;
      }
    }

    .mx-calendar:not(.mx-calendar-week-mode) {
      .mx-date-row > td {
        border-radius: 50%;

        &:active,
        &.active {
          background-color: #006d3a !important;

          div {
            color: white !important;
          }
        }

        &:hover {
          background-color: #d1e8d3;
        }
      }

      div {
        font-weight: 600;
        color: #1a1c1a;
      }

      .not-current-month div {
        color: #c6c7c3;
      }
    }

    .mx-calendar-week-mode {
      .mx-date-row {
        border-radius: 50% !important;
        overflow: hidden !important;

        & > :first-child {
          border-radius: 50% 0 0 50%;
        }

        & > :last-child {
          border-radius: 0 50% 50% 0;
        }

        div {
          font-weight: 600;
          color: #1a1c1a;
        }

        &:hover {
          background-color: #d1e8d3;
        }

        &:active,
        &.mx-active-week {
          background-color: #006d3a !important;

          div {
            color: white !important;
          }
        }
      }
    }
  }

  .mx-datepicker-footer {
    border-top: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: -2px;
  }

  // Removendo estilização padrão da biblioteca
  .datepicker_popup {
    transition: none !important;

    .mx-btn-icon-double-left,
    .mx-btn-icon-double-right {
      display: none;
    }

    .mx-calendar-header-label {
      pointer-events: none;
    }
  }

  .mx-btn-icon-left,
  .mx-btn-icon-right {
    background-position: center;
    background-repeat: no-repeat;
    width: 9px;
    height: 9px;
    margin-top: 13px;

    i {
      display: none;
    }
  }

  .mx-btn-icon-left {
    background-image: url("data:image/svg+xml,%3Csvg width='5' height='9' viewBox='0 0 5 9' fill='none' xmlns='http://www.w3.org/2000/svg' class='icon-left'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 3.79289C-0.0976311 4.18342 -0.0976311 4.81658 0.292893 5.20711L3.29289 8.20711C3.68342 8.59763 4.31658 8.59763 4.70711 8.20711C5.09763 7.81658 5.09763 7.18342 4.70711 6.79289L2.41421 4.5L4.70711 2.20711C5.09763 1.81658 5.09763 1.18342 4.70711 0.792893C4.31658 0.402369 3.68342 0.402369 3.29289 0.792893L0.292893 3.79289Z' fill='%231A1C1A' /%3E%3C/svg%3E");
  }

  .mx-btn-icon-right {
    background-image: url("data:image/svg+xml,%3Csvg width='5' height='9' viewBox='0 0 5 9' fill='none' xmlns='http://www.w3.org/2000/svg' class='icon-right'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.70711 3.79289C5.09763 4.18342 5.09763 4.81658 4.70711 5.20711L1.70711 8.20711C1.31658 8.59763 0.683417 8.59763 0.292893 8.20711C-0.097631 7.81658 -0.097631 7.18342 0.292893 6.79289L2.58579 4.5L0.292893 2.20711C-0.0976308 1.81658 -0.0976308 1.18342 0.292893 0.792893C0.683418 0.402369 1.31658 0.402369 1.70711 0.792893L4.70711 3.79289Z' fill='%231A1C1A' /%3E%3C/svg%3E");
  }
</style>
