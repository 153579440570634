<template>
  <div class="row row-index">
    <ModalDuplicate
      v-if="copyItemModal"
      :item-duplicate="copyItemModal"
      @reloadFeature="loadFeature"
      @loadTreeComponent="loadTreeComponent"
      @closeModal="closeModal"
      @idDuplicate="idDuplicate($event)"
    />
    <div class="col-sm-5">
      <ColumnTreeComponents
        :feature="feature"
        :action="actionFeature"
        :tree-components="treeComponents"
        :block-outside="blockOutside"
        :component-edit-id="componentEditId"
        :component-parent-type-id="componentParentTypeId"
        :force-close-component="closedComp"
        @copyItem="copyItem"
        @loadTreeComponent="loadTreeComponent"
        @reloadFeature="loadFeature"
        @newComponent="newComponent"
        @editComponent="editComponent"
        @editFeature="editFeature"
        @openDiscardChanges="openDiscardChanges"
        @undoChangesTree="undoChangesTree"
        @saveActualTree="saveActualTree"
        @handleDiscard="handleDiscard"
      />
    </div>
    <div class="col-sm-7">
      <ConfigComp
        v-if="actionComponent != ''"
        :allow-discard="allowDiscard"
        :component="component"
        :action="actionComponent"
        :component-type-edit-id="componentTypeEditId"
        :component-edit-id="componentEditId"
        :list-component-types="listComponentTypes"
        :list-all-types="listAllTypes"
        :component-new-parent-id="componentNewParentId"
        @loadComponentType="loadComponentType"
        @loadTreeComponent="loadTreeComponent"
        @clearWorkspace="clearWorkspace"
        @blockOutside="updateblockOutside"
        @editComponent="editComponent"
        @reloadFeature="loadFeature"
        @handleDiscard="handleDiscard"
      />
      <ConfigFeature
        v-else-if="feature && actionFeature"
        :feature="feature"
        :action="actionFeature"
        @openDiscardChanges="openDiscardChanges"
        @loadFeature="loadFeature"
        @blockOutside="updateblockOutside"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState, mapMutations } from "vuex";
  import ConfigFeature from "./components/ConfigFeature.vue";
  import ColumnTreeComponents from "./components/ColumnTreeComponents.vue";
  import ConfigComp from "./components/ConfigComp.vue";
  import ModalDuplicate from "../../components/ModalDuplicate.vue";
  export default {
    components: {
      ConfigFeature,
      ConfigComp,
      ColumnTreeComponents,
      ModalDuplicate
    },
    props: ["blockOutside"],
    data() {
      return {
        projectId: null,
        groupId: null,
        subgroupId: null,
        featureId: null,
        componentEditId: null,
        componentTypeEditId: null,
        componentParentTypeId: null,
        componentNewParentId: null,
        listComponentTypes: [],
        listAllTypes: [],
        feature: {},
        treeComponents: [],
        treeComponentsStart: [],
        actionFeature: "",
        component: {},
        actionComponent: "",
        allowDiscard: false,
        closedComp: 0,
        copyItemModal: null,
        copyNotEdit: null,
        idComponentDuplicate: null
      };
    },
    methods: {
      ...mapActions("Features", ["ActionGetFeature", "ActionGetTreeFeature"]),
      ...mapActions("Components", ["ActionGetComponentTypes", "ActionPutComponentOrder"]),
      ...mapMutations("CompFeat", [
        "setOpenComponentDuplicate",
        "setElemToOpen",
        "setIsNewSubComponent",
        "setIsNewComponent"
      ]),
      closeModal() {
        this.copyItemModal = null;
      },
      copyItem(data) {
        this.copyItemModal = data;
      },
      handleDiscard(e) {
        this.allowDiscard = e;
      },
      forceCloseComponent() {
        this.closedComp += 1;
      },
      clearWorkspace() {
        // Limpa os dados que permitem e exibe a visualização do workspace do lado direito da tela
        this.recursiveSearch(this.treeComponents);

        this.componentTypeEditId = null;
        this.componentEditId = null;
        this.componentNewParentId = null;
        this.actionFeature = "";
        this.actionComponent = "";
      },
      updateblockOutside(value) {
        this.$emit("alterBlockOutside", value);
      },
      undoChangesTree() {
        this.treeComponents = JSON.parse(JSON.stringify(this.treeComponentsStart));
      },
      saveActualTree() {
        this.treeComponentsStart = JSON.parse(JSON.stringify(this.treeComponents));
      },
      async loadFeature() {
        let projectId = this.projectId;
        let groupId = this.groupId;
        let subgroupId = this.subgroupId;
        let featureId = this.featureId;
        await this.ActionGetFeature({
          projectId,
          groupId,
          subgroupId,
          featureId
        }).then((response) => {
          this.$emit("setProject", response.data.projectName);
          this.$emit("setFeature", response.data.name);
          this.feature = { ...response.data };
          this.feature.featureTypes = response.data.featureTypes.filter(
            (elem) => elem.id === response.data.featureTypeId || elem.Available == true
          );
          this.setElemToOpen(null);
        });
      },
      recursiveSearch(list) {
        list.forEach((elem) => {
          if (elem.components?.length > 0) {
            this.recursiveSearch(elem.components);
          }
          if (elem.createdNow) {
            list.pop();
          }
        });
      },
      newComponent(elem = null) {
        this.setOpenComponentDuplicate(null);
        this.recursiveSearch(this.treeComponents);
        let componentTypeId = elem?.ComponentId || null;
        this.loadComponentType(
          this.projectId,
          this.groupId,
          this.subgroupId,
          this.featureId,
          componentTypeId
        );
        if (elem) {
          this.componentNewParentId = elem.ComponentId || null;
          this.componentEditId = elem.id || 0;
          elem.highlightOff = true;
          elem.components.push({
            name: "Novo Componente",
            components: [],
            createdNow: true
          });
        } else {
          this.componentEditId = 0;
          this.componentNewParentId = null;
          this.treeComponents.push({
            name: "Novo Componente",
            components: [],
            createdNow: true
          });
        }

        this.updateblockOutside(false);
        this.actionFeature = "";
        this.componentTypeEditId = null;
        this.actionComponent = "new";
        this.setIsNewSubComponent(false);
        this.setIsNewComponent(false);
      },
      isModalActive(e) {
        // Verifica se existe alguma modal ativa (usando o event)
        let element = e.target;
        while (element) {
          if (element.classList?.contains("swal2-shown")) {
            return true;
          }
          element = element.parentNode;
        }
        return false;
      },
      openDiscardChanges(e) {
        if (!this.isModalActive(e)) {
          this.$swal({
            customClass: "swal-warning",
            title: "Atenção",
            text: "Você tem alterações não salvas. Deseja descartar as alterações?"
          })
            .then((result) => {
              if (result.isConfirmed) {
                if (this.isNewSubComponent) {
                  this.setOpenComponentDuplicate(null);
                  this.newComponent(this.elemToOpen);
                  this.updateblockOutside(false);
                  this.allowDiscard = true;
                } else if (this.isNewComponent) {
                  this.setOpenComponentDuplicate(null);
                  this.newComponent();
                  this.updateblockOutside(false);
                  this.allowDiscard = true;
                } else {
                  this.setOpenComponentDuplicate(null);
                  this.setElemToOpen(null);
                  this.updateblockOutside(false);
                  this.allowDiscard = true;
                }
              }
              return result;
            })
            .then((result) => {
              if (result.isConfirmed) {
                let target = e.target;
                for (let i = 0; i < 4; i++) {
                  try {
                    target.click().stopPropagation();
                  } catch (error) {
                    target = target.parentNode;
                  }
                }
                this.updateblockOutside(false);
              }
            });
        }
      },
      editComponent(componentId, componentTypeId) {
        this.setOpenComponentDuplicate(componentId);
        if (componentId !== undefined && componentTypeId !== undefined) {
          this.recursiveSearch(this.treeComponents);
          this.updateblockOutside(false);
          this.actionComponent = "edit";
          this.actionFeature = "";
          this.componentTypeEditId = componentTypeId;
          this.componentEditId = componentId;
        }
      },
      editFeature() {
        this.clearWorkspace();
        this.loadFeature();
        this.actionFeature = "edit";
      },
      loadTreeComponent() {
        let projectId = this.projectId;
        let groupId = this.groupId;
        let subgroupId = this.subgroupId;
        let featureId = this.featureId;
        this.ActionGetTreeFeature({ projectId, groupId, subgroupId, featureId })
          .then((response) => {
            this.treeComponents = response.data.components;
            if (this.treeComponentsStart.length != this.treeComponents.length) {
              this.treeComponentsStart = response.data.components;
            }
            this.findItemById(this.openComponentDuplicate, this.treeComponents);
          })
          .then(() => {
            this.forceCloseComponent();
          });
      },
      loadComponentType(projectId, groupId, subgroupId, featureId, componentTypeId = null) {
        this.ActionGetComponentTypes({
          projectId,
          groupId,
          subgroupId,
          featureId,
          componentTypeId
        }).then((response) => {
          if (this.actionComponent === "new") {
            this.listComponentTypes = response.data.filter((elem) => elem.available == true);
          } else {
            this.listAllTypes = response.data;
          }
        });
      },
      findItemWithCopyNotEdit(array) {
        for (const item of array) {
          if (item.copyNotEdit == true) {
            return item;
          }
          if (item.components && item.components.length > 0) {
            const foundItem = this.findItemWithCopyNotEdit(item.components);
            if (foundItem) {
              return foundItem;
            }
          }
        }
        return null;
      },
      findItemById(componentId, array) {
        for (const item of array) {
          if (item.id == parseInt(componentId)) {
            this.editComponent(item.id, item.ComponentId);
          }
          if (item.components && item.components.length > 0) {
            const foundItem = this.findItemById(componentId, item.components);
            if (foundItem) {
              this.editComponent(foundItem.id, foundItem.ComponentId);
            }
          }
        }
        return null;
      }
    },
    mounted() {
      // Limpa o sessionStorage utilizado para salvar os Id's que o open era false
      if (sessionStorage.getItem("closedIds")) {
        sessionStorage.removeItem("closedIds");
      }
    },
    watch: {
      treeComponents: {
        imediate: true,
        handler(newValue) {
          if (newValue && newValue.length > 0) {
            let foundItem = null;
            if (this.copyNotEdit == 2) {
              foundItem = this.findItemWithCopyNotEdit(newValue);
            }
            if (foundItem) {
              this.editComponent(foundItem.id, foundItem.ComponentId);
            }
          }
        }
      }
    },
    created() {
      if (this.$route.params.copyNotEdit) {
        this.copyNotEdit = this.$route.params.copyNotEdit;
      }
      if (
        this.$route.params.projectId &&
        this.$route.params.groupId &&
        this.$route.params.subgroupId
      ) {
        this.projectId = this.$route.params.projectId;
        this.groupId = this.$route.params.groupId;
        this.subgroupId = this.$route.params.subgroupId;
        if (this.$route.params.featureId) {
          this.featureId = this.$route.params.featureId;
          this.actionFeature = "edit";
          this.loadComponentType(this.projectId, this.groupId, this.subgroupId, this.featureId);
        } else {
          this.actionFeature = "new";
        }
        if (this.actionFeature != "new") {
          this.loadFeature();
        }
      } else {
        this.$router.push({ path: "/projects" });
      }
    },
    computed: {
      ...mapState("CompFeat", {
        openComponentDuplicate: "openComponentDuplicate",
        elemToOpen: "elemToOpen",
        isNewSubComponent: "isNewSubComponent",
        isNewComponent: "isNewComponent"
      })
    }
  };
</script>

<style lang="scss">
  .row-index {
    margin: 0px !important;
    & > div {
      padding: 0px !important;
    }
  }
</style>
