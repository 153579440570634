var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('Overlay',{attrs:{"is-active":_vm.isLoading}}),(_vm.$route.name != "login")?_c('header',{staticClass:"main-menu"},[_c('div',{staticClass:"nav-left-side"},[_c('div',{staticClass:"div-logo"},[_c('LogoTop',{staticClass:"logo"})],1),_c('div',{staticClass:"divisor"}),_c('div',{staticClass:"main-menu"},[(_vm.$route.name != "login")?_c('div',{staticClass:"breadcrumbs"},[_c('span',{staticStyle:{"margin-right":"6px"}},[_c('router-link',{class:_vm.$route.path !== '/projects' ? 'path-pages' : 'actual-page',attrs:{"to":"/projects"}},[_vm._v(" Projetos ")])],1),(!!_vm.project)?[_c('span',{staticClass:"path-pages-divisor"},[_vm._v("/")]),_c('span',{staticClass:"text-format-breadcrumbs"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                  title: _vm.project,
                  placement: 'top',
                  disabled: _vm.disableBreadCrumbTooltipPath,
                  customClass: 'tooltip-width-breadcrumps'
                }),expression:"{\n                  title: project,\n                  placement: 'top',\n                  disabled: disableBreadCrumbTooltipPath,\n                  customClass: 'tooltip-width-breadcrumps'\n                }",modifiers:{"hover":true}}],class:!!_vm.feature ? 'path-pages' : 'actual-page',staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap","width":"100%"},attrs:{"id":"path-tooltip","to":("/project/" + (_vm.$route.params.projectId))}},[_vm._v(_vm._s(_vm.project)+" ")])],1)]:_vm._e(),(!!_vm.feature)?[_c('span',{staticClass:"path-pages-divisor"},[_vm._v("/")]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                title: _vm.feature,
                placement: 'top',
                disabled: _vm.disableBreadCrumbTooltipActual,
                customClass: 'tooltip-width-breadcrumps'
              }),expression:"{\n                title: feature,\n                placement: 'top',\n                disabled: disableBreadCrumbTooltipActual,\n                customClass: 'tooltip-width-breadcrumps'\n              }",modifiers:{"hover":true}}],staticClass:"actual-page",staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap","max-width":"100%"},attrs:{"id":"actual-tooltip"}},[_c('p',{staticClass:"text-format-breadcrumbs",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.feature)+" ")])])]:_vm._e()],2):_vm._e(),_c('PopOverPlay',{attrs:{"start-timer-by-card-project":_vm.startTimerByCardProject,"reset-timer-pop-over":_vm.resetTimerPopOver},on:{"setCurrentFunctionalityId":_vm.setCurrentFunctionalityId,"setCurrentProjectId":_vm.setCurrentProjectId,"openModalAppointment":_vm.openModalAppointment,"setTimerInfo":function($event){return _vm.setTimerInfo($event)},"isTimerAppoint":function ($event) { return _vm.setTimerAppointInfos($event); }}})],1)]),_c('div',{staticClass:"nav-right-side"},[_c('button',{staticClass:"btn-logout",attrs:{"type":"button"},on:{"click":function($event){_vm.blockOutside ? _vm.openDiscardModal($event) : _vm.logout()}}},[_c('IconExit')],1)])]):_vm._e(),_c('router-view',{key:_vm.$route.fullPath,attrs:{"block-outside":_vm.blockOutside},on:{"alterBlockOutside":_vm.alterBlockOutside,"startTimer":_vm.startTimer,"setProject":_vm.setProject,"setFeature":_vm.setFeature,"logout":_vm.logout}}),(_vm.modalAppointment)?_c('AppointmentModal',{attrs:{"action":_vm.action,"timer-appoint-infos":_vm.timerAppoint,"timer":_vm.timer},on:{"resetTimer":_vm.resetTimer,"closeModalAppointment":_vm.closeModalAppointment}}):_vm._e(),(_vm.modalLogin == true)?_c('ModalLoginComponent'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }