<template>
  <div style="height: 100%">
    <div
      id="column-tree-components"
      class="column-tree"
    >
      <div :class="['header', featureId == null && 'top-header']">
        <span
          ><IconFeatures
            class="top-icon"
            style="width: 21px; text-align: center"
        /></span>
        <h3
          v-b-tooltip.hover="{
            title: feature.name,
            placement: 'toprigth',
            boundary: 'window',
            disabled: disableTooltipTitle,
            customClass: 'width-tooltip'
          }"
          class="text-format-tree-comp"
        >
          {{ featureId ? feature.name : "Nova Funcionalidade" }}
        </h3>
        <div class="tools">
          <button
            v-if="featureId"
            v-b-tooltip.hover="{
              title: 'Editar Funcionalidade',
              placement: 'topright',
              boundary: 'window'
            }"
            @click="blockOutside ? openDiscardChanges($event) : editFeature()"
          >
            <IconPencil />
          </button>

          <ReportVersion
            :block-outside="blockOutside"
            :type="feature.active"
            :feature-id="featureId"
            :copy-not-edit="feature.copyNotEdit"
            :subgroup-id="subgroupId"
            :group-id="groupId"
            :hash="feature.hash"
            :project-id="projectId"
            :devops-id="feature.DevOpsId"
            :feature-name="feature.name"
            :access-features="
              () => (feature.copyNotEdit == 1 ? editFeature() : findAndEditCopy(treeComponents))
            "
            @refresh="reloadComponent"
            @openDiscardChanges="openDiscardChanges"
            @reloadFeature="reloadComponent"
          />

          <button
            v-b-tooltip.hover="{
              title: 'Retornar para gestão de funcionalidades',
              placement: 'topleft',
              boundary: 'window'
            }"
            @click="
              blockOutside
                ? openDiscardChanges($event)
                : $router.push({
                    name: 'project',
                    params: { projectid: projectId }
                  })
            "
          >
            <IconReturn />
          </button>
        </div>
      </div>
      <div class="content">
        <form
          v-if="treeComponentsFiltered.length > 0 || termFilterTreeComponents != ''"
          class="form-filter"
        >
          <IconSearch class="icon-search" />
          <input
            v-model="termFilterTreeComponents"
            type="text"
            :class="['form-control', termFilterTreeComponents != '' && 'active']"
            placeholder="Pesquisar"
          />
          <button
            v-if="termFilterTreeComponents != ''"
            class="btn-clear"
            @click="clearFilterTreeComponents"
          >
            <IconClose />
          </button>
        </form>
        <div
          v-if="featureId != null"
          class="tree"
        >
          <DraggableComp
            v-if="forceRenderTreeComponent && treeComponentsFiltered.length > 0"
            ref="draggable"
            class="draggable draggable-groups"
            :list="treeComponentsFiltered"
            :component-edit-id="componentEditId"
            :component-parent-edit-id="componentParentEditId"
            :block-outside="blockOutside"
            :force-close-component="forceCloseComponent"
            :term-filter-tree-components="termFilterTreeComponents"
            @copyItem="copyItem"
            @newComponent="newComponent"
            @editComponent="editComponent"
            @openDiscardChanges="openDiscardChanges"
            @undoChangesTree="undoChangesTree"
            @reloadFeature="reloadComponent"
            @reload="reloadTreeComponent"
            @saveActualTree="saveActualTree"
            @forceRenderTreeComponentsToggle="forceRenderTreeComponentsToggle"
            @handleDiscard="handleDiscard"
          />
          <div>
            <button
              type="button"
              class="btn-new-group"
              @click="openNewComponent(blockOutside, $event)"
            >
              <IconPlus /> Novo Componente
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from "vuex";
  import IconPencil from "@/assets/images/icon-edit-pencil.svg";
  import IconFeatures from "@/assets/images/icon-features.svg";
  import IconReturn from "@/assets/images/icon-arrow-return.svg";
  import IconSearch from "@/assets/images/icon-search.svg";
  import IconPlus from "@/assets/images/icon-plus-arrounded.svg";
  import IconClose from "@/assets/images/icon-close.svg";
  import DraggableComp from "./DraggableComp.vue";
  import ReportVersion from "../../../components/ReportVersion.vue";

  export default {
    components: {
      IconPencil,
      IconFeatures,
      IconReturn,
      IconSearch,
      IconClose,
      IconPlus,
      DraggableComp,
      ReportVersion
    },
    data() {
      return {
        disableTooltipTitle: true,
        nameFeature: "",
        termFilterTreeComponents: "",
        treeComponentTempFilter: [],
        projectId: null,
        groupId: null,
        subgroupId: null,
        featureId: null,
        forceRenderTreeComponent: true,
        scrollPos: 0,
        closedTree: [],
        errors: [],
        copyItemModal: null
      };
    },
    props: [
      "feature",
      "action",
      "treeComponents",
      "blockOutside",
      "componentEditId",
      "componentParentTypeId",
      "componentParentEditId",
      "forceCloseComponent"
    ],
    methods: {
      ...mapMutations("CompFeat", ["setIsNewComponent"]),
      copyItem(id, compId, name) {
        this.copyItemModal = {
          id,
          compId,
          name,
          itemType: "component",
          projectId: this.projectId,
          groupId: this.groupId,
          subgroupId: this.subgroupId,
          featureId: this.featureId
        };
        this.$emit("copyItem", this.copyItemModal);
      },
      openNewComponent(blockOutside, $event) {
        this.setIsNewComponent(true);
        if (blockOutside) {
          this.openDiscardChanges($event);
        } else {
          this.newComponent();
        }
      },
      handleDiscard(e) {
        this.$emit("handleDiscard", e);
      },
      reloadComponent() {
        this.reloadTreeComponent();
        this.$emit("reloadFeature");
      },
      reloadTreeComponent() {
        this.forceRenderTreeComponent = false;
        this.$nextTick().then(() => {
          this.forceRenderTreeComponent = true;
        });
      },
      forceRenderTreeComponentsToggle() {
        let el = document.querySelector(".content");
        this.scrollPos = el.scrollTop;
        this.reloadTreeComponent();
        setTimeout(() => {
          el.scrollTo(0, this.scrollPos);
        }, 10);
      },
      openDiscardChanges(e) {
        this.$emit("openDiscardChanges", e);
      },
      loadTreeComponent() {
        this.$emit("loadTreeComponent");
      },
      saveActualTree() {
        this.$emit("saveActualTree");
      },
      undoChangesTree() {
        this.$emit("undoChangesTree");
      },
      clearFilterTreeComponents() {
        this.termFilterTreeComponents = "";
      },
      newComponent(elem = null) {
        this.$emit("newComponent", elem);
      },
      editComponent(componentId, componentTypeId) {
        this.$emit("editComponent", componentId, componentTypeId);
      },
      findAndEditCopy(feature) {
        const foundItem = this.findItemWithCopyNotEdit(feature);
        this.$emit("editComponent", foundItem.item.id, foundItem.item.ComponentId);
        const dragComp = this.$refs.draggable;
        dragComp.toggleComponents(foundItem.parent, true);
      },
      findItemWithCopyNotEdit(array, parent = null) {
        for (const item of array) {
          if (item.copyNotEdit == true) {
            return { item, parent };
          }
          if (item.components && item.components.length > 0) {
            const foundItem = this.findItemWithCopyNotEdit(item.components, item);
            if (foundItem) {
              return foundItem;
            }
          }
        }
        return null;
      },
      editFeature() {
        this.$emit("editFeature");
      },
      filterComponents() {
        this.treeComponentTempFilter = JSON.parse(JSON.stringify(this.treeComponents));
        this.treeComponentTempFilter = this.recursiveSearchComponent(
          this.treeComponentTempFilter,
          {}
        );
        return this.treeComponentTempFilter;
      },
      recursiveSearchComponent(listComponents, parentComponent) {
        let listFiltered = [];
        let isNameEqual = "";
        let validParentName = this.verifyEqualName(parentComponent.name);

        if (listComponents) {
          listFiltered = listComponents.filter((component) => {
            const newComponent = component;
            isNameEqual = this.verifyEqualName(newComponent.name);
            if (isNameEqual || this.hasTermInsideComponent(newComponent)) {
              this.recursiveSearchComponent(newComponent.components, component);
              return true;
            } else {
              if (!validParentName) {
                component["hidden"] = true;
              } else {
                component["open"] = false;
              }
              return false;
            }
          });
        }
        return listFiltered;
      },
      hasTermInsideComponent(component) {
        // Descobrir se há o termo procurado dentro de um componente, iterando pelos componentes filhos
        let childComponent = {};
        let hasComponent = false;
        if (component.components) {
          for (let i = 0; i < component.components.length; i++) {
            childComponent = component.components[i];
            if (this.verifyEqualName(childComponent.name)) {
              return true;
            }
            hasComponent = this.hasTermInsideComponent(childComponent);
            if (hasComponent) {
              return hasComponent;
            }
          }
        }
        return false;
      },
      verifyEqualName(name) {
        if (name)
          return Boolean(name.toUpperCase().match(this.termFilterTreeComponents.toUpperCase()));
      }
    },
    computed: {
      ...mapState("CompFeat", {
        isNewComponent: "isNewComponent"
      }),
      treeComponentsFiltered() {
        // Gera um novo array com apenas os itens (grupos, subgrupos e funcionalidades) filtrados a partir do termo digitado
        if (this.termFilterTreeComponents != "") {
          return this.filterComponents();
        } else {
          return this.treeComponents;
        }
      }
    },
    created() {
      this.projectId = parseInt(this.$route.params.projectId) || null;
      this.groupId = parseInt(this.$route.params.groupId) || null;
      this.subgroupId = parseInt(this.$route.params.subgroupId) || null;
      this.featureId = parseInt(this.$route.params.featureId) || null;

      if (this.featureId) {
        this.loadTreeComponent();
      }
    },
    updated() {
      let titleTreeComp = this.$el.querySelector(".text-format-tree-comp");
      let isWidthScrollSmallerClient = titleTreeComp.scrollWidth <= titleTreeComp.clientWidth;
      this.disableTooltipTitle = isWidthScrollSmallerClient;
    }
  };
</script>

<style lang="scss">
  .activityComp {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
    background-color: #fbfdf7;
    display: flex;
    justify-content: flex-end;
  }

  .top-icon {
    margin-right: 5px;
  }

  .text-format-tree-comp {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .width-tooltip {
    width: 350px;
  }

  .width-tooltip .tooltip-inner {
    max-width: 350px !important;
  }

  .tools {
    margin-left: 1px;
    display: flex;
  }
</style>
