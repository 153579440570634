import * as actions from "./actions";

const state = {
  isEdit: false,
  actionAppoint: "new",
  fromEditButton: false,
  tempStartTime: null,
  errorAppoint: false,
  cardProjectId: null,
  cardFunctionalityId: null,
  optionViewSelected: null,
  stepControl: false
};

const mutations = {
  setIsEdit(state, newValue) {
    state.isEdit = newValue;
  },
  setActionAppoint(state, newValue) {
    state.actionAppoint = newValue;
  },
  setFromEditButton(state, newValue) {
    state.fromEditButton = newValue;
  },
  setTempStartTime(state, newValue) {
    state.tempStartTime = newValue;
  },
  setErrorAppoint(state, newValue) {
    state.errorAppoint = newValue;
  },
  setCardProjectId(state, newValue) {
    state.cardProjectId = newValue;
  },
  setCardFunctionalityId(state, newValue) {
    state.cardFunctionalityId = newValue;
  },
  setOptionViewSelected(state, newValue) {
    state.optionViewSelected = newValue;
  },
  setStepControl(state, newValue) {
    state.stepControl = newValue;
  }
};

export default {
  state,
  mutations,
  actions,
  namespaced: true
};
