<template>
  <div :class="['inputTypeItemId', !v.required && v.$anyError && 'error']">
    <input
      v-model="selectValue"
      type="text"
      :placeholder="placeholder"
      @blur="closeInputVerify"
      @input="alterValueInput"
    />
    <div
      v-if="!v.required && v.$anyError"
      class="select-error"
    >
      <p>Campo Obrigatório</p>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selectValue: null
      };
    },
    methods: {
      alterValueInput(e) {
        this.$emit("input", e.target.value);
      },
      closeInputVerify() {
        this.v.$touch();
      }
    },
    props: ["v", "value", "placeholder"],
    watch: {
      value() {
        this.selectValue = this.value != undefined ? this.value : null;
      }
    }
  };
</script>

<style lang="scss">
  .inputTypeItemId {
    input {
      width: 100%;
      height: 28px;
      border: 1px solid #b8b8b9;
      border-left: none;
      border-radius: 0 3px 3px 0;
      text-align: end;
      padding: 4.4px 10px;
      font-family: "Work Sans", sans-serif;

      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #c6c7c3;
      }
    }

    &.error {
      input {
        border: 1px solid #cf2e3e;
      }
    }

    .select-error {
      margin: 1px 0 1px 5px;
      p {
        color: #dc3546;
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
</style>
