<template>
  <div>
    <b-modal
      id="modal-group-subgroup"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
    >
      <template #modal-header>
        <h2>
          {{ props.action == "new" ? "Adicionar" : "Editar" }}
          {{ props.type == "group" ? "Grupo" : "Subgrupo" }}
        </h2>
      </template>

      <template #default>
        <div class="form-group-input">
          <label for="input-name">Nome</label>
          <input
            id="input-name"
            v-model="name"
            type="text"
            :v="$v.name"
            :class="['form-control', $v.name.$anyError && 'error']"
            @input="$emit('updateName', $event.target.value)"
            @blur="$v.name.$touch()"
          />
          <p
            v-if="$v.name.$anyError"
            class="validacao-msg error"
          >
            Campo Obrigatório
          </p>
        </div>
        <div class="form-group-input">
          <label for="input-devops">ID DevOps</label>
          <input
            id="input-devops"
            v-model="devOpsId"
            type="number"
            :v="$v.devOpsId"
            placeholder="Digite aqui"
            :class="'form-control input-devops'"
          />
        </div>
        <div class="form-group-input">
          <label
            for="input-descricao"
            class="label-description"
            >Descrição</label
          >
          <textarea
            id="input-descricao"
            v-model="description"
            :v="$v.description"
            :class="['form-control', $v.description.$anyError && 'error']"
            placeholder="Digite aqui"
            @blur="$v.description.$touch()"
          ></textarea>
          <p
            v-if="$v.description.$anyError"
            class="validacao-msg error"
          >
            Campo Obrigatório
          </p>
        </div>
      </template>

      <template #modal-footer>
        <button
          v-if="props.action == 'edit'"
          type="button"
          class="btn-delet"
          @click="delet"
        >
          <IconTrash />Apagar
        </button>
        <button
          v-if="props.action == 'edit' || props.action == 'new'"
          type="button"
          class="btn-close-modal"
          @click="close"
        >
          <IconClose />Fechar
        </button>
        <button
          type="button"
          class="btn-save"
          :disabled="disabledSave"
          @click="save"
        >
          <IconSave />Salvar
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import { required } from "vuelidate/lib/validators";
  import IconClose from "@/assets/images/icon-close.svg";
  import IconTrash from "@/assets/images/icon-trash2.svg";
  import IconSave from "@/assets/images/icon-save.svg";

  export default {
    name: "Modal",
    props: ["props", "open"],
    components: {
      IconClose,
      IconTrash,
      IconSave
    },
    validations() {
      return {
        name: {
          required
        },
        description: {
          required
        },
        devOpsId: {}
      };
    },
    data() {
      return {
        headerConfigAllowLoginModal: {
          headers: {
            allowLoginModal: true
          }
        },
        devOpsId: null,
        devOpsIdOrigin: null,
        name: "",
        nameOrigin: "",
        description: "",
        descriptionOrigin: "",
        permitionSave: true
      };
    },
    watch: {
      open(value) {
        // Assiste a mudança da propriedade e arealiza a abertura da modal, definindo as informações a serem exibidas
        if (value === true) {
          this.permitionSave = true;
          this.$bvModal.show("modal-group-subgroup");
          this.$v.$reset();
          this.name = this.props.item.name || "";
          this.nameOrigin = this.props.item.name || "";
          this.description = this.props.item.description || "";
          this.descriptionOrigin = this.props.item.description || "";
          this.devOpsId = this.props.item.devOpsId || "";
          this.devOpsIdOrigin = this.props.item.devOpsId || "";
        } else {
          this.$bvModal.hide("modal-group-subgroup");
        }
      }
    },
    methods: {
      ...mapActions("Groups", ["ActionPostGroup", "ActionPutGroup", "ActionDeleteGroup"]),
      ...mapActions("Subgroups", [
        "ActionPostSubgroup",
        "ActionPutSubgroup",
        "ActionDeleteSubgroup"
      ]),

      save() {
        // Salvar ação (edição ou criação) da modal de um grupo ou subgrupo
        if (this.permitionSave == true) {
          this.permitionSave = false;
          this.$v.$touch();
          if (!this.$v.$invalid) {
            let payload = {
              name: this.name,
              description: this.description,
              devOpsId: this.devOpsId
            };
            let projectId = this.props.projectId;
            let groupId = this.props.groupId;
            let subgroupId = this.props.subgroupId;
            let config = this.headerConfigAllowLoginModal;

            if (this.props.type == "group" && this.props.action == "new") {
              // Cadastro de Novo Grupo
              this.ActionPostGroup({ projectId, payload, config })
                .then(() => {
                  this.$emit("refreshTreeFeatures");
                })
                .catch(() => {
                  this.permitionSave = true;
                });
            } else if (this.props.type == "group" && this.props.action == "edit") {
              // Edição de Grupo
              this.ActionPutGroup({ projectId, groupId, payload, config })
                .then(() => {
                  this.$emit("refreshTreeFeatures");
                })
                .catch(() => {
                  this.permitionSave = true;
                });
            } else if (this.props.type == "subgroup" && this.props.action == "new") {
              // Cadastro de Novo Subgrupo
              this.ActionPostSubgroup({ projectId, groupId, payload, config })
                .then(() => {
                  this.$emit("refreshTreeFeatures");
                })
                .catch(() => {
                  this.permitionSave = true;
                });
            } else if (this.props.type == "subgroup" && this.props.action == "edit") {
              // Edição de Subgrupo
              this.ActionPutSubgroup({ projectId, groupId, subgroupId, payload, config })
                .then(() => {
                  this.$emit("refreshTreeFeatures");
                })
                .catch(() => {
                  this.permitionSave = true;
                });
            }
          }
        }
      },

      close() {
        // Fecha a modal e aborta a ação (edição ou criação) que estava sendo realizada

        if (!this.editName && !this.editDescription) {
          // Verifica se não houve nenhuma modificação para fechar a modal
          this.$emit("closeModal", this.props.action);
        } else {
          // Exibe modal de confirmação caso tenha alterado algo

          this.$swal({
            customClass: "swal-warning",
            title: "Atenção",
            text: "Você tem alterações não salvas. Deseja descartar as alterações?"
          }).then((result) => {
            if (result.isConfirmed) {
              if (this.props.action == "edit") {
                this.$emit("updateName", this.nameOrigin); // Retorna o nome original ao item (grupo ou subgrupo)
              }
              this.$emit("closeModal", this.props.action);
            }
          });
        }
      },
      delet() {
        // Exclui o item (grupo ou subgrupo) que estava sendo editado na modal

        let projectId = this.props.projectId;
        let groupId = this.props.groupId;
        let subgroupId = this.props.subgroupId;

        let text = `Deseja apagar o ${
          this.props.type == "group" ? "grupo" : "subgrupo"
        }? Se existirem ${
          this.props.type == "group" ? "subgrupos e" : ""
        } funcionalidades associadas ao ${
          this.props.type == "group" ? "grupo" : "subgrupo"
        }, também serão ${this.props.type == "group" ? "apagados" : "apagadas"}.`;

        this.$swal({
          customClass: "swal-delete",
          title: "Apagar",
          text
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.props.type == "group" && this.props.action == "edit") {
              // Exclusão de Grupo
              this.ActionDeleteGroup({ projectId, groupId }).then(() => {
                this.$emit("refreshTreeFeatures");
              });
            } else if (this.props.type == "subgroup" && this.props.action == "edit") {
              // Exclusão de Subgrupo
              this.ActionDeleteSubgroup({ projectId, groupId, subgroupId }).then(() => {
                this.$emit("refreshTreeFeatures");
              });
            }
          }
        });
      }
    },
    computed: {
      disabledSave() {
        // Bloqueia/Disabilita o botão de salvar caso os campos não estejam preenchidos ou alterados
        if (this.name == "" || this.description == "") {
          return true;
        } else if (
          (!this.editName || this.name == "") &&
          (!this.editDescription || this.editDescription == "") &&
          !this.editDevopsId &&
          this.props.action == "edit"
        ) {
          return true;
        } else {
          return false;
        }
      },
      editName() {
        // Verifica se o nome foi editado
        return this.name != this.nameOrigin;
      },
      editDescription() {
        // Verifica se a drescrição foi editada
        return this.description != this.descriptionOrigin;
      },
      editDevopsId() {
        return this.devOpsId != this.devOpsIdOrigin;
      }
    }
  };
</script>

<style lang="scss">
  .modal#modal-group-subgroup {
    h2 {
      font-family: "Work Sans", sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
    }

    .modal-dialog {
      margin-top: 0;
      margin-bottom: 0;
      top: 50%;
      transform: translateY(-50%);
      position: relative;

      .modal-content {
        border-radius: 5px;
        border: none;
        background-color: #fbfbf7;

        .modal-header {
          background-color: transparent;
          border: none;
          padding: 20px;
          height: auto;
          h2 {
            color: #1a1c1a;
            font-weight: 600;
            font-size: 20px;
          }
        }
        .modal-body {
          padding: 20px;
          padding-top: 0;
          padding-bottom: 9px;

          .form-group-input {
            margin-bottom: 15px;
            font-family: "Work Sans", sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;

            &:last-child {
              margin-bottom: 0;
            }
            .form-control {
              height: auto;
              box-shadow: none;
              outline: none;
              background-color: #ffffff;
              font-size: 14px;
              line-height: 1.35em;
              border: 1px solid #b8b8b9;
              border-radius: 3px;

              &.error {
                border-color: #dc3546;
              }
              &::placeholder {
                color: #b8b8b9;
              }
            }
            label {
              margin-bottom: 4px;
              font-size: 14px;
            }
            input {
              padding: 4.5px 10px;
            }
            textarea {
              font-size: 12px;
              resize: none;
              height: 140px !important;
              padding: 10px;
            }
            p.error {
              color: #dc3546;
              font-size: 10px;
              margin: 0px;
              margin-top: 1px;
              margin-left: 5px;
            }
          }
        }
        .modal-footer {
          border: none;
          padding: 20px;

          button {
            font-size: 14px;
            line-height: 1.2em;
            font-weight: 600;
            border: 1px solid transparent;
            border-radius: 15px;
            padding: 8px 16px;
            margin: 0;
            margin-left: 16px;
            box-shadow: none;
            background-color: transparent;

            svg {
              width: 15px;
              float: left;
              position: relative;
              top: 1px;
              margin-right: 6px;
            }

            &.btn-delet {
              color: #dc3546;
              &:hover {
                opacity: 0.7;
                transition: 0.2s;
              }
              svg {
                path {
                  fill: #dc3546;
                }
              }
            }
            &.btn-close-modal {
              border: 1px solid #006d3a;
              color: #006d3a;
              &:hover {
                color: #fff;
                background: #006d3a;
                transition: 0.2s;
                svg {
                  path {
                    fill: #fff;
                  }
                }
              }
            }
            &.btn-save {
              color: #fff;
              background: #006d3a;
              &:hover {
                color: #006d3a;
                border: 1px solid #006d3a;
                background: #fff;
                transition: 0.2s;
                svg {
                  margin-top: 1px;
                  path {
                    fill: #006d3a;
                  }
                }
              }
              svg {
                margin-top: 1px;
                path {
                }
              }
            }
            &:disabled {
              color: #989899 !important;
              background: #ebebec !important;
              cursor: not-allowed;
              border-color: transparent !important;
              svg {
                path {
                  fill: #989899 !important;
                }
              }
            }
          }
        }
      }
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
