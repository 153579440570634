<template>
  <div :class="['input-select-search', !v.required && v.$anyError && 'error']">
    <Select2
      v-model="selectValue"
      :disabled="disabled"
      :options="options"
      :settings="{
        width: '100%',
        placeholder: `${placeholder ? placeholder : 'Selecione'}`,
        minimumResultsForSearch: `${search ? 0 : 'Infinity'}`,
        language: { noResults: () => 'Nenhum resultado encontrado' },
        dropdownCssClass: 'boxSelect'
      }"
      @close="closeSelectVerify"
      @change="alterValue"
    ></Select2>
    <div
      v-if="!v.required && v.$anyError"
      class="select-error"
    >
      <p>Campo Obrigatório</p>
    </div>
  </div>
</template>

<script>
  import Select2 from "v-select2-component";

  export default {
    data() {
      return {
        selectId: null,
        selectValue: null
      };
    },
    props: ["placeholder", "options", "search", "v", "value", "disabled"],
    components: {
      Select2
    },
    methods: {
      alterValue(e) {
        this.$emit("input", parseInt(e));
      },
      closeSelectVerify() {
        this.v.$touch();
      }
    },
    watch: {
      value() {
        this.selectValue = this.value != undefined ? this.value : null;
      }
    },
    computed: {},
    mounted() {
      let vm = this;
      vm.selectValue = vm.value != undefined ? this.value : null;
    }
  };
</script>

<style lang="scss">
  .input-select-search {
    .select2-container--default {
      .select2-selection--single {
        border-radius: 3px;
        border: 1px solid #b8b8b9;
        .select2-selection__arrow {
          right: 5px;
          width: 24px;

          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzUxODNfMTI3NDApIj4KPHBhdGggZD0iTTguNzA5NTYgMTEuNzFMMTEuMjk5NiAxNC4zQzExLjY4OTYgMTQuNjkgMTIuMzE5NiAxNC42OSAxMi43MDk2IDE0LjNMMTUuMjk5NiAxMS43MUMxNS45Mjk2IDExLjA4IDE1LjQ3OTYgMTAgMTQuNTg5NiAxMEg5LjQwOTU2QzguNTE5NTYgMTAgOC4wNzk1NiAxMS4wOCA4LjcwOTU2IDExLjcxWiIgZmlsbD0iIzNEM0QzRSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzUxODNfMTI3NDAiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==);
          background-position: center;
          background-repeat: no-repeat;
          b {
            display: none;
          }
        }
      }
      .select2-results__option--highlighted:hover {
        background: #d1e8d3 !important;
        border-radius: 3px !important;
      }
    }
    .select-error {
      margin: 1px 0 1px 5px;
      p {
        color: #dc3546;
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
      }
    }

    &.error {
      .select2-selection--single {
        border-color: #dc3546;
      }
    }
  }
  .itemtype {
    .select2-selection--single {
      border-radius: 3px 0 0 3px !important;
    }
  }
  .boxSelect {
    border: none;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px !important;
    margin-top: 2px;

    .select2-results__option--highlighted[aria-selected] {
      background: #d1e8d3 !important;
      border-radius: 3px !important;
      color: #005229;
    }
    .select2-results__option[aria-selected="true"] {
      background: #006d3a;
      border-radius: 3px;
      color: #ffffff;
    }
  }
  .select2-search__field:focus {
    outline: none;
  }
  .select2-search__field {
    padding-right: 20px !important;
    border: 1px solid #b8b8b9 !important;
    border-radius: 3px !important;
  }
  .select2-search--dropdown {
    position: relative;
  }
  .select2-search--dropdown::after {
    content: "";
    position: absolute;
    width: 15px !important;
    height: 15px !important;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTggMTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogIDxwYXRoIGQ9Ik0xMS42MjUgMTFIMTEuMDMyNUwxMC44MjI1IDEwLjc5NzVDMTEuNTU3NSA5Ljk0MjUgMTIgOC44MzI1IDEyIDcuNjI1QzEyIDQuOTMyNSA5LjgxNzUgMi43NSA3LjEyNSAyLjc1QzQuNDMyNSAyLjc1IDIuMjUgNC45MzI1IDIuMjUgNy42MjVDMi4yNSAxMC4zMTc1IDQuNDMyNSAxMi41IDcuMTI1IDEyLjVDOC4zMzI1IDEyLjUgOS40NDI1IDEyLjA1NzUgMTAuMjk3NSAxMS4zMjI1TDEwLjUgMTEuNTMyNVYxMi4xMjVMMTQuMjUgMTUuODY3NUwxNS4zNjc1IDE0Ljc1TDExLjYyNSAxMVpNNy4xMjUgMTFDNS4yNTc1IDExIDMuNzUgOS40OTI1IDMuNzUgNy42MjVDMy43NSA1Ljc1NzUgNS4yNTc1IDQuMjUgNy4xMjUgNC4yNUM4Ljk5MjUgNC4yNSAxMC41IDUuNzU3NSAxMC41IDcuNjI1QzEwLjUgOS40OTI1IDguOTkyNSAxMSA3LjEyNSAxMVoiIGZpbGw9IiNDNkM3QzMiLz4NCjwvc3ZnPg0K") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  .select2-results {
    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: #ffffff;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #a9aca7;
      border-radius: 5px;
      border: 3px solid #ffffff;
    }
  }
</style>
