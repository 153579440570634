import { api } from "@/utils/request";

export default {
  async postGroup(projectId, payload, config) {
    const data = await api.post(`projects/${projectId}/groups`, payload, config);
    return data.data;
  },
  async putGroup(projectId, groupId, payload, config) {
    const data = await api.put(`projects/${projectId}/groups/${groupId}`, payload, config);
    return data.data;
  },
  async deleteGroup(projectId, groupId) {
    const data = await api.delete(`projects/${projectId}/groups/${groupId}`);
    return data.data;
  }
};
