<template>
  <div style="height: 100%">
    <div
      id="column-tree-features"
      class="column-tree"
    >
      <div class="header">
        <h3>Funcionalidades</h3>
        <div class="tools">
          <button
            v-b-tooltip.hover="{
              title: 'Retornar para gestão de projetos',
              placement: 'topleft',
              boundary: 'window'
            }"
            @click="$router.push({ name: 'projects' })"
          >
            <IconReturn style="display: block; height: 19px" />
          </button>

          <button
            v-if="treeFeaturesFiltered.length > 0"
            v-b-tooltip.hover="{
              title: 'Atualizar Funcionalidades',
              placement: 'topleft',
              boundary: 'window'
            }"
            @click="refreshTreeFeatures"
          >
            <IconRefresh style="height: 18px; width: 18px" />
          </button>
        </div>
      </div>
      <form
        v-if="treeFeaturesFiltered.length > 0 || termFilterTreeFeatures != ''"
        class="form-filter"
        @submit.prevent="filterTreeFeatures"
      >
        <IconSearch class="icon-search" />
        <input
          v-model="termFilterTreeFeatures"
          type="text"
          :class="['form-control', termFilterTreeFeatures != '' && 'active']"
          placeholder="Pesquisar"
          style="border: 0"
        />
        <button
          v-if="termFilterTreeFeatures != ''"
          class="btn-clear"
          @click="clearFilterTreeFeatures"
        >
          <IconClose />
        </button>
      </form>
      <div class="content">
        <div
          v-if="treeFeaturesFiltered.length > 0 && forceRefreshTreeFeatures"
          class="tree"
        >
          <Draggable
            class="draggable draggable-groups"
            tag="ul"
            :disabled="termFilterTreeFeatures != ''"
            :list="treeFeaturesFiltered"
            :group="{ name: 'tree-features-groups' }"
            @end="orderTreeFeatures"
          >
            <li
              v-for="(group, groupIndex) in treeFeaturesFiltered"
              :key="`group-${groupIndex}`"
              :data-id="group.id"
            >
              <!-- Grupos -->
              <div
                :class="['draggable-item', draggableActiveModal(groupIndex) && 'active-modal']"
                @click.stop="editGroup(groupIndex)"
              >
                <button
                  v-if="group.subgroups && group.subgroups.length > 0"
                  type="button"
                  class="btn-toggle"
                  @click.stop="toggleList(groupIndex)"
                >
                  <IconToggleDown
                    v-if="group.open || !('open' in group)"
                    style="margin-left: 1.5px"
                  />
                  <IconToggle v-else />
                </button>
                <span
                  ><IconGroupsSubgroups
                    class="icon-item"
                    style="width: 15px; text-align: center"
                /></span>
                <p class="name-tree">
                  <TitleTreeFeature
                    :title="group.name"
                    :title-format="
                      formatNameTree(
                        `${group.devOpsId ? `${group.devOpsId} - ` : ''} ${clearHtmlName(
                          group.name
                        )}`
                      )
                    "
                  />
                </p>
                <div class="options-item-draggable">
                  <button
                    type="button"
                    class="btn-new"
                    @click.stop="createNewSubGroup(groupIndex)"
                  >
                    <IconPlus
                      v-b-tooltip.hover="{
                        title: 'Adicionar Subgrupo',
                        placement: 'topleft',
                        boundary: 'window'
                      }"
                      style="display: block"
                    />
                  </button>
                  <button
                    v-b-tooltip.hover="{
                      title: group.description,
                      html: true,
                      placement: 'rightbottom',
                      boundary: 'window'
                    }"
                    type="button"
                    class="btn-info"
                  >
                    <IconInfo style="display: block" />
                  </button>
                </div>
              </div>

              <Draggable
                class="draggable draggable-subgroups"
                tag="ul"
                :disabled="termFilterTreeFeatures != ''"
                :list="group.subgroups"
                :data-idGroup="group.id"
                :group="{ name: 'tree-features-subgroups' }"
                @end="orderTreeFeatures"
              >
                <!-- Subgrupos -->
                <li
                  v-for="(subgroup, subgroupIndex) in group.subgroups"
                  v-show="group.open == null || group.open == true"
                  :key="`subgroup-${subgroupIndex}`"
                  :data-id="subgroup.id"
                  class="line-align"
                >
                  <div
                    :class="[
                      'draggable-item',
                      draggableActiveModal(groupIndex, subgroupIndex) && 'active-modal'
                    ]"
                    @click.stop="editSubGroup(subgroupIndex, groupIndex)"
                  >
                    <button
                      v-if="subgroup.features && subgroup.features.length > 0"
                      type="button"
                      class="btn-toggle"
                      @click.stop="toggleList(groupIndex, subgroupIndex)"
                    >
                      <IconToggleDown
                        v-if="subgroup.open || !('open' in subgroup)"
                        style="margin-left: 1.5px"
                      />
                      <IconToggle v-else />
                    </button>
                    <span
                      ><IconGroupsSubgroups
                        class="icon-item"
                        style="width: 15px; text-align: center"
                    /></span>
                    <p class="name-tree">
                      <TitleTreeFeature
                        :title="subgroup.name"
                        :title-format="
                          formatNameTree(
                            `${subgroup.devOpsId ? `${subgroup.devOpsId} - ` : ''} ${clearHtmlName(
                              subgroup.name
                            )}`
                          )
                        "
                      />
                    </p>
                    <div class="options-item-draggable">
                      <button
                        v-b-tooltip.hover="{
                          title: 'Adicionar Funcionalidade',
                          placement: 'topleft',
                          boundary: 'window'
                        }"
                        type="button"
                        class="btn-new"
                        @click.stop="createNewFeature(subgroupIndex, groupIndex)"
                      >
                        <IconPlus />
                      </button>
                      <button
                        v-b-tooltip.hover="{
                          title: subgroup.description,
                          html: true,
                          placement: 'rightbottom',
                          boundary: 'window'
                        }"
                        type="button"
                        class="btn-info"
                      >
                        <IconInfo />
                      </button>
                    </div>
                  </div>

                  <Draggable
                    class="draggable draggable-features"
                    tag="ul"
                    :disabled="termFilterTreeFeatures != ''"
                    :list="subgroup.features"
                    :data-idGroup="group.id"
                    :data-idSubgroup="subgroup.id"
                    :group="{ name: 'tree-features' }"
                    @end="orderTreeFeatures"
                  >
                    <!-- Funcionalidades -->
                    <li
                      v-for="(feature, featureIndex) in subgroup.features"
                      v-show="subgroup.open == null || subgroup.open == true"
                      :key="`feature-${featureIndex}`"
                      :data-id="feature.id"
                      class="line-align"
                    >
                      <div
                        class="draggable-item"
                        @click.stop="viewFeature(featureIndex, subgroupIndex, groupIndex, 0)"
                      >
                        <span
                          ><IconFeatures
                            class="icon-item"
                            style="width: 15px; text-align: center"
                        /></span>
                        <p
                          class="name-tree"
                          :class="
                            feature.copyNotEdit == 1 || feature.copyNotEdit == 2
                              ? 'titleNotEdit'
                              : ''
                          "
                        >
                          <TitleTreeFeature
                            :title="feature.name"
                            :title-format="
                              formatNameTree(
                                `${
                                  feature.devOpsId ? `${feature.devOpsId} - ` : ''
                                } ${clearHtmlName(feature.name)}`
                              )
                            "
                          />
                        </p>
                        <div class="options-item-draggable">
                          <ReportVersion
                            :type="feature.active"
                            :feature-id="feature.id"
                            :copy-not-edit="feature.copyNotEdit"
                            :subgroup-id="subgroup.id"
                            :group-id="group.id"
                            :hash="feature.hash"
                            :devops-id="feature.devOpsId"
                            :feature-name="feature.name"
                            :project-id="projectId"
                            :access-features="
                              () =>
                                viewFeature(
                                  featureIndex,
                                  subgroupIndex,
                                  groupIndex,
                                  feature.copyNotEdit
                                )
                            "
                            @refresh="refreshTreeFeatures"
                          />

                          <button
                            v-if="!feature.copyNotEdit"
                            :id="feature.id"
                            v-b-tooltip.hover="{
                              title: 'Duplicar Funcionalidade',
                              html: true,
                              placement: 'topleft',
                              boundary: 'window'
                            }"
                            type="button"
                            class="btn-duplicate-funcionality"
                            @click.stop="
                              openModalDuplicate(
                                projectId,
                                group.id,
                                subgroup.id,
                                feature.id,
                                feature.name
                              )
                            "
                          >
                            <IconDuplicate />
                          </button>

                          <button
                            v-b-tooltip.hover="{
                              title: descriptionFeature(
                                feature.description,
                                feature.updateDate,
                                feature.versionDate,
                                feature.totalItems,
                                feature.userNameUpdate,
                                feature.userNameVersion
                              ),
                              html: true,
                              placement: 'rightbottom',
                              boundary: 'viewport',
                              customClass: 'tooltip-description-feature'
                            }"
                            type="button"
                            class="btn-info"
                          >
                            <IconInfo />
                          </button>
                        </div>
                      </div>
                    </li>
                  </Draggable>
                </li>
              </Draggable>
            </li>
          </Draggable>

          <div>
            <button
              type="button"
              class="btn-new-group"
              @click="createNewGroup()"
            >
              <IconPlus />Novo Grupo
            </button>
          </div>
        </div>
        <div
          v-else-if="termFilterTreeFeatures == '' && treeFeatures != null"
          class="info-new-group"
        >
          <VectorTeamWork class="vector-team-work" />
          <p>
            Crie um grupo, um subgrupo e depois a funcionalidade para organizarmos nosso espaço de
            trabalho.
          </p>
          <button
            type="button"
            class="btn-new-group"
            @click="createNewGroup()"
          >
            <IconPlus />Novo Grupo
          </button>
        </div>
      </div>

      <!-- Modal de Criação e Edição de Grupos e Subgrupos -->
      <Modal
        :props="modal.props"
        :open="modal.open"
        @updateName="updateNameTree"
        @closeModal="closeModal"
        @refreshTreeFeatures="refreshTreeFeatures"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import Draggable from "vuedraggable";
  import Modal from "./Modal.vue";
  import moment from "moment";

  import VectorTeamWork from "@/assets/images/vector-team-work-groups.svg";
  import IconClose from "@/assets/images/icon-close.svg";
  import IconRefresh from "@/assets/images/icon-refresh.svg";
  import IconReturn from "@/assets/images/icon-arrow-return.svg";
  import IconSearch from "@/assets/images/icon-search.svg";
  import IconPlus from "@/assets/images/icon-plus-arrounded.svg";
  import IconGroupsSubgroups from "@/assets/images/icon-groups-subgroups.svg";
  import IconFeatures from "@/assets/images/icon-features.svg";
  import IconInfo from "@/assets/images/icon-info-circle.svg";
  import IconToggle from "@/assets/images/icon-arrow-right.svg";
  import IconToggleDown from "@/assets/images/icon-arrow-down.svg";
  import IconDuplicate from "@/assets/images/icon-duplicate-hover.svg";

  import ReportVersion from "../../../components/ReportVersion.vue";

  import TitleTreeFeature from "./TitleTreeFeature.vue";

  export default {
    name: "ColumnTreeFeatures",
    props: ["projectId", "treeFeatures"],
    components: {
      Draggable,
      Modal,
      VectorTeamWork,
      IconClose,
      IconRefresh,
      IconReturn,
      IconSearch,
      IconPlus,
      IconGroupsSubgroups,
      IconFeatures,
      IconInfo,
      IconToggle,
      IconToggleDown,
      IconDuplicate,
      ReportVersion,
      TitleTreeFeature
    },
    data() {
      return {
        disableTooltipTitleGroup: true,
        disableTooltipTitleSubgroup: true,
        termFilterTreeFeatures: "",
        forceRefreshTreeFeatures: true,
        modal: {
          props: {
            action: "",
            type: "",
            projectId: null,
            groupId: null,
            subgroupId: null,
            item: {}
          },
          open: false
        }
      };
    },
    mounted() {},
    methods: {
      ...mapActions("Projects", ["ActionPutOrderTree", "ActionGetProjectTree"]),
      ...mapActions("Reports", ["ActionPutFeatureNewVersionReport"]),

      openModalDuplicate(projectId, groupId, subgroupId, featureId, featureName) {
        this.$emit("copyItem", {
          itemType: "functionality",
          projectId,
          groupId,
          subgroupId,
          featureId,
          featureName
        });
      },
      clearHtmlName(name) {
        return name.replace(/</gi, "&lt").replace(/>/, "&gt");
      },
      refreshTreeFeatures() {
        // Monta o link para a funcionalidade clicada e direciona para a tela da funcionalidade

        this.forceRefreshTreeFeatures = false;
        this.$emit("refreshTreeFeatures");
        this.resetModal();
        this.forceRefreshTreeFeatures = true;
      },
      clearFilterTreeFeatures() {
        this.termFilterTreeFeatures = "";
      },
      orderTreeFeatures(event) {
        // Aciona a rota de ordenação de itens (grupos, subgrupos e funcionalidades) baseado nos movimentos da árvore em tela

        let order = event.newIndex + 1;
        let itemMoveId = parseInt(event.clone.dataset.id);
        let groupId = parseInt(event.to.dataset.idgroup);
        let subgroupId = parseInt(event.to.dataset.idsubgroup);

        let payload = {
          group: {
            id: groupId || itemMoveId,
            order: order
          }
        };
        if (groupId) {
          delete payload.group.order;
          payload.group["subgroup"] = {
            id: subgroupId || itemMoveId,
            order: order
          };
        }
        if (groupId && subgroupId) {
          delete payload.group.subgroup.order;
          payload.group.subgroup["feature"] = {
            id: itemMoveId,
            order: order
          };
        }

        this.ActionPutOrderTree({ projectId: this.projectId, payload })
          .then(() => {})
          .catch((response) => {
            let newList = this.treeFeatures;
            let listItem = newList.splice(event.newIndex, 1);
            newList.splice(event.oldIndex, 0, listItem[0]);
            this.$swal({
              customClass: "swal-danger",
              title: "Erro",
              text: response,
              confirmButtonText: "Fechar",
              showCancelButton: false
            });
          });
      },
      toggleList(groupIndex = null, subgroupIndex = null) {
        this.forceRefreshTreeFeatures = false;
        if (groupIndex != null && subgroupIndex != null) {
          let statusOpen =
            this.treeFeaturesFiltered[groupIndex]["subgroups"][subgroupIndex].open || false;
          if (this.treeFeaturesFiltered[groupIndex]["subgroups"][subgroupIndex].open == null) {
            this.treeFeaturesFiltered[groupIndex]["subgroups"][subgroupIndex]["open"] = false;
          } else {
            this.treeFeaturesFiltered[groupIndex]["subgroups"][subgroupIndex]["open"] = !statusOpen;
          }
        } else if (groupIndex != null) {
          let statusOpen = this.treeFeaturesFiltered[groupIndex].open || false;
          if (this.treeFeaturesFiltered[groupIndex].open == null) {
            this.treeFeaturesFiltered[groupIndex]["open"] = false;
          } else {
            this.treeFeaturesFiltered[groupIndex]["open"] = !statusOpen;
          }
        }
        this.forceRefreshTreeFeatures = true;
      },
      createNewGroup() {
        // Monta o link para a funcionalidade clicada e direciona para a tela da funcionalidade

        this.forceRefreshTreeFeatures = false;

        this.treeFeaturesFiltered.push({
          name: "Novo Grupo",
          createdNow: true
        });

        this.forceRefreshTreeFeatures = true;
        this.openModal("new", "group", this.treeFeaturesFiltered.length - 1);
      },
      editGroup(groupIndex) {
        // Monta o link para a funcionalidade clicada e direciona para a tela da funcionalidade

        this.openModal("edit", "group", groupIndex);
      },
      createNewSubGroup(groupIndex) {
        // Monta o link para a funcionalidade clicada e direciona para a tela da funcionalidade

        this.forceRefreshTreeFeatures = false;

        if (!this.treeFeaturesFiltered[groupIndex]["subgroups"]) {
          this.treeFeaturesFiltered[groupIndex]["subgroups"] = [];
        }
        this.treeFeaturesFiltered[groupIndex]["subgroups"].push({
          name: "Novo Subgrupo",
          createdNow: true
        });

        this.forceRefreshTreeFeatures = true;

        this.openModal(
          "new",
          "subgroup",
          groupIndex,
          this.treeFeaturesFiltered[groupIndex]["subgroups"].length - 1
        );
      },
      editSubGroup(subgroupIndex, groupIndex) {
        // Chama a abertura da modal de edição/criação e envia as informações necessárias

        this.openModal("edit", "subgroup", groupIndex, subgroupIndex);
      },
      createNewFeature(subgroupIndex, groupIndex) {
        // Monta o link para a criação de uma nova funcionalidade dentro do grupo e subgrupo selecionado

        let groupId = this.treeFeaturesFiltered[groupIndex].id;
        let subgroupId = this.treeFeaturesFiltered[groupIndex].subgroups[subgroupIndex].id;

        this.$router.push({
          name: "featureNew",
          params: { projectId: this.projectId, groupId, subgroupId }
        });
      },
      descriptionFeature(
        description,
        updateDate,
        versionDate,
        totalItems,
        userNameUpdate,
        userNameVersion
      ) {
        let descriptionComplete = `<span class='title-tooltip text-tooltip'>Descrição</span><br><span class='information-text text-tooltip'>${description}</span><br><br>`;
        descriptionComplete += `<span class='title-tooltip text-tooltip'>Última atualização</span><br><span class='information-text text-tooltip'>${moment(
          updateDate
        ).format("DD/MM/YYYY - HH:mm")} - ${userNameUpdate}</span><br><br>`;
        if (versionDate) {
          descriptionComplete += `<span class='title-tooltip text-tooltip'>Última versão</span><br><span class='information-text text-tooltip'>${moment(
            versionDate
          ).format("DD/MM/YYYY - HH:mm")} - ${userNameVersion}</span><br><br>`;
        }
        descriptionComplete += `<span class='title-tooltip text-tooltip'>Quantidade de componentes</span><br><span>${totalItems}</span>`;
        return descriptionComplete;
      },
      viewFeature(featureIndex, subgroupIndex, groupIndex, copyNotEdit) {
        // Monta o link para a funcionalidade clicada e direciona para a tela da funcionalidade

        let groupId = this.treeFeaturesFiltered[groupIndex].id;
        let subgroupId = this.treeFeaturesFiltered[groupIndex].subgroups[subgroupIndex].id;
        let featureId =
          this.treeFeaturesFiltered[groupIndex].subgroups[subgroupIndex].features[featureIndex].id;
        this.$router.push({
          name: "featureView",
          params: { projectId: this.projectId, groupId, subgroupId, featureId, copyNotEdit }
        });
      },
      openModal(action, type, groupIndex = null, subgroupIndex = null) {
        // Abre a modal e envia informações pertinente ao item (grupo ou subgrupo) em questão

        this.modal.props.action = action;
        this.modal.props.type = type;
        this.modal.props.projectId = this.projectId;
        this.modal.props.groupId = this.treeFeaturesFiltered[groupIndex].id;
        this.modal.props.groupIndex = groupIndex;
        if (subgroupIndex != null && groupIndex != null) {
          this.modal.props.subgroupIndex = subgroupIndex;
          this.modal.props.subgroupId =
            this.treeFeaturesFiltered[groupIndex].subgroups[subgroupIndex].id;
          this.modal.props.item = this.treeFeaturesFiltered[groupIndex].subgroups[subgroupIndex];
        } else if (groupIndex != null) {
          this.modal.props.item = this.treeFeaturesFiltered[groupIndex];
        }
        this.modal.open = true;
      },
      resetModal() {
        // Fechar modal e reseta todas as propriedades dela
        this.modal.open = false;
        setTimeout(() => {
          this.modal.props.action = "";
          this.modal.props.type = "";
          this.modal.props.projectId = null;
          this.modal.props.groupId = null;
          this.modal.props.groupIndex = null;
          this.modal.props.subgroupId = null;
          this.modal.props.subgroupIndex = null;
          this.modal.props.item = {};
        }, 500);
      },
      closeModal(action) {
        // Remove novo item (grupo ou subgrupo) ao fechar modal sem salvar

        if (action == "new" && this.modal.open == true && this.modal.props.groupIndex != null) {
          let groupIndex = this.modal.props.groupIndex;
          if (this.modal.props.subgroupIndex != null) {
            let subgroupIndex = this.modal.props.subgroupIndex;
            this.treeFeaturesFiltered[groupIndex].subgroups.splice(subgroupIndex, 1);
          } else {
            this.treeFeaturesFiltered.splice(groupIndex, 1);
          }
        }

        this.resetModal();
      },
      updateNameTree(name) {
        // Manipula em tempo real o nome do item (grupo ou subgrupo) em edição/criação dentro da modal
        let nameFormat = name || "&nbsp;";

        if (this.modal.open == true && this.modal.props.groupIndex != null) {
          let groupIndex = this.modal.props.groupIndex;
          if (this.modal.props.subgroupIndex != null) {
            let subgroupIndex = this.modal.props.subgroupIndex;
            this.treeFeaturesFiltered[groupIndex].subgroups[subgroupIndex].name = nameFormat;
          } else {
            this.treeFeaturesFiltered[groupIndex].name = nameFormat;
          }
        }
      },
      formatNameTree(name) {
        // Formata os nomes dos itens (grupo ou subgrupo) destacando o termo pesquisado
        if (this.termFilterTreeFeatures != "") {
          return name.replace(
            new RegExp("(?<!\w)(" + this.termFilterTreeFeatures + ")(?!\w)", "gi"),
            '<span class="mark-term-search">$1</span>'
          );
        } else {
          return name;
        }
      },
      addGroupSearch(array, group, addSubgroup = false) {
        // Adiciona o objeto inteiro do grupo (e seus subgrupos) para dentro do array filtrado

        array.push({
          id: group.id,
          name: group.name,
          description: group.description,
          subgroups: addSubgroup ? group.subgroups : [],
          open: addSubgroup ? false : true,
          order: group.order
        });
        return array.length - 1;
      },
      addSubgroupSearch(array, groupIndex, subgroup, addFeatures = false) {
        // Adiciona o objeto inteiro do subgrupo (e suas funcionalidades) para dentro do array filtrado

        array[groupIndex].subgroups.push({
          id: subgroup.id,
          name: subgroup.name,
          description: subgroup.description,
          features: addFeatures ? subgroup.features : [],
          open: addFeatures ? false : true
        });
        return array[groupIndex].subgroups.length - 1;
      },
      draggableActiveModal(groupIndex, subgroupIndex = null) {
        // Marca os itens (draggable) como active quando sua respectiva modal estiver aberta

        if (this.modal.open == true) {
          if (
            this.modal.props.groupIndex == groupIndex &&
            subgroupIndex == null &&
            this.modal.props.subgroupIndex == null
          ) {
            return true;
          } else if (
            subgroupIndex != null &&
            this.modal.props.groupIndex == groupIndex &&
            this.modal.props.subgroupIndex === subgroupIndex
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
      filterBySearch(obj, text) {
        if (obj.name.toUpperCase().match(text) || obj.devOpsId?.toString().match(text)) {
          return true;
        }
      }
    },
    computed: {
      treeFeaturesFiltered() {
        // Gera um novo array com apenas os itens (grupos, subgrupos e funcionalidades) filtrados a partir do termo digitado
        if (this.termFilterTreeFeatures != "") {
          let tempArrayGroup = [];
          let tempArraySubgroup = [];
          let tempArrayFeature = [];
          let filterText = this.termFilterTreeFeatures.toUpperCase();

          this.treeFeatures?.map((group) => {
            if (this.filterBySearch(group, filterText)) {
              tempArrayGroup.push(group);
            } else {
              tempArraySubgroup = [];
              group.subgroups?.map((subgroup) => {
                if (this.filterBySearch(subgroup, filterText)) {
                  tempArraySubgroup.push(subgroup);
                } else {
                  tempArrayFeature = [];
                  subgroup.features.map((feature) => {
                    if (this.filterBySearch(feature, filterText)) {
                      tempArrayFeature.push(feature);
                    }
                  });
                  if (tempArrayFeature.length > 0) {
                    tempArraySubgroup.push({
                      ...subgroup,
                      features: tempArrayFeature
                    });
                  }
                }
              });
              if (tempArraySubgroup.length > 0) {
                tempArrayGroup.push({ ...group, subgroups: tempArraySubgroup });
              }
            }
          });
          return tempArrayGroup;
        } else {
          return this.treeFeatures != null ? this.treeFeatures : [];
        }
      }
    }
  };
</script>

<style lang="scss">
  .title-tooltip {
    color: #b8b8b9;
    margin-bottom: 15px;
  }
  .text-tooltip {
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  .information-text {
    color: #ffffff;
  }
  .line-align {
    border-left: 1px solid #006d3a;
    padding-left: 5px;
  }

  .text-format-tree-features-group {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .tooltip-width-group {
    width: 350px !important;
  }

  .tooltip-width-group .tooltip-inner {
    max-width: 350px !important;
  }

  .text-format-tree-features-subgroup {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .tooltip-width-subgroup {
    width: 350px !important;
  }

  .tooltip-width-subgroup .tooltip-inner {
    max-width: 350px !important;
  }

  .tooltip-position-subgroup {
    top: -4px;
  }

  .text-format-tree-features-feature {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .tooltip-width-feature {
    width: 350px !important;
  }

  .tooltip-width-feature .tooltip-inner {
    max-width: 350px !important;
  }

  .tooltip-position-feature {
    top: -6px;
  }

  .options-item-draggable {
    display: flex;
  }
</style>
