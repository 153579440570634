<template>
  <div class="modal-container">
    <div
      v-if="step == 1"
      :class="itemDuplicate.itemType == 'functionality' ? 'modal' : 'modal component-height'"
    >
      <div class="title-modal">
        <p>Duplicar {{ title }}</p>
      </div>
      <div class="duplicate-name">
        <p style="font-weight: 700">Duplicando:</p>
        <p v-html="duplicateName"></p>
      </div>
      <div class="destiny">
        <div class="title-detiny">
          <p>Projeto de Destino</p>
        </div>
        <InputSelect2Appoint
          v-model="duplicateDestiny.projectDestinyValue"
          :search="true"
          :options="projectsOptions"
          :v="$v.duplicateDestiny.projectDestinyValue"
          @input="loadOtherInfos"
        />
      </div>
      <div class="destiny">
        <div class="title-detiny">
          <p>Grupo de Destino</p>
        </div>
        <InputSelect2Appoint
          v-model="duplicateDestiny.groupDestinyValue"
          :search="true"
          :options="groupOptions"
          :v="$v.duplicateDestiny.groupDestinyValue"
          :disabled="duplicateDestiny.projectDestinyValue == null"
          @input="loadSubgroupOptions"
        />
      </div>
      <div class="destiny">
        <div class="title-detiny">
          <p>SubGrupo de Destino</p>
        </div>
        <InputSelect2Appoint
          v-model="duplicateDestiny.subgroupDestinyValue"
          :search="true"
          :options="subgroupOptions"
          :v="$v.duplicateDestiny.subgroupDestinyValue"
          :disabled="duplicateDestiny.groupDestinyValue == null"
          @input="loadFeatureOptions"
        />
      </div>
      <div
        v-show="itemDuplicate.itemType == 'component'"
        class="destiny"
      >
        <div class="title-detiny">
          <p>Funcionalidade de Destino</p>
        </div>
        <InputSelect2Appoint
          v-model="duplicateDestiny.featureDestinyValue"
          :search="true"
          :options="featureOptions"
          :v="$v.duplicateDestiny.featureDestinyValue"
          :disabled="duplicateDestiny.subgroupDestinyValue == null"
        />
      </div>
      <div class="buttons">
        <div>
          <b-button
            :class="'button-close'"
            @click="closeModal"
            ><IconClose class="icon-close" /> Fechar</b-button
          >
        </div>
        <div>
          <b-button
            v-if="itemDuplicate.itemType == 'functionality'"
            :class="disableDuplicateButton ? 'button disable' : 'button'"
            :disabled="disableDuplicateButton"
            @click="duplicateFunctionality"
            ><IconDuplicate class="icon" /> Duplicar</b-button
          >

          <b-button
            v-else
            :class="disableDuplicateButton ? 'button disable' : 'button'"
            :disabled="disableDuplicateButton"
            @click="advanceDuplicate()"
            ><IconArrowRight class="icon" /> Avançar</b-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="step == 2"
      class="modal component-destiny"
    >
      <div class="title-modal">
        <p>Duplicar {{ title }}</p>
      </div>
      <div class="duplicate-name destiny-page">
        <div style="display: flex; gap: 4px">
          <p style="font-weight: 700">Duplicando:</p>
          <p v-html="duplicateName"></p>
        </div>
        <p>
          <span style="font-weight: 700">Para: </span
          >{{
            `${projectName.text} / ${groupName.text} / ${subgroupName.text} / ${featureName.text}`
          }}
        </p>
      </div>
      <div class="warning-client">
        <p>
          Se não houver nenhum componente selecionado, o componente duplicado será adicionado ao
          final da árvore.
        </p>
      </div>

      <div class="tree-component">
        <SelectDraggable
          v-model="duplicateDestiny.componentDestinyValue"
          :list="treeComponentDestiny"
          :destiny="duplicateDestiny.componentDestinyValue"
        />
      </div>

      <div class="buttons">
        <b-button
          :class="'button-close'"
          @click="closeModal"
          ><IconClose class="icon-close" /> Fechar</b-button
        >

        <b-button
          :class="'button'"
          @click="step = 1"
          ><IconArrowRight
            class="icon"
            style="transform: rotate(180deg)"
          />
          Voltar</b-button
        >

        <b-button
          :class="'button'"
          @click="duplicateFunctionality"
          ><IconDuplicate class="icon" /> Duplicar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState, mapMutations } from "vuex";
  import InputSelect2Appoint from "./InputSelect2Appoint.vue";
  import IconClose from "@/assets/images/icon-close.svg";
  import IconDuplicate from "@/assets/images/icon-duplicate.svg";
  import IconArrowRight from "@/assets/images/icon-arrow-outlined-right.svg";
  import SelectDraggable from "../views/ComponentsView/components/SelectDraggable.vue";

  export default {
    data() {
      return {
        title: null,
        duplicateName: null,
        duplicateDestiny: {
          projectDestinyValue: null,
          groupDestinyValue: null,
          subgroupDestinyValue: null,
          featureDestinyValue: null,
          componentDestinyValue: null
        },
        projectsOptions: [],
        groupOptions: [],
        subgroupOptions: [],
        featureOptions: [],
        step: 1,
        treeComponentDestiny: [],
        projectName: null,
        groupName: null,
        subgroupName: null,
        featureName: null,
        allInfos: null,
        idDuplicate: null
      };
    },
    components: {
      InputSelect2Appoint,
      IconClose,
      IconDuplicate,
      IconArrowRight,
      SelectDraggable
    },
    props: ["itemDuplicate"],
    validations() {
      return {
        duplicateDestiny: {
          projectDestinyValue: {},
          groupDestinyValue: {},
          subgroupDestinyValue: {},
          featureDestinyValue: {}
        }
      };
    },
    methods: {
      ...mapActions("Features", ["ActionGetFeature", "ActionGetTreeFeature"]),
      ...mapActions("Duplicate", [
        "ActionPostDuplicateFunctionality",
        "ActionPostDuplicateComponent"
      ]),
      ...mapActions("Projects", ["ActionGetProjects", "ActionGetProjectTree"]),
      ...mapMutations("CompFeat", ["setOpenComponentDuplicate"]),
      closeModal() {
        this.$emit("closeModal");
      },
      advanceDuplicate() {
        this.projectName = this.projectsOptions.find(
          (elem) => elem.id == this.duplicateDestiny.projectDestinyValue
        );
        this.groupName = this.groupOptions.find(
          (elem) => elem.id == this.duplicateDestiny.groupDestinyValue
        );
        this.subgroupName = this.subgroupOptions.find(
          (elem) => elem.id == this.duplicateDestiny.subgroupDestinyValue
        );
        this.featureName = this.featureOptions.find(
          (elem) => elem.id == this.duplicateDestiny.featureDestinyValue
        );
        this.step = 2;
        let projectId = this.duplicateDestiny.projectDestinyValue;
        let groupId = this.duplicateDestiny.groupDestinyValue;
        let subgroupId = this.duplicateDestiny.subgroupDestinyValue;
        let featureId = this.duplicateDestiny.featureDestinyValue;

        this.ActionGetTreeFeature({ projectId, groupId, subgroupId, featureId }).then(
          (response) => {
            this.treeComponentDestiny = response.data.components;
          }
        );
      },
      loadInfos() {
        this.ActionGetProjects().then((response) => {
          response.data.projects.map((project) => {
            this.projectsOptions.push({
              id: project.id,
              text: `${project.company} - ${project.title}`
            });
          });
          this.projectsOptions?.sort((a, b) => {
            if (a.text.toUpperCase() < b.text.toUpperCase()) {
              return -1;
            }
            if (a.text.toUpperCase() > b.text.toUpperCase()) {
              return 1;
            }
            return 0;
          });
        });

        if (this.itemDuplicate.itemType == "functionality") {
          this.duplicateName = this.itemDuplicate.featureName.replace(/\\(.)/g, "$1");
          this.title = "Funcionalidade";
        } else {
          this.duplicateName = this.itemDuplicate.name.name.replace(/\\(.)/g, "$1");
          this.title = "Componente";
        }
      },
      loadOtherInfos() {
        (this.groupOptions = []),
          (this.subgroupOptions = []),
          (this.featureOptions = []),
          (this.duplicateDestiny.groupDestinyValue = null);
        this.duplicateDestiny.subgroupDestinyValue = null;
        this.duplicateDestiny.featureDestinyValue = null;
        this.ActionGetProjectTree(this.duplicateDestiny.projectDestinyValue).then((response) => {
          this.allInfos = response.data.groups;
          response.data.groups.map((group) => {
            this.groupOptions.push({
              id: group.id,
              text: group.name
            });
          });

          this.groupOptions.sort((a, b) => {
            if (a.text.toUpperCase() < b.text.toUpperCase()) {
              return -1;
            }
            if (a.text.toUpperCase() > b.text.toUpperCase()) {
              return 1;
            }
            return 0;
          });
        });
      },
      loadSubgroupOptions() {
        this.subgroupOptions = [];
        this.featureOptions = [];
        this.duplicateDestiny.subgroupDestinyValue = null;
        this.duplicateDestiny.featureDestinyValue = null;
        let groupSelect = this.allInfos.filter(
          (group) => group.id == this.duplicateDestiny.groupDestinyValue
        );
        groupSelect.map((subgroup) => {
          subgroup.subgroups.map((elem) => {
            this.subgroupOptions.push({
              id: elem.id,
              text: elem.name
            });
          });
        });
      },
      loadFeatureOptions() {
        this.featureOptions = [];
        let groupSelect = this.allInfos.filter(
          (project) => project.id == this.duplicateDestiny.groupDestinyValue
        );
        let subgroupSelect = null;
        groupSelect.forEach((group) => {
          subgroupSelect = group.subgroups.filter(
            (subgroup) => subgroup.id == this.duplicateDestiny.subgroupDestinyValue
          );
        });
        subgroupSelect.forEach((elem) => {
          elem.features.forEach((feature) => {
            this.featureOptions.push({
              id: feature.id,
              text: feature.name
            });
          });
        });
      },
      duplicateFunctionality() {
        let projectId = this.itemDuplicate.projectId;
        let groupId = this.itemDuplicate.groupId;
        let subgroupId = this.itemDuplicate.subgroupId;
        let featureId = this.itemDuplicate.featureId;

        let duplicateDestiny = {
          projectId: this.duplicateDestiny.projectDestinyValue,
          groupId: this.duplicateDestiny.groupDestinyValue,
          subgroupId: this.duplicateDestiny.subgroupDestinyValue
        };

        const isComponent = this.itemDuplicate.itemType == "component";
        if (isComponent) {
          let elementId = this.itemDuplicate.id;
          let componentId = this.itemDuplicate.name.ComponentId;
          duplicateDestiny.featureId = this.duplicateDestiny.featureDestinyValue;
          duplicateDestiny.elementId = this.duplicateDestiny.componentDestinyValue;

          this.ActionPostDuplicateComponent({
            projectId,
            groupId,
            subgroupId,
            featureId,
            componentId,
            elementId,
            payload: duplicateDestiny
          })
            .then((response) => {
              this.idDuplicate = response.data.id;
              this.$swal({
                customClass: "swal-success",
                title: "Sucesso",
                text: `Componente duplicado.`,
                confirmButtonText: "Acessar",
                cancelButtonText: "Fechar"
              }).then((result) => {
                if (result.isConfirmed) {
                  this.setOpenComponentDuplicate(this.idDuplicate);
                  this.$router.push({
                    path: `/project/${this.duplicateDestiny.projectDestinyValue}/group/${this.duplicateDestiny.groupDestinyValue}/subgroup/${this.duplicateDestiny.subgroupDestinyValue}/feature/${this.duplicateDestiny.featureDestinyValue}`
                  });
                  this.$emit("closeModal");
                  this.$emit("loadTreeComponent");
                }
              });
            })
            .catch(() => {
              this.$swal({
                customClass: "swal-danger",
                title: "Erro ao Duplicar",
                text: "Não é possível mover itens da árvore de componentes que não respeitem as relações diponíveis entre componentes.",
                confirmButtonText: "Fechar",
                showCancelButton: false
              }).then((result) => {
                if (result.isConfirmed) {
                  this.duplicateDestiny.componentDestinyValue = null;
                }
              });
            });
        } else {
          this.ActionPostDuplicateFunctionality({
            projectId,
            groupId,
            subgroupId,
            featureId,
            payload: duplicateDestiny
          })
            .then((response) => {
              this.idDuplicate = response.data.id;
              this.$swal({
                customClass: "swal-success",
                title: "Sucesso",
                text: `Funcionalidade duplicada.`,
                confirmButtonText: "Acessar",
                cancelButtonText: "Fechar"
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$emit("closeModal");
                  this.$router.push({
                    path: `/project/${this.duplicateDestiny.projectDestinyValue}/group/${this.duplicateDestiny.groupDestinyValue}/subgroup/${this.duplicateDestiny.subgroupDestinyValue}/feature/${this.idDuplicate}`
                  });
                }
              });
            })
            .catch(() => {
              this.$swal({
                customClass: "swal-danger",
                title: "Erro ao Duplicar",
                text: "Não é possível mover itens da árvore de componentes que não respeitem as relações diponíveis entre componentes.",
                confirmButtonText: "Fechar",
                showCancelButton: false
              }).then((result) => {
                if (result.isConfirmed) {
                  this.duplicateDestiny.componentDestinyValue = null;
                }
              });
            });
        }
      }
    },
    computed: {
      ...mapState("CompFeat", {
        openComponentDuplicate: "openComponentDuplicate"
      }),
      disableDuplicateButton: function () {
        if (
          !this.duplicateDestiny.projectDestinyValue ||
          !this.duplicateDestiny.groupDestinyValue ||
          !this.duplicateDestiny.subgroupDestinyValue
        ) {
          return true;
        }
        if (this.itemDuplicate.itemType == "component") {
          if (!this.duplicateDestiny.featureDestinyValue) {
            return true;
          }
        }
        return false;
      }
    },
    watch: {},
    mounted() {
      this.loadInfos();
    }
  };
</script>

<style lang="scss" scoped>
  .modal-container {
    position: absolute;
    width: 100%;
    height: -webkit-fill-available !important;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.6);

    display: flex;
    justify-content: center;
    align-items: center;

    .component-height {
      height: 407px !important;
    }
    .modal {
      display: flex;
      flex-direction: column;
      gap: 15px;
      position: relative;
      width: 605px;
      height: 341px;
      background: #fbfdf7;
      padding: 16px;

      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
        0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 5px;

      .title-modal {
        font-family: "Work Sans", sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #3d3d3e;
      }

      .duplicate-name {
        color: #000;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;

        display: flex;
        gap: 4px;

        overflow: hidden;
        text-overflow: ellipsis;

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .destiny-page {
        display: flex;
        flex-direction: column;
      }

      .destiny {
        .title-detiny {
          font-family: "Work Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #3d3d3e;

          margin-bottom: 4px;
        }
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        .button-close {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;

          border-radius: 15px;
          border: 1px solid #006d3a;
          padding: 7px 15px;

          font-family: "Work Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #006d3a;
          background: #fbfdf7;

          &:focus {
            box-shadow: none;
          }

          &:hover {
            background: #d1e8d3;
          }
          .icon-close {
            width: 15px;
            height: 15px;
          }
        }

        .button {
          background: #006d3a;
          border-radius: 15px;
          border: none;

          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;
          padding: 8px 16px;

          font-family: "Work Sans", sans-serif;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;

          .icon {
            path {
              fill: #fff;
            }
          }

          &:hover {
            background: #288750;
          }

          &:focus {
            box-shadow: none;
          }
        }

        .disable {
          background: #ebebec !important;
          color: #989899 !important;

          .icon {
            path {
              fill: #989899 !important;
            }
          }
        }
      }
    }

    .component-destiny {
      width: 610px;
      height: 523px;

      .warning-client {
        padding: 10px;
        background: #3d5366;
        border-radius: 7px;

        font-family: "Work Sans", sans-serif;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #fff;
      }

      .tree-component {
        height: 290px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #ffffff;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #a9aca7;
          border-radius: 5px;
          border: 3px solid #ffffff;
        }
      }
    }
  }
</style>
