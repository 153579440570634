import Login from "./Login";
import Projects from "./Projects";
import Groups from "./Groups";
import Subgroups from "./Subgroups";
import Features from "./Features";
import Components from "./Components";
import Reports from "./Reports";
import Appointments from "./Appointments";
import Duplicate from "./Duplicate";
import CompFeat from "./CompFeat";
import Loader from "./Loader";

export default {
  Login,
  Projects,
  Groups,
  Subgroups,
  Features,
  Components,
  Reports,
  Appointments,
  Duplicate,
  CompFeat,
  Loader
};
