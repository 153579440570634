import SubgroupsServices from "@/services/Subgroups";

export const ActionPostSubgroup = ({}, data) => {
  return SubgroupsServices.postSubgroup(data.projectId, data.groupId, data.payload, data.config);
};

export const ActionPutSubgroup = ({}, data) => {
  return SubgroupsServices.putSubgroup(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.payload,
    data.config
  );
};

export const ActionDeleteSubgroup = ({}, data) => {
  return SubgroupsServices.deleteSubgroup(data.projectId, data.groupId, data.subgroupId);
};
