import LoginServices from "@/services/Login";
import * as storage from "@/utils/localStorage";
import * as types from "./mutation-types";

export const ActionLogin = ({ dispatch }, payload) => {
  return LoginServices.postLogin(payload)
    .then((response) => {
      dispatch("ActionSetUser", { name: response.data.data.userName });
      dispatch("ActionSetToken", response.data.data.token);
      dispatch("ActionSetAllowProjects", response.data.data.allowProjects);
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const ActionCheckToken = ({ dispatch, state }) => {
  if (state.token) {
    return Promise.resolve(state.token);
  }

  const token = storage.getStorage("token");
  if (!token) {
    return Promise.reject(new Error("Token Inválido"));
  }

  return dispatch("ActionSetToken", token);
};

export const ActionSetUser = ({ commit }, payload) => {
  commit(types.SET_USER, payload);
};
export const ActionSetToken = ({ commit }, payload) => {
  storage.setStorage("token", payload);
  commit(types.SET_TOKEN, payload);
};

export const ActionSetAllowProjects = ({}, payload) => {
  storage.setStorage("allowProjects", payload);
};

export const ActionLogout = ({ dispatch }) => {
  storage.deleteStorage("token");
  storage.deleteStorage("allowProjects");
  dispatch("ActionSetUser", {});
  dispatch("ActionSetToken", "");
};
