<template>
  <button
    v-if="type < 2 && featureId != null"
    v-b-tooltip.hover="{
      title: messageTooltipVersion(copyNotEdit, type),
      html: true,
      placement: 'topright',
      boundary: 'window',
      customClass: 'tooltip-lg'
    }"
    type="button"
    class="btn-new-version"
    :class="
      copyNotEdit == 1 || copyNotEdit == 2
        ? 'copied-not-edited'
        : type === 1
        ? 'active-feature'
        : 'inactive-feature'
    "
    @click.stop="discardChangesReport($event)"
  >
    <IconVersion />
  </button>
</template>

<script>
  import { mapActions } from "vuex";
  import IconVersion from "@/assets/images/icon-paper-archive.svg";

  export default {
    components: {
      IconVersion
    },
    props: {
      type: {
        type: Number,
        default: null
      },
      copyNotEdit: {
        type: Number,
        default: null
      },
      featureId: {
        type: Number,
        default: null
      },
      accessFeatures: {
        type: Function,
        deafult: null
      },
      subgroupId: {
        type: Number,
        default: null
      },
      groupId: {
        type: Number,
        default: null
      },
      hash: {
        type: String,
        default: null
      },
      projectId: {
        type: Number,
        default: null
      },
      blockOutside: {
        type: Boolean,
        default: false
      },
      devopsId: {
        type: Number,
        default: null
      },
      featureName: {
        type: String,
        default: null
      }
    },
    data() {
      return {};
    },

    methods: {
      ...mapActions("Projects", ["ActionPutOrderTree", "ActionGetProjectTree"]),
      ...mapActions("Reports", [
        "ActionPutFeatureNewVersionReport",
        "ActionGetFeatureReportToDownload"
      ]),

      discardChangesReport(e) {
        if (this.copyNotEdit == 1) {
          this.accessFeatures(e);
        } else if (this.copyNotEdit == 2) {
          this.accessFeatures(e);
        } else {
          if (this.blockOutside) {
            this.openDiscardChanges(e);
          } else {
            this.selectAction(
              this.type,
              this.featureId,
              this.subgroupId,
              this.groupId,
              this.hash,
              this.projectId
            );
          }
        }
      },

      openDiscardChanges(e) {
        this.$emit("openDiscardChanges", e);
      },
      reloadFeature() {
        this.$emit("reloadFeature");
      },
      messageTooltipVersion(copyNotEdit, featureActive) {
        if (copyNotEdit == 1 || copyNotEdit == 2) {
          return "Editar registro duplicado antes de gerar uma nova versão.";
        } else {
          if (featureActive == 0) {
            return "Existem atualizações. Clique para gerar uma nova versão.";
          } else if (featureActive == 1) {
            return "Download do Relatório";
          } else {
            return "";
          }
        }
      },
      selectAction(type, featureId, subgroupId, groupId, hash, projectId) {
        let link = `${this.linkUrl}report/projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}?hash=${hash}`;
        if (type == 1) {
          this.ActionGetProjectTree(projectId).then(() => {
            this.openSuccessModal(type, featureId, subgroupId, groupId, link, hash, projectId);
          });
        } else {
          this.generateVersionReport(type, featureId, subgroupId, groupId, link, hash, projectId);
        }
      },
      openSuccessModal(type, featureId, subgroupId, groupId, link, hash, projectId) {
        this.$swal({
          html: `<p class="text">${
            type == 1
              ? "Versão gerada com sucesso. Escolha o modelo do relatório."
              : " Escolha o modelo do relatório a ser exportado."
          }</p>
            <div class="options">
            <label class="optionText">
                <input type="radio" name="userType" checked class="input-radio" value="1">
                <div class="fake-radio-border">
                <div class="fake-radio-circle"></div>
                </div>
                <p>Desenvolvedor</p>
            </label>
            <label class="optionText">
                <input type="radio" name="userType" class="input-radio" value="2">
                <div class="fake-radio-border">
                <div class="fake-radio-circle"></div>
                </div>
                <p>Cliente</p>
            </label>
            </div>
            <div class="input-link">
            <input id="link-report" type="text" value="${link}">
            <div class="icon-input-link">
                <div onclick="document.querySelector('#link-report').select(); document.execCommand('copy');">
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 1.125H2.5C1.8125 1.125 1.25 1.6875 1.25 2.375V11.125H2.5V2.375H10V1.125ZM11.875 3.625H5C4.3125 3.625 3.75 4.1875 3.75 4.875V13.625C3.75 14.3125 4.3125 14.875 5 14.875H11.875C12.5625 14.875 13.125 14.3125 13.125 13.625V4.875C13.125 4.1875 12.5625 3.625 11.875 3.625ZM11.875 13.625H5V4.875H11.875V13.625Z" fill="#006D3A"/>
                </svg>
                </div>
                <div onclick="window.open(document.querySelector('#link-report').value, '_blank')">
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.7938 4.70625L8.14375 11.0375L7.31875 8.9L7.11875 8.38125L6.60625 8.18125L4.4625 7.35L10.7938 4.70625ZM13.125 2.375L1.875 7.08125V7.69375L6.15 9.35L7.8 13.625H8.4125L13.125 2.375Z" fill="#006D3A"/>
                </svg>
                </div>
            </div>
            </div>`,
          title: "Sucesso",
          showConfirmButton: true,
          customClass: `${type == 1 ? "swal-version" : "swal-success"}`,
          confirmButtonText: "Download do Relatório"
        }).then((result) => {
          if (result.isConfirmed) {
            this.refreshComponent();
            let to = "";
            let opts = document.querySelectorAll(".input-radio");
            opts.forEach((opt) => {
              if (opt.checked) {
                to = parseInt(opt.value);
              }
            });

            this.ActionGetFeatureReportToDownload({
              projectId,
              groupId,
              subgroupId,
              featureId,
              to,
              hash
            }).then((response) => {
              const blob = new Blob([response]);
              const url = URL.createObjectURL(blob);
              const name = `${this.devopsId ? `${this.devopsId}-` : ""}${this.featureName}-${
                to == 1 ? "developer" : "client"
              }.html`;
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", name);
              document.body.appendChild(link);
              link.click();
            });
          }
        });
        this.watchOptionModal();
      },
      generateVersionReport(type, featureId, subgroupId, groupId, link, hash, projectId) {
        this.$swal({
          customClass: "swal-warning",
          title: "Atenção",
          text: "Deseja realmente gerar uma nova versão?",
          footer:
            hash != null
              ? `<div class="link-group"><a href=${link} class="link" target="_blank">Acessar relatório da versão atual</a></div>`
              : ""
        }).then((result) => {
          if (result.isConfirmed) {
            this.ActionPutFeatureNewVersionReport({ projectId, groupId, subgroupId, featureId })
              .then((result) => {
                let link = `${this.linkUrl}report/projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}?hash=${result.data.hash}`;
                if (result.errors.length == 0) {
                  this.openSuccessModal(
                    type,
                    featureId,
                    subgroupId,
                    groupId,
                    link,
                    result.data.hash,
                    projectId
                  );
                  this.refreshComponent();
                }
              })
              .catch(() => {
                this.$swal({
                  customClass: "swal-danger",
                  title: "Erro",
                  text: "Ocorreu algum erro ao gerar a versão. Tente novamente mais tarde.",
                  confirmButtonText: "Fechar",
                  showCancelButton: false
                });
              });
          }
        });
      },
      watchOptionModal() {
        let opts = document.querySelectorAll(".input-radio");
        opts.forEach((opt) => {
          let input = document.querySelector(".input-link");
          opt.addEventListener("click", function () {
            input.classList.toggle("hidden-input-link");
          });
        });
      },
      refreshComponent() {
        this.$emit("refresh");
      }
    },
    computed: {
      linkUrl() {
        if (window.location.origin.includes("cars.codenapp.com")) {
          return `https://cars.codenapp.com/`;
        } else if (window.location.origin.includes("homolog")) {
          return `https://app-sistema-requisitos-web-homolog.azurewebsites.net/`;
        } else {
          return `https://app-sistema-requisitos-web-dev.azurewebsites.net/`;
        }
      }
    }
  };
</script>

<style lang="scss"></style>
