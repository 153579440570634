import { api } from "@/utils/request";

export default {
  async postDuplicateFunctionality(projectId, groupId, subgroupId, featureId, payload) {
    const data = await api.post(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/duplicate`,
      payload
    );
    return data.data;
  },
  async postDuplicateComponent(
    projectId,
    groupId,
    subgroupId,
    featureId,
    componentId,
    elementId,
    payload
  ) {
    const data = await api.post(
      `projects/${projectId}/groups/${groupId}/subgroups/${subgroupId}/features/${featureId}/component/${componentId}/element/${elementId}/duplicate`,
      payload
    );
    return data.data;
  }
};
