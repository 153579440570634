<template>
  <PopOver>
    <template v-slot:trigger>
      <IconFunctionality
        class="popover__title"
        style="cursor: pointer"
      />
    </template>
    <template v-slot:title>
      <div class="title">
        <IconFunctionality class="IconFuncionalityBlack" />
        <p>Últimas Funcionalidades Alteradas</p>
      </div>
    </template>
    <template v-slot:content>
      <div
        v-for="(func, index) in projectFunc"
        :key="'func' + index"
        class="func-div-link"
      >
        <div :class="'indicartor-status cor' + func.status"></div>
        <router-link
          :to="`${func.link}`"
          :class="'link-func-card'"
          >{{ func.title }}</router-link
        >
      </div>
    </template>
  </PopOver>
</template>

<script>
  import PopOver from "@/components/PopOver.vue";
  import IconFunctionality from "@/assets/images/icon-functionality.svg";

  export default {
    data() {
      return {
        active: false
      };
    },
    components: {
      PopOver,
      IconFunctionality
    },
    props: {
      projectFunc: []
    }
  };
</script>

<style lang="scss">
  /* Base styling*/

  /* Popover styling */

  .title {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-left: 14px;
    margin-bottom: 10px;
    margin-top: 14px;
    width: 319px;
  }

  .link-func-card {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    color: #1a1c1a;

    letter-spacing: 0.5px;
  }

  .cor0 {
    width: 2px;
    height: 15px;
    border-radius: 40px;
    background-color: #ffb300;
  }
  .cor1 {
    width: 2px;
    height: 15px;
    border-radius: 40px;
    background-color: #42a5f5;
  }
  .cor2 {
    width: 2px;
    height: 15px;
    border-radius: 40px;
    background-color: #10b981;
  }

  .link-func-card:hover {
    text-decoration: none;
    color: #1a1c1a;
  }

  .func-div-link {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    gap: 4px;
    padding: 0 14px;
  }

  .func-div-link:last-child {
    margin-bottom: 16px;
  }

  .func-div-link:hover {
    background-color: #d1e8d3;
  }

  .IconFuncionalityBlack path {
    fill: #5c5f5b;
  }
</style>
