import Vue from "vue";

import App from "./App";
import store from "./store";
import * as valida from "./utils/valida";
import router from "./router";
import Vuelidate from "vuelidate";

// eslint-disable-next-line no-undef
window.$ = require("jquery");
// eslint-disable-next-line no-undef
window.JQuery = require("jquery");

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "@/assets/css/main.css";
import "@/assets/css/treeStyle.css";

import Select2 from "v-select2-component";
Vue.component("Select2", Select2);

import VueQuillEditor from "vue-quill-editor";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueQuillEditor);

import VueMask from "v-mask";
Vue.use(VueMask);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2, {
  confirmButtonText: "Confirmar",
  showCancelButton: true,
  cancelButtonText: "Cancelar",
  reverseButtons: true,
  allowOutsideClick: false,
  allowEscapeKey: false,
  heightAuto: false
});

new Vue({
  router,
  store,
  valida,
  render: (h) => h(App)
}).$mount("#app");
