<template>
  <div class="modal-container">
    <div :class="'modal'">
      <b-button
        :class="'button-close'"
        @click="closeModal"
      >
        <IconClose class="icon-close" />
      </b-button>

      <div class="holder-logo">
        <IconLogo />
      </div>
      <div class="title-modal">
        <p>Login</p>
      </div>
      <div class="subtitle-modal">
        <p>Seu acesso a página expirou, realize o login para não perder suas alterações.</p>
      </div>
      <div class="form-login">
        <form @submit.prevent="doLogin()">
          <div class="holder_form">
            <label class="label-form">
              <p>E-mail</p>
              <input
                id="user-login"
                v-model="email"
                :class="errorEmail || errorLogin ? 'error' : ''"
                type="email"
                name="user"
                placeholder="Digite seu e-mail"
                @blur="showErrorEmail"
              />
            </label>
            <p
              v-if="errorEmail"
              class="msg-error"
            >
              Campo Obrigatório
            </p>
            <label class="label-form margin-top">
              <p>Senha</p>
              <input
                id="password-login"
                v-model="password"
                :class="errorPassword || errorLogin ? 'error' : ''"
                type="password"
                name="password"
                placeholder="Digite sua senha"
                @blur="showErrorPassword"
              />
            </label>
            <p
              v-if="errorPassword"
              class="msg-error"
            >
              Campo Obrigatório
            </p>
            <p
              v-if="errorLogin"
              class="msg-error-login"
            >
              *E-mail ou senha incorretos
            </p>
          </div>
          <button
            type="submit"
            :class="['btn', loader || errorEmail || errorPassword ? 'event-none' : '']"
            :disabled="disabled || email == '' || password == ''"
          >
            <span v-if="!loader">Entrar</span>
            <IconLoading
              v-else
              class="loader"
            />
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from "vuex";
  import IconLogo from "@/assets/images/logo-modal-login.svg";
  import IconClose from "@/assets/images/icon-close.svg";
  import IconLoading from "@/assets/images/icon-loading.svg";
  import { required, email } from "vuelidate/lib/validators";

  export default {
    validations() {
      return {
        email: { required, email },
        password: { required }
      };
    },
    components: {
      IconLoading,
      IconLogo,
      IconClose
    },
    data() {
      return {
        emailStart: "",
        email: "",
        password: "",
        disabled: true,
        errorLogin: false,
        errorEmail: false,
        errorPassword: false,
        loader: false
      };
    },
    watch: {
      email() {
        if (this.email !== "" && this.password !== "") {
          this.disabled = false;
        }
        if (this.email !== "") {
          this.errorEmail = false;
        }
        if (this.email == this.emailStart) {
          this.errorEmail = true;
        }
      },
      password() {
        if (this.email !== "" && this.password !== "") {
          this.disabled = false;
        }
        if (this.password !== "") {
          this.errorPassword = false;
        }
      }
    },
    methods: {
      ...mapMutations(["SET_MODAL_LOGIN"]),
      ...mapActions("Login", ["ActionLogin"]),
      closeModal() {
        this.SET_MODAL_LOGIN(false);
        this.$router.push({ path: "/login" });
      },
      showErrorEmail() {
        if (!this.disabled && this.email == "") {
          this.errorEmail = true;
        }
      },
      showErrorPassword() {
        if (!this.disabled && this.password == "") {
          this.errorPassword = true;
        }
      },
      doLogin() {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.loader = true;
          this.errorLogin = false;
          this.ActionLogin({
            user: this.email,
            password: this.password,
            origin: "modal_login"
          }).then((result) => {
            if (result.status === 200) {
              this.SET_MODAL_LOGIN(false);
              this.loader = false;
            } else {
              this.errorLogin = true;
              this.loader = false;
            }
          });
        } else {
          this.errorLogin = true;
          this.loader = false;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .modal-container {
    position: absolute;
    width: 100%;
    height: -webkit-fill-available !important;
    z-index: 1051;
    background: rgba(0, 0, 0, 0.6);
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    .component-height {
      height: 407px !important;
    }
    .modal {
      display: flex;
      flex-direction: column;
      position: relative;
      max-width: 500px;
      width: 95%;
      height: auto;
      background: #fbfdf7;
      padding: 25px 20px;

      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
        0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      .holder-logo {
        width: 114.49px;
        height: 90px;
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          max-width: 100%;
        }
      }
      .title-modal {
        margin-top: 25px;
        p {
          font-family: "Work Sans", sans-serif;
          font-weight: 600;
          font-size: 40px;
          line-height: 54px;
          color: #3d3d3e;
          text-align: center;
        }
      }
      .subtitle-modal {
        margin-top: 10px;
        padding: 0px 5px;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #1a1c1a;
        margin-bottom: 25px;
      }
      .form-login {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .holder_form {
          padding: 0px 5px;
        }
        form {
          width: 100%;
        }
        .label-login {
          text-align: left;
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #1a1c1a;
        }

        .margin-top {
          margin-top: 20px;
        }

        .label-form {
          display: flex;
          flex-direction: column;

          p {
            margin-bottom: 4px;
            font-family: "Work Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #1a1c1a;
          }

          input {
            width: 100%;
            height: 28px;
            border: 1px solid #b8b8b9;
            border-radius: 3px;
            background-color: #fff;
            padding: 4.5px 10px;
          }
          input::placeholder {
            color: #b8b8b9;
          }
          input:focus {
            outline: none;
          }

          .error {
            border-color: #dc3546;
          }
        }

        .btn {
          background: #0e695d;
          color: #ffffff;
          border-radius: 15px;
          padding: 8px 0;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          height: 35px;
          width: 100%;
          box-shadow: none;
          margin-top: 25px;
          display: flex;
          justify-content: center;
          align-items: center;

          .loader {
            animation: is-rotating 1s linear infinite;

            path {
              fill: #ffffff !important;
            }
          }
          @keyframes is-rotating {
            to {
              transform: rotate(1turn);
            }
          }
        }
        .event-none {
          pointer-events: none;
        }
        .btn[disabled] {
          color: #b8b8b9;
          background: #dbdbdc;
        }
        .msg-error {
          margin: 1px 0 0 5px;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: #dc3546;
        }

        .msg-error-login {
          color: #dc3546;
          margin-bottom: -10px;
          margin-top: 15px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
        }
      }
    }
  }
  .button-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 15px;
    border: 1px solid #006d3a;
    padding: 3px 3px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    color: #006d3a;
    background: #fbfdf7;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      background: #d1e8d3;
    }
    .icon-close {
      width: 15px;
      height: 15px;
    }
  }
</style>
