import DuplicateServices from "@/services/Duplicate";

export const ActionPostDuplicateFunctionality = ({}, data) => {
  return DuplicateServices.postDuplicateFunctionality(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId,
    data.payload
  );
};

export const ActionPostDuplicateComponent = ({}, data) => {
  return DuplicateServices.postDuplicateComponent(
    data.projectId,
    data.groupId,
    data.subgroupId,
    data.featureId,
    data.componentId,
    data.elementId,
    data.payload
  );
};
