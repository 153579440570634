<template>
  <div class="popover__wrapper">
    <span @click="handleClick">
      <slot name="trigger"></slot>
    </span>
    <div
      v-show="active"
      :class="'popover__content'"
    >
      <div class="popover__message">
        <slot name="title"></slot>
      </div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        active: false
      };
    },
    components: {},
    methods: {
      closePopoverClickOutside(e) {
        /* Verifica o click dentro do Popover content e fecha o Popover quando clicado fora*/
        let clickOutsidePopover = e.target.closest(".popover__content") == null;
        this.active = !clickOutsidePopover;

        if (clickOutsidePopover) {
          /* Remove o evento do click ao fechar o Popover*/
          document
            .querySelector("body")
            .removeEventListener("click", this.closePopoverClickOutside);
        }
      },
      handleClick() {
        this.active = !this.active;

        if (this.active) {
          setTimeout(() => {
            document.querySelector("body").addEventListener("click", this.closePopoverClickOutside);
          }, 10);
        }
      }
    }
  };
</script>

<style lang="scss">
  a {
    text-decoration: none;
  }

  .popover__title {
    font-size: 24px;
    line-height: 36px;
    text-decoration: none;
    color: #5c5f5b;
    text-align: center;
  }

  .popover__wrapper {
    font-family: "Work Sans", sans-serif;
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: center;

    .popover__content {
      z-index: 10;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);

      position: absolute;
      right: -7px;
      bottom: -7px;
      transform: translateY(100%);
      background-color: #fff;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
      border-radius: 6px;
      min-height: 26px;
      max-width: 343px;

      :before {
        position: absolute;
        z-index: -1;
        content: "";
        right: 11px;
        top: -7px;
        border-style: solid;
        border-width: 2px 6px 6px 6px;
        border-color: transparent transparent #3d5366 transparent;
        transition-duration: 0.3s;
        transition-property: transform;
      }
    }
    .popover__message {
      p {
        font-weight: 600;
        color: #5c5f5b;
      }
    }
  }
</style>
