<template>
  <div :class="['textAreaAppoint', !v.required && v.$anyError && 'error']">
    <textarea
      cols="50"
      rows="5"
      :class="counter < 300 ? 'descriptionAppoint' : 'descriptionAppointMax'"
      :placeholder="placeholder"
      maxlength="300"
      :value="value"
      @input="alterValueDesc"
      @blur="closeTextareaVerify"
    ></textarea>
    <div class="textareaCounterMandatory">
      <div class="visibleErrors">
        <div
          v-if="!v.required && v.$anyError"
          class="select-error"
        >
          <p>Campo Obrigatório</p>
        </div>
      </div>
      <div :class="counter < 300 ? 'counterTextarea' : 'counterTextarea stop'">
        {{ counter }}<span class="count-total">/300</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["placeholder", "v", "value"],
    data() {
      return {
        counter: 0
      };
    },
    methods: {
      alterValueDesc(e) {
        this.$emit("input", e.target.value);
      },
      closeTextareaVerify() {
        this.v.$touch();
      }
    },
    watch: {
      value() {
        this.counter = this.value?.length;
      }
    }
  };
</script>

<style lang="scss">
  .textAreaAppoint {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .counterTextarea {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;

      .count-total {
        color: #8f918d;
      }
    }
    .stop {
      color: #cf2e3e;

      .count-total {
        color: #cf2e3e;
      }
    }
    .textareaCounterMandatory {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .select-error {
        margin: 1px 0 1px 5px;
        p {
          color: #dc3546;
          font-family: "Open Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
        }
      }
      .visibleErrors {
        height: 16px;
      }
    }

    &.error {
      textarea {
        border-color: #dc3546;
      }
    }
  }
  .descriptionAppoint {
    width: 100%;
    resize: none;
    padding: 10px 10px 0 10px;
    border: 1px solid #b8b8b9;
    border-radius: 3px;

    &:focus {
      outline: none;
      border: 1px solid #009d60;
    }
  }

  .descriptionAppointMax {
    width: 100%;
    resize: none;
    padding: 10px 10px 0 10px;
    border: 1px solid #b8b8b9;
    border-radius: 3px;

    &:focus {
      outline: none;
      border: 1px solid #dc3546;
    }
  }
</style>
