import Vue from "vue";
import axios from "axios";

import { getStorage, deleteStorage } from "./localStorage";

import store from "@/store";
import { SET_API_CALL_IN_PROGRESS, SET_MODAL_LOGIN, SET_MODAL_APPOINTMENT } from "@/store/root";
import router from "@/router";

let baseURL;

const prodUrl =
  window.location.origin.includes("prod") ||
  (window.location.origin.includes("cars") &&
    !window.location.origin.includes("dev") &&
    !window.location.origin.includes("stag"));

const homologUrl = window.location.origin.includes("homolog");

if (prodUrl) {
  baseURL = "https://app-sistema-requisitos-api-prod.azurewebsites.net/api/";
} else if (homologUrl) {
  baseURL = "https://app-sistema-requisitos-api-homolog.azurewebsites.net/api/";
} else {
  baseURL = "https://app-sistema-requisitos-api-dev.azurewebsites.net/api/";
}

const api = axios.create({
  baseURL
});

api.interceptors.request.use(
  (request) => {
    if (!request.headers.ignoreLoading) {
      store.commit("Loader/pending");
    }
    store.commit(SET_API_CALL_IN_PROGRESS, true);

    request.headers["Accept"] = `application/json`;
    request.headers["Content-Type"] = `application/json`;
    if (getStorage("token")) {
      request.headers["Authorization"] = `Bearer ${getStorage("token")}`;
    }
    return request;
  },
  (error) => {
    store.commit("Loader/done");
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    store.commit("Loader/done");
    store.commit(SET_API_CALL_IN_PROGRESS, false);
    return response;
  },
  (error) => {
    if (!error.response) return Promise.reject(error);

    const allowLoginModal = error.response.config.headers?.allowLoginModal;

    if (
      error.response.status == 401 &&
      error.response.statusText == "Unauthorized" &&
      allowLoginModal
    ) {
      store.commit(SET_MODAL_LOGIN, true);
      store.commit("Loader/done");
      return Promise.reject(error);
    }

    if (
      error.response.status == 401 &&
      error.response.statusText == "Unauthorized" &&
      !allowLoginModal
    ) {
      deleteStorage("token");
      store.commit(SET_MODAL_APPOINTMENT, false);
      router.push({ name: "login" });
      store.commit("Loader/done");
      return Promise.reject(error);
    }

    let errors = error.response.data.errors?.map((error) => error.message);
    if (!error.config.data?.includes("modal_login")) {
      Vue.swal({
        customClass: "swal-danger",
        title: "Erro",
        html: errors.join("<br>"),
        confirmButtonText: "Fechar",
        showCancelButton: false
      });
    }
    store.commit("Loader/done");
    return Promise.reject(error);
  }
);

export { api };
