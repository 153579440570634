<template>
  <div class="select">
    <div
      v-if="showMandatoryMessage"
      class="select-error"
      @click="$refs['input-select-' + id].focus()"
    >
      <IconDangerous class="icon-error" />
      <p>Campo Obrigatório</p>
    </div>
    <div
      :class="
        focusSelectField
          ? 'select-container border-focus'
          : valueSelected
          ? 'select-container'
          : 'empty-field'
      "
    >
      <select
        :id="id"
        :ref="'input-select-' + id"
        :class="[value == '' && 'placeholder', showMandatoryMessage && 'show-mandatory']"
        :disabled="disabled"
        :value="valueSelected"
        @input="updateSelectValue($event.target.value)"
        @change="$emit('change')"
        @blur="blurField"
        @focus="focusField"
      >
        <slot />
      </select>
      <IconCancel
        v-show="!mandatory && valueSelected"
        class="icon-clear-field"
        @click.stop="clearField"
      />
    </div>
  </div>
</template>

<script>
  import IconDangerous from "@/assets/images/icon-dangerous-filled.svg";
  import IconCancel from "@/assets/images/icon-cancel.svg";

  export default {
    components: {
      IconDangerous,
      IconCancel
    },
    props: {
      value: {
        default: ""
      },
      mandatory: {
        type: Boolean,
        default: false
      },
      touchedForm: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      id: {
        default: ""
      }
    },
    data() {
      return {
        valueSelected: "",
        mandatoryShow: false,
        touchedField: false,
        focusSelectField: false
      };
    },
    computed: {
      showMandatoryMessage: function () {
        return (
          this.mandatory &&
          this.mandatoryShow &&
          (this.touchedField || this.touchedForm) &&
          !this.valueSelected
        );
      }
    },
    watch: {
      touchedForm() {
        if (this.mandatory && this.valueSelected == "") {
          this.mandatoryShow = this.mandatory;
        }
      }
    },
    methods: {
      clearField() {
        this.valueSelected = "";
        this.$emit("cleanField");
      },
      updateSelectValue(value) {
        this.valueSelected = value;
        this.focusSelectField = false;
        this.$emit("input", parseInt(value));
      },
      blurField() {
        this.mandatoryShow = true;
        this.focusSelectField = false;
      },
      focusField() {
        this.focusSelectField = true;
        this.touched();
        this.mandatoryShow = false;
      },
      touched() {
        this.touchedField = true;
        this.validateField();
      },
      validateField() {
        if (this.valueSelected == "" && this.touchedField && this.mandatory) {
          this.mandatoryShow = true;
        }
      }
    },
    mounted() {
      let vm = this;
      if (vm.value) {
        this.valueSelected = vm.value;
      }
      this.mandatoryShow = this.mandatory;
    }
  };
</script>

<style lang="scss">
  .select {
    width: 100%;
    position: relative;

    .select-container {
      display: flex;
      align-items: center;
      border-radius: 3px;
      border: 1px solid transparent;

      .icon-clear-field {
        cursor: pointer;
        width: 16px !important;
        height: 16px !important;
        margin-right: 5px;
      }
    }
    .border-focus {
      border: 1px solid #5c5f5b;
    }

    .empty-field {
      select {
        color: #c6c7c3;
      }
    }
    select {
      -webkit-appearance: none;
      option {
        color: #1a1c1a;
        opacity: 1;
      }
    }
    select.placeholder {
      color: #c6c7c3;
      background: transparent;
      opacity: 1;
    }
    select.show-mandatory {
      opacity: 0;
    }
    select {
      background: transparent;
      outline: none;
      resize: none;
      padding: 1px 14px;
      font-size: 14px;
      line-height: 19px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border: 1px solid transparent;

      &:hover {
        cursor: initial;
      }
    }
    .select-error {
      display: flex;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      position: absolute;

      .icon-error {
        margin-right: 5px;
      }
      p {
        margin: 0;
        font-size: 14px;
        line-height: 1.35em;
        font-weight: 600;
        color: #ef3e4d;
      }
    }
  }
</style>
