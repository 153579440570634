<template>
  <div class="config-comp">
    <div class="component-settings">
      <div class="general">
        <div class="top">
          <IconGear class="top-icon" />
          <p class="title">Configurações do Componente</p>
        </div>
        <form
          v-if="forceRenderComponent"
          @submit.prevent="saveComponent"
        >
          <div class="feature-list row">
            <div class="item-component">
              <div class="col-sm-4">
                <IconArrowDown class="icon" />
                <span class="require-comp"></span>
                <label>Tipo do Componente</label>
              </div>
              <div class="col-sm-8">
                <InputSelect
                  v-model="componentSelectTypeId"
                  :mandatory="true"
                  :disabled="action == 'edit'"
                  :class="[!componentSelectTypeId && 'valueNull']"
                >
                  <option
                    value=""
                    hidden
                  >
                    Vazio
                  </option>
                  <option
                    v-if="staticComponent !== null && action == 'edit'"
                    :value="staticComponent.id"
                  >
                    {{ staticComponent.name }}
                  </option>
                  <option
                    v-for="(type, index) in action == 'new' ? listComponentTypes : listAllTypes"
                    :key="'componentSelectTypeId' + index"
                    :value="type.id"
                  >
                    {{ type.name }}
                  </option>
                </InputSelect>
              </div>
            </div>

            <div
              v-if="componentSelectTypeId == null"
              class="new-component"
            >
              <div class="info-new-component">
                <ImageNewComponent class="image-new-component" />
                <h6>Selecione o tipo de componente para configurar as suas propriedades.</h6>
              </div>
            </div>

            <div
              v-if="
                ((componentSelectTypeId != null && action == 'new') || action == 'edit') &&
                listProperties
              "
            >
              <div
                v-for="(item, index) in listProperties"
                :key="'item-' + index"
                class="item-component"
              >
                <div
                  v-if="!item.multiple"
                  class="col-sm-4"
                >
                  <div
                    :id="'label-propertie-' + index"
                    class="container-label-propertie"
                    @mouseover="visibleTooltipLabelPropertie(index, 'over')"
                    @mouseleave="visibleTooltipLabelPropertie(index, 'leave')"
                  >
                    <div
                      v-b-tooltip.click="{
                        title: item.description,
                        html: true,
                        placement: 'topright',
                        customClass: 'tooltip-lg',
                        id: 'tooltip-label-propertie-' + index
                      }"
                      class="div-icon"
                    >
                      <IconArrowDown
                        v-if="propertySelect(item.id)"
                        class="icon"
                      />
                      <IconTextA
                        v-else
                        class="icon"
                      />
                    </div>
                    <span
                      :class="
                        checkMandatory(item, listPropertiesStart[index])
                          ? 'require-comp'
                          : 'span-width-comp'
                      "
                    ></span>
                    <label>{{ item.name }}</label>
                  </div>
                </div>
                <div
                  v-if="!item.multiple"
                  class="col-sm-8"
                >
                  <InputSelect
                    v-if="propertySelect(item.id)"
                    :id="'select-' + item.id"
                    v-model="item.propertyValueId"
                    :touched-form="touchedForm"
                    :mandatory="checkMandatory(item, listPropertiesStart[index])"
                    :class="[!item.propertyValueId && 'valueNull']"
                    @cleanField="item.propertyValueId = null"
                  >
                    <option
                      value=""
                      hidden
                      selected
                      class="empty-select"
                    >
                      Vazio
                    </option>
                    <option
                      v-for="(value, optionIndex) in listPropertiesValue.filter(
                        (property) => property.propertyId == item.id
                      )"
                      :key="'componentValue' + optionIndex"
                      :value="value.id"
                    >
                      {{ value.value }}
                    </option>
                  </InputSelect>
                  <QuillEditor
                    v-else
                    :id="`item-component-${index}`"
                    v-model="item.value[0]"
                    :allow-style="item.allowStyle"
                    :close-quill="closeQuill"
                    :touched-form="touchedForm"
                    :accept-enter="item.name !== 'Nome'"
                    :mandatory="checkMandatory(item, listPropertiesStart[index])"
                    @QuillFocus="quillFocus"
                  />
                </div>
                <div
                  v-else
                  class="extra-config col-sm-12"
                >
                  <div class="extra-content">
                    <div
                      class="opening container-label-propertie"
                      @mouseover="visibleTooltipLabelPropertie(index, 'over')"
                      @mouseleave="visibleTooltipLabelPropertie(index, 'leave')"
                    >
                      <div
                        v-b-tooltip.click="{
                          title: item.description,
                          html: true,
                          placement: 'topright',
                          customClass: 'tooltip-lg',
                          id: 'tooltip-label-propertie-' + index
                        }"
                        class="div-icon"
                      >
                        <IconDirections class="icon" />
                        <span
                          v-if="checkMandatory(item, listPropertiesStart[index])"
                          class="require-comp"
                        ></span>
                      </div>
                      <label>{{ item.name }}</label>
                      <div
                        v-if="
                          checkMandatory(item, listPropertiesStart[index]) &&
                          touchedForm &&
                          item.value.length === 0
                        "
                        class="div-multiple-error"
                      >
                        <IconDangerous class="icon-error" />
                        <p>Campo Obrigatório</p>
                      </div>
                    </div>
                    <div class="extra-fields-container">
                      <div
                        v-for="(value, valueIndex) in item.value"
                        :key="'item-' + valueIndex"
                        class="extra-description"
                      >
                        <div class="buttons-order-container">
                          <div
                            :class="`btn-order ${valueIndex == 0 ? 'hidden-button-oreder' : ''}`"
                            @click="orderUp(index, valueIndex)"
                          >
                            <IconOrderUp />
                          </div>
                          <div
                            :class="`btn-order ${
                              valueIndex == item.value.length - 1 ? 'hidden-button-oreder' : ''
                            }`"
                            @click="orderDown(index, valueIndex)"
                          >
                            <IconOrderDown />
                          </div>
                        </div>
                        <div class="icon-label">
                          <label class="number"
                            ><span>{{ valueIndex + 1 }}</span
                            >°</label
                          >
                          <button
                            v-if="valueIndex != 0"
                            type="button"
                            class="btn-trash"
                            @click="removeExtraField(index, valueIndex)"
                          >
                            <IconTrash />
                          </button>
                        </div>
                        <QuillEditor
                          :id="`extra-description-${item.name}-${valueIndex}`"
                          v-model="item.value[valueIndex]"
                          :allow-style="item.allowStyle"
                          :close-quill="closeQuill"
                          :touched-form="touchedForm"
                          :mandatory="checkMandatory(item, listPropertiesStart[index])"
                          :force-mandatory="
                            checkMandatory(item, listPropertiesStart[index]) &&
                            item.value.length > 1
                          "
                          @QuillFocus="quillFocus"
                        />
                      </div>
                    </div>
                    <div class="area-add-button">
                      <button
                        type="button"
                        class="btn btn-add"
                        @click="item.value.push('')"
                      >
                        <IconPlus />
                        {{ item.name }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <button
              v-if="action == 'new'"
              type="button"
              class="btn btn-cancel"
              @click="cancelComponent"
            >
              <IconClose class="icon-button" />
              Cancelar
            </button>
            <button
              v-if="action == 'edit'"
              type="button"
              class="btn btn-cancel"
              @click="deleteComponent"
            >
              <IconTrash class="icon-button" />
              Apagar
            </button>
            <button
              v-if="action == 'edit'"
              type="button"
              class="btn btn-discard"
              :disabled="!alterInfo"
              @click="discardChanges"
            >
              <IconHistory class="icon-button" />
              Descartar Alterações
            </button>
            <button
              :disabled="!alterInfo"
              type="submit"
              class="btn btn-save"
            >
              <IconSave class="icon-button" />
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable vue/no-unused-components */
  import { mapActions, mapMutations, mapState } from "vuex";
  import IconOrderDown from "@/assets/images/icon-order-down.svg";
  import IconOrderUp from "@/assets/images/icon-order-up.svg";
  import IconGear from "@/assets/images/icon-gear.svg";
  import IconArrowDown from "@/assets/images/icon-arrow-drop-down.svg";
  import IconTextA from "@/assets/images/icon-text.svg";
  import IconSave from "@/assets/images/icon-save.svg";
  import IconClose from "@/assets/images/icon-close.svg";
  import IconTrash from "@/assets/images/icon-trash.svg";
  import IconHistory from "@/assets/images/icon-clock-return.svg";
  import IconDirections from "@/assets/images/icon-directions-run.svg";
  import IconPlus from "@/assets/images/icon-plus-arrounded.svg";
  import IconCheck from "@/assets/images/icon-check-circle.svg";
  import InputTextarea from "../../../components/InputTextarea.vue";
  import InputSelect from "../../../components/InputSelect.vue";
  import IconDangerous from "@/assets/images/icon-dangerous-filled.svg";
  import QuillEditor from "./QuillEditorComp.vue";
  import ImageNewComponent from "@/assets/images/image-new-component.svg";

  export default {
    components: {
      IconOrderDown,
      IconOrderUp,
      IconGear,
      IconArrowDown,
      IconTextA,
      IconSave,
      IconClose,
      IconTrash,
      IconHistory,
      IconDirections,
      IconPlus,
      IconCheck,
      InputTextarea,
      QuillEditor,
      InputSelect,
      IconDangerous,
      ImageNewComponent
    },
    props: [
      "component",
      "action",
      "componentTypeEditId",
      "componentEditId",
      "componentNewParentId",
      "listComponentTypes",
      "listAllTypes",
      "allowDiscard"
    ],
    data() {
      return {
        componentSelectTypeId: null,
        listPropertiesStart: [],
        listProperties: [],
        listPropertiesValue: [],
        projectId: null,
        groupId: null,
        subgroupId: null,
        featureId: null,
        forceRenderComponent: true,
        touchedForm: false,
        loading: false,
        closeQuill: false,
        headerConfigAllowLoginModal: {
          headers: {
            allowLoginModal: true
          }
        },
        modal: {
          props: {
            action: "",
            type: "",
            projectId: null,
            groupId: null,
            subgroupId: null,
            item: {}
          },
          open: true
        },
        staticComponent: null
      };
    },
    computed: {
      alterInfo: function () {
        const replacedValue = "<p><br></p>";
        const replacedListStart = JSON.stringify(
          JSON.parse(JSON.stringify(this.listPropertiesStart))
        ).replaceAll(replacedValue, "");
        const replacedpropList = JSON.stringify(
          JSON.parse(JSON.stringify(this.listProperties))
        ).replaceAll(replacedValue, "");
        let value = replacedpropList != replacedListStart;
        this.$emit("blockOutside", value);
        return value;
      },
      invalidForm: function () {
        return this.validateForm();
      },
      ...mapState("CompFeat", {
        openComponentDuplicate: "openComponentDuplicate"
      })
    },
    destroyed() {
      this.setStateNull();
    },
    watch: {
      allowDiscard() {
        if (this.allowDiscard) {
          this.resetForm();
          this.reloadComponent();
          this.$emit("handleDiscard", false);
        }
      },
      componentTypeEditId() {
        this.componentSelectTypeId = this.componentTypeEditId;
      },
      componentEditId() {
        /*      if(this.action == 'new'){
        if(this.componentEditId != 0) {
          debugger;
          this.$emit('loadComponentType', this.projectId, this.groupId, this.subgroupId, this.featureId, this.componentTypeId)
        }
        this.componentSelectTypeId = null
        this.reloadComponent()
      } else {
        this.loadComponentInfoEdit()
      }*/
        if (this.action !== "new") {
          this.loadComponentInfoEdit();
        }
      },
      action() {
        if (this.action == "new") {
          this.reloadFeature();
          this.componentSelectTypeId = null;
        }
      },
      componentSelectTypeId() {
        if (this.componentSelectTypeId && this.action == "new") {
          this.loadComponentInfos();
        }
      }
    },
    methods: {
      ...mapActions("Components", [
        "ActionGetComponentTypesProperties",
        "ActionPostComponentProperties",
        "ActionPutComponentProperties",
        "ActionDeleteComponentProperties",
        "ActionGetComponentProperties"
      ]),
      ...mapMutations("CompFeat", ["setOpenComponentDuplicate"]),
      setStateNull() {
        this.setOpenComponentDuplicate(null);
      },

      orderUp(index, valueIndex) {
        if (valueIndex > 0) {
          // Trocar o índice do item com o anterior na lista
          const temp = this.listProperties[index].value[valueIndex];
          this.$set(
            this.listProperties[index].value,
            valueIndex,
            this.listProperties[index].value[valueIndex - 1]
          );
          this.$set(this.listProperties[index].value, valueIndex - 1, temp);
          this.reloadComponent();
        }
      },
      orderDown(index, valueIndex) {
        if (valueIndex < this.listProperties[index].value.length - 1) {
          // Trocar o índice do item com o próximo na lista
          const temp = this.listProperties[index].value[valueIndex];
          this.$set(
            this.listProperties[index].value,
            valueIndex,
            this.listProperties[index].value[valueIndex + 1]
          );
          this.$set(this.listProperties[index].value, valueIndex + 1, temp);
          this.reloadComponent();
        }
      },
      async reloadFeature() {
        await this.reloadComponent();
        await this.$emit("reloadFeature");
      },
      removeTagsAndWhitespaces(input) {
        if (input == null || input?.length == 0 || input[0] == null) {
          return false;
        } else {
          let InputReplaced = input[0].replace(/<[^>]*>/g, "");

          // Remove os espaços em branco
          let InputReplacedWhitespace = InputReplaced.replace(/\s/g, "");

          // Verifica se há alguma letra
          if (InputReplacedWhitespace.length > 0) {
            return true;
          } else {
            return false;
          }
        }
      },
      reloadComponent() {
        this.forceRenderComponent = false;
        this.$nextTick().then(() => {
          this.forceRenderComponent = true;
        });
      },
      visibleTooltipLabelPropertie(indexElement, action) {
        if (action == "leave") {
          this.$root.$emit("bv::hide::tooltip", "tooltip-label-propertie-" + indexElement);
        } else if (action == "over") {
          this.$root.$emit("bv::show::tooltip", "tooltip-label-propertie-" + indexElement);
        }
      },
      propertySelect(propertyId) {
        for (let i = 0; i < this.listPropertiesValue.length; i++) {
          if (this.listPropertiesValue) {
            if (this.listPropertiesValue[i].propertyId == propertyId) {
              return true;
            }
          }
        }
        return false;
      },
      quillFocus() {
        this.closeQuill = false;
      },
      saveComponent() {
        if (this.loading) {
          return;
        }
        this.closeQuill = true;
        this.loading = true;
        this.touchedForm = true;

        if (!this.invalidForm) {
          let projectId = this.projectId;
          let groupId = this.groupId;
          let subgroupId = this.subgroupId;
          let featureId = this.featureId;
          let componentId = this.componentEditId;
          let componentTypeId = this.componentSelectTypeId;
          this.listProperties.forEach((property) => {
            if (
              property.multiple == true &&
              property.value.length > 0 &&
              property.value[0] !== null
            ) {
              property.value = property.value.filter(
                (value) => value.replace(/<[^>]*>/g, "").replace(/\s/g, "") !== ""
              );
            }
          });
          let payload = this.listProperties.map((property) => {
            return {
              propertyId: property.propertyId,
              value:
                property.value === null
                  ? []
                  : property.value[0] === null
                  ? []
                  : this.removeTagsAndWhitespaces(property.value)
                  ? property.value
                  : [],
              propertyValueId: property.propertyValueId
            };
          });
          let config = this.headerConfigAllowLoginModal;

          if (this.action == "new") {
            this.ActionPostComponentProperties({
              projectId,
              groupId,
              subgroupId,
              featureId,
              componentTypeId,
              componentId,
              payload,
              config
            })
              .then(async (response) => {
                await this.reloadFeature();
                this.$emit("editComponent", response.data.id, componentTypeId);
                this.$emit("loadTreeComponent");
                this.loading = false;
                this.$emit("forceCloseComponent");
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            this.ActionPutComponentProperties({
              projectId,
              groupId,
              subgroupId,
              featureId,
              componentTypeId,
              componentId,
              payload,
              config
            })
              .then(() => {
                this.listPropertiesStart = JSON.parse(JSON.stringify(this.listProperties));
                this.$emit("loadTreeComponent");
                this.reloadFeature();
                this.loading = false;
                this.$emit("forceCloseComponent");
                this.loadComponentInfoEdit();
              })
              .catch(() => {
                this.loading = false;
              });
          }
        } else {
          this.loading = false;
        }
      },
      discardChanges() {
        this.closeQuill = true;
        this.$swal({
          customClass: "swal-warning",
          title: "Atenção",
          text: "Você tem alterações não salvas. Deseja descartar as alterações?"
        }).then((result) => {
          if (result.isConfirmed) {
            this.listProperties = JSON.parse(JSON.stringify(this.listPropertiesStart));
            this.reloadComponent();
          }
        });
      },
      deleteComponent() {
        // Deletar componente existente e em edição

        this.$swal({
          customClass: "swal-delete",
          title: "Apagar",
          text: "Todas as configurações do componente serão apagadas. Deseja continuar?"
        }).then((result) => {
          if (result.isConfirmed) {
            let projectId = this.projectId;
            let groupId = this.groupId;
            let subgroupId = this.subgroupId;
            let featureId = this.featureId;
            let componentId = this.componentEditId;
            let componentTypeId = this.componentTypeEditId;

            this.ActionDeleteComponentProperties({
              projectId,
              groupId,
              subgroupId,
              featureId,
              componentTypeId,
              componentId
            }).then(() => {
              this.$emit("reloadFeature");
              this.reloadComponent();
              this.$emit("loadTreeComponent");
              this.$emit("clearWorkspace");
              this.$emit("blockOutside", false);
              this.$emit("forceCloseComponent");
            });
          }
        });
      },
      resetForm() {
        this.touchedForm = false;
        this.listProperties = JSON.parse(JSON.stringify(this.listPropertiesStart));
      },
      cancelComponent() {
        // Cancelamento da criação de novo componente
        if (this.alterInfo) {
          // Se houveram informações alteradas no formulário, exibe alerta de confirmação
          this.$swal({
            customClass: "swal-warning",
            title: "Atenção",
            text: "Você tem alterações não salvas. Deseja descartar as alterações?"
          }).then((result) => {
            if (result.isConfirmed) {
              this.$emit("clearWorkspace");
              this.$emit("blockOutside", false);
            }
          });
        } else {
          // Caso o usuário não tenha digitado nenhuma informação nos campos, limpando o workspace
          this.$emit("clearWorkspace");
          this.$emit("blockOutside", false);
        }
      },
      validateForm() {
        // Percorre todos os campos do formulário e define a partir da obrigatoriedade se o formulário está validado para ser enviado
        let validationItem = this.listProperties.map((item, index) => {
          let itemStart = this.listPropertiesStart[index];
          let itemMandatory = this.checkMandatory(item, itemStart);
          let validateFieldWhitespace = this.removeTagsAndWhitespaces(item.value);
          if (
            itemMandatory &&
            item.value == null &&
            (item.propertyValueId != null || item.propertyValueId != undefined)
          ) {
            return false;
          } else if (
            itemMandatory &&
            item.value?.length > 0 &&
            (item.value[0] == "" || item.value[0] == null)
          ) {
            return true;
          } else if (
            itemMandatory &&
            item.value == null &&
            (item.propertyValueId == undefined || item.propertyValueId == null)
          ) {
            return true;
          } else if (itemMandatory && !validateFieldWhitespace) {
            return true;
          } else {
            return false;
          }
        });
        let validation = validationItem.some((item) => item === true);
        return validation;
      },
      loadComponentInfos() {
        this.touchedForm = false;
        let projectId = this.projectId;
        let groupId = this.groupId;
        let subgroupId = this.subgroupId;
        let featureId = this.featureId;
        let componentTypeId;
        if (this.componentTypeEditId != null) {
          componentTypeId = this.componentTypeEditId;
        } else {
          componentTypeId = this.componentSelectTypeId;
        }
        this.listProperties = [];
        this.listPropertiesStart = [];
        this.ActionGetComponentTypesProperties({
          projectId,
          groupId,
          subgroupId,
          featureId,
          componentTypeId
        }).then((response) => {
          this.listPropertiesValue = response.data.propertyValues;
          response.data.property.map((property) => {
            this.listProperties.push({
              ...property,
              propertyId: property.id,
              value: this.propertySelect(property.id)
                ? null
                : property.default
                ? [property.default]
                : [null],
              propertyValueId: this.propertySelect(property.id)
                ? this.listPropertiesValue.find(
                    (listItem) =>
                      listItem.propertyId === property.id && listItem.value === property.default
                  )?.id
                : null,
              allowStyle: property.allowStyle
            });
          });
          this.listProperties.sort((a, b) => (a.order > b.order ? 1 : -1));
          this.listPropertiesStart = JSON.parse(JSON.stringify(this.listProperties));
          this.reloadComponent();
        });
      },
      removeExtraField(indexProperty, indexPropertyValue) {
        this.listProperties[indexProperty].value.splice(indexPropertyValue, 1);
        this.reloadComponent();
      },
      loadComponentInfoEdit() {
        let projectId = this.projectId;
        let groupId = this.groupId;
        let subgroupId = this.subgroupId;
        let featureId = this.featureId;
        let componentTypeId = this.componentTypeEditId || null;
        let componentId = this.componentEditId || null;

        if (componentTypeId !== null && componentId !== null) {
          this.componentSelectTypeId = this.componentTypeEditId || null;
          this.listProperties = [];
          this.listPropertiesStart = [];
          this.ActionGetComponentProperties({
            projectId,
            groupId,
            subgroupId,
            featureId,
            componentTypeId,
            componentId
          }).then((response) => {
            this.staticComponent = response.data.component;
            this.listPropertiesValue = response.data.propertyValues;
            let newListProperties = [];
            response.data.property.map((property) => {
              newListProperties.push({
                ...property,
                propertyId: property.id,
                value: this.propertySelect(property.id) ? null : property.value,
                propertyValueId: this.propertySelect(property.id) ? property.propertyValueId : null,
                allowStyle: property.allowStyle,
                multiple: property.value.length <= 1 ? property.multiple : true
              });
            });
            this.listProperties = JSON.parse(JSON.stringify(newListProperties));
            setTimeout(() => {
              this.listPropertiesStart = JSON.parse(JSON.stringify(this.listProperties));
            }, 1);
            this.reloadComponent();
          });
        }
      },
      checkMandatory(item, itemStart = null) {
        let noMandatoryAlternative = !(
          (itemStart?.value?.length > 0 && itemStart?.value[0] != "") ||
          (itemStart?.value == null && itemStart?.propertyValueId != null)
        );

        if (
          this.action == "edit" &&
          noMandatoryAlternative &&
          item.mandatory == true &&
          this.action != "new"
        ) {
          return false;
        } else {
          return (
            item.mandatory ||
            item.rules?.reduce(
              (mandatory, element) =>
                mandatory ||
                this.listProperties.reduce(
                  (mandatory, listItem) =>
                    mandatory ||
                    (listItem.id === element.conditionalPropertyId &&
                      listItem.propertyValueId === element.conditionalValueId),
                  false
                ),
              false
            )
          );
        }
      }
    },
    created() {
      this.projectId = this.$route.params.projectId || null;
      this.groupId = this.$route.params.groupId || null;
      this.subgroupId = this.$route.params.subgroupId || null;
      this.featureId = this.$route.params.featureId || null;
      if (this.action == "new") {
        this.componentSelectTypeId = null;
      } else {
        this.componentSelectTypeId = this.componentTypeEditId;
        this.loadComponentInfoEdit();
      }
    }
  };
</script>

<style lang="scss">
  .config-comp {
    position: relative;
    height: 100%;
  }

  .mandatory-indicator-comp {
    width: 5px;
    margin-right: 3px;
    height: 31px;
    color: #ef3e4d;
  }

  .require-comp::after {
    vertical-align: super;
    display: inherit;
    width: 5px;
    margin-right: 3px;
    height: 31px;
    color: #ef3e4d;
    content: "*";
  }

  .span-width-comp {
    height: 31px;
    vertical-align: super;
    margin-right: 11px;
  }

  .component-settings {
    height: 100%;
    background: #fbfdf7;
    border: 1px solid #f0f1ec;
    box-sizing: border-box;

    svg {
      width: 21px;
    }

    & > div {
      height: 100%;
      overflow: hidden;
    }

    .general {
      height: 100%;
      overflow: hidden;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .new-component {
        position: relative;
        top: 50%;
      }

      .info-new-component {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .info-new-component .image-new-component {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 10px;
        width: 178.82px;
        height: 194px;
      }

      .info-new-component h6 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #8f918d;
        width: 391px;
        height: 44px;
      }

      .top {
        display: flex;
        align-items: center;
        margin: 10px 0 0 0;

        .top-icon {
          margin-right: 5px;
          width: 21px;
        }
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          color: #1a1c1a;
          margin: 0;
        }
      }

      form {
        height: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        overflow-x: hidden;
        overflow-y: auto;
        padding-top: 15px;
        padding-bottom: 20px;
      }

      .feature-list {
        width: 100%;

        label {
          color: #5c5f5b;
        }

        .extra-config {
          .extra-fields-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .extra-content {
            border: 1px solid #f0f1ec;
            box-sizing: border-box;
            border-radius: 5px;
            margin: 10px 0;
            padding: 10px;
          }

          .buttons-order-container {
            .btn-order {
              width: 12px;
              height: 12px;
              cursor: pointer;
              svg {
                width: 12px;
              }
            }
            .hidden-button-oreder {
              visibility: hidden;
            }
          }

          .icon-label {
            display: flex;
            flex-direction: column;
            svg {
              position: relative;
              right: 3px;
              top: 1px;
              width: 15px;
              cursor: pointer;
            }
            .btn-trash {
              margin: 0;
              padding: 0;
              border: none;
              background: transparent;
            }
          }
          .opening {
            label {
              position: relative;
              top: 1px;
              font-size: 14px;
            }
            margin-bottom: 7px;
          }

          .extra-description {
            display: flex;
            gap: 5px;
            .number {
              margin-top: 5px;
              margin-right: 5px;
              color: #1a1c1a;
              font-weight: 600;
            }
          }

          .area-add-button {
            margin-top: 10px;
            display: flex;
            justify-content: center;
          }
        }

        .btn-add {
          display: flex;
          box-shadow: none;
          align-items: center;
          color: #006d3a;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          border-radius: 15px;
          svg {
            margin-right: 3px;
            width: 15px;
          }
        }
        .btn-add:hover {
          background-color: #006d3a1f;
          border-radius: 15px;
        }

        .container-label-propertie {
          .div-icon {
            display: inline-block;
          }
          .div-multiple-error {
            display: flex;
            float: right;
            .icon-error {
              margin-right: 5px;
            }
          }
          p {
            margin: 0;
            font-size: 14px;
            line-height: 1.35em;
            font-weight: 600;
            color: #ef3e4d;
          }
        }

        .item-component,
        .last-item-component {
          display: flex;
          margin-bottom: 10px;
          margin-right: 10px;

          .valueNull {
            select {
              color: #c6c7c3;
            }
          }
        }
        .last-item-component {
          margin-bottom: 0px;
        }
      }
      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-top: 15px;
        padding-right: 10px;

        .btn-cancel {
          svg {
            width: 15px;
            path {
              fill: #ef3e4d;
            }
          }
          color: #ef3e4d;
          background: transparent;
          font-weight: 600;
          font-size: 14px;
          border-radius: 15px;
          box-shadow: none;

          .icon-button {
            margin-right: 6px;
            margin-bottom: 2px;
          }
          &:hover {
            opacity: 0.7;
          }
        }
        .btn-save {
          color: #fff;
          background: #006d3a;
          border-radius: 15px;
          font-weight: 600;
          font-size: 14px;
          margin-left: 10px;
          box-shadow: none;

          .icon-button {
            margin-right: 6px;
            margin-bottom: 2px;
            width: 15px;
          }

          &:hover {
            transition: 0.2s;
            border: 1px solid #006d3a;
            color: #006d3a;
            background: #fff;

            svg {
              path {
                fill: #006d3a;
              }
            }
          }
        }
        .btn-save[disabled],
        .btn-discard[disabled] {
          color: #989899;
          background: #ebebec;
          border: 1px solid transparent;
          svg {
            path {
              fill: #989899;
            }
          }
        }
        .btn-discard {
          border: 1px solid #006d3a;
          border-radius: 15px;
          font-weight: 600;
          font-size: 14px;
          box-shadow: none;
          color: #006d3a;

          svg {
            margin-bottom: 2px;
            width: 15px;
          }

          &:hover {
            transition: 0.2s;
            color: #fff;
            background: #006d3a;
            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }
</style>
