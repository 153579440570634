<template>
  <div
    class="appointment-screen"
    :class="`${optionSelected == 'semana_util' ? 'w-100' : ''}`"
  >
    <div
      :class="[
        'activityNavbar',
        optionSelected == 'semana' || optionSelected == 'semana_util'
          ? ''
          : optionSelected == 'fim_semana'
          ? 'endOfWeek'
          : 'day'
      ]"
    >
      <div
        class="visualizationSelect"
        @click="handleTriggerSelect"
      >
        <div class="selected">
          <p>
            {{
              optionSelected == "dia"
                ? "Dia"
                : optionSelected == "fim_semana"
                ? "Fim de Semana"
                : optionSelected == "semana"
                ? "Semana"
                : "Semana Útil"
            }}
          </p>
          <div style="margin-right: 14px">
            <IconArrow />
          </div>
        </div>
      </div>
      <div
        v-if="isOpen"
        class="selectContent"
      >
        <ul>
          <li
            :class="['optionVisualization', optionSelected == 'dia' && 'selected-option']"
            @click="optionSelect('dia')"
          >
            Dia
          </li>
          <li
            :class="['optionVisualization', optionSelected == 'fim_semana' && 'selected-option']"
            @click="optionSelect('fim_semana')"
          >
            Fim de Semana
          </li>
          <li
            :class="['optionVisualization', optionSelected == 'semana' && 'selected-option']"
            @click="optionSelect('semana')"
          >
            Semana
          </li>
          <li
            :class="['optionVisualization', optionSelected == 'semana_util' && 'selected-option']"
            @click="optionSelect('semana_util')"
          >
            Semana Útil
          </li>
        </ul>
      </div>
      <div class="rightSide">
        <div class="divDatePicker">
          <div>
            <IconArrowLeft @click="alterPeriod('return')" />
            <IconArrowRight @click="alterPeriod('advance')" />
          </div>
          <DatePicker
            :format="formatDatePicker"
            :value.sync="dateValue"
          >
            <template v-slot:trigger="{ triggerDatepicker }">
              <div
                class="triggerDatePicker"
                @click="triggerDatepicker"
              >
                <span>{{ formataMesAno(dateValue) }}</span>
                <IconArrow />
              </div>
            </template>
          </DatePicker>
        </div>
        <div
          class="iconClose"
          @click="closeModal"
        >
          <IconCLose />
        </div>
      </div>
    </div>
    <div class="activityCards">
      <div :class="['listOfDaysWeek', optionSelected == 'semana' && 'fullScreen']">
        <div
          v-for="(date, index) in dates"
          :key="`day-week-${index}`"
          :class="[
            optionSelected == 'semana' || optionSelected == 'semana_util'
              ? 'daysWeek week'
              : 'daysWeek day',
            findHolidays(appointments, date) && 'holiday',
            isCurrentDate(date) && 'dayAppointemntFocus'
          ]"
        >
          <div
            v-if="findHolidays(appointments, date) || !canEditTask(date)"
            style="width: 100%; height: 100%"
          >
            <div
              v-b-tooltip.hover="{
                title: `${
                  findHolidays(appointments, date)
                    ? findHolidays(appointments, date)
                    : !canEditTask(date) && appointInEdit
                    ? ''
                    : !canEditTask(date)
                    ? 'Dia Bloqueado'
                    : ''
                }`,
                placement: 'top',
                boundary: 'window',
                customClass: 'tooltip-font-style'
              }"
              class="title-icon hover-appointement"
              style="padding: 5.5px 0"
              @click="canEditTask(date) && createTask({ date })"
            >
              <IconPlus class="icon-plus" />
              <span>Apontamento</span>
            </div>
            <div
              class="title-icon initial"
              style="padding: 5.5px 0"
            >
              <span class="dayDate">{{ formatDate(date) }}</span>
              <span class="dayName">{{
                optionSelected == "semana"
                  ? dayNamesWeek[dayweekName ? dayweekName : index]
                  : optionSelected == "semana_util"
                  ? dayNamesWeekuseful[index]
                  : optionSelected == "fim_semana"
                  ? endOfWeek[index]
                  : dayNamesWeek[dayweekName ? dayweekName : index]
              }}</span>
            </div>
          </div>
          <div
            v-else
            style="margin: 5.5px 0"
          >
            <div
              class="title-icon hover-appointement"
              @click="canEditTask(date) && createTask({ date })"
            >
              <IconPlus class="icon-plus" />
              <span>Apontamento</span>
            </div>
            <div class="title-icon initial">
              <span class="dayDate">{{ formatDate(date) }}</span>
              <span class="dayName">{{
                optionSelected == "semana"
                  ? dayNamesWeek[dayweekName ? dayweekName : index]
                  : optionSelected == "semana_util"
                  ? dayNamesWeekuseful[index]
                  : optionSelected == "fim_semana"
                  ? endOfWeek[index]
                  : dayNamesWeek[dayweekName ? dayweekName : index]
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="gridAppointments"
        :class="optionSelected == 'semana' && 'overflow-width'"
        @scroll="handleScroll"
      >
        <!-- Renderização das colunas de dias que exibirão as tasks referentes ao dia -->
        <div
          v-for="(date, indexDate) in dates"
          :key="`card-${indexDate}`"
          class="CompAppointmentCard"
          :class="[
            isCurrentDate(date) && 'appointemntFocus',
            optionSelected == 'semana' || optionSelected == 'semana_util' ? 'week' : 'day'
          ]"
        >
          <div
            v-for="(task, index) in tasksByDay(appointments, date)"
            :key="`appointment-${index}`"
            :class="[
              optionSelected == 'semana' || optionSelected == 'semana_util' ? 'week' : 'day',
              isCurrentDate(date) && 'appointemntFocus'
            ]"
          >
            <AppointmentActivity
              :trigger-close-popover="triggerClosePopover"
              :calendar-type="optionSelected"
              :title="task.title || null"
              :start-appointment="task.startAppointment"
              :end-appointment="task.endAppointment"
              :interval="task.interval"
              :date="date"
              :class-appointment="
                optionSelected == 'semana' || optionSelected == 'semana_util' ? 'week' : 'day'
              "
              :task-id="task.id"
              :contexts-appointments-activity="contextsAppointments"
              :can-edit="canEditTask(date)"
              :task="task"
              :resize-card="
                optionSelected == 'semana' || optionSelected == 'semana_util'
                  ? minWidthCardAppointment
                  : 0
              "
              @createTask="createTask"
              @taskAppointmentDeleted="repopulateGrid($event)"
              @taskAppointmentEdit="editTask($event)"
              @taskAppointmentDuplicate="duplicateTask($event)"
            />
          </div>
          <div
            v-for="(_, index) in minAppointmentsPerDay - tasksByDay(appointments, date).length"
            :key="index"
            :class="[
              'AppointmentCardDiv',
              'no-appointment',
              (optionSelected == 'fim_semana' || optionSelected == 'dia') && 'day',
              isCurrentDate(date) && 'appointemntFocus'
            ]"
            :style="`width: ${
              optionSelected == 'semana' || optionSelected == 'semana_util'
                ? minWidthCardAppointment
                : 0
            }px`"
          ></div>
        </div>
      </div>
      <div class="timeAppointment">
        <div
          v-for="(date, index) in dates"
          :key="index"
        >
          <div
            :class="[
              optionSelected == 'semana' || optionSelected == 'semana_util'
                ? 'timeAppointment week'
                : 'timeAppointment day'
            ]"
            :style="`width: ${
              optionSelected == 'semana_util' || optionSelected == 'semana'
                ? minWidthCardAppointment
                : 0
            }px`"
          >
            <div v-html="totalHoursByDay(date)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState, mapMutations } from "vuex";
  import IconArrow from "@/assets/images/icon-arrow-down-activity.svg";
  import IconCLose from "@/assets/images/icon-close-activity.svg";
  import IconPlus from "@/assets/images/icon-plus-arrounded.svg";
  import IconArrowRight from "@/assets/images/icon-arrow-outlined-right.svg";
  import IconArrowLeft from "@/assets/images/icon-arrow-outlined-left.svg";
  import AppointmentActivity from "./AppointmentActivity.vue";

  import DatePicker from "@/components/DatePicker.vue";

  import { startOfWeek, addDays, format, getDay } from "date-fns";
  import { ptBR } from "date-fns/locale";

  export default {
    components: {
      AppointmentActivity,
      IconArrow,
      IconCLose,
      IconArrowRight,
      IconArrowLeft,
      IconPlus,
      DatePicker
    },
    props: [
      "showGridAppointments",
      "contextsAppointments",
      "updateGrid",
      "dateAppointmentValue",
      "getDatesActv"
    ],
    data() {
      return {
        appointInEdit: false,
        triggerClosePopover: 0,
        dateValue: new Date(),
        holidaysType: [],
        minAppointmentsPerDay: 1,
        minWidthCardAppointment: 0,
        appointments: [],
        dayweekName: null,
        optionSelected: "semana_util",
        selectVisualization: "",
        renderDays: 5,
        isOpen: false,
        dates: [],
        dayNamesWeek: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesWeekuseful: ["Segunda", "Terça", "Quarta", "Quinta", "Sexta"],
        endOfWeek: ["Domingo", "Sábado"]
      };
    },
    computed: {
      ...mapState("Appointments", {
        actionAppoint: "actionAppoint",
        optionViewSelected: "optionViewSelected"
      }),
      formatDatePicker() {
        return this.optionSelected == "dia" ? "day" : "week";
      }
    },
    methods: {
      ...mapActions("Appointments", ["ActionGetTaskDate"]),
      ...mapMutations("Appointments", ["setOptionViewSelected"]),
      handleScroll() {
        this.triggerClosePopover += 1;
      },
      isCurrentDate(date) {
        return format(date, "yyyy-MM-dd") == format(new Date(), "yyyy-MM-dd");
      },
      editTask(value) {
        this.$emit("editTask", value);
      },
      duplicateTask(value) {
        this.$emit("duplicateTask", value);
      },
      repopulateGrid(value) {
        if (value) {
          this.updateAppointmentDates();
        }
      },
      createTask(info) {
        this.$emit("createTask", info);
      },
      alterPeriod(action) {
        let date = this.dateValue;
        if (action === "return") {
          if (this.optionSelected === "dia") {
            date.setDate(date.getDate() - 1);
          } else {
            date.setDate(date.getDate() - 7);
          }
        } else {
          if (this.optionSelected === "dia") {
            date.setDate(date.getDate() + 1);
          } else {
            date.setDate(date.getDate() + 7);
          }
        }
        this.dateValue = new Date(date);
      },
      formataMesAno(date) {
        return format(date, "MMMM yyyy", { locale: ptBR });
      },
      showAppointment(appointmentDate, currentDate) {
        return appointmentDate == format(currentDate, "yyyy-MM-dd");
      },
      findHolidays(appointments, date) {
        let appointment = this.findAppointementByDate(appointments, date);
        return appointment
          ? `${appointment.holiday ? "Feriado: " + appointment.holiday.name : ""}`
          : false;
      },
      findAppointementByDate(appointments, date) {
        let dateFormated = format(date, "yyyy-MM-dd");
        let indexAppointement = appointments.findIndex(
          (appointment) => appointment.date == dateFormated
        );
        if (indexAppointement >= 0) {
          return appointments[indexAppointement];
        }
        return null;
      },
      canEditTask(date) {
        let appointment = this.findAppointementByDate(this.appointments, date);
        if (this.actionAppoint != "new") {
          this.appointInEdit = true;
          return false;
        } else if (appointment) {
          this.appointInEdit = false;
          return appointment.canEdit;
        }
        return false;
      },
      totalHoursByDay(date) {
        let appointment = this.findAppointementByDate(this.appointments, date);
        if (appointment && appointment.tasks.length != 0) {
          let formatDate = this.formatDate(date);
          let dayOfWeek = format(date, "E", { locale: ptBR });
          let weekEnd = false;
          this.dates.forEach((date) => {
            if (date.getDate() == formatDate && (dayOfWeek == "domingo" || dayOfWeek == "sábado")) {
              weekEnd = true;
            }
          });
          let hour = appointment.totalHour;
          let minute = appointment.totalMinute;
          if (hour < 10) {
            hour = `0${hour}`;
          }
          if (minute < 10) {
            minute = `0${minute}`;
          }
          let colorGreen = "#10B981";
          let colorRed = "#F75965";
          let colorRule = parseInt(hour) >= 8 || weekEnd ? colorGreen : colorRed;
          return `<span style="color: ${colorRule}">
                            ${hour}:${minute}
                        </span>`;
        }
        return "00:00";
      },
      tasksByDay(appointments, date) {
        let appointment = this.findAppointementByDate(appointments, date);
        if (appointment) {
          let listTasks = this.mapTasksInterval(appointment.tasks);
          if (this.minAppointmentsPerDay < listTasks.length) {
            this.minAppointmentsPerDay = listTasks.length;
          }
          return listTasks;
        }
        return [];
      },
      mapTasksInterval(tasks) {
        let tasksInterval = [];
        tasks.forEach((task, index) => {
          if (index != 0) {
            let taskBefore = tasks[index - 1];
            if (
              task.startAppointment.initialHour != taskBefore.endAppointment.endHour ||
              task.startAppointment.initialMinute != taskBefore.endAppointment.endMinute
            ) {
              tasksInterval.push({
                interval: true,
                startAppointment: {
                  initialHour: taskBefore.endAppointment.endHour,
                  initialMinute: taskBefore.endAppointment.endMinute
                },
                endAppointment: {
                  endHour: task.startAppointment.initialHour,
                  endMinute: task.startAppointment.initialMinute
                }
              });
            }
          }
          tasksInterval.push(task);
        });
        return tasksInterval;
      },
      handleTriggerSelect() {
        this.isOpen = !this.isOpen;
      },
      handleCloseContent() {
        this.isOpen = !this.isOpen;
      },
      optionSelect(value) {
        this.optionSelected = value;
        this.isOpen = false;
        if (this.optionSelected == "dia") {
          this.setOptionViewSelected(this.optionSelected);
          this.renderDays = 1;
        } else if (this.optionSelected == "semana_util") {
          this.setOptionViewSelected(this.optionSelected);
          this.renderDays = 5;
        } else if (this.optionSelected == "semana") {
          this.setOptionViewSelected(this.optionSelected);
          this.renderDays = 7;
        } else {
          this.setOptionViewSelected(this.optionSelected);
          this.renderDays = 2;
        }
      },
      closeModal() {
        this.$emit("closeModal");
      },
      getWeekDates(option = "semana") {
        if (option == "semana") {
          this.dayweekName = null;
          const startOfWeekDate = startOfWeek(this.dateValue, { locale: ptBR });
          const dates = [];
          for (let i = 0; i < this.renderDays; i++) {
            const date = addDays(startOfWeekDate, i);
            dates.push(date);
          }
          this.dates = dates;
        } else if (option == "dia") {
          const result = getDay(this.dateValue);
          this.dayweekName = result;
          const startOfWeekDate = startOfWeek(this.dateValue, {
            locale: ptBR,
            weekStartsOn: result
          });
          const dates = [];
          for (let i = 0; i < this.renderDays; i++) {
            const date = addDays(startOfWeekDate, i);
            dates.push(date);
          }
          this.dates = dates;
        } else if (option == "semana_util") {
          this.dayweekName = null;
          const startOfWeekDate = startOfWeek(this.dateValue, { locale: ptBR, weekStartsOn: 1 });
          const dates = [];
          for (let i = 0; i < this.renderDays; i++) {
            const date = addDays(startOfWeekDate, i);
            dates.push(date);
          }
          this.dates = dates;
        } else if (option == "fim_semana") {
          this.dayweekName = null;
          const startOfWeekDate = startOfWeek(this.dateValue, { locale: ptBR });
          const dates = [];
          for (let i = 0; i < 7; i++) {
            const date = addDays(startOfWeekDate, i);
            dates.push(date);
          }
          let sunday = dates.shift();
          let saturday = dates.pop();
          this.dates = [sunday, saturday];
        } else {
          this.dates = null;
        }
        this.getDatesActv(this.dates);
      },
      formatDate(date) {
        return format(date, "dd", { locale: ptBR });
      },
      getHeights() {
        let heightActivityNavbar = document.querySelector(".activityNavbar").scrollHeight;
        let heightDaysWeek = document.querySelector(".daysWeek").scrollHeight;
        let heightTimeAppointment = document.querySelector(".timeAppointment").scrollHeight;
        let heightOuterGrid = heightActivityNavbar + heightDaysWeek + heightTimeAppointment;
        let heightAppointmentCard = document.querySelector(".AppointmentCardDiv").scrollHeight;
        return Math.ceil(
          (document.querySelector(".appointment-screen").scrollHeight - heightOuterGrid) /
            heightAppointmentCard
        );
      },
      getWidth(value) {
        let widthDaysOfWeek;
        if (value == "semana_util") {
          widthDaysOfWeek = document.querySelector(".listOfDaysWeek").clientWidth / 5;
        } else {
          widthDaysOfWeek = document.querySelector(".listOfDaysWeek").clientWidth / 7;
        }
        return widthDaysOfWeek;
      },
      orderTasksDate(listTasks) {
        listTasks.sort(function (a, b) {
          if (a.datetime.initialHour < b.datetime.initialHour) {
            return -1;
          } else if (a.datetime.initialHour > b.datetime.initialHour) {
            return 1;
          } else {
            if (a.datetime.initialMinute < b.datetime.initialMinute) {
              return -1;
            } else if (a.datetime.initialMinute > b.datetime.initialMinute) {
              return 1;
            } else {
              return 0;
            }
          }
        });
        return listTasks;
      },
      updateAppointmentDates() {
        let bodyGetTaskDate = {
          startDate: format(this.dates[0], "MM-dd-yyyy")
        };
        if (this.dates.length > 1) {
          bodyGetTaskDate.endDate = format(this.dates[this.dates.length - 1], "MM-dd-yyyy");
        }
        this.ActionGetTaskDate(bodyGetTaskDate).then((response) => {
          this.appointments = response.period.map((item) => {
            this.orderTasksDate(item.tasks).map((subitem) => {
              subitem.startAppointment = {
                initialHour: subitem.datetime.initialHour,
                initialMinute: subitem.datetime.initialMinute
              };
              subitem.endAppointment = {
                endHour: subitem.datetime.endHour,
                endMinute: subitem.datetime.endMinute
              };
              subitem.title = {
                client: subitem.client,
                project: subitem.project
              };
              delete subitem.datetime;
              delete subitem.client;
              delete subitem.project;
              return subitem;
            });
            return item;
          });
        });
      }
    },
    watch: {
      updateGrid() {
        this.getWeekDates(this.optionSelected);
        this.updateAppointmentDates();
      },
      optionSelected() {
        this.getWeekDates(this.optionSelected);
        this.updateAppointmentDates();
        if (this.optionSelected == "semana_util") {
          setTimeout(() => {
            let teste = this.getWidth(this.optionSelected);
            this.minWidthCardAppointment = teste;
          }, 10);
        } else if (this.optionSelected == "semana") {
          setTimeout(() => {
            let teste = this.getWidth(this.optionSelected);
            this.minWidthCardAppointment = teste;
          }, 10);
        }
      },
      dateValue() {
        this.minAppointmentsPerDay = this.getHeights();
        this.getWeekDates(this.optionSelected);
        this.updateAppointmentDates();
      },
      showGridAppointments() {
        this.minAppointmentsPerDay = this.getHeights();
      },
      dateAppointmentValue() {
        this.dateValue = new Date(this.dateAppointmentValue);
      }
    },
    mounted() {
      let dayOfWeek = format(this.dateValue, "E", { locale: ptBR });
      dayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
      if (this.endOfWeek.indexOf(dayOfWeek) != -1) {
        this.optionSelected = "semana";
        this.setOptionViewSelected(this.optionSelected);
        this.renderDays = 7;
      } else {
        this.optionSelected = "semana_util";
        this.setOptionViewSelected(this.optionSelected);
      }
      this.getWeekDates(this.optionSelected);
      this.updateAppointmentDates();
      setTimeout(() => {
        this.minAppointmentsPerDay = this.getHeights();
        this.minWidthCardAppointment = this.getWidth(this.optionSelected);
      }, 10);
    }
  };
</script>

<style lang="scss" scoped>
  .tooltip-font-style {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  .appointment-screen {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #fbfdf7;
  }

  .day {
    width: 300px !important;
  }
  .endOfWeek {
    width: 600px;
  }
  .activityNavbar {
    border: 1px solid #c6c7c3;
    border-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .visualizationSelect {
      border: none;
      margin: 9px 0 8px 10px;
      padding: 0.5px 0;
      color: #006d3a;
      font-family: "Work Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      cursor: pointer;

      display: flex;
      align-items: center;
      gap: 8px;

      .selected {
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          padding: 1.5px 0 1.5px 10px;
        }
      }
      .selected:hover {
        background: #9bb19e;
        border-radius: 15px;
        color: #00391a;
      }
    }
    .visualizationSelect:focus {
      outline: none;
    }
    .selectContent {
      top: 34px;
      position: absolute;
      z-index: 1001;
      margin: 4px 0 0 10px;
      width: 220px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 5px;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
        0px 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      background: #ffffff;

      ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .optionVisualization {
        padding: 5.5px 5px;
        font-family: "Work Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        cursor: pointer;

        &:hover {
          background: #d1e8d3;
          border-radius: 3px;
          color: #005229;
        }
      }
      .selected-option {
        background-color: #005229;
        border-radius: 3px;
        color: #ffffff;
      }
    }
    .rightSide {
      display: flex;
      align-items: center;
      .divDatePicker {
        display: flex;
        align-items: center;
        cursor: pointer;

        .selectDate {
          display: flex;
        }

        .triggerDatePicker {
          color: #006d3a;
          font-family: "Work Sans", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.0025em;
          padding-left: 10px;
          display: flex;
          align-items: center;
          svg {
            margin-right: 10px;
            margin-left: 8px;
            margin-bottom: 1px;
          }

          span {
            text-transform: capitalize;
          }
        }
        .triggerDatePicker:hover {
          background: #9bb19e;
          border-radius: 15px;
          color: #00391a;
        }
      }
      .iconClose {
        margin-right: 10px;
        margin-left: 10px;
        cursor: pointer;
      }
      .iconClose:hover {
        background: #d1e8d3;
        border-radius: 15px;
      }
    }
  }
  .activityCards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    height: 100%;
    overflow-x: auto;
    .listOfDaysWeek {
      width: 100%;
      display: flex;

      .holiday {
        background: repeating-linear-gradient(
          #bfdaca -1px,
          #bfdaca 3px,
          #fbfdf7 4px,
          #fbfdf7 7.5px
        );
      }

      .dayAppointemntFocus {
        background-color: #ffffff;
        margin: 0.1px;
        border-width: 0px 1px 1px 0px;
        border-style: solid;
        border-color: #c6c7c3;
        box-shadow: inset 0px 4px 0px #006d3a !important;
        .title-icon {
          .dayName,
          .dayDate {
            font-weight: 600;
            color: #006d3a !important;
          }
        }
      }

      .daysWeek {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        border-width: 0px 1px 1px 0px;
        border-style: solid;
        border-color: #c6c7c3;

        .title-icon.hover-appointement {
          display: none;
        }

        &:hover {
          background-color: #f1f7ef;
          color: #006d3a;
          cursor: pointer;

          .title-icon.hover-appointement {
            display: flex;
          }
          .title-icon.initial {
            display: none;
          }
        }
        .dayName {
          font-family: "Work Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #1a1c1a;
        }
        .dayDate {
          font-family: "Work Sans", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #1a1c1a;
        }

        .title-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #006d3a;
        }
        .icon-plus {
          width: 16px;
          height: 16px;
          margin-bottom: 3px;
        }
      }
      .week {
        min-width: 163.8px;
      }
    }

    .fullScreen {
      width: 100vw;
    }

    .overflow-width {
      width: 100vw;
    }
    .gridAppointments {
      display: flex;
      overflow: hidden;
      overflow-y: scroll;

      .CompAppointmentCard {
        height: 100%;

        .appointemntFocus {
          background-color: #ffffff;
        }
        .no-appointment {
          display: flex;
          width: 100%;
          min-width: 163.8px;
          height: 79px !important;
          border-width: 0px 1px 1px 0px;
          border-style: solid;
          border-color: #c6c7c3;
        }
      }
    }
  }
  .timeAppointment {
    width: 100%;
    display: flex;
    box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    div {
      width: 100%;
      display: flex;
      justify-content: center;
      .timeAppointment {
        font-family: "Work Sans", sans-serif;
        text-align: center;
        font-weight: 600;
        padding: 5.5px 0;
        color: #5c5f5b;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
      }
      .week {
        min-width: 163.8px;
        width: 163.8px;
      }
    }
  }
</style>
