<template>
  <div>
    <DraggableDestiny
      v-if="forceRenderTreeComponent"
      :list="list"
      :value-selected="value"
      @input="alterValue"
      @reloadTreeComponent="reloadTreeComponent"
      @forceRenderTreeComponentsToggle="forceRenderTreeComponentsToggle"
    />
  </div>
</template>

<script>
  import DraggableDestiny from "./DraggableDestiny.vue";
  export default {
    data() {
      return {
        value: null,
        forceRenderTreeComponent: true
      };
    },
    props: ["list", "destiny"],
    components: {
      DraggableDestiny
    },
    methods: {
      alterValue(value) {
        this.value = value;
        this.$emit("input", value);
      },
      reloadTreeComponent() {
        this.forceRenderTreeComponent = false;
        this.$nextTick().then(() => {
          this.forceRenderTreeComponent = true;
        });
      },
      forceRenderTreeComponentsToggle() {
        let el = document.querySelector(".content");
        this.scrollPos = el.scrollTop;
        this.reloadTreeComponent();
        setTimeout(() => {
          el.scrollTo(0, this.scrollPos);
        }, 10);
      }
    },
    watch: {
      destiny() {
        this.value = this.destiny;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
