<template>
  <div class="geralDiv">
    <div class="headerAppointmentComp">
      <div class="appointmentTitle">
        <h1>Apontamento</h1>
      </div>
      <div
        v-b-tooltip.hover="{
          title: `Não é possível alterar o dia para apontamentos realizados pelo temporizador`,
          disabled: !isDatepickerDisabled,
          customClass: 'datepicker-disabled-tooltip'
        }"
        class="datePicker"
      >
        <Datepicker
          :value.sync="formAppointment.dateValue"
          :disabled="isDatepickerDisabled"
        >
          <template v-slot:trigger="{ triggerDatepicker }">
            <div
              :class="isDatepickerDisabled && 'datepicker-disabled'"
              @click="triggerDatepicker"
            >
              <span class="textDatePicker">{{ formataDiaMes(formAppointment.dateValue) }}</span>
              <span style="margin-right: 16px"><IconArrowDown /></span>
            </div>
          </template>
        </Datepicker>
      </div>
    </div>
    <div class="formAppointmentComp">
      <div style="margin-right: 2px">
        <div style="height: 65px">
          <p class="titleForm">Projeto <span class="mandatory">*</span></p>
          <div class="formSelect">
            <SelectSearch
              :value="formAppointment.projectValue"
              :close-button="false"
              :options="projectOptions"
              :v="$v.formAppointment.projectValue"
              @optionSelectedId="formAppointment.projectValue = $event"
            />
          </div>
        </div>
        <div
          v-show="formAppointment.projectValue != '' && funcionalityOptions.length > 0"
          style="height: 65px"
        >
          <p class="titleForm">
            Funcionalidade
            <span
              v-show="
                formAppointment.funcionalityStage != 8 &&
                formAppointment.funcionalityStage != 9 &&
                stepControl
              "
              class="mandatory"
              >*</span
            >
          </p>
          <div class="formSelect">
            <SelectSearch
              :value="formAppointment.funcionalityValue"
              :close-button="true"
              :options="funcionalityOptions"
              :v="$v.formAppointment.funcionalityValue"
              @optionSelectedId="formAppointment.funcionalityValue = $event"
            />
          </div>
        </div>
        <div style="height: 65px">
          <p class="titleForm">
            Etapa da funcionalidade
            <span
              v-show="stepControl"
              class="mandatory"
              >*</span
            >
          </p>
          <div class="formSelect">
            <SelectSearch
              :value="formAppointment.funcionalityStage"
              :close-button="true"
              :options="funcionalityStagesOptions"
              :v="$v.formAppointment.funcionalityStage"
              @optionSelectedId="formAppointment.funcionalityStage = $event"
            />
          </div>
        </div>
        <div style="height: 65px">
          <p class="titleForm">Tarefa <span class="mandatory">*</span></p>
          <div class="formSelect">
            <SelectSearch
              :value="formAppointment.taskValue"
              :close-button="false"
              :options="taskOptions"
              :v="$v.formAppointment.taskValue"
              @optionSelectedId="formAppointment.taskValue = $event"
            />
          </div>
        </div>
        <div style="height: 65px">
          <p class="titleForm">Tipo do item</p>
          <div class="appointmentItemType">
            <div class="formSelect">
              <SelectSearch
                class="itemTypeField"
                :value="formAppointment.itemTypeValue"
                :close-button="true"
                :options="itemTypeOptions"
                :v="$v.formAppointment.itemTypeValue"
                @optionSelectedId="formAppointment.itemTypeValue = $event"
              />
            </div>
            <div>
              <InputAppoint
                v-model="formAppointment.itemTypeId"
                :value="formAppointment.itemTypeId || null"
                placeholder="Digite aqui"
                :v="$v.formAppointment.itemTypeId"
              />
            </div>
          </div>
        </div>
        <div style="height: 67px">
          <p class="titleForm">Hora <span class="mandatory">*</span></p>
          <div>
            <div>
              <InputRangeHour
                :id="editTaskId"
                v-model="formAppointment.hour"
                :value="formAppointment.hour"
                :v="$v.formAppointment.hour"
                :touched="touchedAll"
                :appointments-tasks="appointmentsTasks"
                :current-date="formAppointment.dateValue"
                :check-future-time="checkFutureTime"
              />
            </div>
          </div>
        </div>
        <div class="descDiv">
          <p class="titleForm">Descrição <span class="mandatory">*</span></p>
          <div class="descAppoint">
            <TextareaAppoint
              v-model="formAppointment.description"
              :value="formAppointment.description"
              :placeholder="'Descrição sucinta'"
              :v="$v.formAppointment.description"
            />
          </div>
        </div>
      </div>
      <div class="nonconformityTitle">
        <h2>Inconformidade</h2>
        <div class="nonconformityLine"></div>
      </div>
      <div class="nonconformityDiv">
        <div style="height: 65px">
          <p class="titleForm">Origem</p>
          <div class="formSelect">
            <SelectSearch
              :value="formAppointment.originValue"
              :close-button="true"
              :options="originOptions"
              :v="$v.formAppointment.originValue"
              @optionSelectedId="formAppointment.originValue = $event"
              @cleanDescription="cleanDescription"
            />
          </div>
        </div>
        <div
          v-show="formAppointment.originValue != null && !isNaN(formAppointment.originValue)"
          class="descDiv"
        >
          <p class="titleForm">Descrição <span class="mandatory">*</span></p>
          <TextareaAppoint
            v-model="formAppointment.descriptionNonConformity"
            :placeholder="'Descrição sucinta'"
            :v="$v.formAppointment.descriptionNonConformity"
          />
        </div>
        <div v-show="formAppointment.originValue != null && !isNaN(formAppointment.originValue)">
          <p class="titleForm">Impacto <span class="mandatory">*</span></p>
          <div
            class="formSelect"
            style="margin-bottom: 0"
          >
            <SelectSearch
              :value="formAppointment.selectImpact"
              :close-button="false"
              :options="impactOptions"
              :v="$v.formAppointment.selectImpact"
              @optionSelectedId="formAppointment.selectImpact = $event"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="formButtons">
      <b-button
        :class="!showDiscardbutton ? 'discardButton disable' : 'discardButton'"
        :disabled="!showDiscardbutton"
        @click="discardAppointment"
        ><IconClose class="icon-close" />Cancelar</b-button
      >
      <b-button
        :disabled="disableSave"
        variant="success"
        :class="'saveButton'"
        @click="saveAppointment()"
        ><IconSave /> Salvar</b-button
      >
    </div>
  </div>
</template>

<script>
  import IconClose from "@/assets/images/icon-close.svg";
  import IconSave from "@/assets/images/icon-save.svg";
  import IconArrowDown from "@/assets/images/icon-arrow-down-rounded.svg";
  import TextareaAppoint from "./TextareaAppoint.vue";
  import InputRangeHour from "@/components/InputRangeHour.vue";
  import SelectSearch from "@/components/SelectSearch.vue";

  import InputAppoint from "@/components/InputAppoint.vue";
  import { mapActions, mapState, mapMutations } from "vuex";

  import { required, requiredIf } from "vuelidate/lib/validators";

  import { format, getMinutes, getHours } from "date-fns";
  import ptBR from "date-fns/locale/pt-BR";

  import Datepicker from "@/components/DatePicker.vue";

  export default {
    props: ["timer", "actionModal", "taskIdEdit", "timerAppointInfo", "createTask", "closeModal"],
    data() {
      return {
        isDatepickerDisabled: this.timerAppointInfo,
        action: "",
        formAppointment: {
          projectValue: "",
          funcionalityValue: "",
          funcionalityStage: null,
          taskValue: "",
          itemTypeValue: null,
          itemTypeId: null,
          hour: null,
          description: "",
          originValue: null,
          descriptionNonConformity: "",
          selectImpact: "",
          dateValue: new Date(),
          appointmentTypeId: 1
        },
        formAppointmentReset: {
          projectValue: "",
          funcionalityValue: "",
          funcionalityStage: null,
          taskValue: "",
          itemTypeValue: null,
          itemTypeId: null,
          hour: null,
          description: "",
          originValue: null,
          descriptionNonConformity: "",
          selectImpact: "",
          dateValue: new Date(),
          appointmentTypeId: 1
        },
        editTaskId: null,
        formAppointmentStart: {},
        placeholder: "Selecione",
        isDisabled: true,
        projectOptions: [],
        funcionalityOptions: [],
        funcionalityStagesOptions: [],
        taskOptions: [],
        itemTypeOptions: [],
        originOptions: [],
        impactOptions: [],
        touchedAll: false,
        errorSave: false,
        appointmentsTasks: null,
        funcionalityStagesOptionsStart: null
      };
    },
    validations() {
      return {
        formAppointment: {
          projectValue: {
            required
          },
          funcionalityValue: {
            required: requiredIf(function () {
              let funcionalityStage = this.formAppointment.funcionalityStage;
              return (
                funcionalityStage != 8 &&
                funcionalityStage != 9 &&
                this.funcionalityOptions.length > 0 &&
                this.stepControl
              );
            })
          },
          funcionalityStage: {
            required: requiredIf(function () {
              return this.stepControl;
            })
          },
          taskValue: {
            required
          },
          itemTypeValue: {},
          itemTypeId: {
            required: requiredIf(function () {
              let itemTypeValue = this.formAppointment.itemTypeValue;
              return itemTypeValue != null && itemTypeValue != "" && !isNaN(itemTypeValue);
            })
          },
          description: {
            required
          },
          originValue: {},
          selectImpact: {
            required: requiredIf(function () {
              let originValue = this.formAppointment.originValue;
              return originValue != null && originValue != "" && !isNaN(originValue);
            })
          },
          descriptionNonConformity: {
            required: requiredIf(function () {
              let originValue = this.formAppointment.originValue;
              return originValue != null && originValue != "" && !isNaN(originValue);
            })
          }
        }
      };
    },
    components: {
      IconArrowDown,
      Datepicker,
      IconSave,
      InputAppoint,
      TextareaAppoint,
      InputRangeHour,
      SelectSearch,
      IconClose
    },
    computed: {
      ...mapState("Appointments", {
        isEdit: "isEdit",
        fromEditButton: "fromEditButton",
        tempStartTime: "tempStartTime",
        errorAppoint: "errorAppoint",
        cardProjectId: "cardProjectId",
        cardFunctionalityId: "cardFunctionalityId",
        stepControl: "stepControl"
      }),
      ...mapState("CompFeat", ["isTimerAppoint"]),
      disableSave: function () {
        if (this.stepControl == true && this.formAppointment.funcionalityStage == null) {
          return true;
        }
        if (
          this.formAppointment.funcionalityStage != 8 &&
          this.formAppointment.funcionalityStage != 9 &&
          this.stepControl == true &&
          (this.formAppointment.funcionalityValue == "" ||
            this.formAppointment.funcionalityValue == null) &&
          this.funcionalityOptions.length > 0
        ) {
          return true;
        }
        if (this.errorAppoint) {
          return true;
        }
        if (this.checkFutureTime(this.formAppointment.dateValue, this.formAppointment.hour)) {
          return true;
        }
        if (!this.formAppointment.itemTypeId && this.formAppointment.itemTypeValue) {
          return true;
        }
        if (
          (!this.formAppointment.descriptionNonConformity || !this.formAppointment.selectImpact) &&
          this.formAppointment.originValue
        ) {
          return true;
        }
        if (JSON.stringify(this.formAppointment) == JSON.stringify(this.formAppointmentStart)) {
          return true;
        }
        if (Object.keys(this.formAppointmentStart).length == 0) {
          return true;
        }
        if (
          !this.formAppointment.projectValue ||
          !this.formAppointment.taskValue ||
          !this.formAppointment.hour ||
          !this.formAppointment.description
        ) {
          return true;
        }
        if (this.errorSave) {
          this.disableButtonAfterSave();
          return true;
        }
        return false;
      },
      showDiscardbutton: function () {
        if (Object.keys(this.formAppointmentStart).length == 0) {
          this.setIsEdit(false);
          return false;
        } else if (
          JSON.stringify(this.formAppointment) == JSON.stringify(this.formAppointmentReset)
        ) {
          this.setActionAppoint("new");
          this.setIsEdit(false);
          return false;
        } else if (
          JSON.stringify(this.formAppointmentStart) != JSON.stringify(this.formAppointment)
        ) {
          this.setActionAppoint("edit");
          this.setIsEdit(true);
          return true;
        }
        this.setIsEdit(true);
        return true;
      }
    },
    methods: {
      ...mapActions("Appointments", [
        "ActionPostTask",
        "ActionPutTask",
        "ActionGetTaskDate",
        "ActionGetTask",
        "ActionDeleteTask",
        "ActionContextProject",
        "ActionContextFuncionality",
        "ActionContextTaskType",
        "ActionNonConformityOrigin",
        "ActionNonConformityImpact",
        "ActionAppointmentType",
        "ActionHolidayType",
        "ActionTaskItemTypes",
        "ActionFuncionalityItemType",
        "ActionStopTimer",
        "ActionDeleteTimer",
        "ActionContextStages"
      ]),
      ...mapMutations("Appointments", [
        "setIsEdit",
        "setActionAppoint",
        "setFromEditButton",
        "setTempStartTime",
        "setErrorAppoint",
        "setCardProjectId",
        "setCardFunctionalityId",
        "setStepControl"
      ]),
      ...mapActions("Projects", ["ActionGetProjectTree"]),
      ...mapMutations("CompFeat", ["setIsTimerAppoint"]),
      async deleteCurrentTimer() {
        await this.ActionDeleteTimer();
      },
      disableButtonAfterSave() {
        this.errorSave = !this.errorSave;
      },
      checkFutureTime(current, timeInput) {
        if (timeInput) {
          const today = new Date(current);
          const time = today.setHours(timeInput.startHour, timeInput.startMinute, 0);
          return new Date(time) > new Date();
        }
      },
      cleanDescription() {
        this.formAppointment.descriptionNonConformity = "";
      },
      touchedReset() {
        this.touchedAll = true;
        this.$nextTick().then(() => {
          this.touchedAll = false;
        });
      },
      formataDiaMes(date) {
        return format(new Date(date), "dd MMM", { locale: ptBR });
      },
      loadAppoint() {
        if (this.editTaskId && this.action == "edit") {
          this.formAppointmentStart = {};
          this.ActionGetTask(this.editTaskId).then((response) => {
            this.formAppointment.projectValue = response.projectId;
            this.formAppointment.funcionalityStage = response.funcionalityStageId;
            this.formAppointment.taskValue = response.taskTypeId;
            this.formAppointment.itemTypeValue = response.item.id;
            this.formAppointment.itemTypeId = response.item.typeId;
            this.formAppointment.hour = {
              startHour: response.task.startHour,
              startMinute: response.task.startMinute,
              endHour: response.task.endHour,
              endMinute: response.task.endMinute
            };
            this.formAppointment.description = response.task.description;
            this.formAppointment.originValue = response.nonConformity.originId;
            this.formAppointment.descriptionNonConformity = response.nonConformity.description;
            this.formAppointment.selectImpact = response.nonConformity.impactId;
            let date = new Date(`${response.task.date}T00:00`);
            this.formAppointment.dateValue = date;
            setTimeout(() => {
              this.formAppointment.funcionalityValue = response.funcionalityId || null;
              this.formAppointmentStart = { ...this.formAppointment };
            }, 300);
          });
        } else if (this.editTaskId && this.action == "duplicate") {
          this.formAppointmentStart = {};
          this.ActionGetTask(this.editTaskId).then((response) => {
            this.formAppointment.projectValue = response.projectId;
            this.formAppointment.funcionalityStage = response.funcionalityStageId;
            this.formAppointment.taskValue = response.taskTypeId;
            this.$nextTick(() => {
              this.formAppointment.itemTypeValue = response.item.id;
              this.formAppointment.itemTypeId = response.item.typeId;
            });
            this.formAppointment.hour = null;
            this.formAppointment.description = response.task.description;
            this.formAppointment.originValue = response.nonConformity.originId;
            this.formAppointment.descriptionNonConformity = response.nonConformity.description;
            this.formAppointment.selectImpact = response.nonConformity.impactId;
            setTimeout(() => {
              this.formAppointment.funcionalityValue = response.funcionalityId || null;
              this.formAppointmentStart = { ...this.formAppointment };
            }, 300);
          });
        }
      },
      saveAppointment() {
        this.setErrorAppoint(false);
        this.$v.$touch();
        this.touchedAll = true;
        this.$emit("alterDateAppointmentValue", this.formAppointment.dateValue, () => {
          this.$emit("changeViewWeek", this.formAppointment.dateValue);
        });
        this.isDatepickerDisabled = false;
        this.formAppointment.dateValue = new Date(this.formAppointment.dateValue);
        if (!this.$v.$invalid) {
          let objComplete = {
            id: this.action != "edit" ? null : this.editTaskId,
            projectId: this.formAppointment.projectValue,
            funcionalityStageId: this.formAppointment.funcionalityStage || null,
            funcionalityId: this.formAppointment.funcionalityValue || null,
            taskTypeId: this.formAppointment.taskValue,
            appointmentTypeId: this.formAppointment.appointmentTypeId || 1,
            item: {
              id: this.formAppointment.itemTypeId || null,
              typeId: this.formAppointment.itemTypeValue || null
            },
            task: {
              date: format(this.formAppointment.dateValue, "yyyy-MM-dd"),
              startHour: Number(this.formAppointment.hour.startHour),
              startMinute: Number(this.formAppointment.hour.startMinute),
              endHour: Number(this.formAppointment.hour.endHour),
              endMinute: Number(this.formAppointment.hour.endMinute),
              description: this.formAppointment.description
            },
            nonConformity: {
              originId: this.formAppointment.originValue || null,
              description: this.formAppointment.descriptionNonConformity || null,
              impactId: this.formAppointment.selectImpact || null
            }
          };
          if (this.timer?.current != null) {
            this.$emit("resetTimer");
          }

          if (this.action != "edit") {
            this.ActionPostTask(objComplete)
              .then((response) => {
                if (!response?.errors) {
                  this.$emit("updateGridAppointments", "post");
                }
                this.$v.$reset();
                this.touchedReset();
                this.editTaskId = null;
                this.formAppointment = JSON.parse(JSON.stringify(this.formAppointmentReset));
                this.formAppointmentStart = JSON.parse(JSON.stringify(this.formAppointmentReset));
                this.setActionAppoint("new");
                this.setIsTimerAppoint(false);
                this.setTempStartTime(null);
                this.setStepControl(false);
                this.$emit("update:taskIdEdit", null);
              })
              .catch((error) => {
                this.errorSave = !this.errorSave;
                if (error.response.data.errors[0].id == 87) {
                  this.$swal({
                    customClass: "swal-warning",
                    title: "Dia bloqueado",
                    text: "Não é possível realizar apontamentos para a data selecionada.",
                    confirmButtonText: "Fechar",
                    showCancelButton: false
                  });
                }
                return;
              });
          } else {
            this.ActionPutTask({ id: this.editTaskId, payload: objComplete })
              .then((response) => {
                if (!response?.errors) {
                  this.$emit("updateGridAppointments", "put");
                  this.action = "new";
                }
                this.$emit("update:taskIdEdit", null);
                this.$v.$reset();
                this.touchedReset();
                this.editTaskId = null;
                this.formAppointment = JSON.parse(JSON.stringify(this.formAppointmentReset));
                this.formAppointmentStart = JSON.parse(JSON.stringify(this.formAppointmentReset));
                this.setActionAppoint("new");
                this.setIsTimerAppoint(false);
                this.setTempStartTime(null);
                this.setStepControl(false);
              })
              .catch((_error) => {
                this.errorSave = !this.errorSave;
                return;
              });
          }
        }
      },
      loadInfos() {
        this.ActionContextProject().then((response) => {
          this.projectOptions = response.projects.map((project) => ({
            id: project.id,
            name: project.name,
            functionalityStep: project.functionalityStep
          }));

          this.ActionContextStages().then((response) => {
            this.funcionalityStagesOptions = response.stages.map((stage) => ({
              id: stage.id,
              name: stage.name
            }));
            this.funcionalityStagesOptionsStart = JSON.parse(
              JSON.stringify(this.funcionalityStagesOptions)
            );
          });

          this.ActionContextTaskType()
            .then((response) => {
              this.taskOptions = response.tasksTypes.map((task) => ({
                id: task.id,
                name: task.name
              }));
            })
            .then(() => {
              this.$emit("contextTaskType", this.taskOptions);
            });

          this.ActionTaskItemTypes()
            .then((response) => {
              this.itemTypeOptions = [];
              this.itemTypeOptions = response.tasksItemTypes.map((taskItemType) => ({
                id: taskItemType.id,
                name: taskItemType.name
              }));
            })
            .then(() => {
              this.$emit("contextTaskItemType", this.itemTypeOptions);
            });
          this.ActionNonConformityOrigin()
            .then((response) => {
              this.originOptions = [];
              this.originOptions = response.origins.map((origin) => ({
                id: origin.id,
                name: origin.name
              }));
            })
            .then(() => {
              this.$emit("nonConformityOrigin", this.originOptions);
            });
          this.ActionNonConformityImpact()
            .then((response) => {
              this.impactOptions = response.impacts.map((impact) => ({
                id: impact.id,
                name: impact.name
              }));
            })
            .then(() => {
              this.$emit("nonConformityImpact", this.impactOptions);
            });

          this.loadAppointSave();
        });

        if (this.timerAppointInfo == true && this.timer?.current != null) {
          if (this.timer.hasStartedToday === false) {
            const dateFormat = format(this.timer.startTime, "yyyy-MM-dd");
            this.ActionStopTimer(dateFormat).then((response) => {
              this.updateFormAppointment(response);
            });
          } else {
            this.ActionStopTimer().then((response) => {
              this.updateFormAppointment(response);
            });
          }
        }
      },
      updateFormAppointment(response) {
        this.formAppointment.dateValue = new Date(response.date.replace(/-/g, "/"));
        this.$emit("alterDateAppointmentValue", new Date(response.date.replace(/-/g, "/")));

        // Horário em que o usuário clicou no botão de stop
        const endTime = {
          minute: getMinutes(new Date(this.timer.paused.pausedAt)),
          hour: getHours(new Date(this.timer.paused.pausedAt))
        };

        this.formAppointment.appointmentTypeId = response.appointmentTypeId;
        this.formAppointment.itemTypeId = response.devopsId;
        this.formAppointment.itemTypeValue = response.devopsId ? 1 : null;
        this.formAppointment.hour = {
          startHour: response.startHour,
          startMinute: response.startMinute,
          endHour: endTime.hour,
          endMinute: endTime.minute
        };
        this.formAppointment.projectValue = response.projectId;
        this.formAppointment.funcionalityValue = response.funcionalityId;
        this.editTaskId = response.taskId;

        this.initialform();
      },
      loadFuncionalityInfo() {
        this.funcionalityOptions = [];
        this.ActionContextFuncionality(this.formAppointment.projectValue).then((response) => {
          this.funcionalityOptions = response.funcionalities.map((funcionality) => ({
            id: funcionality.id,
            name: `${
              funcionality.devOpsId
                ? `${funcionality.devOpsId} - ${funcionality.name}`
                : `${funcionality.name}`
            }`
          }));

          this.funcionalityOptions.sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (a.name.toUpperCase() > b.name.toUpperCase()) {
              return 1;
            }
            return 0;
          });
        });
      },
      async initialform() {
        this.formAppointmentStart = JSON.parse(JSON.stringify(this.formAppointment));
      },
      discardAppointment(value) {
        const appointmentIcon = `<svg class="appointment-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.75 10.375H2.25C1.90625 10.375 1.625 10.0938 1.625 9.75V2.25C1.625 1.90625 1.90625 1.625 2.25 1.625H5.375C5.71875 1.625 6 1.34375 6 1C6 0.65625 5.71875 0.375 5.375 0.375H1.625C0.93125 0.375 0.375 0.9375 0.375 1.625V10.375C0.375 11.0625 0.9375 11.625 1.625 11.625H10.375C11.0625 11.625 11.625 11.0625 11.625 10.375V6.625C11.625 6.28125 11.3438 6 11 6C10.6562 6 10.375 6.28125 10.375 6.625V9.75C10.375 10.0938 10.0938 10.375 9.75 10.375ZM7.25 1C7.25 1.34375 7.53125 1.625 7.875 1.625H9.49375L3.7875 7.33125C3.54375 7.575 3.54375 7.96875 3.7875 8.2125C4.03125 8.45625 4.425 8.45625 4.66875 8.2125L10.375 2.50625V4.125C10.375 4.46875 10.6562 4.75 11 4.75C11.3438 4.75 11.625 4.46875 11.625 4.125V1C11.625 0.65625 11.3438 0.375 11 0.375H7.875C7.53125 0.375 7.25 0.65625 7.25 1Z" fill="white"/></svg>`;
        this.$swal({
          customClass: {
            popup: "swal-delete",
            confirmButton: "btn-confirm"
          },
          title: "Descartar",
          confirmButtonText: `${appointmentIcon} Confirmar`,
          text: "Deseja descartar as alterações feitas no apontamento?"
        }).then((result) => {
          if (result.isConfirmed) {
            this.formAppointment = JSON.parse(JSON.stringify(this.formAppointmentReset));
            this.setActionAppoint("new");
            this.setErrorAppoint(false);
            this.$v.$reset();
            this.touchedReset();
            this.setStepControl(false);
            this.isDatepickerDisabled = false;
            if (this.timer?.current != null) {
              this.$emit("resetTimer");
              this.deleteCurrentTimer();
            }
            this.$emit("discardAppointmentCloseModal", false);
            if (!value) {
              this.$emit("closeModalAppointment");
            } else {
              if (value.date) {
                this.$emit("createTaskTg", value);
              } else {
                this.$emit("editTaskTg", value);
              }
            }
          }
        });
      },
      loadItemType() {
        let projectId = this.formAppointment.projectValue;
        let featureid = this.formAppointment.funcionalityValue;
        this.ActionFuncionalityItemType({ projectId, featureid }).then((response) => {
          this.formAppointment.itemTypeId =
            response.item?.id != undefined ? response.item?.id : null;
          this.formAppointment.itemTypeValue =
            response.item?.typeId != undefined ? response.item?.typeId : null;
          if (this.functionalityCardId) {
            this.initialform();
          }
        });
      },
      async loadAppointSave() {
        const parsedDate = format(this.formAppointment.dateValue, "yyyy-MM-dd");

        // Verificar se data está bloqueada
        const response = await this.ActionGetTaskDate({
          startDate: parsedDate,
          endDate: parsedDate
        });
        this.appointmentsTasks = response;
      },
      removeCardId() {
        this.setCardProjectId(null);
        this.setCardFunctionalityId(null);
      }
    },
    watch: {
      action(value) {
        this.$emit("update:actionModal", value);
        this.loadAppoint();
      },
      formAppointment: {
        handler(value) {
          this.$emit("formAppointmentChange", value);
        },
        deep: true // Habilita a observação profunda do objeto para detectar mudanças nas propriedades internas
      },
      formAppointmentStart: {
        handler(value) {
          this.$emit("formAppointmentStartChange", value);
        },
        deep: true // Habilita a observação profunda do objeto para detectar mudanças nas propriedades internas
      },
      closeModal() {
        this.formAppointment.hour = {};
        this.formAppointmentStart.hour = {};
        if (!this.showDiscardbutton) {
          this.discardAppointment();
        } else {
          this.$emit("discardAppointmentCloseModal", true);
        }
      },
      "formAppointment.originValue"() {
        if (this.formAppointment.originValue == null || this.formAppointment.originValue == "") {
          this.formAppointment.selectImpact = null;
          this.formAppointment.descriptionNonConformity = null;
        }
      },
      "formAppointment.hour"() {
        const hour = this.formAppointment.hour;

        if (
          hour?.startHour === "" &&
          hour?.startMinute === "" &&
          !("endHour" in hour) &&
          !("endMinute" in hour)
        ) {
          this.formAppointment.hour = null;
        }
        if (
          hour?.endHour === "" &&
          hour?.endMinute === "" &&
          !("startHour" in hour) &&
          !("startMinute" in hour)
        ) {
          this.formAppointment.hour = null;
        }
        if (
          hour?.startHour === "" &&
          hour?.endHour === "" &&
          hour?.startMinute === "" &&
          hour?.endMinute === ""
        ) {
          this.formAppointment.hour = null;
        }
      },
      "formAppointment.itemTypeValue"() {
        if (this.formAppointment.itemTypeValue == null) {
          this.formAppointment.itemTypeId = null;
        }
      },
      "formAppointment.projectValue"() {
        if (this.cardProjectId || this.cardFunctionalityId) {
          this.loadFuncionalityInfo();
          this.removeCardId();
        } else {
          if (
            this.formAppointment.projectValue != null &&
            this.formAppointment.projectValue != ""
          ) {
            if ((this.action == "edit" || this.action == "duplicate") && this.isEdit) {
              this.formAppointment.funcionalityValue = "";
              this.setIsEdit(false);
              this.loadFuncionalityInfo();
            } else if (
              (this.formAppointment.itemTypeId != null ||
                this.formAppointment.itemTypeValue != null) &&
              this.formAppointment.funcionalityValue == ""
            ) {
              this.removeCardId();
              this.formAppointment.funcionalityValue = "";
              this.loadFuncionalityInfo();
            } else if (this.isTimerAppoint) {
              this.removeCardId();
              this.loadFuncionalityInfo();
            } else {
              this.removeCardId();
              this.formAppointment.itemTypeId = null;
              this.formAppointment.itemTypeValue = null;
              this.formAppointment.funcionalityValue = "";
              this.loadFuncionalityInfo();
            }
          } else {
            this.funcionalityOptions = [];
          }
        }
      },
      actionModal() {
        this.action = this.actionModal;
      },
      taskIdEdit(value) {
        this.editTaskId = value;
        this.loadAppoint();
      },
      async "formAppointment.funcionalityValue"() {
        this.funcionalityStagesOptions = JSON.parse(
          JSON.stringify(this.funcionalityStagesOptionsStart)
        );
        if (this.formAppointment.funcionalityStage != null) {
          if (
            (this.formAppointment.funcionalityStage == 8 ||
              this.formAppointment.funcionalityStage == 9) &&
            this.formAppointment.funcionalityValue != "" &&
            this.formAppointment.funcionalityValue != null
          ) {
            this.formAppointment.funcionalityStage = null;
          }
        }
        setTimeout(() => {
          if (
            this.formAppointment.funcionalityValue != "" &&
            this.formAppointment.funcionalityValue != null
          ) {
            this.funcionalityStagesOptions.splice(-2);
          } else {
            this.funcionalityStagesOptions = JSON.parse(
              JSON.stringify(this.funcionalityStagesOptionsStart)
            );
          }
        }, 600);
        if (this.fromEditButton) {
          this.setFromEditButton(false);
        } else {
          if (
            this.formAppointment.funcionalityValue == null ||
            this.formAppointment.funcionalityValue == ""
          ) {
            this.formAppointment.itemTypeValue = null;
            this.formAppointment.itemTypeId = null;
          } else {
            this.loadItemType();
          }
        }
      },
      async "formAppointment.dateValue"(currentDate, _) {
        const parsedDate = format(currentDate, "MM-dd-yyyy");

        // Verificar se data está bloqueada
        const response = await this.ActionGetTaskDate({
          startDate: parsedDate,
          endDate: parsedDate
        });
        this.appointmentsTasks = response;
        this.$v.$reset();
        this.touchedReset();
        const editable = response?.period?.[0]?.canEdit === true || false;

        this.formAppointmentReset = {
          ...this.formAppointmentReset,
          dateValue: this.formAppointment.dateValue
        };

        if (editable) {
          this.$emit("alterDateAppointmentValue", new Date(this.formAppointment.dateValue));
        }
      },
      createTask() {
        if (this.createTask !== null) {
          let infoCreateTasks = this.createTask;
          this.formAppointment.projectValue = "";
          this.formAppointment.funcionalityValue = "";
          this.formAppointment.taskValue = "";
          this.formAppointment.itemTypeValue = null;
          this.formAppointment.itemTypeId = null;
          this.formAppointment.description = "";
          this.formAppointment.originValue = null;
          this.formAppointment.descriptionNonConformity = "";
          this.formAppointment.selectImpact = "";
          this.$v.$reset();
          this.touchedReset();
          this.formAppointment.dateValue = new Date(infoCreateTasks.date);
          if (infoCreateTasks.hour) {
            this.formAppointment.hour = {
              startHour: infoCreateTasks.hour.start.split(":")[0],
              startMinute: infoCreateTasks.hour.start.split(":")[1],
              endHour: infoCreateTasks.hour.end.split(":")[0],
              endMinute: infoCreateTasks.hour.end.split(":")[1]
            };
          } else {
            this.formAppointment.hour = null;
          }
          this.formAppointmentStart = JSON.parse(JSON.stringify(this.formAppointment));
        }
      }
    },
    async mounted() {
      this.loadInfos();
      if (!this.isTimerAppoint) {
        this.initialform();
      }
      this.action = this.actionModal;
      if (this.cardFunctionalityId || this.cardProjectId) {
        if (!this.cardFunctionalityId) {
          this.formAppointment.projectValue = this.cardProjectId;
          this.loadFuncionalityInfo();
        } else {
          this.formAppointment.projectValue = this.cardProjectId;
          this.formAppointment.funcionalityValue = this.cardFunctionalityId;
          this.loadFuncionalityInfo();
          this.loadItemType();
        }
      }
    },
    updated() {}
  };
</script>

<style lang="scss">
  .datepicker-disabled-tooltip {
    width: 303px !important;
    .tooltip-inner {
      font-family: "Work Sans" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
  .datepicker-disabled {
    cursor: default !important;
    span {
      color: #989899 !important;
      svg path {
        fill: #989899 !important;
      }
    }
  }
  .btn-confirm {
    .appointment-icon {
      margin-right: 6px;
      margin-bottom: 3px;
    }
    &:hover {
      .appointment-icon {
        path {
          fill: #006d3a;
        }
      }
    }
  }
  .swal2-html-container {
    font-family: "Work Sans", sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  .swal2-title {
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
  }

  .itemTypeField {
    span {
      .select-btn {
        border-radius: 3px 0 0 3px !important;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .separator {
    display: flex;
    height: 28px;
    width: 13%;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #b8b8b9;
    border-bottom: 1px solid #b8b8b9;
  }
  .geralDiv {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    .headerAppointmentComp {
      margin: 0 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .datePicker {
        cursor: pointer;
        .textDatePicker {
          margin-right: 6px;
          text-transform: capitalize;
          color: #006d3a;
          font-family: "Work Sans", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
        }
      }
      .appointmentTitle {
        margin-top: 13px;
        margin-bottom: 13px;

        h1 {
          font-family: "Work Sans", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 33px;
        }
      }

      .appointmentDatePicker {
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }

    .formAppointmentComp {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      margin-left: 12px;
      height: 100%;

      .itemTypeSelect {
        height: 28px !important;
      }
    }
    .custom-select {
      width: 100%;
      height: 26px;

      border-radius: 3px;
      border: 1px solid #b8b8b9;
    }
    .titleForm {
      margin-bottom: 4px;

      font-family: "Work Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #3d3d3e;
    }

    .mandatory {
      color: #cf2e3e;
    }

    .appointmentItemType {
      display: flex;

      .formSelect {
        width: 57%;
        .custom-select {
          height: 28px;
          border-radius: 3px 0 0 3px;
        }
      }
      .form-control {
        max-height: 28px;
        border-radius: 0 3px 3px 0;
        border: 1px solid #b8b8b9;
        border-left: none;
        text-align: right;
      }
    }
    .timeForm {
      display: flex;
      justify-content: space-between;
      .inputTime {
        width: 45%;
        height: 28px;
      }
      .inputTime:focus {
        box-shadow: none;
      }
      p {
        font-family: "Work Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
    }

    .descriptionInput {
      margin-bottom: 31px;
      border: 1px solid #b8b8b9;
    }

    .descriptionInput:focus {
      box-shadow: none;
    }

    .nonconformityTitle {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 16px;

      h2 {
        font-family: "Work Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;

        width: 125px;
        height: 22px;
      }
      .nonconformityLine {
        width: 247px;
        height: 1px;
        border-top: 1px solid #c6c7c3;
      }
    }

    .nonconformityDiv {
      .descriptionInput {
        margin-bottom: 16px;
      }
    }
    .descDiv {
      .descTextarea {
        width: 100%;
        height: 120px;
        padding: 10px;
        margin-bottom: 49px;
        resize: none;

        border: 1px solid #b8b8b9;
        border-radius: 3px;
      }
      .descNonConformity {
        margin-block: 18px;
      }

      .descTextarea:focus {
        outline: none;
        border: 1px solid #009d60;
      }
      .counter {
        text-align: end;
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
      }

      .counterRed {
        color: red;
      }

      .descAppoint {
        margin-bottom: 31px;
      }
    }

    .inputItemType:focus {
      box-shadow: none;
    }
    .formButtons {
      display: flex;
      justify-content: flex-end;
      gap: 15px;
      bottom: 0;
      left: 0;
      background-color: #fff;
      padding: 10px;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
        0px 1px 8px rgba(0, 0, 0, 0.2);

      .disable {
        border-radius: 15px !important;
        background: #ebebec !important;
        color: #989899 !important;
        border: none !important;
      }
      .saveButton {
        display: flex;
        align-items: center;
        text-align: center;

        background: #006d3a;
        border-radius: 15px;

        font-family: "Work Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.0025em;
        padding: 7px 14.5px;

        svg {
          width: 15px !important;
          height: 15px !important;

          margin-right: 6px;
          margin-bottom: 2px;
        }
      }
      .disabled {
        background-color: #ebebec;
        border: 1px solid #ebebec;
        color: #989899;

        svg {
          path {
            fill: #989899;
          }
        }
      }
      .saveButton:focus {
        box-shadow: none;
      }

      .discardButton {
        display: flex;
        gap: 6px;
        align-items: center;
        background-color: #fff;
        color: #ef3e4d;
        border: 1px solid #ef3e4d;
        border-radius: 15px;

        font-family: "Work Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.0025em;
      }
      .discardButton:focus {
        box-shadow: none;
      }
    }

    .spanWidthAjust {
      span {
        width: 100% !important;
      }
    }

    .requiredField {
      color: #dc3546;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }
    .impactNonConformity {
      margin-bottom: 65px;
    }
    .hourSeparator {
      display: flex;
      align-items: center;
      padding: 0px 13px;
      border-top: 1px solid #c6c7c3;
      border-bottom: 1px solid #c6c7c3;
    }
    .icon-close {
      width: 15px;
      height: 15px;
    }
    .icon-close path {
      fill: #ef3e4d;
    }
  }
</style>
