const state = {
  openComponentDuplicate: null,
  elemToOpen: null,
  isNewSubComponent: false,
  isNewComponent: false,
  isTimerAppoint: false
};

const mutations = {
  setOpenComponentDuplicate(state, newValue) {
    state.openComponentDuplicate = newValue;
  },
  setElemToOpen(state, newValue) {
    state.elemToOpen = newValue;
  },
  setIsNewSubComponent(state, newValue) {
    state.isNewSubComponent = newValue;
  },
  setIsNewComponent(state, newValue) {
    state.isNewComponent = newValue;
  },
  setIsTimerAppoint(state, newValue) {
    state.isTimerAppoint = newValue;
  }
};

export default {
  state,
  mutations,
  namespaced: true
};
