var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"config-comp"},[_c('div',{staticClass:"component-settings"},[_c('div',{staticClass:"general"},[_c('div',{staticClass:"top"},[_c('IconGear',{staticClass:"top-icon"}),_c('p',{staticClass:"title"},[_vm._v("Configurações do Componente")])],1),(_vm.forceRenderComponent)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveComponent.apply(null, arguments)}}},[_c('div',{staticClass:"feature-list row"},[_c('div',{staticClass:"item-component"},[_c('div',{staticClass:"col-sm-4"},[_c('IconArrowDown',{staticClass:"icon"}),_c('span',{staticClass:"require-comp"}),_c('label',[_vm._v("Tipo do Componente")])],1),_c('div',{staticClass:"col-sm-8"},[_c('InputSelect',{class:[!_vm.componentSelectTypeId && 'valueNull'],attrs:{"mandatory":true,"disabled":_vm.action == 'edit'},model:{value:(_vm.componentSelectTypeId),callback:function ($$v) {_vm.componentSelectTypeId=$$v},expression:"componentSelectTypeId"}},[_c('option',{attrs:{"value":"","hidden":""}},[_vm._v(" Vazio ")]),(_vm.staticComponent !== null && _vm.action == 'edit')?_c('option',{domProps:{"value":_vm.staticComponent.id}},[_vm._v(" "+_vm._s(_vm.staticComponent.name)+" ")]):_vm._e(),_vm._l((_vm.action == 'new' ? _vm.listComponentTypes : _vm.listAllTypes),function(type,index){return _c('option',{key:'componentSelectTypeId' + index,domProps:{"value":type.id}},[_vm._v(" "+_vm._s(type.name)+" ")])})],2)],1)]),(_vm.componentSelectTypeId == null)?_c('div',{staticClass:"new-component"},[_c('div',{staticClass:"info-new-component"},[_c('ImageNewComponent',{staticClass:"image-new-component"}),_c('h6',[_vm._v("Selecione o tipo de componente para configurar as suas propriedades.")])],1)]):_vm._e(),(
              ((_vm.componentSelectTypeId != null && _vm.action == 'new') || _vm.action == 'edit') &&
              _vm.listProperties
            )?_c('div',_vm._l((_vm.listProperties),function(item,index){return _c('div',{key:'item-' + index,staticClass:"item-component"},[(!item.multiple)?_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"container-label-propertie",attrs:{"id":'label-propertie-' + index},on:{"mouseover":function($event){return _vm.visibleTooltipLabelPropertie(index, 'over')},"mouseleave":function($event){return _vm.visibleTooltipLabelPropertie(index, 'leave')}}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.click",value:({
                      title: item.description,
                      html: true,
                      placement: 'topright',
                      customClass: 'tooltip-lg',
                      id: 'tooltip-label-propertie-' + index
                    }),expression:"{\n                      title: item.description,\n                      html: true,\n                      placement: 'topright',\n                      customClass: 'tooltip-lg',\n                      id: 'tooltip-label-propertie-' + index\n                    }",modifiers:{"click":true}}],staticClass:"div-icon"},[(_vm.propertySelect(item.id))?_c('IconArrowDown',{staticClass:"icon"}):_c('IconTextA',{staticClass:"icon"})],1),_c('span',{class:_vm.checkMandatory(item, _vm.listPropertiesStart[index])
                        ? 'require-comp'
                        : 'span-width-comp'}),_c('label',[_vm._v(_vm._s(item.name))])])]):_vm._e(),(!item.multiple)?_c('div',{staticClass:"col-sm-8"},[(_vm.propertySelect(item.id))?_c('InputSelect',{class:[!item.propertyValueId && 'valueNull'],attrs:{"id":'select-' + item.id,"touched-form":_vm.touchedForm,"mandatory":_vm.checkMandatory(item, _vm.listPropertiesStart[index])},on:{"cleanField":function($event){item.propertyValueId = null}},model:{value:(item.propertyValueId),callback:function ($$v) {_vm.$set(item, "propertyValueId", $$v)},expression:"item.propertyValueId"}},[_c('option',{staticClass:"empty-select",attrs:{"value":"","hidden":"","selected":""}},[_vm._v(" Vazio ")]),_vm._l((_vm.listPropertiesValue.filter(
                      function (property) { return property.propertyId == item.id; }
                    )),function(value,optionIndex){return _c('option',{key:'componentValue' + optionIndex,domProps:{"value":value.id}},[_vm._v(" "+_vm._s(value.value)+" ")])})],2):_c('QuillEditor',{attrs:{"id":("item-component-" + index),"allow-style":item.allowStyle,"close-quill":_vm.closeQuill,"touched-form":_vm.touchedForm,"accept-enter":item.name !== 'Nome',"mandatory":_vm.checkMandatory(item, _vm.listPropertiesStart[index])},on:{"QuillFocus":_vm.quillFocus},model:{value:(item.value[0]),callback:function ($$v) {_vm.$set(item.value, 0, $$v)},expression:"item.value[0]"}})],1):_c('div',{staticClass:"extra-config col-sm-12"},[_c('div',{staticClass:"extra-content"},[_c('div',{staticClass:"opening container-label-propertie",on:{"mouseover":function($event){return _vm.visibleTooltipLabelPropertie(index, 'over')},"mouseleave":function($event){return _vm.visibleTooltipLabelPropertie(index, 'leave')}}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.click",value:({
                        title: item.description,
                        html: true,
                        placement: 'topright',
                        customClass: 'tooltip-lg',
                        id: 'tooltip-label-propertie-' + index
                      }),expression:"{\n                        title: item.description,\n                        html: true,\n                        placement: 'topright',\n                        customClass: 'tooltip-lg',\n                        id: 'tooltip-label-propertie-' + index\n                      }",modifiers:{"click":true}}],staticClass:"div-icon"},[_c('IconDirections',{staticClass:"icon"}),(_vm.checkMandatory(item, _vm.listPropertiesStart[index]))?_c('span',{staticClass:"require-comp"}):_vm._e()],1),_c('label',[_vm._v(_vm._s(item.name))]),(
                        _vm.checkMandatory(item, _vm.listPropertiesStart[index]) &&
                        _vm.touchedForm &&
                        item.value.length === 0
                      )?_c('div',{staticClass:"div-multiple-error"},[_c('IconDangerous',{staticClass:"icon-error"}),_c('p',[_vm._v("Campo Obrigatório")])],1):_vm._e()]),_c('div',{staticClass:"extra-fields-container"},_vm._l((item.value),function(value,valueIndex){return _c('div',{key:'item-' + valueIndex,staticClass:"extra-description"},[_c('div',{staticClass:"buttons-order-container"},[_c('div',{class:("btn-order " + (valueIndex == 0 ? 'hidden-button-oreder' : '')),on:{"click":function($event){return _vm.orderUp(index, valueIndex)}}},[_c('IconOrderUp')],1),_c('div',{class:("btn-order " + (valueIndex == item.value.length - 1 ? 'hidden-button-oreder' : '')),on:{"click":function($event){return _vm.orderDown(index, valueIndex)}}},[_c('IconOrderDown')],1)]),_c('div',{staticClass:"icon-label"},[_c('label',{staticClass:"number"},[_c('span',[_vm._v(_vm._s(valueIndex + 1))]),_vm._v("°")]),(valueIndex != 0)?_c('button',{staticClass:"btn-trash",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeExtraField(index, valueIndex)}}},[_c('IconTrash')],1):_vm._e()]),_c('QuillEditor',{attrs:{"id":("extra-description-" + (item.name) + "-" + valueIndex),"allow-style":item.allowStyle,"close-quill":_vm.closeQuill,"touched-form":_vm.touchedForm,"mandatory":_vm.checkMandatory(item, _vm.listPropertiesStart[index]),"force-mandatory":_vm.checkMandatory(item, _vm.listPropertiesStart[index]) &&
                          item.value.length > 1},on:{"QuillFocus":_vm.quillFocus},model:{value:(item.value[valueIndex]),callback:function ($$v) {_vm.$set(item.value, valueIndex, $$v)},expression:"item.value[valueIndex]"}})],1)}),0),_c('div',{staticClass:"area-add-button"},[_c('button',{staticClass:"btn btn-add",attrs:{"type":"button"},on:{"click":function($event){return item.value.push('')}}},[_c('IconPlus'),_vm._v(" "+_vm._s(item.name)+" ")],1)])])])])}),0):_vm._e()]),_c('div',{staticClass:"buttons"},[(_vm.action == 'new')?_c('button',{staticClass:"btn btn-cancel",attrs:{"type":"button"},on:{"click":_vm.cancelComponent}},[_c('IconClose',{staticClass:"icon-button"}),_vm._v(" Cancelar ")],1):_vm._e(),(_vm.action == 'edit')?_c('button',{staticClass:"btn btn-cancel",attrs:{"type":"button"},on:{"click":_vm.deleteComponent}},[_c('IconTrash',{staticClass:"icon-button"}),_vm._v(" Apagar ")],1):_vm._e(),(_vm.action == 'edit')?_c('button',{staticClass:"btn btn-discard",attrs:{"type":"button","disabled":!_vm.alterInfo},on:{"click":_vm.discardChanges}},[_c('IconHistory',{staticClass:"icon-button"}),_vm._v(" Descartar Alterações ")],1):_vm._e(),_c('button',{staticClass:"btn btn-save",attrs:{"disabled":!_vm.alterInfo,"type":"submit"}},[_c('IconSave',{staticClass:"icon-button"}),_vm._v(" Salvar ")],1)])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }