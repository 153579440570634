<template>
  <PopOver>
    <template v-slot:trigger>
      <IconChart
        class="popover__title"
        style="cursor: pointer"
      />
    </template>
    <template v-slot:title>
      <div class="title">
        <IconChart class="iconChartBlack" />
        <p>
          Backlog: <span>{{ projectIndicators.total }}</span>
        </p>
      </div>
    </template>
    <template v-slot:content>
      <div class="row row-chart">
        <div class="bar-container">
          <div
            class="bar val-done"
            :style="`flex-basis: ${(100 * projectIndicators.done) / projectIndicators.total}%`"
          ></div>
          <div
            class="bar val-open"
            :style="`flex-basis: ${(100 * projectIndicators.open) / projectIndicators.total}%`"
          ></div>
          <div
            class="bar val-notStarted"
            :style="`flex-basis: ${
              (100 * projectIndicators.notStarted) / projectIndicators.total
            }%`"
          ></div>
        </div>
      </div>
      <div class="bar-indicator-div">
        <div class="indicartorBar-done"></div>
        <div class="indicator-div-done">
          <div class="done-indicator">
            <p>Concluídas</p>
          </div>
          <div class="number-result">
            <span>{{ projectIndicators.done }}</span>
          </div>
        </div>

        <div class="indicartorBar-open"></div>
        <div class="indicator-div-open">
          <div class="done-indicator">
            <p>Em Aberto</p>
          </div>
          <div class="number-result">
            <span>{{ projectIndicators.open }}</span>
          </div>
        </div>

        <div class="indicartorBar-notStarted"></div>
        <div class="indicator-div-notStarted">
          <div class="done-indicator">
            <p>Não iniciadas</p>
          </div>
          <div class="number-result">
            <span>{{ projectIndicators.notStarted }}</span>
          </div>
        </div>
      </div>
    </template>
  </PopOver>
</template>

<script>
  import IconChart from "@/assets/images/icon-chart.svg";
  import PopOver from "@/components/PopOver.vue";

  export default {
    data() {
      return {
        activeChart: false
      };
    },
    components: {
      PopOver,
      IconChart
    },
    props: {
      projectIndicators: []
    }
  };
</script>

<style lang="scss">
  .title {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-left: 14px;
    margin-bottom: 10px;
    margin-top: 14px;
  }

  .link-func-card-chart {
    margin-left: -10px;
    padding-left: 5px;
    text-decoration: none;
    color: #1a1c1a;

    letter-spacing: 0.5px;
  }

  .cor0 {
    border-left: 2px solid #ffb300;
  }
  .cor1 {
    border-left: 2px solid #42a5f5;
  }
  .cor2 {
    border-left: 2px solid #10b981;
  }

  .indicartorBar-done {
    width: 5px;
    height: 20px;
    border-radius: 40px;
    background-color: #10b981;
  }
  .indicartorBar-open {
    width: 5px;
    height: 20px;
    border-radius: 40px;
    background-color: #ffa000;
  }
  .indicartorBar-notStarted {
    width: 5px;
    height: 20px;
    border-radius: 40px;
    background-color: #41a8ff;
  }

  .done-indicator {
    text-align: initial;
    width: 95px;
    height: 16px;
  }
  .done-indicator p {
    font-size: 12px;
    color: #909094;
  }

  .indicator-div-done {
    display: flex;
    margin-left: 5px;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5px;
  }

  .indicator-div-open {
    display: flex;
    margin-left: 5px;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5px;
  }

  .indicator-div-notStarted {
    display: flex;
    margin-left: 5px;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5px;
  }

  .bar-indicator-div {
    display: flex;
    width: 100%;
    padding: 0 14px 14px 14px;
  }

  /*======================================================================*/
  /*======================================================================*/
  /*======================================================================*/

  .row {
    display: flex;
    align-items: stretch;
  }

  .row .label {
    flex: 0 0 120px;
  }

  .row .bar-container {
    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
  }

  .row .bar {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .val-done {
    background: #10b981;
  }
  .val-open {
    background: #ffb300;
  }
  .val-notStarted {
    background: #42a5f5;
  }

  .row-chart {
    width: 100%;
    height: 10px;
    margin: 0 0 6px 0 !important;
    padding: 0 14px;
  }

  .number-result {
    margin-left: 1px;
    font-weight: 700;
  }

  .iconChartBlack path {
    fill: #5c5f5b;
  }
</style>
